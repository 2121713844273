const initialState={
    loading:false,
    homePageProduct:null,
    error:false
}
export default function(state=initialState, action){
    switch (action.type){
      case 'HOME_PAGE_PRODUCT_REQUEST':
        return { loading:true, homePageProduct:null};

      case 'HOME_PAGE_PRODUCT_SUCCESS':
        return { loading:false, homePageProduct:action.payload};

      case 'HOME_PAGE_PRODUCT_FAILED':
        return { loading:false, error:action.payload};
      default:
        return state;
    }
  };
  