import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  ListGroup,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/message";
// import { LinkContainer } from "react-router-bootstrap";
import { profile, updateUserProfile } from "../actions/actionUser";
import {
  USER_UPDATE_PROFILE_RESET,
} from "../constants/userLoginConstants";
import { mineOrder } from "../actions/orderAction";
import {MediumButtonStyle } from "../components/ButtonStyle";

function Profile({ history }) {
  const [username, setUsername] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");

  const dispatch = useDispatch();

  //const redirect = location.search ? location.search.split("=")[1] : "/";

  const userProfile = useSelector((state) => state.userProfile);
  const { loading, error, user } = userProfile;

  const {userInfo=user} = useSelector((state) => state.auth);

  const updateProfile = useSelector((state) => state.userLogin);
  const { success } = updateProfile;

  const orderMine = useSelector((state) => state.orderMine);
  const { loading: loadingOrders, error: errorOrders, orders } = orderMine;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user || !user.username || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(profile("profile"));
        dispatch(mineOrder());
      } else {
        setUsername(user.username);
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setEmail(user.email);
        setRole(user.role);
        setPhone(user.phone);
      }
    }
  }, [dispatch, history, userInfo, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    let user = {
      username: username,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      role: role,
    };
    dispatch(updateUserProfile(user));
  };

  return (
    <Row>
      <Col md={3}>
        <h2>User Profile</h2>
        {error && <Message variant="danger">{error}</Message>}
        <Loader loading={loading}/>

        <Form onSubmit={submitHandler}>
          <Form.Group controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="username"
              placeholder="Enter Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="first_name">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="first_name"
              placeholder="Enter your first name"
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="last_name">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="last_name"
              placeholder="Enter your last name"
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          
          <Form.Group controlId="role">
            <Form.Label>Role</Form.Label>
            <Form.Control
              type="role"
              placeholder="Enter role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="phone">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="phone"
              placeholder="Enter Mobile Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" style={MediumButtonStyle}>
            Update
          </Button>
        </Form>
      </Col>
      <Col md={9}>
        <h2>My Orders</h2>
        {loadingOrders ? (
          <Loader />
        ) : errorOrders ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Table responsive className="table-sm">
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <tr>
                    <td>
                      <th>Order ID #</th>
                      <tr>{order._id}</tr>
                    </td>
                    <td>
                      <th>Order Placed At</th>
                      <tr>{order.createdAt.substring(0, 10)}</tr>
                    </td>
                    {/* <td>
                      <th>Ship to:</th>
                      <tr>{order.shippingAddress.fullname}</tr>
                    </td> */}
                    <td>
                      <th>Amount:</th>
                      <tr>₹ {order.totalPrice}</tr>
                    </td>
                    <td>
                      <th>Order Paid: </th>
                      <tr>
                        {order.isPaid ? (
                          <i
                            className="fab fa-angellist"
                            style={{ color: "green" }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </tr>
                    </td>
                    <td>
                      <th>Order Details:</th>
                      <tr>
                        {/* <LinkContainer to={`/order/${order._id}`}>
                          <div>
                          <Button className="btn-sm" style={ExtraSmallButtonStyle}>Details</Button>
                          </div>
                        </LinkContainer> */}
                      </tr>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="6">
                      <ListGroup variant="flush">
                        {order.orderItems.map((item, index) => (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col md={2}>
                                <Image
                                  src={item.image}
                                  alt={item.name}
                                  fluid
                                  rounded
                                />
                              </Col>
                              <Col>
                                <Link to={`/product/${item.product}`}>
                                  {item.name}
                                </Link>
                                <p>Sold By Seller 1</p>
                              </Col>
                              <Col md={4}>
                                {item.qty} X {item.price} = ₹
                                {(item.qty * item.price).toFixed(2)}
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </td>
                  </tr>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  );
}

export default Profile;
