// payment service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const InitiateOrder=(data)=>{
    return axios.post(`${API_URL}/initpayment`, data,
      {
        headers : authHeader()
      }
      )
    }

const completeOrder=(data)=>{
        console.log("completeOrder :: ", data)
        return axios.post(`${API_URL}/razorpay/status`,
        data,
        {
            headers : authHeader()
        }
        )
    }
  
const exportedObject={
    InitiateOrder,
    completeOrder
  }

export default exportedObject;