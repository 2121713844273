import React, { useEffect, useState } from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { useSelector } from "react-redux";
import GA4 from "./components/GoogleAnalytics4/GA-4";
import "./App.scss";
import Header from "./components/Header";
import Header2 from "./components/Header2";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import ProductScreenWithVariation from "./screens/ProductVariationScreen";
import CartScreen from "./screens/CartScreen";
import PreviousOrder from "./screens/PreviousOrder";
import ShippingScreen from "./screens/ShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import LoginScreen from "./screens/Login";
import Register from "./screens/Register";
import Profile from "./screens/Profile";
import OrderScreen from "./screens/OrderScreen";
import WishlistScreen from "./screens/WishlistScreen";
import ProductCategoryScreen from "./screens/ProductCategoryScreen";
import FarmProductBuyingScreen from "./screens/FarmProductBuyingScreen";
import FarmerDashboard from "./screens/FarmerDashboard";
import VendorDashboard from "./screens/VendorDashboard";
import ConfirmationScreen from "./screens/ConfirmationScreen";
import ResetPasswordScreen from "./screens/ResetPassword";
import ResetPasswordConfirmScreen from "./screens/ResetPasswordConfirm";
import ProductSearchScreen from "./screens/SearchScreen";
import PageNotFound from "./screens/PageNotFound";
import AgriOpScreen from "./screens/AgriOpScreen";
import NutrientPredict from "./screens/MLScreen/NutritionPre/NutrientPredict";
import CropPredict from "./screens/CropPredict";
import DiseasePredict from "./screens/MLScreen/DiseasePre/DiseasePredict";
import PestPredict from "./screens/MLScreen/PestPre/PestPredict";
import VisualSearch from "./screens/VisualMl";
import { VisualResult } from "./screens/VisualResult";
import { NutritionResults } from "./screens/MLScreen/NutritionPre/NutritionResults";
import MainFarmer from "./screens/Registers/Farmer/MainFarmer";
import FarmersViaCWC from "./screens/Registers/FarmersViaCWC/mainpage";
import MainCwc from "./screens/Registers/Cwc/MainCwc";
import MainLogistics from "./screens/Registers/Logistics/MainLogistics";
import MainVendor from "./screens/Registers/Vendor/MainVendor";
import Otp from "./screens/Registers/Otp";
import ChangePass from "./screens/Registers/CreatePass";
import GoToTopButton from "./components/GoToTopButton";
import RegistrationPayment from "./screens/Registers/Payment/RegistrationPayment";
import RegPayment from "./screens/Registers/Payment/RegPaymentSuccess";
import FarmEquiRent from "./screens/FarmEquipRent";
import DiseasePredictionResult from "./screens/MLScreen/DiseasePre/DiseasePredictionResult";
import PestPredictionResult from "./screens/MLScreen/PestPre/PestPredictionResult";
import ScrollToTop from "./components/ScrollToTop";
import SoilTest from "./screens/SoilTest";
import SoilTestResult from "./screens/SoilTestResult";
import RegistrationTerms from "./screens/Registration_Terms";
import MainFpo from "./screens/Registers/Fpo/MainFpo";
import RequireAuth from "./Authentication/RequireAuth";
import MyAccount from "./screens/MyAccount";
import ManageProfile from "./screens/ManageProfile";
import MyAccountLayout from "./screens/MyAccountLayout";


function App(){
  const {user, isLoggedIn} = useSelector((state) => state.auth);
  const [image,setImage] = useState("")
  useEffect(()=>{
    if (isLoggedIn) {
      GA4.loggedInAction(user);
    }
  },[isLoggedIn])
  return (
    <Router>
      <ScrollToTop/>
      <GoToTopButton/>
      <Header/>
      <Header2/>
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<HomeScreen/>}/>
        <Route path="login" element={<LoginScreen/>}/>
        <Route path="register" element={<Register/>}/>
        <Route path="otp" element={<Otp/>}/>
        <Route path="reset_password" element={<ResetPasswordScreen/>}/>
        <Route path="productcategory" element={<ProductCategoryScreen/>}/>
        <Route path="productdetail/:id" element={<ProductScreenWithVariation/>}/>
        <Route path="search" element={<ProductSearchScreen/>}/>

        {/* Authorized routes */}
        {/* e-commerce */}
        <Route element={<RequireAuth/>}>
          <Route path="account" element={<MyAccountLayout/>}>
              <Route index element={<MyAccount/>}/>
              <Route path="manage-profile" element={<ManageProfile/>}/>
          </Route>
          <Route path="cart" element={<CartScreen/>}/>
          <Route path="wishlist" element={<WishlistScreen/>}/>
          <Route path="shipping" element={<ShippingScreen/>}/>
          <Route path="payment" element={<PaymentScreen/>}/>
          <Route path="confirmation" element={<ConfirmationScreen/>}/>
          <Route path="dashboard" element={<FarmerDashboard/>}/>
          <Route path="previous-orders" element={<PreviousOrder/>}/>
          <Route path="order-detail" element={<OrderScreen/>}/>
        </Route>

        {/* ML-AI */}
        <Route element={<RequireAuth/>}>
          <Route path="farmequipmentrental" element={<FarmEquiRent/>}/>
          <Route path="predict-disease" element={<DiseasePredict setImage={setImage}/>}/>
          <Route path="disease-prediction-result" element={<DiseasePredictionResult image={image}/>}/>
          <Route path="pest-predict" element={<PestPredict/>}/>
          <Route path="pest-prediction-result" element={<PestPredictionResult/>}/>
          <Route path="predict-nutrient" element={<NutrientPredict/>}/>
          <Route path="nutrition-result" element={<NutritionResults/>} />
          {/* <Route path="updatefarmproduct/:id" element={<FarmerSell/>}/> */}
          <Route path="AgricultureOutput" element={<AgriOpScreen/>}/>
          {/* <Route path="seller" element={<FarmerSell/>} /> */}
          <Route path="reset_password_confirm/" element={<ResetPasswordConfirmScreen/>}/>
          <Route path="buyingproduct/:category/:catgselect" element={<FarmProductBuyingScreen/>}/>
          <Route path="soiltest" element={<SoilTest/>}/>
          <Route path="soiltestresult" element={<SoilTestResult/>}/>
          {/* <Route path="/predictcrop" element={<CropPredict/>}/> */}
          <Route path="/VisualSearch" element={<VisualSearch/>}/>
          <Route path="/VisualResult" element={<VisualResult/>}/>
        </Route>

        {/* Registration */}
        <Route element={<RequireAuth/>}>
          <Route path="/mainfarmer" element={<MainFarmer/>}/>
          <Route path="/maincoordinator" element={<MainCwc/>}/>
          <Route path="/farmerViaCWC" element={<FarmersViaCWC/>}/>
          <Route path="/mainlogistics" element={<MainLogistics/>}/>
          <Route path="/mainfpo" element={<MainFpo/>}/> 
          <Route path="/mainvendor" element={<MainVendor/>}/>
          <Route path="/registrationpayment" element={<RegistrationPayment/>}/>
          <Route path="/Changepass" element={<ChangePass/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/regpayment" element={<RegPayment/>}/>
          <Route path="/regterms" element={<RegistrationTerms/>}/>
          <Route path="/farmerdashboard" element={<FarmerDashboard/>}/>
          <Route path="/vendordashboard" element={<VendorDashboard/>}/>
        </Route>
        
        {/* Undefined routes */}
        {/*
        <Route path="/PredictPriceResult" element={<PricePredictionResult/>}/>
        <Route path="/AgriService" element={AgriServiceScreen} />
        <Route path="/Invoice" element={Invoice} />
        <Route path="/AllBrowsingHistory" element={AllBrowsingHistory} />
        <Route path="/SavedAddress/:type" element={<SavedAddress/>}/>
        <Route path="/SellingProducts" element={<FarmerSellScreen/>} exact/>
        */}

        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
