export const BackgroundGradientStyle = {
    '--color-1': 'rgba(191, 226, 186, 0.3)',
    '--color-2': 'rgba(92,167,201, 0.17)',
    background: `
      linear-gradient(
        90deg,
        var(--color-1) 33.85%,
        var(--color-2) 88.33%
      )
    `,
    // Unrelated styles:
    textAlign: 'center',
    borderRadius: 0,
    fontFamily: 'Roboto',

}