// Farmer Page 2
import { Button, Form } from "react-bootstrap";
import {
  MediumButtonStyle,
} from "../../../components/ButtonStyle";
import React, { Component } from "react";
import axios from "axios";
import { API_FARMER_DATA_RET } from "../Constants";
import Loader from "../../../components/Loader";
class FarmerReg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      farmerdet: "",
      CWCID: this.props.CWCID,
    };
  }

  saveAndContinue = async (e) => {
    //console.log("save and continue");
    e.preventDefault();
    this.Validation();
    // this.setState({ isLoading: true });
  };

  back = (e) => {
    //console.log("back");
    e.preventDefault();
    this.props.prevStep();
  };

  Validation = () => {
    const reg2 = /^[0-9\b]+$/; //mobile

    if (
      this.props.values._firstname == "" ||
      this.props.values._lastname == "" ||
      reg2.test(this.props.values._mobile) === false ||
      this.props.values._mobile == "" ||
      this.props.values._mobile.length < 10
    ) {
      if (this.props.values._firstname == "") {
        this.props.handleChange(
          "_firstnameErr",
          "first name field can't be empty"
        );
      } else {
        this.props.handleChange("_firstnameErr", "");
      }

      if (this.props.values._lastname == "") {
        this.props.handleChange(
          "_lastnameErr",
          "last name field can't be empty"
        );
      } else {
        this.props.handleChange("_lastnameErr", "");
      }

      //alert part
      if (this.props.values._mobile.length < 10) {
        alert("Mobile Number must be of 10 digits");
      }

      if (reg2.test(this.props.values._mobile) === false) {
        alert("Please Enter a Valid Mobile Number");
      }
      if (this.props.values._mobile == "") {
        this.props.handleChange(
          "_mobileErr",
          "Mobile Number field can't be empty"
        );
      } else {
        this.props.handleChange("_mobileErr", "");
      }
    } else {
      //clearing all error field

      this.props.values._fathersnameErr = "";
      this.props.values._lastnameErr = "";
      this.props.values._mobileErr = "";
      this.setState({ isLoading: false });
      this.props.handle_signup();
    }
  };

  componentDidMount = () => {
    //console.log("this.props.CWCID");
    //console.log(this.state.CWCID);
    axios
      .get(
        `${API_FARMER_DATA_RET}?state=${this.props.values.state.state}&block=${this.props.values.block.block}&village=${this.props.values.village.village_hin}&fname=${this.props.values._firstname}`
      )
      .then((result) => {
        // console.log(result.data)
        result.data.map((res) => {
          this.props.handleChange("_fathersname", res.fathersName);
          this.props.handleChange("_mobile", res.mobileNo.toString());
          this.props.handleChange("_rakba", res.rakba);
          this.props.handleChange("_rakbaunit", res.rakba_unit);
        });
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  render() {
    const { values } = this.props;
    return (
      <view style={styles.farmerinfo}>
        {this.state.isLoading && <Loader />}
        <text style={styles.header}>👨🏼‍🌾 Farmer Registration</text>
        <view>
          <view>
            <Form.Group
              controlId="firstName"
              style={{
                borderRadius: 0,
                marginTop: 20,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                direction: "row",
              }}
            >
              <Form.Label style={{ paddingTop: 5 }}>First Name :</Form.Label>
              <Form.Control
                style={{
                  width: 750,
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  border: 0,
                }}
                type="firstName"
                placeholder="Enter First Name"
                // value={values._firstname}
                defaultValue={this.props.values._firstname}
                onChange={(e) =>
                  this.props.handleChange("_firstname", e.target.value)
                }
              ></Form.Control>
            </Form.Group>
            {<text style={styles.ERR}>{values._firstnameErr}</text>}
          </view>

          <view>
            <Form.Group
              controlId="Last Name"
              style={{
                borderRadius: 0,
                marginTop: 20,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                direction: "row",
              }}
            >
              <Form.Label style={{ paddingTop: 5 }}>Last Name :</Form.Label>
              <Form.Control
                style={{
                  width: 750,
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  border: 0,
                }}
                type="Last Name"
                placeholder="Enter Last Name"
                // value={values._lastname}
                defaultValue={this.props.values._lastname}
                onChange={(e) =>
                  this.props.handleChange("_lastname", e.target.value)
                }
              ></Form.Control>
            </Form.Group>
            {<text style={styles.ERR}>{values._lastnameErr}</text>}
          </view>

          <view>
            <Form.Group
              controlId="Mobile Number"
              style={{
                borderRadius: 0,
                marginTop: 20,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                direction: "row",
              }}
            >
              <Form.Label style={{ paddingTop: 5 }}>Mobile Number :</Form.Label>
              <Form.Control
                style={{
                  width: 750,
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  border: 0,
                }}
                type="numeric"
                placeholder="Enter Mobile number"
                maxLength="10"
                // value={values._mobile}
                defaultValue={this.props.values._mobile}
                onChange={(e) =>
                  this.props.handleChange("_mobile", e.target.value)
                }
              ></Form.Control>
            </Form.Group>
            {<text style={styles.ERR}>{values._mobileErr}</text>}
          </view>
        </view>

        <div
          style={{
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            onClick={this.saveAndContinue}
            style={MediumButtonStyle}
          >
            Save And Continue{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
          </Button>
        </div>
        {/* <text style={{ paddingTop : 30}}> Note: All the fields are mandatory and can't be updated by CWCs once created.</text> */}
        {/* <view>
            {this.state.toastVisible && <ToastDefined message="Data saved..!!!" />}
          </view> */}
      </view>
    );
  }
}

const styles = {
  farmerinfo: {
    padding: 10,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },

  header: {
    marginTop: 50,
    marginBottom: 10,
    textAlign: "center",
    fontSize: 24,
    color: "#000",
    width: 250,
  },
  textinput: {
    alignSelf: "center",
    height: 45,
    color: "#000",
    backgroundColor: "transparent",
  },

  itemborder: {
    width: "100%",
    borderBottomWidth: 2,
    borderBottomColor: "#bfe2ba",
  },

  pickerStyle: {
    height: 60,
    width: "50%",
    color: "#4D4D4D",
    alignSelf: "center",
  },

  background2: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 20,
    height: 40,
  },

  ERR: {
    color: "red",
    marginTop: -5,
    marginBottom: 8,
  },
};

export default FarmerReg;
