import React, { useState,useEffect } from "react";
import {  Grid } from "@mui/material";
import { Carousel, Card  } from 'react-bootstrap';
import "../../style/NutritionResults.css";
import {
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);



export const NutritionResults = () => {
   const location = useLocation()
  // Redirect To PredictNutrient Page
  // const history = useHistory();

  // const redirectToPredictNutrient = () => {
  //   history.push('/predictnutrient'); 
  // };

  // Add  Back to PredictNutrient button css 
  const buttonStyles = {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#4CAF50', // Green color, you can change it to your preferred color
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle box shadow
  };

  const iconStyles = {
    marginRight: '8px', // Adjust the spacing between the text and the icon
  };

  const containerStyles = {
    display: 'flex',
    alignItems: 'center', // Align items vertically in the center
    justifyContent: 'space-between', // Space between the child elements
  };

  // uncomment when you test localdata
  // export const NutritionResults = () => {

    // This is a local data ,In case of no use remove  it 
    const locdata={
      "data": {
          "Fertilizers": {
              "Nitrogen": [
                  "Farmyard manure, Green manures, Biofertilizers (Rhizobium, AzollaAzospirillum, Azotobacter) Castor cake, Neem cake, Urea, Ammonium nitrate, Ammonium sulphate. "
              ],
              "Sulfur": [
                  "Ammonium sulfate, Single superphosphate, Potassium sulfate, gypsum."
              ]
          },
          "HeadNutrient": [
              {
                  "Fullform": "Nitrogen",
                  "Key": "N",
                  "Status": "Low"
              },
              {
                  "Fullform": "Phosphorous",
                  "Key": "P",
                  "Status": "High"
              },
              {
                  "Fullform": "Pottasium",
                  "Key": "K",
                  "Status": "High"
              },
              {
                  "Fullform": "Sulfur",
                  "Key": "S",
                  "Status": "Low"
              },
              {
                  "Fullform": "Zinc",
                  "Key": "Zn",
                  "Status": "High"
              },
              {
                  "Fullform": "Magnesium",
                  "Key": "Mg",
                  "Status": "Good level"
              },
              {
                  "Fullform": "Calcium",
                  "Key": "Ca",
                  "Status": "High"
              },
              {
                  "Fullform": "Boron",
                  "Key": "B",
                  "Status": "High"
              },
              {
                  "Fullform": "Iron",
                  "Key": "Fe",
                  "Status": "High"
              },
              {
                  "Fullform": "Copper",
                  "Key": "Cu",
                  "Status": "High"
              },
              {
                  "Fullform": "Manganese",
                  "Key": "Mn",
                  "Status": "High"
              },
              {
                  "Fullform": "Potential of Hydrogen",
                  "Key": "pH",
                  "Status": "Good level"
              }
          ],
          "Management": {
              "Boron": "Reduce boron-containing amendments or pesticides. Adjust soil pH to the recommended range for boron availability (usually between 5.0 and 7.0).",
              "Calcium": "Reduce calcium-containing amendments or lime application if soil pH is excessively high. Improve soil drainage to prevent calcium buildup.",
              "Copper": "It's essential to address copper toxicity promptly by identifying and mitigating the source of excess copper, whether it's from contaminated soil or excessive copper-based fungicides or pesticides. Soil testing and proper management practices are key to preventing and managing copper toxicity in tomato plants.",
              "Iron": "Adjust soil pH to the recommended range for iron availability (usually between 6.0 and 7.0). Reduce iron-containing amendments if necessary.",
              "Manganese": "Reduce manganese-containing fertilizers. Adjust soil pH to the recommended range for manganese availability (usually between 5.5 and 7.0).",
              "Nitrogen": [
                  "Recovery can be done by top dressing of urea of as recommended on soil test basis. Recovery of deficient plants to applied nitrogen is immediate (days) . Foliar spray of urea 1% twice at weekly interval."
              ],
              "Phosphorous": "Reduce phosphorus-containing fertilizers. Ensure proper soil drainage to prevent phosphorus buildup. Use soil amendments to adjust pH if necessary.",
              "Pottasium": "Reduce potassium-rich fertilizers. Maintain proper soil pH and improve drainage to prevent potassium accumulation. Adjust calcium levels in the soil if needed.",
              "Sulfur": [
                  "Foliar spray of 2% Calcium sulphate twice at weekly intervals, gypsum @ 50 kg/ha."
              ],
              "Zinc": " Reduce zinc-containing fertilizers. Improve soil drainage to prevent zinc accumulation."
          },
          "Symptoms": {
              "Nitrogen": [
                  "When nitrogen is deficient leaves are small and pale green to yellow in color. Symptoms are first seen in the old leaves and gradually progress to new growth. The plant appears thin and upright. With severe deficiency the old leaves become completely yellow or turn brown before dropping from the plant. Stems and veins look purple. Growth is clearly stunted. Sulphur is not as mobile as nitrogen within the plant. For that reason symptoms start on younger leaves and progress to older plant parts.The leaves are stiff and curled downward. They develop an interveinal chlorosis turning yellowish green to yellow. Stems, veins and petioles look purple. Flowers buds turn yellow and fall off. Fruits when formed, remain small."
              ],
              "Sulfur": [
                  "The reddish color on the undersides of the leaves and the leaf stems has a somewhat pinkish tone and is less intense compared to what's seen in nitrogen deficiency. In cases of severe sulfur deficiency, brown lesions or necrotic spots often develop along the leaf stems, and the leaves tend to become more upright and sometimes twisted and brittle.",
                  "The leaves display a general yellowing while still retaining some greenish color, and the veins and leaf stems show a noticeable reddish hue. The visible symptoms of sulfur deficiency resemble the yellowing seen in nitrogen deficiency, but in the case of sulfur deficiency, the yellowing is more uniform across the entire plant, including young leaves. "
              ]
          }
      }
  }
  
  // data from database (actual data)
  const result = location.state.result;
  // console.log("object",result);

  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.down("sm"));

  const GreyBackStyle = {background: 'rgba(217, 217, 217, 0.25)', height: '262px', display: 'flex', justifyContent:'space-around', alignItems: 'center'}
  const TopHeadStyleDesktop = {display:"flex", justifyContent:'center', alignItems:'center', height: '150px', color: 'black'}
  const TopHeadStyleMobile = {display:"flex", justifyContent:'center', alignItems:'center', height: '150px', color: 'black', fontSize: '2rem'}
     

  // for test local data remove it when you use real one
  // const  result = locdata.data
  // console.log(" nutrent result: ",result);
     
  // Split the data into groups of 4 for each slide
  const nutrientGroups = [];
  for (let i = 0; i < result.HeadNutrient.length; i += 4) {
    nutrientGroups.push(result.HeadNutrient.slice(i, i + 4));
  }


  const [selectedNutrient, setSelectedNutrient] = useState('Nitrogen');
  const [clickedCardIndex, setClickedCardIndex] = useState(null);

// Find nutrent name and index 
  const handleCardClick = (nutrent_name,index) => {
    setSelectedNutrient(nutrent_name)
    setClickedCardIndex(index)
    // console.log("object index",selectedNutrient);
  };


  // Find the value associated with the selected nutrient in the data object
  let nutrientDescription = null;
  if (selectedNutrient && result.Symptoms[selectedNutrient]) {
    nutrientDescription = result.Symptoms[selectedNutrient].join(' ');
  }

  // Select all nutrent Fertilizers
  let fertilizersDescription = null;
  if (selectedNutrient && result.Fertilizers[selectedNutrient]) {
    fertilizersDescription = result.Fertilizers[selectedNutrient].join(' ');
  }

  // Select all nutrent Management
  let nutrientValue = null;
  if (result.Management[selectedNutrient]) {
    nutrientValue = Array.isArray(result.Management[selectedNutrient])
      ? result.Management[selectedNutrient].join(' ')
      : result.Management[selectedNutrient];
  }

  // Color for each level 
  const getStatusClass = (status) => {
    switch (status) {
      case 'Low':
        return 'low-status';
      case 'High':
        return 'high-status';
      case 'Good level':
        return 'good-status';
      default:
        return ''; // Default class if status is not recognized
    }
  };

  // Color for each element 
  const getElementColor =(status)=>{
    switch (status) {
      case 'N':
        return 'nitrogen-bg';
      case 'P':
        return 'phosphorus-bg-red';
      case 'K':
        return 'potassium-bg';
      case 'S':
        return 'sulfur-bg';
      case 'Zn':
        return 'zinc-bg';
      case 'Mg':
        return 'magnesium-bg';
      case 'Ca':
        return 'calcium-bg';
      case 'B':
        return 'boron-bg-brown';
        case 'Fe':
          return 'iron-bg';
        case 'Cu':
          return 'copper-bg';
        case 'Mn':
          return 'manganese-bg';
      default:
        return 'defult-color'; // Default class if status is not recognized
    }
}

// reset selected card class
const [resetClickedClassFlag,setResetClickedClassFlag]=useState(0)
// selected card
const [activeIndex, setActiveIndex] = useState(0);
const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
    setResetClickedClassFlag((prevState) => (prevState === 0 ? 1 : 0));
  };

  useEffect(() => {
    // Function to reset the clicked class on cards and add my-class
    const resetClickedClass = () => {
      const cards = document.querySelectorAll('.my-card');
      cards.forEach((card) => {
        card.classList.remove('clicked');
        card.classList.add('my-class');
      });
    };
  
    // Add event listeners to reset the clicked class on various actions
    const carousel = document.querySelector('.my-carousel');
    carousel.addEventListener('click', resetClickedClass);
  
    // Add event listeners to the custom indicators
    const customIndicators = document.querySelector('.custom-carousel-indicators');
    customIndicators.addEventListener('click', (event) => {
      if (event.target.classList.contains('custom-carousel-indicator')) {
        resetClickedClass();
      }
    });
  
    return () => {
      // Remove event listeners when the component unmounts
      carousel.removeEventListener('click', resetClickedClass);
      customIndicators.removeEventListener('click', resetClickedClass);
    };

  }, [resetClickedClassFlag]);

  return (
    <div style={{backgroundColor:'#D6F0FC'}}>
      {result.HeadNutrient && result.HeadNutrient.length > 0 ?
       <>
       {/* Result Heading  */}
       <Grid container justifyContent='center'  >
       <Grid item xs={1} />
       <Grid item xs={10}>
        <div style={containerStyles} >
          <h1 style={xsmall ? TopHeadStyleMobile : TopHeadStyleDesktop}>Nutrient Prediction Result</h1>
          <button style={buttonStyles}
          // onClick={redirectToPredictNutrient}
          > <i className="fas fa-plus" style={iconStyles} ></i>Added Nutrient Value</button>
        </div>
           
       </Grid>
       <Grid item xs={1} />
       </Grid>

        {/* All 12 elements show in carousel */}       
      <div className="custom-carousel-container">
        <Carousel activeIndex={activeIndex} onSelect={handleSelect} indicators={false} interval={null} className="my-carousel">
          {nutrientGroups.map((group, index) => (
            <Carousel.Item key={index}>
              <div className="d-flex justify-content-center inner-carousel">
                {group.map((nutrient, nutrientIndex) => (
                  <Card
                    key={nutrient.Key}
                    className={`my-card ${nutrientIndex === clickedCardIndex ? 'clicked' : ''}`}
                  >
                    <Card.Body onClick={() => handleCardClick(nutrient.Fullform, nutrientIndex, nutrient.Fullform)}>
                      <Card.Text>
                        <span className={`white-text-with-rectangle ${getElementColor(nutrient.Key)}`}>
                          {nutrient.Key}
                        </span>
                      </Card.Text>
                      <Card.Text>{nutrient.Fullform}</Card.Text>
                      <Card.Text className={`status-text ${getStatusClass(nutrient.Status)}`}>{nutrient.Status}</Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        {/* indicators of carousel */}
        <div className="custom-carousel-indicators" >
          {nutrientGroups.map((_, index) => (
            <button
              key={index}
              className={`custom-carousel-indicator ${index === activeIndex ? 'active' : ''}`}
              onClick={() => handleSelect(index)}
            />
          ))}
        </div>
      </div>

        {
          // Whenever any elements are in good level 
          nutrientDescription && nutrientValue && fertilizersDescription ?null
          :(<> {!nutrientDescription && !nutrientValue && !fertilizersDescription && (
            <div className="side-margin">
              <Card style={{ backgroundColor: '#f4f4f4' , marginBottom:20,color:'black'}}>
              <Card.Body>
                <Card.Text>
                  <h6>
                  We apologize, but the data for <b>{selectedNutrient} </b> recommendation is
                  currently unavailable. We are actively working on updating
                  our database and expect to have it resolved soon.
                  Thank you for your patience.
                  {/* "Great news! Your soil's <b>{selectedNutrient} </b> levels are just right for its overall health and well-being." */}
                  </h6>
                </Card.Text>
              </Card.Body>
              </Card>
           </div>
          )}</>)
        }

      {/* Symptoms of all 12 elements */}
      <div className="side-margin">
          {nutrientDescription ? (
            <>
              <Card className="symptom-card">
              <Card.Body>
                <h1>Symptoms</h1>
                <Card.Title> <b>{selectedNutrient}</b> </Card.Title>
                  <Card.Text>{nutrientDescription}</Card.Text>
              </Card.Body>
            </Card>
            </>
          ) : null}   
            
      </div>

      {/* Management of all 12 elements */}
      <div className="side-margin" >
        {nutrientValue ? (
          <>
            <Card className="symptom-card">
            <Card.Body>
              <h1>Management</h1>
              <Card.Title><b>{selectedNutrient}</b></Card.Title>
                <Card.Text>{nutrientValue}</Card.Text>
            </Card.Body>
          </Card>
          </>
        ) : null}   
          
      </div>

      {/* Fertilizers of all 12 elements */}
      <div className="side-margin">
        {fertilizersDescription ? (
          <>
            <Card className="symptom-card">
            <Card.Body>
              <h1>Fertilizers</h1>
              <Card.Title><b>{selectedNutrient}</b></Card.Title>
                <Card.Text>{fertilizersDescription}</Card.Text>
            </Card.Body>
          </Card>
          </>
        ) : null}   
          
      </div>
       </> :
       
       <>
       {/* When all 12 elements are at a good level  */}
       <Grid container justifyContent='center'  >
       <Grid item xs={1} />
       <Grid item xs={10}>
           
           <h1 style={xsmall ? TopHeadStyleMobile : TopHeadStyleDesktop}>Nutrient Prediction Result</h1>
           
       </Grid>
       <Grid item xs={1} />
       </Grid>
  
       <Grid container justifyContent='center'  >
       <Grid item xs={1} />
       <Grid item xs={10}>
           
       <div style={GreyBackStyle} > 
       <h2 style={xsmall ? TopHeadStyleMobile : TopHeadStyleDesktop}>All Nutrients are good</h2>
       </div>
           
       </Grid>
       <Grid item xs={1} />
       </Grid>
       </>
       }
  
    </div>
  );
};
