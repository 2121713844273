import React from "react";
import '../components/Style/custom.css'
import { Button, Row, Col, Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import './Style/homepageproduct.css';
const Posts = React.lazy(() => import("./Posts"));

function HomePageShowProductDesk({
    loading,
    error,
    HeaderText,
    productToShow,
    end,
    href
}){
    const { t } = useTranslation();
    let carouseLength = productToShow?.length <= 5 ? [1] : [1,1]
    return(
    <div style={{margin:10, padding:10, display:loading || error? 'none' : 'block'}}>
        <Row>
            <div style={{margin:10, padding:5, width:'100%', display:'flex', alignItems:'center'}}>
                <text className="homepage-header">{HeaderText}</text>
                <Button
                    href={href}
                >
                    <text className="homepage-header-button">
                        {t("SHOW-MORE")} <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </text>
                </Button>
            </div>
            <Col>
            </Col>
        </Row>
        <Carousel
            variant="dark"
            prevIcon={<span className="carousel-control-prev-icon"/>}
            nextIcon={<span className="carousel-control-next-icon"/>}
        >
            {
                carouseLength.map((num, index)=>(
                    <Carousel.Item key={index}>
                        <Row
                            style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                            flexWrap:'nowrap'
                            }}
                        >
                        {
                            productToShow?.slice(((index)*end),((index+1)*end))?.map((product, key)=>(
                                <Posts key={key} post={product}/>
                            ))
                        }
                        </Row>
                    </Carousel.Item>
                ))
            }
        </Carousel>
    </div>
    )
}

export default HomePageShowProductDesk ; 