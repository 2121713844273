import React from "react";
import { Form, Button, Row, Col, Card, Container } from "react-bootstrap";
import Message from "../../../components/message";
import {
  SmallButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
export default class CwcReg4 extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    mobileNo: "",
    altMobileNo: "",
    idType: "",
    idNo: "",
    error: null,
    email: "",
    // gst:"",
    storageArea: "",
    address: "",
    state: "",
    district: "",
    block: "",
    villTownWard: "",
    postalCode: "",
    firmAddress2: "",
    firmAddress1: "",
    firmName: "",
    designation: "",
  };


  saveandContinue = async (e) => {
    await this.props.postData();
    this.props.nextStep();
    this.props.retrieve_BankDetails();
  };

  back = () => {
    this.props.prevStep();
  };

  render() {
    const {
      error,
    } = this.state;

    return (
      <Container>
        {error && 
          <Message width="100%" variant="danger">
            'something is wrong can you please check the fields, are they correctly filled or not?'
          </Message>
         }
        <Col style={{...BackgroundGradientStyle, display:'flex',justifyContent:'center'}}>
          <Row>
            <Form className="px-2 py-2" style={{width:300, backgroundColor:"white"}}>
          <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      alignItems: "left",
                    }}
                  >
                   Preview Summary
                  </strong>
                  <text 
                  style={{
                      display: "flex",
                      alignItems: "left",
                  }}
                  >
                    Submit to create a new Coordinator account.
                  </text>

                  <Form.Group style={{ marginTop: 25 }}>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>State</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.states}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>District</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.district}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Block</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.block}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Village</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.villTownWard}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Firm Name/ Society Name / Indivitidual
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firmName}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Designation</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.designation}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Firm Address 1</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firmAddress1}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Firm Address 2</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firmAddress2}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Postal Code</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.postalCode}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        First Name of Authorised Representative
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firstName}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Last Name of Authorised Representative
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.lastName}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Fathers Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.fathername}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Gender</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.gender}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Email</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.email}
                      </strong>
                    </Form.Text>

                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>Mobile No.</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.mobileNo}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Alternative Mobile No.
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.altMobileNo}
                      </strong>
                    </Form.Text>

                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Identification Card Number
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.idnumber}
                      </strong>
                    </Form.Text>

                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>PAN Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.pannumber}
                      </strong>
                    </Form.Text>

                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Do you have GST Certifcate
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.gst === true ||
                        this.props.values.gst === "True"
                          ? "Yes"
                          : "No"}
                      </strong>
                    </Form.Text>
                    <Form.Text style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Storage Space (sq. ft.)
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.storageSpace}
                      </strong>
                    </Form.Text>
                  </Form.Group>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      className="my-2"
                      onClick={this.saveandContinue}
                      style={SmallButtonStyle}
                    >
                      Submit
                      <i className="fas fa-angle-right mx-2" />
                    </Button>
                    
                    <Button
                      className="my-2"
                      onClick={this.back}
                      style={SmallButtonStyle}
                    >
                      Back
                      <i className="fas fa-angle-left mx-2" />
                    </Button>
                  </div>
                </Form>
            </Row>
        </Col>
      </Container>
    );
  }
}

const styles = {
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 0,
    height: "auto",
    margin: 3,
    width: "45%",
  },

  confirmtext: {
    display: "flex",
    flexDirection: "row",
  },
};
