import React from "react";
import { useTranslation } from "react-i18next";
import Posts from "./Posts";
import PaginationRounded from "./Pagination";
import '../components/Style/productcategory.css';

export const DesktopSection = ({
  loading,
  product,
  setPage,
  page,
  totalProduct,
  category,
  getCategory,
  brand,
  filterConfig,
  setSortConfig,
  sortConfig,
  brandConfig,
  brandList,
  clearFilter
}) => {

  const sortData=[
    {name:'price : low to high', value:'selling_price'},
    {name:'price : high to low', value:'-selling_price'},
    {name:'latest', value:'-created_at'},
  ]

  const Brand=()=>{
    return(
      <div>
        <strong style={{color:'black'}}>Brands</strong>
        <div style={{display:'flex', flexDirection:'column', maxHeight:'100vh', overflowY:'auto'}}>
          {brand?.map((data, key)=>{
            return(
              <lable key={key} style={{color:'black', fontSize:18, margin:5, cursor:'default', display:'flex',}}>
                <input
                id='brand'
                style={{margin:5}}
                type="checkbox"
                title={data.name}
                value={data.id}
                checked={brandConfig?.value?.includes(String(data.id))}
                onChange={(event)=>brandList(brandConfig?.value?.includes(String(data.id)) ? 'remove' : 'add', event.target.value)}
                />
                <div for='brand'>{data.name}</div>
              </lable>
            )
          })}
        </div>
      </div>
    )
  }

  const CategoryHierarchical=()=>{
    return(
      <div>
        {
        category?.category?.map((cat, key1)=>(
          <div key={key1}>
            <div
              onClick={()=>console.log('already fetched')}
              style={{color:!filterConfig?'green':'#000', fontSize:18, cursor:'pointer'}}>
                {cat.name}
            </div>
            <div style={{marginLeft:10, borderLeft: category?.subcategory ? '1px dotted black' : 'none', padding:5}}>
              {
                category?.subcategory?.map((subcat, key2)=>(
                  <div key={key2}>
                    <div
                      onClick={()=>(getCategory('sub2category', 'subcategory', subcat.id), setPage(1))}
                      style={{color:subcat.id==filterConfig?.value? 'green' :'#000', fontSize:18, cursor:'pointer'}}>
                      {subcat.name}
                    </div>
                    <div style={{marginLeft:10, padding:2, borderLeft:category?.sub2category ? '1px dotted black' : 'none', padding:5}}>
                      {
                        category?.sub2category?.map((sub2cat, key3)=>
                        subcat.id==sub2cat.subcategory &&
                        (
                          <div key={key3}>
                            <div
                              onClick={()=>(getCategory('sub3category', 'subsubcategory', sub2cat.id), setPage(1))}
                              style={{color:sub2cat.id==filterConfig?.value? 'green' :'#000', fontSize:18, cursor:'pointer'}}>{sub2cat.name}</div>
                            <div style={{marginLeft:10, padding:2, borderLeft:category?.sub3category ? '1px dotted black' : 'none', padding:5}}>
                              {
                                category?.sub3category?.map((sub3cat,key4)=>
                                sub2cat.id==sub3cat.subsubcategory &&
                                (<div
                                  key={key4}
                                  onClick={()=>(getCategory(null, 'subsubsubcategory', sub3cat.id), setPage(1))}
                                  style={{color:sub3cat.id==filterConfig?.value?'green':'#000', fontSize:18, cursor:'pointer'}}>
                                    {sub3cat.name}
                                  </div>
                                )
                                )
                              }
                            </div>
                          </div>
                        )
                        )
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
        }
      </div>
    )
  }

  const Filter=()=>{
     return(
        <div className="product-filter">
          <div className="filter-header">
            <text className="filter-header-text">Filter</text>
            <text
             className="filter-header-button"
             onClick={()=>clearFilter()}
            >
              Clear Filter
            </text>
          </div>
          <div className="filter">
            <CategoryHierarchical/>
            <Brand/>
          </div>
        </div>
     )
  }

  const Sort=()=>{
    return(
      <div className="product-sort">
          <text className="sort-text">Sort by : </text>
          <select
            className="sort-select"
            onChange={(event)=>setSortConfig({'lable':'sort', 'value':event.target.value})}
            defaultValue={sortConfig?.value}
          >
            <option className="sort-option" value=''></option>
            {sortData?.map((sort, key)=>(
              <option className="sort-option" key={key} value={sort.value}>{sort.name}</option>
            ))}
          </select>
        </div>
    )
  }

  const FilterButtonForMobile=()=>{
    return(
      <div className="filter-mobile-button" onClick={()=>console.log('FilterButtonForMobile')}>
        <text style={{fontSize:16}}>Filter</text>
      </div>
    )
  }

  return (
    <div>
      <div className="sort-section">
        <FilterButtonForMobile/>
        <Sort/>
      </div>

      <div style={{width:'100vw', display:'flex'}}>
        {/* filter */}
        <Filter/>
        <div className="products-list">
          {/* <div className="sponsored" style={{height:'20%',border:'1px solid black', display:'flex', flexWrap:'wrap'}}>
            sponsored
          </div> */}
          <div className="product">
            {product?.map((post, key)=>(
              <Posts key={key} post={post}/>
            ))}
            {/* filler items */}
            <div style={{width:'calc(15rem + 3.5%)'}} />
            <div style={{width:'calc(15rem + 3.5%)'}} />
            {/* filler items */}
            {!(product?.length) && !loading && 
              <div style={{width:'100%', textAlign:'center'}}>
                  No available products at the moment. Apologies for any inconvenience.
              </div>
              }
          </div>
          <div className="default-pagination" style={{display:'flex', justifyContent:'center', margin:10}}>
            <PaginationRounded
              totalProduct={totalProduct}
              setPage={setPage}
              page={page}
            />
          </div>
          <hr style={{width:'90%'}}/>
          {/* other products - Recommended Product/Browsing History */}
          {/* <text style={{fontSize:20, color:'black'}}>Recommended Product</text>
          <div className="product" style={{display:'flex', flexWrap:'wrap', justifyContent:'space-around'}}>
            {product?.map((post, key)=>(
              <Posts key={key} post={post}/>
              ))}
            <div style={{width:'calc(15rem + 3.5%)'}} />
            <div style={{width:'calc(15rem + 3.5%)'}} />
          </div>
          <hr style={{width:'90%'}}/>
          <text style={{fontSize:20, color:'black'}}>Browsing History</text>
          <div className="product" style={{display:'flex', flexWrap:'wrap', justifyContent:'space-around'}}>
            {product?.map((post, key)=>(
              <Posts key={key} post={post}/>
            ))}
            <div style={{width:'calc(15rem + 3.5%)'}} />
            <div style={{width:'calc(15rem + 3.5%)'}} />
          </div> */}
        </div>
      </div>
    </div>
  );
};
