import React from "react";
import { Nav, Row, Col, Container } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";

function ManageOrders({ step1, step2, step3, step4, t }) {
  const LinkBorder = { borderBottom: "8px solid #5DA7CA", color: "#5DA7CA" };
  const PlaneLink = { color: "#747D88" };

  return (
    <Container fluid>
      <Row style={{ marginTop: 15 }}>
        <h2>{t("Previous-Orders")}</h2>
      </Row>
      <Row md>
        {/* <Nav className = 'justify-content-left'>

            <Nav.Item>
              
            { step1 ? <LinkContainer  style={LinkBorder} to='/PreviousOrders'  id='colorstext1' >
                      <Nav.Link >Previous Orders</Nav.Link>
              </LinkContainer>
              : 
              <LinkContainer  style={PlaneLink} to='/PreviousOrders'  id='colorstext1' >
                      <Nav.Link >Previous Orders</Nav.Link>
              </LinkContainer>}
            </Nav.Item>

            <Nav.Item>
              
            {step2 ? <LinkContainer style={LinkBorder} to='/wishlist' id='colorstext2' >
                      <Nav.Link>Wishlist</Nav.Link>
              </LinkContainer> : 
              <LinkContainer style={PlaneLink} to='/wishlist' id='colorstext2' >
              <Nav.Link>Wishlist</Nav.Link>
            </LinkContainer>
              }
            </Nav.Item>


            <Nav.Item>
              
              {step4 ? <LinkContainer style={LinkBorder} to='OrderTracking' id='colorstext4' >
                      <Nav.Link>Order Tracking</Nav.Link>
              </LinkContainer>
              :
              <LinkContainer style={PlaneLink} to='OrderTracking' id='colorstext4' >
                      <Nav.Link>Order Tracking</Nav.Link>
              </LinkContainer>
              }
            </Nav.Item>

            </Nav> */}
      </Row>
    </Container>
  );
}

export default ManageOrders;
