import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import { logout } from "../actions/auth.action";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    marginTop: 100,
  },
}));

const SideNavbar = ({ MainMenu, handleDrawerClose }) => {
  const classes = useStyles();
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const MenuB = [
    {
      title: t("ContactUs"),
      path: { pathname: "https://www.cropway.com/contactus/" },
      icon: <i className="fas fa-phone-alt" />,
    },
    // {
    //   title: "Settings",
    //   path: "/",
    //   icon: <i className="fas fa-cog" />,
    // },
  ];

  const MenuA=[
    {
      title: t("Home"),
      path: "/",
      icon: <HomeIcon />,
    },
    {
      title: t("Cart"),
      path: "/cart",
      icon: <ShoppingCartIcon />,
    },
   // {
   //   title: t("Sell-Products"),
   //   path: "/seller",
   //   icon: <i className="fab fa-sellcast" />,
   // },
  ];

  const Productcategory = [
    {
      title: t("AgricultureInput"),
      path: "/productcategory",
      icon: <i className="fas fa-angle-right" />,
    },
    {
      title: t("AgricultureOutput"),
      path: { pathname: "https://www.cropway.com/seller-studio/" },
      icon: <i className="fas fa-angle-right" />,
    },
    // {
    //   title: "Agriculture Finance",
    //   path: "/productcategory/Agriculture Finance/category",
    //   icon: <i className="fas fa-angle-right" />,
    // },
    {
      title: t("AgricultureServices"),
      path: { pathname: "https://www.cropway.com/agriculture-services/" },
      icon: <i className="fas fa-angle-right" />,
    },
    {
      title: t("FarmEquipmentRental"),
      path: "/login",
      icon: <i className="fas fa-angle-right" />,
    },
    {
      title: t("Resources&Insights"),
      path: { pathname: "https://www.cropway.com/blog/" },
      icon: <i className="fas fa-angle-right" />,
    },
  ];

  const Products = [
    {
      title: t("Vegetables"),
      path: "/buyingproduct/Vegetables/subcategory",
      icon: <i className="fas fa-angle-right" />,
    },
    {
      title: t("Pulses"),
      path: "/buyingproduct/Pulses/subcategory",
      icon: <i className="fas fa-angle-right" />,
    },
  ];

  const Mlcomponent = [
    {
      title: t("DynamicPricing"),
      path: { pathname: "https://dypr-dot-cropwayml-344010.el.r.appspot.com/" },
      icon: <i className="fas fa-angle-right" />,
    },
    {
      title: t("Priceprediction"),
      path: { pathname: "https://predprice-1-dot-cropwayml-344010.el.r.appspot.com/" },
      icon: <i className="fas fa-angle-right" />,
    },
    {
      title: t("diseaseDetection"),
      path: { pathname: "/predict-disease" },
      icon: <i className="fas fa-flask" />,
    },
    {
      title: t("pestPrediction"),
      path: { pathname: "/pest-predict" },
      icon: <i className="fas fa-flask" />,
    },
    {
      title: t("NutrientPredictor"),
      path: { pathname: "/predict-nutrient" },
      icon: <i className="fas fa-flask" />,
    },
  ];

  useEffect(()=>{
    let handler=(event)=>{
      if (!MainMenu.current.contains(event.target)) {
        handleDrawerClose();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [dispatch]);

  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };
  const logoutHandler = () => {
    dispatch(logout());
    navigate('/')
  };
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  return (
    <div>
      <List>
        {user===null && (
          <ListItemLink onClick={()=>navigate('/login')}>
              <ListItemIcon>
                <i className="fas fa-user" />
              </ListItemIcon>
              <ListItemText primary="Login" />
          </ListItemLink>
        )}

        {MenuA.map((item, key) => (
          <ListItemLink key={key} onClick={()=>navigate(item.path)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title}/>
          </ListItemLink>
        ))}

        {[
          {
            title: t("Shop-by-Categories"),
            path: "#",
            icon: <i className="fas fa-layer-group" />,
          },
        ].map((item, key) => (
          <ListItem key={key} button onClick={handleClick1}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            {open1 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ))}

        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Productcategory.map((item, index) => (
              <ListItemLink key={index} onClick={() => typeof item.path === 'string' ? navigate(item.path) : window.open(item.path.pathname)}
              >
                <NavigateNextIcon/>
                <ListItemText primary={item.title}/>
              </ListItemLink>
            ))}
          </List>
        </Collapse>

        {/*[
          {
            title: t("Buy-Farm-Produce"),
            path: "#",
            icon: <i className="fas fa-shopping-basket" />,
          },
        ].map((item, key) => (
          <ListItem key={key} button onClick={handleClick2}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            {open2 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

        ))*/}

        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Products.map((item, index) => (
              <ListItemLink key={index} className={classes.nested} onClick={()=>navigate(item?.path)}>
                <NavigateNextIcon/>
                <ListItemText primary={item.title}/>
              </ListItemLink>
            ))}
          </List>
        </Collapse>

        {[
          {
            title: t("Precision-Agri"),
            path: "#",
            icon: <i className="fas fa-chart-line" />,
          },
        ].map((item, key) => (
          <ListItem key={key} button onClick={handleClick3}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            {open3 ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
        ))}

        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Mlcomponent.map((item, key) => (
              <ListItemLink key={key} className={classes.nested}  onClick={() => item?.path?.pathname?.startsWith('/') 
                ? navigate(item.path.pathname) 
                : window.open(item.path.pathname)}>
                <NavigateNextIcon/>
                <ListItemText primary={item.title}/>
              </ListItemLink>
            ))}
          </List>
        </Collapse>

        {MenuB.map((item, key) => (
          <ListItemLink key={key} onClick={() => window.open('https://www.cropway.com/contact-us/')}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title}/>
          </ListItemLink>
        ))}

        {user && (
          <ListItemLink onClick={logoutHandler}>
            <ListItemIcon>
              <i className="fas fa-sign-out-alt" />
            </ListItemIcon>
            <ListItemText primary={t("Logout")} />
          </ListItemLink>
        )}
      </List>
    </div>
  );
};

export default SideNavbar;
