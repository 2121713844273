import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "2px solid #5DA7CA",
    "&:hover": {
      borderBottonColor: "#5DA7CA",
    },
  },
}));

export default function InputAdornments({ handleClickShowEdit, showEdit }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = React.useState({
    Google: "",
    FB: "",
    Twitter: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const UpdateData = () => {
    // console.log("dataUpdated");
    // need to add social media data first
    handleClickShowEdit();
  };

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Google"
            value={values.Google}
            onChange={handleChange("Google")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Google-Profile")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <Input
            style={{ borderBottom: "2px solid #5DA7CA" }}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-FB"
            value={values.FB}
            onChange={handleChange("FB")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Facebook-URL")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <Input
            style={{ borderBottom: "2px solid #5DA7CA" }}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Twitter"
            value={values.Twitter}
            onChange={handleChange("Twitter")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Twitter-URL")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
              onClick={async () => await handleClickShowEdit()}
            >
              {t("Edit-Profile")}
            </Button>
            <Button
              variant="contained"
              style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
              onClick={() => UpdateData()}
            >
              {t("Save-Settings")}
            </Button>
          </div>
        </FormControl>
      </div>
    </div>
  );
}
