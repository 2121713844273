// Data service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const addToCartHandler=async(data)=>{
  return axios.post(`${API_URL}/addtocart`, data,
    {headers :  authHeader()}
  )
}

const getCart=(count)=>{
    return axios.get(`${API_URL}/getcart`,
    {
      headers : authHeader(),
      params : { count : count }
    },
    )
}

const updateCartHandler=(data)=>{
    return axios.put(`${API_URL}/updatecart`, data,
      {headers : authHeader()}
    )
  }

const removeFromCartHandler=(cartId)=>{
    return axios.delete(`${API_URL}/deletecartitem`,
    {
      headers : authHeader(),
      params : {cart_id : cartId}
    }
    )
  }

const removeAllFromCartHandler=()=>{
    return axios.delete(`${API_URL}/clearcart`,
    {
      headers : authHeader()
    }
    )
  }

const getWishlistCartDetails=(variationId)=>{
    // to check product status in product-view-screen add to cart or goto cart.
    // console.log("variationId :: ", variationId)
    return axios.get(`${API_URL}/wishlistcartdetail`,
      {
        headers : authHeader(),
        params: {
          variation_id : variationId
        }
      },
      )
    }
  
const quationHandler=(data)=>{
  return axios.post(`${API_URL}/quotation`, data,
   {
    headers : authHeader(),
   }
  )
}

const exportedObject={
    addToCartHandler,
    getCart,
    updateCartHandler,
    removeFromCartHandler,
    removeAllFromCartHandler,
    getWishlistCartDetails,
    quationHandler
}

export default exportedObject