import React from "react";
import companyLogo from '../HeaderImages/Logo.png';
import { Link } from "react-router-dom";
import { Button, Row, Card, Container, Form, Col, Dropdown,Navbar, Nav, NavDropdown } from "react-bootstrap";
import Wishlist from '../HeaderImages/Wishlist.png';
import Cart from '../HeaderImages/Cart.png';
import Rating from "./Rating";
function RecommandProduct({FilterPageProduct, category}) {

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', margin: 0, padding: 0 ,flexWrap:'wrap'}}>
        {FilterPageProduct.length > 3 ?
          (FilterPageProduct.slice(-5, -2).map((product) =>
          <div
          style={{margin:'2%',display:'inline-block',width:'20%',minWidth:170,maxHeight:'150',border:0,borderRadius:0,textAlign:'left',}}
          >
      
        <Link to={`/product/${product._id}/${product.pdt_type}`}>
          <Card.Img alt={product.name} src={product.image} width="200" height="200" style={{borderRadius:0}} />
        </Link>
        <Card.Body style={{paddingTop: '1.25rem!important', paddingLeft: 0, paddingRight: 0}}>
        <Link to={`/product/${product._id}/${product.pdt_type}`}>
          <Card.Title style={{ overflow: 'hidden', textOverflow: "ellipsis", marginBottom: 0, whiteSpace: "nowrap", fontSize: 16, textAlign: 'left'}}>
            {product.name}
          </Card.Title>
      </Link>

      <Card.Text style={{ overflow: 'hidden', textOverflow: "ellipsis", marginBottom: 0,whiteSpace: "nowrap", fontSize: 16, textAlign: 'left', margin: 0}}>
          By {product.brand}
      </Card.Text>

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Card.Text>
          <div style={{fontSize:12}}>
            <Rating
              value={product.rating}
              text={`${product.numReviews} reviews`}
              color={"#f8e825"}
            />
          </div>
        </Card.Text>
        <Link to={`/cart/${product._id}?qty=1/`}>
        <img
              src={Cart}
              alt="Cart Icon"
              style={{marginTop: 10, marginRight: 5}}
            />
        </Link>
      </div>
         
         <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Card.Text as="h5" style={{fontSize:30,textAlign: 'left'}} >₹{product.pdt_type ==="farmproduct" ? product.price_max : product.price}</Card.Text>
            <Link to={`/wishlist/${product._id}`}>
            <img
              src={Wishlist}
              alt="wishListIcon"
              style={{marginRight: 5}}
            />
            </Link>
         </div>
    </Card.Body>
  </div>
          )) :
          (FilterPageProduct.map((product) =>
          <div
          style={{  margin: '2%', display: 'inline-block', width: '20%', minWidth: 170, maxHeight: '150', border:0,borderRadius:0, textAlign:'left'}}
      >
      
        <Link to={`/product/${product._id}/${product.pdt_type}`}>
          <Card.Img alt={product.name} src={product.image} width="200" height="200" style={{borderRadius:0}} />
        </Link>
    <Card.Body style={{paddingTop: '1.25rem!important', paddingLeft: 0, paddingRight: 0}}>
      <Link to={`/product/${product._id}/${product.pdt_type}`}>
        <Card.Title style={{ overflow: 'hidden', textOverflow: "ellipsis", marginBottom: 0, whiteSpace: "nowrap", fontSize: 16, textAlign: 'left'}}>
          {product.name}
        </Card.Title>
      </Link>

      <Card.Text style={{ overflow: 'hidden', textOverflow: "ellipsis", marginBottom: 0,whiteSpace: "nowrap", fontSize: 16, textAlign: 'left', margin: 0}}>
          By {product.brand}
      </Card.Text>

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Card.Text>
          <div style={{fontSize:12}}>
            <Rating
              value={product.rating}
              text={`${product.numReviews} reviews`}
              color={"#f8e825"}
            />
          </div>
        </Card.Text>
        <Link to={`/cart/${product._id}?qty=1/`}>
        <img
              src={Cart}
              alt="Cart Icon"
              style={{marginTop: 10, marginRight: 5}}
            />
        </Link>
      </div>
         
         <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Card.Text as="h5" style={{fontSize:30,textAlign: 'left'}} >₹{product.pdt_type ==="farmproduct" ? product.price_max : product.price}</Card.Text>
            <Link to={`/wishlist/${product._id}`}>
            <img
              src={Wishlist}
              alt="wishListIcon"
              style={{marginRight: 5}}
            />
            </Link>
         </div>
    </Card.Body>
  </div>
          ))
        }

      </div>
    </div>
    
  )
}

export default RecommandProduct;
