import React from 'react'

const Error=({error})=>{
  return (
    <div style={{display: error? 'block' : 'none', padding:10, borderRadius:10, background:'red', margin:"0.1% 1%", textAlign:'center'}}>
        <text style={{color:'#fff', textAlign:'center'}}>
            {error}
        </text>
    </div>
  )
}

export default Error