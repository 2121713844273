import React, { useEffect, useRef, useState } from "react";
import { SidebarData } from "../components/SidebarData";
import { Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { ShowImgSearch } from "../actions/ShowpageAction";
import Visual from "../assets/Visual.png";
import linkIcon from "../assets/link.png";
import Dropzone from "./Dropzone";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { ML_MODEL_URL } from "../constants/api";

function ImageSearch({ Cam }) {
  const ImageSearchDrop = useSelector((state) => state.ImageSearchDrop);
  let navigate = useNavigate();
  const { ImageSearch } = ImageSearchDrop;
  const [loading, setLoading] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const dispatch = useDispatch();

  const SaveData = () => {
    // console.log("data", productcategory, "data2:", productname )
    const uploadData = new FormData();
    uploadData.append("file", selectedFiles[0], selectedFiles[0].name);
    try {
      setLoading(true);
      fetch(`${ML_MODEL_URL}Visual`, {
        method: "POST",
        body: uploadData,
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          //console.log(data);
          navigate('/VisualResult', {state: { result: data }});
        });
    } catch (error) {
      //console.log(error);
    }
  };

  const LinkStyle = {
    "--color-1": "#BFE2BA",
    "--color-2": "#5DA7CA",
    background: `
          linear-gradient(
            90deg,
            var(--color-1) 23.12%,
            var(--color-2) 67.92%
          )
        `,
    // Unrelated styles:
    textAlign: "center",
    // borderRadius: 0,
    fontFamily: "Roboto",
    display: "flex",
    flexDirection: "row",
    marginTop: -40,
    marginLeft: 105,
    //  border: "1px solid red",
    width: 250,
    borderRadius: 20,
    height: 30,
    alignItems: "left",
  };

  let menuRef = useRef();

  useEffect(() => {
    let handler = async (event) => {
      if (menuRef.current.contains(event.target)) {
        await dispatch(ShowImgSearch(true));
      }

      if (
        !menuRef.current.contains(event.target) &&
        !Cam.current.contains(event.target)
      ) {
        await dispatch(ShowImgSearch(false));
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
    // dispatch(ShowImgSearch(true))
  }, [dispatch, 1]);

  //   console.log(sidebar)
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          ref={menuRef}
          id="root"
          style={{ margin: "50px 10px" }}
          className={ImageSearch ? "ImageSearch active" : "ImageSearch"}
        >
          <div style={{ height: "60vh" }}>
            <div style={{ width: "100%", height: "6vh", position: "relative" }}>
              <text
                style={{
                  position: "absolute",
                  color: "black",
                  margin: '20%',
                  padding: '20%',
                }}
              >
                Visual Search
              </text>
            </div>
            <div style={{ padding: 20 }}>
              <div
                style={{
                  width: "100%",
                  height: "50vh",
                  border: "1px dashed gray",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ margin: "1px 40px 20px 40px", padding: 10 }}>
                  <img src={Visual} alt="icon" />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <strong
                    style={{
                      fontSize: 20,
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    Drag and Drop your Image here or
                    <br /> <a href="#">Browse</a> to Choose a file.
                  </strong>

                  {/* <div style={{display:'flex',padding:10,justifyContent:'center',alignItems:'center'}}> */}
                  <Dropzone
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                  />
                  {/* </div> */}
                </div>
                {/* {console.log(selectedFiles)}
                    <img src={selectedFiles[0]} /> */}
              </div>

              <div style={LinkStyle}>
                <div style={{ marginTop: 2 }}>
                  <img alt="Link Icon" src={linkIcon} />
                </div>
                <input
                  className="form-control"
                  placeholder="PASTE AN IMAGE OR URL"
                  type="text"
                  style={{
                    borderRadius: 20,
                    width: 300,
                    boxShadow: "none",
                    border: 0,
                    background: "transparent",
                    color: "White",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: -5,
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <Button
              onClick={() => {
                SaveData();
              }}
            >
              submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
export default ImageSearch;
