import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_LOGISTICSBYUSERID,
  API_URL_LOGISTICS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function LogisticsBusinessInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const [values, setValues] = useState({
    CRegNo: "",
    CName: "",
    PANNo: "",
    GSTIN: "",
    IBARegNo: "",
    EstablishmentYrs: null,
    // ////////////////////////////////
    // error : null,
  });

  const matches = useMediaQuery("(min-width:600px)");

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_Logistics = async () => {
    try {
      axios.get(`${API_URL_LOGISTICSBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            // logisticID : res.data[0].id,
            CRegNo: res.data[0].CRegNo,
            CName: res.data[0].CName,
            EstablishmentYrs: res.data[0].EstablishmentYrs,
            PANNo: res.data[0].PANNo,
            GSTIN: res.data[0].GSTIN,
            IBARegNo: res.data[0].IBARegNo,

            //    ////////////////////////////////////////

            //    userID: res.data[0].userID,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // console.log("inside upload data")

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();
    let RouteMap = values._rootMap;

    form_data.append("CRegNo", values.CRegNo);
    form_data.append("CName", values.CName);
    form_data.append("PANNo", values.PANNo);
    form_data.append("GSTIN", values.GSTIN);
    form_data.append("IBARegNo", values.IBARegNo);
    if (values.EstablishmentYrs) {
      form_data.append("EstablishmentYrs", values.EstablishmentYrs);
    }

    //   ////////////////////////////////////////////////////////

    let response = [];

    // console.log("config", config)

    await axios
      .post(API_URL_LOGISTICS, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_Logistics();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_Logistics();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-CName"
            value={values.CName}
            onChange={handleChange("CName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Name-of-Transport-Company")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-EstablishmentYrs"
            value={values.EstablishmentYrs}
            onChange={handleChange("EstablishmentYrs")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Year-of-Establishment")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-CRegNo"
            value={values.CRegNo}
            onChange={handleChange("CRegNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("CRN")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-PANNo"
            value={values.PANNo}
            onChange={handleChange("PANNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("PAN")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-GSTIN"
            value={values.GSTIN}
            onChange={handleChange("GSTIN")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("GST-No")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-IBARegNo"
            value={values.IBARegNo}
            onChange={handleChange("IBARegNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("IBARN")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        {/* ///////////////////////////////////////////////////// */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LogisticsBusinessInfo;
