const initialState=
    {
        loading:false,
        error:false,
        cart:null,
    }

export default function(state=initialState, action){
    switch (action.type){
      case 'CART_REQUEST':
        return {loading:true, error:null, cart:null};

      case 'CART_SUCCESS':
        return {loading:false, error:null, cart:action.payload};

      case 'CART_FAILED':
        return {loading:false, error:action.payload, cart:null};

      default:
        return state;
    }
  };
  