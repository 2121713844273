import {
  CARD_SAVE_PAYMENT_METHOD,
  GET_CART_ITEM_REQUEST,
  GET_CART_ITEM_SUCCESS,
  GET_CART_ITEM_FAIL,
  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAIL,


} from "../constants/CartConstants";
import axios from "axios";
import { BASE_URL_HOSTED } from "../constants/api";
import axiosAPI from "../components/AxiosAPI";
export const PushToCart = (
  pdt_id,
  pdt_name,
  image,
  price,
  countInStock,
  pdt_type,
  qty,
  user_id,
  variation_arr,
  negStatus
) => {
  const uploadData = new FormData();
  uploadData.append("pdt_id", pdt_id);
  uploadData.append("pdt_name", pdt_name);
  uploadData.append("image", image);
  uploadData.append("price", price);
  uploadData.append("countInStock", countInStock);
  uploadData.append("pdt_type", pdt_type);
  uploadData.append("quantity", qty);
  uploadData.append("user_id", user_id);
  uploadData.append("pdt_variation", variation_arr);
  uploadData.append("Negotiation", negStatus);

  axiosAPI
    .post(`${BASE_URL_HOSTED}api/PostCart/`, uploadData)
    .then((res) => console.log(""))
    .catch((error) => console.log(""));
};

// GET CART ITEMS
/////////////////////////////////////////////////

export const GetCartItems = (id) => async (dispatch) => {
    // console.log("try1")
    // console.log("try2")
    let cart_data
    dispatch({ type: GET_CART_ITEM_REQUEST });
    await axios.get(`${BASE_URL_HOSTED}api/getCart/${id}`)
    .then((response)=>{
                  cart_data = response.data.data
                  // console.log("try3", cart_data)
                  dispatch({
                    type: GET_CART_ITEM_SUCCESS,
                    payload:cart_data,
                  });

                })
    .catch((error)=>{
      // console.log("error while fetching cart-Items(maybe user-id not found!)", error)
      dispatch({
        type: GET_CART_ITEM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    })
};

// Delete Cart Items
////////////////////////////////////////////////////////////////

export const RemoveFromCart = (id) => (dispatch) => {
  //  console.log('cheking id as arrray ::', id)
   dispatch({type: DELETE_CART_ITEM_REQUEST})
  axios
    .delete(`${BASE_URL_HOSTED}api/getCart/${id}`)
    .then((response)=>{
      // console.log("delete cart item response :: ",response)
      dispatch({
        type: DELETE_CART_ITEM_SUCCESS,
        payload:response.data.data ,
      });
    })
    .catch((error)=>{
      // console.log("Remove from cart Error :: ",error)

      dispatch({
        type: DELETE_CART_ITEM_FAIL,
        payload: error.response && error.response.data.message
                                ? error.response.data.message
                                : error.message,
      });

    })

};

// Update qty of Cart Items
////////////////////////////////////////////////////////////////

export const UpdateCartItem = (id, qty) => {
  //  console.log(id);
  //  console.log(status)
  const uploadData = new FormData();
  uploadData.append("quantity", qty);

  fetch(`${BASE_URL_HOSTED}api/UpdateCart/${id}/`, {
    method: "PUT",
    body: uploadData,
  }).catch((error) => console.log(""));
};

export const saveShippingAddress = (data) => {
  return function (dispatch, getState) {
    // localStorage.setItem("shippingAddress", JSON.stringify(data));
    // console.log("data");
    // console.log(data);
    const uploadData = new FormData();
    uploadData.append("fullName", data.fullname);
    uploadData.append("address", data.address);
    uploadData.append("appartment", data.appartment);
    uploadData.append("city", data.city);
    uploadData.append("state", data.state);
    uploadData.append("mobile", data.mobile);
    uploadData.append("postalCode", data.postalCode);
    uploadData.append("country", data.country);
    uploadData.append("user_id", data.id);
    uploadData.append("type", data.type);

    fetch(`${BASE_URL_HOSTED}api/shippingaddress/`, {
      method: "POST",
      body: uploadData,
    }).catch((error) => console.log(""));
  };
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CARD_SAVE_PAYMENT_METHOD,
    payload: data,
  });
  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
