import { BASE_URL_HOSTED } from "../../constants/api";
import { HOST } from "../../services/host.header";
// Deployed API

export const API_URL_LOGISTICS = `${HOST}/api/logisticsWithConfig/`;
export const API_URL_BANKDETAILS = `${HOST}/api/bankdetailsWithConfig/`;
export const API_URL_COMPANYBRANCH = `${HOST}/api/combranchWithConfig/`;
export const API_URL_PARTNERS = `${HOST}/api/partnersWithConfig/`;
export const API_URL_CWC = `${HOST}/api/cwcWithConfig/`;
export const API_URL_FPO = `${HOST}/api/fpo/`;
export const API_URL_FPO_BY_USERID = `${HOST}/api/fpodetails/`;
export const API_URL_FARMERS = `${HOST}/api/farmersWithConfig/`;
export const API_URL_VENDORS = `${HOST}/api/vendorsWithConfig/`;
//export const API_URL_USERS = `${HOST}/api/users/`;
export const API_URL_USERS = `${HOST}/core/register/`;
export const API_URL_USERS_LIST = `${HOST}/api/users_list/`;
export const API_URL_OTP_SEND = `${HOST}/verify/`;
export const API_URL_OTP_VERIFY = `${HOST}/Demo_verify_check/`;
export const API_URL_STATE = `${HOST}/api/state/`;
export const API_URL_DISTRICT = `${HOST}/api/district_census/`;
export const API_URL_BLOCK = `${HOST}/api/block_census/`;
export const API_URL_VILLAGE = `${HOST}/api/village_census/`;
export const API_URL_SOCIETY = `${HOST}/api/society/`;
export const API_URL_LOGIN = `${HOST}/core/login/`;
export const API_URL_COUNTRY = `${HOST}/api/country/`;
export const API_CHANGE_PASS = `${HOST}/core/userscheck/`;
export const API_FARMER_DATA_RET = `${HOST}/api/query_farmer/`;
export const API_URL_LOGISTICSBYUSERID = `${HOST}/api/logisticsdetails/`;
export const API_URL_COMPANYBRANCHBYUSERID = `${HOST}/api/combranchdetails/`;
export const API_URL_BANKDETAILSBYUSERID = `${HOST}/api/bankdetaildetails/`;
export const API_URL_PARTNERBYUSERID = `${HOST}/api/partnerdetails/`;
export const API_URL_FARMERBYUSERID = `${HOST}/api/farmerdetails/`;
export const API_URL_CWCBYUSERID = `${HOST}/api/cwcdetails/`;
export const API_URL_VENDORBYUSERID = `${HOST}/api/vendordetails/`;
export const API_URL_FARMERREFEREE = `${HOST}/api/farmerreferee/`;
export const API_URL_FARM_RENTAL_DETAILS = `${HOST}/api/farmequiprental/`;
export const API_URL_FARM_EQUIPMENT_LIST = `${HOST}/api/rentalequipmentlist/`;
export const API_URL_CHECK_RENT_EQUIP_LOC = `${HOST}/api/checkrentequiploc/`;
export const API_URL_SOIL_TEST_LAB_DETAILS = `${HOST}/api/getsoiltestlabdetails/`;

//LOCAL HOST API

// export const API_URL_LOGISTICS = "http://192.168.1.105:8000/api/logistics/";
// export const API_URL_BANKDETAILS = "http://192.168.1.105:8000/api/bankdetails/";
// export const API_URL_COMPANYBRANCH = "http://192.168.1.105:8000/api/combranch/";
// export const API_URL_PARTNERS = "http://192.168.1.105:8000/api/partners/";
// export const API_URL_CWC = "http://192.168.1.105:8000/api/cwc/";
// export const API_URL_FARMERS = "http://192.168.1.105:8000/api/farmers/";
// export const API_URL_VENDORS = "http://192.168.1.105:8000/api/vendors/";
// //// export const API_URL_USERS = "http://192.168.1.105:8000/api/users/";
// export const API_URL_USERS = "http://192.168.1.105:8000/core/register/";
// export const API_URL_USERS_LIST = "http://192.168.1.105:8000/api/users_list";
// export const API_URL_OTP_SEND = "http://192.168.1.38:8000/otpsend/";
// export const API_URL_OTP_VERIFY = "http://192.168.1.38:8000/verify/";
// export const API_URL_STATE = "http://192.168.1.105:8000/api/state/";
// export const API_URL_DISTRICT = "http://192.168.1.105:8000/api/district/";
// export const API_URL_BLOCK = "http://192.168.1.105:8000/api/block/";
// export const API_URL_VILLAGE = "http://192.168.1.105:8000/api/village/";
// export const API_URL_SOCIETY = "http://192.168.1.105:8000/api/society/";
// export const API_URL_LOGIN = "http://192.168.1.105:8000/token-auth/";
// export const API_URL_COUNTRY = "http://192.168.1.105:8000/api/country/";
// export const API_CHANGE_PASS = "http://192.168.1.105:8000/core/userscheck/"
