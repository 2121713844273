
export const DropMenuHover = {
    '--color-1': 'rgba(191, 226, 186, 1)',
    '--color-2': 'rgba(92,167,201,1)',
    background: `
      linear-gradient(
        170deg,
        var(--color-1),
        var(--color-2) 90%
      )
    `,
    // Unrelated styles:
    color: 'white',
    // textAlign: 'center',
    // padding: 30,
    // borderRadius: 20,
    width: "100%",
  }


  export const DropMenuNotHover = {
    background: "transparent",
    color: 'black',
  }

