import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import {
  API_URL_BANKDETAILSBYUSERID,
  API_URL_BANKDETAILS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function LogisticsUploadedDocs({ history }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  //   const [values, setValues] = useState({

  //                         haveGst: null,
  //                         // fresher: false,
  //                         // loading: true,
  //                         // password: password,
  //                         ParticularImage: null,
  //                         gstImage: null,
  //                         // loading: false,
  //                         // userID: userID
  //   });

  const [showEdit, setShowEdit] = useState(false);
  const [PreviewCancelledCheueueImage, setPreviewCancelledCheueueImage] =
    useState(null);

  const [CancelledCheueueImage, setCancelledCheueueImage] = useState(null);
  const [_uploadCancelledCheueueImage, setUploadCancelledCheueueImage] =
    useState(null);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  ////////////////////////////////////////////////////////////////////////////////

  // //////////////////////////////////////////////////////////////////

  const UpdateBankData = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();
    let Cheque = _uploadCancelledCheueueImage;
    //console.log("cheque---->: " + JSON.stringify(Cheque));

    if (Cheque && Cheque != null) {
      form_data.append("CanceledChequeImage", Cheque, Cheque.name);
    }

    let response = [];

    await axios
      .post(API_URL_BANKDETAILS, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_Bank_Doc();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((error) => {
        response = error;
        setLoading(false);
      });

    return response;
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const retrieve_Bank_Doc = async () => {
    try {
      axios.get(`${API_URL_BANKDETAILSBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          // CanceledChequeImage: CanceledChequeImage,
          setCancelledCheueueImage(res.data[0].CanceledChequeImage);

          setLoading(false);

          // setValues({
          //             loading: false,
          //             haveGst: res.data[0].haveGST,
          //             // password: password,
          //             ParticularImage: res.data[0].regParicularImage,
          //             gstImage: res.data[0].gstImage,
          //             // userID: res.data[0].userID
          //        })
        } else {
          setFresher(true);
          setLoading(false);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  // const setImg = (evt, prop) => {
  //   setValues({ ...values, [prop]: URL.createObjectURL(evt.target.files[0]) });
  // };

  const handleDoc3 = async (evt) => {
    try {
      setUploadCancelledCheueueImage(evt.target.files[0]);
      setPreviewCancelledCheueueImage(URL.createObjectURL(evt.target.files[0]));
      // retrieve_Have_Gst()
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      //   retrieve_Vendor_Doc()
      retrieve_Bank_Doc();
    }
  }, [user]);

  return (
    <div>
      <div>
        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          <ImageListItem
            key={
              PreviewCancelledCheueueImage
                ? PreviewCancelledCheueueImage
                : CancelledCheueueImage
            }
          >
            <img
              // src={`${CancelledCheueueImage}?w=248&fit=crop&auto=format`}
              src={
                PreviewCancelledCheueueImage
                  ? PreviewCancelledCheueueImage
                  : `${CancelledCheueueImage}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${CancelledCheueueImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t("Cancelled-Cheque")}
              // loading="lazy"
            />
            <ImageListItemBar
              title={t("Cancelled-Cheque")}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc3(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateBankData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LogisticsUploadedDocs;
