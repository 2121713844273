import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_FARMERBYUSERID,
  API_URL_FARMERS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function FarmerPersonalInfo({ history }) {
  const {user} = useSelector((state) => state.auth);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const [values, setValues] = useState({
    FathersName: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    IdType: "",
    IdNumber: "",
    Rakba: "",
    RakbaUnit: "",
    Address: "",
    State: "",
    District: "",
    Block: "",
    Village: "",
    Mobile: "",
    Email: "",
    // fresher: false,
    // loading: true
  });

  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_Farmer = async () => {
    try {
      axios.get(`${API_URL_FARMERBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            FirstName: res.data[0].firstName,
            LastName: res.data[0].lastName,
            FathersName: res.data[0].fathersName,
            Gender: res.data[0].gender,
            Mobile: res.data[0].mobileNo,
            IdType: res.data[0].idType,
            IdNumber: res.data[0].idNo,
            Rakba: res.data[0].rakba,
            // landParcelNo: res.data[0].landParcelNo,
            RakbaUnit: res.data[0].rakba_unit,
            Address: res.data[0].address1,
            State: res.data[0].state,
            Block: res.data[0].block,
            Village: res.data[0].villTownWard,
            District: res.data[0].district,
            // freight: res.data[0].freight,
            // society: res.data[0].society,
            // userID: res.data[0].userID,
            // loading: false,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // console.log("inside upload data")

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
    };

    const data = {
      firstName: values.FirstName,
      lastName: values.LastName,
      fathersName: values.FathersName,
      gender: values.Gender,
      // DOB: values.DOB,
      mobileNo: values.Mobile,
      idType: values.IdType,
      idNo: values.IdNumber,
      rakba: values.Rakba,
      rakba_unit: values.RakbaUnit,
      // landParcelNo: values.landParcelNo,
      address1: values.Address,
      state: values.State,
      district: values.District,
      block: values.Block,
      villTownWard: values.Village,
      // freight: values.freight,
      // society: values.society,
      // paymentMode: values.paymentMode,
      // userID: values.userID,
    };

    let response = [];

    // console.log("config", this.state.config)

    await axios
      .post(API_URL_FARMERS, data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_Farmer();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_Farmer();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-FirstName"
            value={values.FirstName}
            onChange={handleChange("FirstName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("FirstName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-LastName"
            value={values.LastName}
            onChange={handleChange("LastName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("LastName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-FathersName"
            value={values.FathersName}
            onChange={handleChange("FathersName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("FathersName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Gender"
            value={values.Gender}
            onChange={handleChange("Gender")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Gender")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Mobile"
            value={values.Mobile}
            onChange={handleChange("Mobile")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Mobile-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-Email"
            value={values.Email}
            onChange={handleChange("Email")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Email")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-IdType"
            value={values.IdType}
            onChange={handleChange("IdType")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Id-Type")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-IdNumber"
            value={values.IdNumber}
            onChange={handleChange("IdNumber")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Id-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-Rakba"
            value={values.Rakba}
            onChange={handleChange("Rakba")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Rakba")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-RakbaUnit"
            value={values.RakbaUnit}
            onChange={handleChange("RakbaUnit")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Rakba-Unit")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-Address"
            value={values.Address}
            onChange={handleChange("Address")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("address")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize={true}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-State"
            value={values.State}
            onChange={handleChange("State")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("state")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-District"
            value={values.District}
            onChange={handleChange("District")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("District")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-Block"
            value={values.Block}
            onChange={handleChange("Block")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Block")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-Village"
            value={values.Village}
            onChange={handleChange("Village")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Village")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FarmerPersonalInfo;
