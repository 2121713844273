import {
  GET_DISEASE_DETECTION_HISTORY_REQUEST,
  GET_DISEASE_DETECTION_HISTORY_FAIL,
  GET_DISEASE_DETECTION_HISTORY_SUCCESS,
  GET_PEST_DETECTION_HISTORY_REQUEST,
  GET_PEST_DETECTION_HISTORY_FAIL,
  GET_PEST_DETECTION_HISTORY_SUCCESS,
} from "../constants/MlConstants";

import { ML_MODEL_URL } from "../constants/api";

//DISEASE_DETECTION History lists
////////////////////////////////////////////////////////////

export const listDiseaseDetectionHistory =
  (user_id, language) => async (dispatch) => {
    try {
      dispatch({ type: GET_DISEASE_DETECTION_HISTORY_REQUEST });

      const uploadData = new FormData();
      uploadData.append("user_id", user_id);
      uploadData.append("ln", language);

      let data = [];

      await fetch(`${ML_MODEL_URL}disease-predict-history`, {
        method: "POST",
        body: uploadData,
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("daya", res);
          data = await res.data;
          // await setData(data)
        });

      dispatch({
        type: GET_DISEASE_DETECTION_HISTORY_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_DISEASE_DETECTION_HISTORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

export const listPestDetectionHistory =
  (user_id, language) => async (dispatch) => {
    try {
      dispatch({ type: GET_PEST_DETECTION_HISTORY_REQUEST });

      const uploadData = new FormData();
      uploadData.append("user_id", user_id);
      uploadData.append("ln", language);

      let data = [];

      await fetch(`${ML_MODEL_URL}pest-predict-history`, {
        method: "POST",
        body: uploadData,
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("daya", res);
          data = await res.data;
          // await setData(data)
        });

      dispatch({
        type: GET_PEST_DETECTION_HISTORY_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_PEST_DETECTION_HISTORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };
