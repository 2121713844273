import React from "react";
import { Nav } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";

function ManageSettings({ step1, step2 }) {
  // const ColorChange=(id)=>{

  //         var el = document.getElementById(id)
  //         el.style.borderBottom ='8px solid #5DA7CA'
  //         el.style.color ='#5DA7CA'
  //         // el.style.borderBottomHeight ='80px'
  // }

  // const ColorPrevious=(id)=>{
  //     var el = document.getElementById(id)
  //     el.style.borderBottom ='0px'
  //     el.style.color ='#747D88'
  // }

  const LinkBorder = { borderBottom: "8px solid #5DA7CA", color: "#5DA7CA" };

  const PlaneLink = { color: "#747D88" };

  return (
    <div>
      <h2 style={{ fontWeight: "bold" }}>Manage Settings</h2>

      <Nav className="justify-content-left mb-3" style={{ paddingLeft: "6%" }}>
        <Nav.Item>
          {/* {step1 ? (
            <LinkContainer style={LinkBorder} to="/ManageProfile">
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
          ) : (
            <LinkContainer style={PlaneLink} to="/ManageProfile">
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
          )} */}
        </Nav.Item>

        <Nav.Item>
          {/* {step2 ? (
            <LinkContainer style={LinkBorder} to="/SavedAddress/settings">
              <Nav.Link>Saved Address</Nav.Link>
            </LinkContainer>
          ) : (
            <LinkContainer style={PlaneLink} to="/SavedAddress/settings">
              <Nav.Link>Saved Address</Nav.Link>
            </LinkContainer>
          )} */}
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default ManageSettings;
