// MainForm.jsx
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import CwcReg from "./CwcReg";
import CwcReg2 from "./CwcReg2";
import CwcReg3 from "./CwcReg3";
import CwcReg4 from "./CwcReg4";
import BankDetail from "../BankDetail";
import BankDetail2 from "../BankDetail2";
import RegPayment from "../Payment/RegistrationPayment";
import axios from "axios";
import {
  API_URL_CWC,
  API_URL_BANKDETAILS,
  API_URL_CWCBYUSERID,
  API_URL_BANKDETAILSBYUSERID,
} from "../Constants";
import { connect } from "react-redux";
import Loader from "../../../components/Loader";
import { withRouter } from "../../../components/Router/withRouter";


class MainCwc extends Component {
  constructor(props) {
    super(props);
    const { navigate } = this.props;
    const { user } = this.props.user;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    this.state = {
      step: 0,
      resStatus: false,
      navigate: navigate,
      states: "",
      district: "",
      villTownWard: "",
      firmName: "",
      designation: "",
      firmAddress1: "",
      firmAddress2: "",
      postalCode: "",
      error: null,
      firstName: user.first_name,
      lastName: user.last_name,
      fathername: "",
      gender: "",
      block: "",
      dob: "",
      email: "",
      mobileNo: null,
      altMobileNo: null,
      CWCID: null,
      userID: user.id,
      config: config,
      gstnumber: "",
      gst: "",

      // Banks values
      accountHolderName: "",
      bankName: "",
      accountType: "",
      accountNumber: "",
      branch: "",
      ifscCode: "",
      micr: "",
      city: "",
      BankPostal: "",
      BankPostalErr: '',
      idnumber: "",
      idcardtype: "",
      storageSpace: "",
      CanceledChequeImage: null,
      VendorID: null,
      CompanyBranchID: null,

      _idupload: "",
      _gstupload: "",
      _photoupload: "",
      _storageareaupload: "",
      _shopfrontupload: "",
      _CWCLiveImg: "",
      pannumber: "",

      role: user.role,

      //Loader
      setLoading: true,
    };
  }

  componentDidMount = () => {
    this.retrieve_CWC();
  };

  timestamp_str = () => {
    let date = this.state.date_str.getDate();
    let month = this.state.date_str.getMonth() + 1;
    let year = this.state.date_str.getFullYear();
    let hour = this.state.date_str.getHours();
    let min = this.state.date_str.getMinutes();
    let sec = this.state.date_str.getSeconds();

    let time_str = date + "" + month + "" + year + hour + "" + min + "" + sec;
    return time_str;
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  retrieve_CWC = async () => {
    // alert("!!!");
    try {
      axios
        .get(`${API_URL_CWCBYUSERID}`, this.state.config)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].status) {
              this.setState({
                step: 5,
                setLoading: true,
                CWCID: res.data[0].id,
              });

              this.retrieve_BankDetails();
            } else {
              this.setState({
                CWCID: res.data[0].id,
                firstName: res.data[0].firstName,
                lastName: res.data[0].lastName,
                firmName: res.data[0].firmName,
                fathername: res.data[0].fatherName,
                gender: res.data[0].gender,
                mobileNo: res.data[0].mobileNo,
                idcardtype: res.data[0].idType,
                idnumber: res.data[0].idNo,
                postalCode: res.data[0].postalCode,
                firmAddress1: res.data[0].firmAddress1,
                firmAddress2: res.data[0].firmAddress2,
                designation: res.data[0].designation,
                email: res.data[0].email,
                altMobileNo: res.data[0].altMobileNo,
                pannumber: res.data[0].panNo,
                gstnumber: res.data[0].gstNo,
                gst: res.data[0].haveGST === true ? "True" : "False",
                storageSpace: res.data[0].storageArea,
                villTownWard: res.data[0].villTownWard,
                block: res.data[0].block,
                district: res.data[0].district,
                states: res.data[0].state,
                id: res.data[0].userID,
                setLoading: false,
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  retrieve_BankDetails = async () => {

    try {
      axios
        .get(`${API_URL_BANKDETAILSBYUSERID}`, this.state.config)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].status) {
              this.setState({
                resStatus: true,
                BankDetailsID: res.data[0].id,
                setLoading: false,
                CWCID: res.data[0].CWCID,
                step: 7,
              });
            } else {
              this.setState({
                bankName: res.data[0].bankName,
                branch: res.data[0].branch,
                city: res.data[0].city,
                BankPostal: res.data[0].postalCode,
                accountType: res.data[0].accountType,
                accountNumber: res.data[0].accountNumber,
                ifscCode: res.data[0].ifscCode,
                micr: res.data[0].micr,
                accountHolderName: res.data[0].accountHolderName,
                setLoading: false,
                CWCID: this.state.CWCID,
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log("ERROR..!!!");
      alert(error.response);
    }
  };

  handleChange = (input, val) => {
    this.setState({ [input]: val });
  };

  handleDoc7 = async (evt) => {
    try {
      this.setState({ _idupload: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc1 = async (evt) => {
    try {
      this.setState({ _photoupload: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc8 = async (evt) => {
    try {
      this.setState({ _CWCLiveImg: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc2 = async (evt) => {
    try {
      this.setState({ _gstupload: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc3 = async (evt) => {
    try {
      this.setState({ _storageareaupload: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc4 = async (evt) => {
    try {
      this.setState({ _shopfrontupload: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc5 = async (evt) => {
    try {
      this.setState({ CancledChequeImage: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  postBankDetails = async () => {
    let form_data = new FormData();
    let Cheque = this.state.CancledChequeImage;
    //console.log("cheque---->: " + JSON.stringify(Cheque));

    if (Cheque && Cheque != "") {
      form_data.append("CanceledChequeImage", Cheque, Cheque.name);
    }

    form_data.append("bankName", this.state.bankName);
    form_data.append("branch", this.state.branch);
    form_data.append("city", this.state.city);
    if (this.state.BankPostal && this.state.BankPostal !== null) {
      form_data.append("postalCode", parseInt(this.state.BankPostal));
    }
    if (this.state.accountNumber && this.state.accountNumber !== null) {
      form_data.append("accountNumber", parseInt(this.state.accountNumber));
    }
    form_data.append("accountType", this.state.accountType);
    form_data.append("ifscCode", this.state.ifscCode);
    form_data.append("micr", this.state.micr);
    form_data.append("accountHolderName", this.state.accountHolderName);

    if (this.state.CWCID) {
      form_data.append("CWCID", this.state.CWCID);
    }

    let response = [];

    await axios
      .post(API_URL_BANKDETAILS, form_data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false });
        response = res;
        //console.log("response", response);
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        response = error;
      });

    return response;
  };

  postData = async () => {
    let head = {
      headers: {
        //Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };

    let form_data = new FormData();
    let LivePhoto = this.state._CWCLiveImg;
    let IDImage = this.state._idupload;
    let PassportImage = this.state._photoupload;
    let StorageArea = this.state._storageareaupload;
    let StorageFrontImage = this.state._shopfrontupload;
    let GSTCertificate = this.state._gstupload;

    // console.log("++++++++++ \n" + JSON.stringify(LivePhoto) + "\n+++++++");

    if (IDImage != "") {
      form_data.append("idImage", IDImage, IDImage.name);
    }

    if (PassportImage != "") {
      form_data.append("CWCImage", PassportImage, PassportImage.name);
    }

    if (LivePhoto != "") {
      form_data.append("CWCLiveImage", LivePhoto, LivePhoto.name);
    }

    if (StorageArea != "") {
      form_data.append("storageAreaImage", StorageArea, StorageArea.name);
    }

    if (StorageFrontImage != "") {
      form_data.append(
        "storeFrontImage",
        StorageFrontImage,
        StorageFrontImage.name
      );
    }

    if (GSTCertificate != "") {
      form_data.append("gstCert", GSTCertificate, GSTCertificate.name);
    }

    let state_field = this.state.states;
    let district_field = this.state.district;
    let block_field = this.state.block;
    let village_field = this.state.villTownWard;

    form_data.append("state", state_field);
    form_data.append("district", district_field);
    form_data.append("block", block_field);
    form_data.append("villTownWard", village_field);
    form_data.append("firmName", this.state.firmName);
    form_data.append("designation", this.state.designation);
    form_data.append("firmAddress1", this.state.firmAddress1);
    form_data.append("firmAddress2", this.state.firmAddress2);
    form_data.append("firstName", this.state.firstName);
    form_data.append("lastName", this.state.lastName);
    form_data.append("gender", this.state.gender);

    if (this.state.email && this.state.email !== null) {
      form_data.append("email", this.state.email);
    }
    if (this.state.mobileNo && this.state.mobileNo !== null) {
      form_data.append("mobileNo", parseInt(this.state.mobileNo));
    }
    if (this.state.altMobileNo && this.state.altMobileNo !== null) {
      form_data.append("altMobileNo", this.state.altMobileNo);
    }
    if (this.state.postalCode && this.state.postalCode !== null) {
      form_data.append("postalCode", parseInt(this.state.postalCode));
    }
    // form_data.append("password", "Dummy");
    form_data.append("idType", this.state.idcardtype);
    form_data.append("idNo", this.state.idnumber);
    form_data.append("panNo", this.state.pannumber);
    form_data.append("gstNo", this.state.gstnumber);

    if (this.state.gst && this.state.gst !== null) {
      form_data.append("haveGST", this.state.gst);
    }
    if (this.state.storageSpace && this.state.storageSpace !== null) {
      form_data.append("storageArea", this.state.storageSpace);
    }
    form_data.append("paymentMode", "Testing");
    // form_data.append("userID", this.state.userID);

    ///For tsting purpose for making the status true
    form_data.append("fatherName", this.state.fathername);
    let response = [];

    await axios
      .post(API_URL_CWC, form_data, this.state.config)
      .then((res) => {
        this.setState(
          { setLoading: false, CWCID: res.data.id }
          //console.log(res)
        );
        response = res;
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        //console.log(error);
        response = error;
        // alert(error);
      });

    return response;
  };
  // Network check
  ////////////////////////////////////
  componentWillUnmount = () => {
    this.NetInfoSubscription && this.NetInfoSubscription();
  };

  handleConnectivityChange = (state) => {
    this.setState({ connection_status: state.isConnected });
  };
  ///////////////////////////////////////////////////////////

  handleBackLogin = () => {
    this.props.navigation.navigate("Login");
  };

  redirect = (res) => {
    //console.log("redirected!!!", res);
    if (res == "YES") {
      this.setState({ step: 1 });
    } else if (res == "NO") {
      this.props.navigate("/");
      // this.setState({ step: 8 });
    }
  };

  handleNavRegisterFarmers = () => {
    //console.log("registration farmer");
    this.state.navigate({
      pathname: "/farmerViaCWC",
      state: { CWCID: this.state.CWCID },
    });
  };

  render() {
    const { user } = this.props.user;
    const { step, navigate } = this.state;
    const {
      states,
      district,
      villTownWard,
      firmName,
      designation,
      firmAddress1,
      firmAddress2,
      postalCode,
      error,
      firstName,
      lastName,
      fathername,
      gender,
      dob,
      email,
      mobileNo,
      altMobileNo,
      accountHolderName,
      bankName,
      accountType,
      accountNumber,
      branch,
      ifscCode,
      micr,
      city,
      BankPostal,
      BankPostalErr,
      storageSpace,
      CanceledChequeImage,
      VendorID,
      CWCID,
      CompanyBranchID,
      block,
      _idupload,
      _gstupload,
      _photoupload,
      _storageareaupload,
      _shopfrontupload,
      idnumber,
      idcardtype,
      gstnumber,
      gst,
      pannumber,
    } = this.state;
    const values = {
      states,
      district,
      villTownWard,
      firmName,
      designation,
      firmAddress1,
      firmAddress2,
      postalCode,
      error,
      firstName,
      lastName,
      fathername,
      gender,
      dob,
      email,
      mobileNo,
      altMobileNo,
      accountHolderName,
      bankName,
      accountType,
      accountNumber,
      branch,
      ifscCode,
      micr,
      city,
      BankPostal,
      BankPostalErr,
      block,
      storageSpace,
      CanceledChequeImage,
      VendorID,
      CWCID,
      CompanyBranchID,
      _idupload,
      _gstupload,
      _photoupload,
      _storageareaupload,
      _shopfrontupload,
      idnumber,
      idcardtype,
      gstnumber,
      gst,
      pannumber,
    };

    switch (step) {
      case 0:
        return this.state.setLoading == true ? (
          <Loader />
        ) : this.state.resStatus ? (
          <>{this.setState({ step: 7 })}</>
        ) : (
          <>
            <Modal.Dialog>
              {/* <Modal.Header closeButton>
                {/* <Modal.Title>Modal title</Modal.Title>
              </Modal.Header> */}
              {/* {console.log("status Res:  ", this.state.resStatus)} */}
              <Modal.Body>
                <h5>Do You want to complete your Profile ?</h5>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.redirect("NO")}>
                  No
                </Button>
                <Button variant="primary" onClick={() => this.redirect("YES")}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </>
        );
      case 1:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <CwcReg
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            postData={this.postData}
          />
        );

      case 2:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <CwcReg2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            postData={this.postData}
          />
        );

      case 3:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <CwcReg3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            postData={this.postData}
            handleDoc7={this.handleDoc7}
            handleDoc1={this.handleDoc1}
            handleDoc2={this.handleDoc2}
            handleDoc3={this.handleDoc3}
            handleDoc4={this.handleDoc4}
            handleDoc8={this.handleDoc8}
          />
        );

      case 4:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <CwcReg4
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            postData={this.postData}
            retrieve_BankDetails={this.retrieve_BankDetails}
          />
        );

      case 5:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <BankDetail
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            handleDoc5={this.handleDoc5}
            postBankDetails={this.postBankDetails}
          />
        );

      case 6:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <BankDetail2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            postBankDetails={this.postBankDetails}
            LastPage= {false} //temporarily change  because the reg fees for vendor not declared yet
          />
        );

      case 7:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <RegPayment
            //nextStep={this.nextStep}
            user={user}
            prevStep={this.prevStep}
            values={values}
            navigate={navigate}
            uploadData={this.uploadData}
          />
        );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default withRouter(connect(mapStateToProps)(MainCwc));