import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContent from "@mui/material/CardContent";
import { Button, Form, Col, Image } from "react-bootstrap";
import Stack from "@mui/material/Stack";
// import { Card } from "react-bootstrap";
import Card from "@mui/material/Card";
import {
  PredictionResetButton,
  PredictionResetButtonb,
} from "../../../components/ButtonStyle";
import { useState, useEffect } from "react";
// import { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PredictionCheckPopup from "./PredictionCheckPopup";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { NoEncryption } from "@material-ui/icons";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: "linear-gradient(to right bottom, #5DA7CA, #BFE2BA)",
  },
}));

export default function PredictionResult() {
  const theme = useTheme();
  const location = useLocation()
  const xsmall = useMediaQuery(theme.breakpoints.down("xs"));
  const medium = useMediaQuery(theme.breakpoints.up("md"));
  const [pest, setPest] = useState("");
  const [iden, setInden] = useState("");
  const { t } = useTranslation();
  const [symptoms, setSymptoms] = useState("");
  const [mgmt, setMgmt] = useState("");
  const [percentage, setPercentage] = useState(null);
  const [file_name, setFileName] = useState("");
  const [predictionCheckModal, setPredictionCheckModal] = useState(true);
  const {user} = useSelector((state) => state.auth);
  const result = location?.state?.result; //{ "crop" : "Paddy" }//

  useEffect(()=>{
    if (result) {
      setPercentage(result.percentage);
      setPest(result.pest_name);
      setFileName(result.file_name);
      setPredictionCheckModal(result.status === "True" ? false : true);
      setInden(result.iden);
      setSymptoms(result.symptom);
      setMgmt(result.mgmt);
    }
  }, [result]);

  return (
    <>
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#D6F0FC",
      }}
    >
      <div
        textAlign="center"
        style={{
          width: "80%",
          marginTop: "3%",
          marginBottom: "6vh",
        }}
      >
        <Typography
          component="div"
          variant="h5"
          style={{
            fontSize: 28,
            fontWeight: "bold",
            marginBottom: "2%",
          }}
        >
          {t("result")}
        </Typography>
        <Card style={{ padding: "0.5rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
                {t("pestName")}
              </Typography>
              <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
                {pest}
              </Typography>
            </CardContent>{" "}
            <CardContent sx={{ flex: "1 0 auto" }}>
              {xsmall ? (
                <div></div>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <div
                      style={{
                        width: "3.75rem",
                        height: "1.875",
                      }}
                    >
                      {percentage ? (
                        <CircularProgressbarWithChildren
                          value={Math.round(percentage)}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.25,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            pathColor: `linear-gradient(180deg, #5DA7CA 0%, #BFE2BA 100%)`,
                          })}
                        >
                          <div style={{ fontSize: 10, marginTop: -3 }}>
                            <strong>
                              {" "}
                              <text>
                                <text style={{ marginLeft: 12 }}>
                                  {Math.round(percentage)}%
                                </text>
                                <br />
                                {t("accuracy")}
                              </text>{" "}
                            </strong>
                          </div>
                        </CircularProgressbarWithChildren>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              )}
            </CardContent>{" "}
            <div style={{ display: xsmall ? "none" : null }}>
              <Button style={PredictionResetButtonb}>Reset</Button>
            </div>
          </div>
          <div
            style={{
              paddingLeft: "0.5rem",
              paddingRight: "0.5rem",
              paddingBottom: "0.5rem",
              display: xsmall ? "block" : "none",
            }}
          >
            <Stack direction="row" spacing={2} justifyContent={"space-between"}>
              <Typography fontSize={"0.625rem"}>{t("accuracy")}</Typography>
              <Typography fontSize={"0.625rem"}>
                {" "}
                {Math.round(percentage)}%{" "}
              </Typography>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              value={percentage ? Math.round(percentage) : null}
            />
          </div>

          <div
            style={{
              display: xsmall ? "flex" : "none",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Button style={PredictionResetButtonb}>
              <Typography style={{ fontSize: "1rem" }}> Reset</Typography>
            </Button>
          </div>
        </Card>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
              {t("symptom")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
              {symptoms &&
                symptoms.map((symp, key) =>
                  symp.length < 1 && symp === "" ? null : (
                    <div key={key}>
                      <text>{`${key + 1}. ${symp}`}</text>
                    </div>
                  )
                )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
              {t("identification")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={16}>
              {iden &&
                iden.map((idn, key) =>
                  idn.length < 1 && idn == "" ? null : (
                    <div key={key}>
                      <text>{`${key + 1}. ${idn}`}</text>
                    </div>
                  )
                )}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography fontSize={xsmall ? "0.875rem" : "1.5rem"}>
              {t("management")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={xsmall ? "0.875rem" : "1rem"}>
              {mgmt &&
                mgmt.map((des, key) =>
                  des.length < 1 && des == "" ? null : (
                    <div key={key}>
                      <text>{`${key + 1}. ${des}`}</text>
                    </div>
                  )
                )}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      {user ? (
        <PredictionCheckPopup
          show={predictionCheckModal}
          onHide={() => setPredictionCheckModal(false)}
          user_id={user && user.id}
          file_name={file_name}
          pest_name={pest}
          setPredictionCheckModal={setPredictionCheckModal}
          t={t}
        />
      ) : null}
    </div>
    <div>
    {/* <RelatedProducts param={pest} /> */}
  </div>
  </>
  );
}
