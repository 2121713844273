import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_PARTNERBYUSERID,
  API_URL_PARTNERS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function LogisticsPartnerInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    // inchargeFname: '',
    fName: "",
    lName: "",
    designation: "",
    phone: "",
    altPhoneNo: "",
    partner_address1: "",
    partner_address2: "",
    partner_state: "",
    partner_district: "",
    partner_country: "",
    partner_postalCode: "",
    panNo: "",

    // ////////////////////////////////
    // error : null,
  });

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_PartnerInfo = async () => {
    try {
      axios.get(`${API_URL_PARTNERBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            // partnerID: res.data[0].id,
            fName: res.data[0].fName,
            lName: res.data[0].lName,
            designation: res.data[0].designation,
            phone: res.data[0].phone,
            altPhoneNo: res.data[0].altPhoneNo,
            partner_address1: res.data[0].address1,
            partner_address2: res.data[0].address2,
            partner_state: res.data[0].state,
            partner_district: res.data[0].district,
            partner_country: res.data[0].country,
            partner_postalCode: res.data[0].postalCode,
            panNo: res.data[0].panNo,
            //   setLoading: false
            //    ////////////////////////////////////////

            //    userID: res.data[0].userID,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // console.log("inside upload data")

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();
    //let RouteMap = values._rootMap;

    form_data.append("fName", values.fName);
    form_data.append("lName", values.lName);
    form_data.append("designation", values.designation);
    if (values.phone) {
      form_data.append("phone", values.phone);
    }
    if (values.altPhoneNo) {
      form_data.append("altPhoneNo", values.altPhoneNo);
    }
    form_data.append("address1", values.partner_address1);
    // form_data.append("", values._numdriv);
    // form_data.append("", values._numvehicle);
    form_data.append("address2", values.partner_address2);
    form_data.append("country", values.partner_country);
    form_data.append("state", values.partner_state);
    form_data.append("district", values.partner_district);
    if (values.partner_postalCode) {
      form_data.append("postalCode", values.partner_postalCode);
    }
    form_data.append("panNo", values.panNo);
    //   if(values.logisticID){
    //   form_data.append("LogisticID", values.logisticID);
    // }
    // form_data.append("userID", values.userID); //values.id

    //   ////////////////////////////////////////////////////////

    let response = [];

    // console.log("config", config)

    await axios
      .post(API_URL_PARTNERS, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_PartnerInfo();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_PartnerInfo();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-fName"
            value={values.fName}
            onChange={handleChange("fName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("FirstName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-lName"
            value={values.lName}
            onChange={handleChange("lName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("LastName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-designation"
            value={values.designation}
            onChange={handleChange("designation")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Designation")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-partner_address1"
            value={values.partner_address1}
            onChange={handleChange("partner_address1")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Add-Line")}1</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-partner_address2"
            value={values.partner_address2}
            onChange={handleChange("partner_address2")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Add-Line")}2</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-partner_country"
            value={values.partner_country}
            onChange={handleChange("partner_country")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("country")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-partner_state"
            value={values.partner_state}
            onChange={handleChange("partner_state")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("state")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-partner_district"
            value={values.partner_district}
            onChange={handleChange("partner_district")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("District")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-partner_postalCode"
            value={values.partner_postalCode}
            onChange={handleChange("partner_postalCode")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Postal-Code")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-phone"
            value={values.phone}
            onChange={handleChange("phone")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Mobile-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-altPhoneNo"
            value={values.altPhoneNo}
            onChange={handleChange("altPhoneNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Alt-Mobile")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-panNo"
            value={values.panNo}
            onChange={handleChange("panNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("PAN")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        {/* ///////////////////////////////////////////////////// */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LogisticsPartnerInfo;
