import React from "react";
import { Form, Button} from "react-bootstrap";
import Message from "../../components/message";
import {
  MediumButtonStyle
} from "../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


export default class BankDetail extends React.Component {
  setImg = (evt) => {
    this.setUploadimage(evt.target.files[0]);
    this.setPreview(URL.createObjectURL(evt.target.files[0]));
  };
  constructor(props) {
    super(props);
    this.state = {
      uploadimage: "",
      preview: "",
      accountHolderName: "",
      bankName: "",
      accountType: "",
      accountNumber: "",
      branch: "",
      ifscCode: "",
      micr: "",
      city: "",
      postalCode: "",
      // storagespace: "",
      CanceledChequeImage: "",
      VendorID: null,
      CWCID: null,
      FPOID: null,
      CompanyBranchID: null,
    };
  }

  accounttypelist = [
    { title: "Current account" },
    { title: "Savings account" },
    { title: "Salary account" },
    { title: "Fixed deposit account" },
    { title: "Recurring deposit account" },
  ];

  flatProps = {
    options: this.accounttypelist.map((option) => option.title),
  };

  defaultProps1 = {
    options: this.accounttypelist,
    getOptionLabel: (option) => option.title,
  };

  componentDidUpdate = () => {
    //console.log(this.props.values);
  };

  saveandContinue = async (e) => {

    const regPostal = /^[1-9][0-9]{5}$/;
  //   if(regPostal.test(this.props.values.BankPostal) === false){
     
  //    if (regPostal.test(this.props.values.BankPostal) === false) {
  //      this.props.handleChange(
  //        "BankPostalErr",
  //        'Invalid Postal Code'
  //      );

  //    } else {
  //      this.props.handleChange("BankPostalErr", "");
  //    }


  //  }

  //  else{

    //  this.props.handleChange("idNoErr", "")
     this.props.handleChange("BankPostalErr", "")

    await this.props.postBankDetails();
    this.props.nextStep();

    // }
  };

 

  render() {
    const values = this.props;
    const {
      error,
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 850,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Fill Bank Details :
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Fill the below bank details form to create account.
                  </Form.Label>

                  <Form.Group
                    controlId="accountHolderName"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Account Holder Name"
                      // value={values.accountHolderName}
                      defaultValue={this.props.values.accountHolderName}
                      onChange={(e) =>
                        this.props.handleChange(
                          "accountHolderName",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="bankName"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Bank Name"
                      // value={values.bankName}
                      defaultValue={this.props.values.bankName}
                      onChange={(e) =>
                        this.props.handleChange("bankName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Autocomplete
                    style={{ marginTop: -25, marginBottom: -10 }}
                    {...this.defaultProps1}
                    defaultValue={this.props.values.accountType}
                    id="accountType"
                    onInputChange={(e, v) =>
                      this.props.handleChange("accountType", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Account Type"
                        margin="normal"
                      />
                    )}
                  />

                  <Form.Group
                    controlId="accountNumber"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="number"
                      placeholder="Account Number"
                      // value={values.accountNumber}
                      defaultValue={this.props.values.accountNumber}
                      onChange={(e) =>
                        this.props.handleChange("accountNumber", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="branch"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Branch"
                      // value={values.branch}
                      defaultValue={this.props.values.branch}
                      onChange={(e) =>
                        this.props.handleChange("branch", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="ifscCode"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="IFSC Code"
                      // value={values.ifscCode}
                      defaultValue={this.props.values.ifscCode}
                      onChange={(e) =>
                        this.props.handleChange("ifscCode", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="micr"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="MICR Code"
                      // value={values.micr}
                      defaultValue={this.props.values.micr}
                      onChange={(e) =>
                        this.props.handleChange("micr", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="city"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="City"
                      // value={values.city}
                      defaultValue={this.props.values.city}
                      onChange={(e) =>
                        this.props.handleChange("city", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="postalCode"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="number"
                      placeholder="Postal Code"
                      // value={values.postalCode}
                      defaultValue={this.props.values.BankPostal}
                      onChange={(e) =>
                        this.props.handleChange("BankPostal", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                  {this.props.values.BankPostalErr==='' ?null : <text style={{ color: "red", marginLeft:-175 }}>{this.props.values.BankPostalErr}</text>}

                  {/* <Form.Group controlId="storagespace" style={{borderRadius:0, marginTop:20, border:0, borderBottom:"1px solid #5DA7CA", display:"flex", direction:"row", }}>
              <Form.Control
                style={{width:750, borderRadius:0,fontSize:15, boxShadow:"none", paddingLeft:0, border:0,}}
                type="storagespace"
                placeholder="Enter Storage Space (sq. ft)"
                // value={values.firstName}
                defaultValue={this.props.values.firstName}
                onChange={(e) => this.props.handleChange("firstName", e.target.value)}
              ></Form.Control>
            </Form.Group> */}

                  <Form.Group controlId="uploadimage">
                    <Form.Label
                      style={{
                        borderRadius: 0,
                        marginTop: 20,
                        border: 0,
                        display: "flex",
                        direction: "row",
                      }}
                    >
                      Cancelled Cheque Image
                    </Form.Label>
                    <input
                      type="file"
                      onChange={(evt) => this.props.handleDoc5(evt)}
                      style={{ marginLeft: 0 }}
                    />
                    {/* {console.log(uploadimage)} */}
                  </Form.Group>
                  {/* <Card><Image src={preview} alt={"previewImage"} fluid /></Card> */}

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Save & Preview{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.props.prevStep}
                      style={MediumButtonStyle}
                    >
                      Back{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
