import React from "react";
import "./style/PageNotFound.css";
import { FRONTEND_URL } from "../constants/api";

class PageNotFound extends React.Component {
  render() {
    return (
      <div id="notfound">
        <div className="notfound">
          <h3>404</h3>
          <h2>Page not found</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <a href={FRONTEND_URL}>home page</a>
        </div>
      </div>
    );
  }
}
export default PageNotFound;
