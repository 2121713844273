import React, {useEffect, useState} from 'react';

import { useSelector } from "react-redux";
import { 
  Button,
} from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import  CWCPersonalInfo from "./CWCPersonalInfo";
import  FarmerPersonalInfo from "./FarmerPersonalInfo"



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: 'black',
  },
  border:{
    borderBottom:"1px solid #5DA7CA",
    // textTransform: 'capitalize',
}

}));





function RegistrationPersonalInfo({history}) {

  const {user} = useSelector((state) => state.auth);
  const classes = useStyles();

  return (
    <div >
        {user.role ==='farmer' ? //if
     <FarmerPersonalInfo /> 
     :   //else
     <CWCPersonalInfo />
    }
     
    </div>
  );
}

export default RegistrationPersonalInfo
