export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const SAVE_SHIPPING_ADDRESS = "SAVE_SHIPPING_ADDRESS";
export const CARD_SAVE_PAYMENT_METHOD = "CARD_SAVE_PAYMENT_METHOD";
export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";
export const GET_CART_ITEM_REQUEST = "GET_CART_ITEM_REQUEST";
export const GET_CART_ITEM_SUCCESS = "GET_CART_ITEM_SUCCESS";
export const GET_CART_ITEM_FAIL = "GET_CART_ITEM_FAIL"; 
export const DELETE_CART_ITEM_REQUEST = "GET_CART_ITEM_REQUEST";
export const DELETE_CART_ITEM_SUCCESS = "GET_CART_ITEM_SUCCESS";
export const DELETE_CART_ITEM_FAIL = "GET_CART_ITEM_FAIL"; 

