import React from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

import { BASE_URL_HOSTED } from "../constants/api";
import { FRONTEND_URL } from "../constants/api";
const user = JSON.parse(localStorage.getItem("user"));

const logoutHandler = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  <Navigate to="login/" />;
};

const axiosAPI = axios.create({
  baseURL: BASE_URL_HOSTED,
  timeout: 5000,
  headers: {
    Authorization: user?.access ? "Bearer " + user?.access : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite loops
    if (
      error.response.status === 401 &&
      originalRequest.url === BASE_URL_HOSTED + "core/refresh/"
    ) {
      window.location.href = `${FRONTEND_URL}login/`;
      return Promise.reject(error);
    }

    if (
      error.response.status === 401 //&&
      //error.response.statusText === "Unauthorized"
    ) {
      const refresh = localStorage.getItem("refresh_token");

      if (refresh) {
        const tokenParts = JSON.parse(atob(refresh.split(".")[1]));

        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);

        if (tokenParts.exp > now) {
          try {
            const response = await axios.post(
              `${BASE_URL_HOSTED}core/refresh/`,
              {
                refresh,
              }
            );
            setNewHeaders(response);
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.access;
            return axiosAPI(originalRequest);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("Refresh token is expired", tokenParts.exp, now);
          logoutHandler();
          window.location.href = `${FRONTEND_URL}login/`;
        }
      } else {
        console.log("Refresh token not available.");
        // alert("")
      }
    } else {
      console.log("StatusCode: ", error.response.status)
      console.log("STatusText: ", error.response.statusText)
      console.log("STatusText: ", error.response)
      // logoutHandler();
      // window.location.href = `${FRONTEND_URL}login/`;
      return Promise.reject(error);  
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export function setNewHeaders(response) {
  // console.log(typeof(user))
  // console.log("user Access: ", "Bearer " + response.data.access);
  axiosAPI.defaults.headers["Authorization"] = "Bearer " + response.data.access;
  if (response.data.refresh) {
    localStorage.setItem("refresh_token", response.data.refresh);
  }
  localStorage.setItem("access_token", response.data.access);
}

export default axiosAPI;
