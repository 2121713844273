import React from "react";
import { Form, Button, Container, Col, Row } from "react-bootstrap";
import Message from "../../../components/message";
import {
  MediumButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
export default class FarmerReg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      fathername: "",
      gender: "",
      DOB: "",
      mobileNo: "",
      idType: "",
      idNo: "",
      rakba: "",
      error: null,
    };
  }

  genderlist = [{ title: "Male" }, { title: "Female" }];
  idtypelist = [{ title: "Aadhaar Card" }, { title: "Voter Id Card" }];
  RakbaUnit = [{ title: "acre" }, { title: "hectare" }, { title: "dismil" }];
  flatProps = {
    options: this.genderlist.map((option) => option.title),
    options: this.idtypelist.map((option) => option.title),
  };

  defaultProps1 = {
    options: this.genderlist,
    getOptionLabel: (option) => option.title,
  };
  defaultProps2 = {
    options: this.idtypelist,
    getOptionLabel: (option) => option.title,
  };

  defaultProps3 = {
    options: this.RakbaUnit,
    getOptionLabel: (option) => option.title,
  };

  componentDidUpdate = () => {
    //console.log(this.props.values);
  };

  saveandContinue = async (e) => {

    const regMobile = /^(\+\d{1,3}[- ]?)?\d{10}$/; //mobile
    const regAadhar = /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/; //Aadhar
    const regVoter = /^([a-zA-Z]){3}([0-9]){7}?$/g; //Voter id
    
    if(regMobile.test(this.props.values.mobileNo) === false ||
     this.props.values.idType === 'Aadhaar Card' ? regAadhar.test(this.props.values.idNo) === false: this.props.values.idType === 'Voter Id Card'? regVoter.test(this.props.values.idNo) === false :this.props.values.idType===''){
      
      if (regMobile.test(this.props.values.mobileNo) === false) {
        this.props.handleChange(
          "mobileNoErr",
          'Invalid Mobile Number'
        );

      } else {
        this.props.handleChange("mobileNoErr", "");
      }

      if(this.props.values.idType===''){
        this.props.handleChange(
          "idTypeErr",
          'Please Select the Id Type'
        );
      }
      else{

        this.props.handleChange(
          "idTypeErr",
          ''
        );

      if ((regAadhar.test(this.props.values.idNo) === false && this.props.values.idType === 'Aadhaar Card') || (regVoter.test(this.props.values.idNo) === false && this.props.values.idType === 'Voter Id Card')) {
        this.props.handleChange(
          "idNoErr",
          'Invalid Id Number'
        );

      } else {
        this.props.handleChange("idNoErr", "");
      }}


    }

    else{

      this.props.handleChange("idNoErr", "")
      this.props.handleChange("mobileNoErr", "")
      this.props.handleChange("idTypeErr", "")

    await this.props.uploadData();
    this.props.nextStep();
    }
  };

  render() {
    const values = this.props;
    const {
      error
    } = this.state;
    const formStyle = {
      formGroupStyle : {
                        borderRadius: 0,
                        marginTop: 20,
                        border: 0,
                        borderBottom: "1px solid #5DA7CA",
                        display: "flex",
                        direction: "row",
                      },
      formControlStyle : {
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      },
      Err : { color: "red", marginLeft:-225 }
    }
    
    return (
      <Container>
        
            {error && <Message variant="danger">{error}</Message>}
            <Col style={{...BackgroundGradientStyle, display:'flex',justifyContent:'center'}}>
          
          {/* {loading && <Loader/>} */}
      
          
          <Row style={{padding:15}}>
              <Form className="px-2 py-2" style={{width:300, backgroundColor:"white"}}>
                  <strong
                     style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      alignItems: "left",
                    }}
                  >
                    Create Account
                  </strong>
                  
                  <text 
                  style={{
                      display: "flex",
                      alignItems: "left",

                  }}
                  >
                    Fill the below form to create a new Farmer account.
                  </text>
                  

                  <Form.Group
                    controlId="firstName"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="text"
                      placeholder="Enter First Name"
                      // value={values.firstName}
                      defaultValue={this.props.values.firstName}
                      onChange={(e) =>
                        this.props.handleChange("firstName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="lastName"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="text"
                      placeholder="Enter Last Name"
                      // value={values.lastName}
                      defaultValue={this.props.values.lastName}
                      onChange={(e) =>
                        this.props.handleChange("lastName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="fathersName"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="text"
                      placeholder="Enter Father Name"
                      // value={values.fathersName}
                      defaultValue={this.props.values.fathersName}
                      onChange={(e) =>
                        this.props.handleChange("fathersName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Autocomplete
                    style={{
                      marginTop: -20,
                      marginBottom: -5,
                      borderBottomColor: "#5DA7CA",
                    }}
                    {...this.defaultProps1}
                    defaultValue={this.props.values.gender}
                    id="gender"
                    onInputChange={(e, v) =>
                      this.props.handleChange("gender", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Gender"
                        margin="normal"
                      />
                    )}
                  />

                  <Form.Group
                    controlId="mobileNo"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="number"
                      placeholder="Enter Mobile Number"
                      // value={values.mobileNo}
                      defaultValue={this.props.values.mobileNo}
                      onChange={(e) =>
                        this.props.handleChange("mobileNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                  {this.props.values.mobileNoErr==='' ?null : <text style={formStyle.Err}>{this.props.values.mobileNoErr}</text>}

                  <Autocomplete
                    style={{
                      marginTop: -20,
                      marginBottom: -5,
                      borderBottomColor: "#5DA7CA",
                    }}
                    {...this.defaultProps2}
                    defaultValue={this.props.values.idType}
                    id="idType"
                    onInputChange={(e, v) =>
                      this.props.handleChange("idType", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Id Type"
                        margin="normal"
                      />
                    )}
                  />
                  {this.props.values.idTypeErr==='' ?null : <text style={formStyle.Err}>{this.props.values.idTypeErr}</text>}

                  <Form.Group
                    controlId="idNo"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="text"
                      placeholder="Enter Id Number"
                      // value={values.idNo}
                      defaultValue={this.props.values.idNo}
                      onChange={(e) =>
                        this.props.handleChange("idNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                  {this.props.values.idNoErr==='' ?null : <text style={formStyle.Err}>{this.props.values.idNoErr}</text>}

                  <Form.Group
                    controlId="rakba"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="number"
                      placeholder="Enter Rakba"
                      // value={values.rakba}
                      defaultValue={this.props.values.rakba}
                      onChange={(e) =>
                        this.props.handleChange("rakba", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Autocomplete
                    style={{
                      marginTop: -20,
                      marginBottom: -5,
                      borderBottomColor: "#5DA7CA",
                    }}
                    {...this.defaultProps3}
                    defaultValue={this.props.values.rakba_unit}
                    id="rakba_unit"
                    onInputChange={(e, v) =>
                      this.props.handleChange("rakba_unit", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Rakba Unit"
                        margin="normal"
                      />
                    )}
                  />

                  <Form.Group
                    controlId="landParcelNo"
                    style={formStyle.formGroupStyle}
                  >
                    <Form.Control
                      style={formStyle.formControlStyle}
                      type="text"
                      placeholder="Enter Land Parcel Number"
                      // value={values.rakba}
                      defaultValue={this.props.values.landParcelNo}
                      onChange={(e) =>
                        this.props.handleChange("landParcelNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Save & Continue{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button type="submit" style={MediumButtonStyle}>
                      Back to Login{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
          </Form>
         </Row>
          </Col>
      </Container>
    );
  }
}
