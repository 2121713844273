import React from "react";
import { Form, Button } from "react-bootstrap";
import Loader from "../../components/Loader";
import {
  MediumButtonStyle,
} from "../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";
import axios from "axios";
import { connect } from "react-redux";
import { API_CHANGE_PASS } from "./Constants";
import { withRouter } from "../../components/Router/withRouter";

class ChangePass extends React.Component {

  constructor(props) {
    super(props);
    const { navigate } = this.props;
    const { user } = this.props.user;

    this.state = {
      pass: "",
      Repass: "",
      id: user.id,
      navigate: navigate,
      error: null,
      setLoading: false,
      token: user.access,
      role: user.role,
    };
  }

  changeuserpass = async () => {
    if (this.state.pass === this.state.Repass) {
      let data = {
        id: this.state.id,
        password: this.state.pass,
        isPassUpdated: true,
      };
      let header = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      };
      this.setState({ setLoading: true });

      axios
        .put(`${API_CHANGE_PASS}`, data, header)
        .then((res) => {
          this.setState({ setLoading: false });
          alert("Password Changed Succesfully");
          this.state.navigate(`/main${this.state.role}`);
          //  this.roleform();
        })
        .catch((err) => {
          this.setState({ setLoading: false });
          if (err == "Error: Network Error") {
            alert(
              "There is some problem at our side please try again later. Sorry for the inconvenience."
            );
          }
        });
    } else {
      alert(`Password & Confirm Password Doesn't Match`);
    }
  };

  render() {
    // state = this.props
    return (
      <div id="root">
        {this.state.setLoading === true ? (
          <Loader />
        ) : (
          //else
          <div style={BackgroundGradientStyle}>
            {/* <div style={{fontSize:12}}>{error && <Message variant="danger">{error}</Message>}</div> */}
            {/* {loading && <Loader/>} */}

            <div
              id="root"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px 10px",
                    margin: "20px 20px 20px 20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 360,
                      height: 730,
                      padding: "10px 25px",
                    }}
                  >
                    <strong
                      style={{
                        color: "black",
                        fontSize: 24,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                      }}
                    >
                      Create new Password
                    </strong>
                    <Form.Group controlId="password">
                      <Form.Control
                        style={{
                          borderRadius: 0,
                          // fontSize: "1vw",
                          height: "5vh",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        type="password"
                        placeholder="Enter new Password"
                        value={this.state.pass}
                        onChange={(e) =>
                          this.setState({ pass: e.target.value })
                        }
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="Repassword">
                      <Form.Control
                        style={{
                          borderRadius: 0,
                          // fontSize: "1vw",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                        type="password"
                        placeholder="Confirm new Password"
                        value={this.state.Repass}
                        onChange={(e) =>
                          this.setState({ Repass: e.target.value })
                        }
                      ></Form.Control>
                    </Form.Group>

                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        type="submit"
                        onClick={this.changeuserpass}
                        style={MediumButtonStyle}
                      >
                        Save & Continue{" "}
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-right"
                        />
                      </Button>
                      <div style={{ marginTop: 10 }}></div>
                      <Button
                        type="submit"
                        style={MediumButtonStyle}
                        onClick={() =>
                          this.state.navigate(`/main${this.state.role}`)
                        }
                      >
                        Skip{" "}
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-right"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default  withRouter(connect(mapStateToProps) (ChangePass));
