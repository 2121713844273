import {
    GET_BROWSING_HISTORY_REQUEST,
    GET_BROWSING_HISTORY_FAIL,
    GET_BROWSING_HISTORY_SUCCESS,
  } from "../constants/BrowsingHistoryConstants";
  

  import { BASE_URL_HOSTED } from "../constants/api";
  import axiosAPI from "../components/AxiosAPI";


  export const PushBrowsingHistory = (
    pdt_id,
    pdt_variation,
    pdt_type
  ) => {
    const uploadData = new FormData();
    uploadData.append("pdt_id", pdt_id);
    uploadData.append("pdt_type", pdt_type);
    uploadData.append("pdt_variation", pdt_variation);
  
    axiosAPI
      .post(`${BASE_URL_HOSTED}api/PostBrowsingHistory/`, uploadData)
      .then((res) => console.log(""))
      .catch((error) => console.log(""));
  };

  //Browsing History lists
////////////////////////////////////////////////////////////

export const listBrowsingHistory = (detail_type) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: GET_BROWSING_HISTORY_REQUEST });
  
      const { data } = await axiosAPI.get(`${BASE_URL_HOSTED}api/GetBrowsingHistory/${detail_type}/`);
      
  
      dispatch({
        type: GET_BROWSING_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_BROWSING_HISTORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };
  