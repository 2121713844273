import React from "react";
import { useState, useEffect } from "react";
import { ML_MODEL_URL } from "../constants/api";

export const VisualResult = ({ location }) => {
  const result = location.state.result;
  let predict = [];
  const [prediction_result, setprediction_result] = useState([]);

  const visSearch = () => {
    if (result) {
      // var str = result;
      // console.log(str.scores);

      var path = result.scores;
      // console.log("path",path);

      var res_arr = path.replaceAll("[[", "");
      res_arr = res_arr.replaceAll("]]", "");
      res_arr = res_arr.replaceAll("]", "");
      res_arr = res_arr.replaceAll("[", "");
      res_arr = res_arr.replaceAll("[", "");
      res_arr = res_arr.replaceAll("WindowsPath(", "");
      res_arr = res_arr.replaceAll(")", "");
      res_arr = res_arr.replaceAll("'", "");
      res_arr = res_arr.replaceAll(" ", "");
      res_arr = res_arr.replaceAll("PosixPath(", "");

      //console.log("res_arr", res_arr);

      var arr = res_arr.split(",");
      var arr1 = [];
      for (let i = 0; i < arr.length - 1; ) {
        arr1.push([arr[i], arr[i + 1]]);
        i = i + 2;
      }
      predict = arr1;
      //console.log("path", predict);

      return predict;
      // console.log("sjddd", arr1)
      // console.log(typeof(JSON.parse(result.scores)));
    }
  };

  useEffect(() => {
    setprediction_result(visSearch());
    //console.log(prediction_result);
  },[visSearch]);

  return (
    <div>
      {/* <ListGroupItem> */}
      <h5>Query Image:</h5>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {/* {pdtDescription &&
                  pdtDescription.map((des) => (
                    des.length<1 && des==''? null :
                    <li>
                      <text>{des}</text>
                    </li>
                  ))} */}
        <img src={`${ML_MODEL_URL}${result.query_path}`} alt="imageQuery" />

        {/* <h5>PredictionResultnnj</h5> */}
      </div>

      <h5>Result:</h5>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {prediction_result &&
          prediction_result.map((pred, i) => (
            <div key={i}>
              {/* {console.log("path", pred[1])} */}
              <img
                style={{ height: 250, width: 250 }}
                key={i}
                src={`${ML_MODEL_URL}${pred[1]}`}
                alt="MLmodel"
              />
              <h5 key={i}>Score: {pred[0]}</h5>
            </div>
          ))}
      </div>
    </div>
  );
};
