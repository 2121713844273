import React from 'react';
import  ConfirmationDesktop from "../components/ConfirmationDesktop";

function ConfirmationScreen({history, location}) {
  return (
    <div >
     <ConfirmationDesktop history={history} location={location} />
    </div>
  );
}

export default ConfirmationScreen
