import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_CWCBYUSERID,
  API_URL_CWC,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function CWCBusinessInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };
  const { t } = useTranslation();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    states: "",
    district: "",
    villTownWard: "",
    firmName: "",
    designation: "",
    firmAddress1: "",
    firmAddress2: "",
    postalCode: "",
    block: "",
    storageSpace: "",

    //   CWCID: null,
    //   userID: user.id,
    //   config: config,
    gstnumber: "",
    gst: "",
    pannumber: "",

    // fresher: false,
    // loading: true
  });

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_CWC = async () => {
    try {
      axios.get(`${API_URL_CWCBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            firmName: res.data[0].firmName,
            postalCode: res.data[0].postalCode,
            // idImage: idImage,
            // CWCImage: CWCImage,
            firmAddress1: res.data[0].firmAddress1,
            firmAddress2: res.data[0].firmAddress2,
            designation: res.data[0].designation,
            // email: res.data[0].email,
            // altMobileNo: res.data[0].altMobileNo,

            pannumber: res.data[0].panNo,
            gstnumber: res.data[0].gstNo,
            gst: res.data[0].haveGST,
            // gstCert: gstCert,
            storageSpace: res.data[0].storageArea,

            // _storagespace: res.data[0].storageArea,
            // storageAreaImage: storageAreaImage,
            // storeFrontImage: storeFrontImage,
            // CWCLiveImage: CWCLiveImage,
            villTownWard: res.data[0].villTownWard,
            // society: res.data[0].society,
            block: res.data[0].block,
            district: res.data[0].district,
            states: res.data[0].state,
            // _postal: res.data[0].postalCode,
            // password: password,
            // paymentMode: paymentMode,
            // id: res.data[0].userID,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // //console.log("inside upload data")

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();

    let state_field = values.states;

    // let district_obj =
    let district_field = values.district;
    // let block_obj =
    let block_field = values.block;
    // console.log("Mobile", typeof values.mobileNo);
    // let society_obj =
    // let society_field = values.society.society_hin
    // let villTownWard_obj =

    // test comment down here
    let village_field = values.villTownWard;

    form_data.append("state", state_field);
    form_data.append("district", district_field);
    form_data.append("block", block_field);
    // form_data.append("society", society_field);
    form_data.append("villTownWard", village_field);
    form_data.append("firmName", values.firmName);
    form_data.append("designation", values.designation);
    form_data.append("firmAddress1", values.firmAddress1);
    form_data.append("firmAddress2", values.firmAddress2);

    if (values.postalCode && values.postalCode !== "") {
      form_data.append("postalCode", parseInt(values.postalCode));
    }
    // form_data.append("password", "Dummy");

    form_data.append("panNo", values.pannumber);

    if (values.gst && values.gst !== "") {
      form_data.append("gstNo", values.gstnumber);
      form_data.append("haveGST", values.gst ? "True" : "False");
    }
    if (values.storageSpace && values.storageSpace !== "") {
      form_data.append("storageArea", values.storageSpace);
    }

    let response = [];

    // console.log("config", config)

    await axios
      .post(API_URL_CWC, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_CWC();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_CWC();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firmName"
            value={values.firmName}
            onChange={handleChange("firmName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Firm-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-designation"
            value={values.designation}
            onChange={handleChange("designation")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Designation")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firmAddress1"
            value={values.firmAddress1}
            onChange={handleChange("firmAddress1")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Firm-Add-Line")}1</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firmAddress2"
            value={values.firmAddress2}
            onChange={handleChange("firmAddress2")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Firm-Add-Line")}2</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-states"
            value={values.states}
            onChange={handleChange("states")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("state")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-district"
            value={values.district}
            onChange={handleChange("district")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("District")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-block"
            value={values.block}
            onChange={handleChange("block")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Block")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-villTownWard"
            value={values.villTownWard}
            onChange={handleChange("villTownWard")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>
                  :{t("Village")}/{t("Town")}/{t("Ward")}
                </text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-pannumber"
            value={values.pannumber}
            onChange={handleChange("pannumber")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("PAN")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-storageSpace"
            value={values.storageSpace}
            onChange={handleChange("storageSpace")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Storage-Space")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        {
          values.gst ? ( //if
            <FormControl fullWidth className={classes.margin}>
              <Input
                className={classes.border}
                disableUnderline="true"
                disabled={showEdit ? false : true}
                id="standard-adornment-gstnumber"
                value={values.gstnumber}
                onChange={handleChange("gstnumber")}
                dir="rtl"
                endAdornment={
                  <InputAdornment className={classes.title} position="start">
                    <text>:{t("GST-No")}</text>
                  </InputAdornment>
                }
              />
            </FormControl>
          ) : null //else
        }

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CWCBusinessInfo;
