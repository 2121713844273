import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import {
  SmallButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";

export default class VenodrReg3 extends React.Component {
  state = {
    firstName: "",
    firmAddress1: "",
    firmAddress2: "",
    firmName: "",
    mobileNo: null,
    email: "",
    website: "",
    landlineNo: null,
    businessAddress1: "",
    businessAddress2: "",
    pincode: null,
    state: "",
    country: "",
    district: "",
    uploadimage: "",
    preview: "",
    startYear: "",
    annualSales: "",
    // productsell: "",
    panNo: "",
    gstNo: "",
    gstImage: "",
    regParicularImage: "",
    ownershipInfo: "",
    natureBusiness: "",
  };

  saveandContinue = async (e) => {
    await this.props.uploadData();
    this.props.nextStep();
    this.props.retrieve_BankDetails();
  };

  render() {
    const {
      error
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 400,
                    height: 1150,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Preview Summary{" "}
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Submit to create a new Vendor account.
                  </Form.Label>

                  <div style={{ marginTop: 25 }}>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Firm Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firmName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Registered office Address Line1
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firmAddress1}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Registered office Address Line2
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firmAddress2}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Authorised Person Name
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firstName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Mobile Number (+91)
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.mobileNo}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Email Id</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.email}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Website</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.website}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Landline Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.landlineNo}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Business Address Line1
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.businessAddress1}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Business Address Line2
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.businessAddress2}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Country</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.country}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>State</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.state}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>City</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.district}
                      </strong>
                    </div>

                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Picode</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.pincode}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Ownership Information
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.ownershipInfo}
                      </strong>
                    </div>

                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Nature of Business
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.natureBusiness}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Year of Commencement of Business
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.startYear}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Annual Sales</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.annualSales}
                      </strong>
                    </div>
                    {/* <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Number of Products</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.annualsale}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Do you sell in website</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.sellother}</strong>
        </div> */}

                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>PAN Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.panNo}
                      </strong>
                    </div>
                    {/* <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Do you have GST Number</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.gst}</strong>
        </div> */}
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>GST Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.gstNo}
                      </strong>
                    </div>
                    {/* <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>GST Upload</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.gstImage}</strong>
        </div> */}
                  </div>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={this.saveandContinue}
                      style={SmallButtonStyle}
                    >
                      Submit{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      type="submit"
                      onClick={this.props.prevStep}
                      style={SmallButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 0,
    height: "auto",
    margin: 3,
    width: "45%",
  },

  confirmtext: {
    display: "flex",
    flexDirection: "row",
  },
};
