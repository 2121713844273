import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/message";
import { SmallButtonStyle } from "../components/ButtonStyle";
import FarmerRafiki from "../assets/FarmerRafiki.png";
import { BackgroundGradientStyle } from "../components/BackgoundGradient";
import { password_reset_confirm } from "../actions/actionUser";
import { HOST } from "../services/host.header";
import axios from "axios";

function ResetPasswordConfirmScreen({ match, history, location }) {
  const [password, setPassword] = useState("");
  const [re_password, setRe_Password] = useState("");
  const [otp, setOtp] = useState(null)
  const [access, setAccess] = useState(null)
  const [passVisible, setPassVisible] = useState(false)
  const dispatch = useDispatch();

  const passwordResetConfirm = useSelector(
    (state) => state.passwordResetConfirm
  );
  const { loading, error, success } = passwordResetConfirm;

  useEffect(() => {
    // if (success) {
    //   history.push("/Login");
    // }
  }, [history, success]);

  const dis = (access, password) => new Promise((resolve, reject) => {
    dispatch(password_reset_confirm(access, password));
    resolve()
  })

  const submitHandler = (e) => {
    e.preventDefault();

    dis(access, password).then(() => {
      console.log("Success: Yesss...", success)
        alert("Your password has been changed successfully")
        history.push("/Login");
    })
  };

  const submitOtp = (e) => {
    e.preventDefault();

    const username = location.state.username
    console.log(username, "------")
    
    axios.post(`${HOST}/reset_pass_verify/`, { otp: otp, username: username})
          .then((res) => {
            console.log(res)
            if (res.status === 201){
              console.log("-----------")
              console.log(res)
              setAccess(res.data.access)
              console.log(access)
              setPassVisible(true)
            }
          })
  }

  return (
    <div id="root">
      <div style={BackgroundGradientStyle}>
        <div style={{ fontSize: 12 }}>
          {error && <Message variant="danger">{error}</Message>}
        </div>
        {loading && <Loader />}
        <div style={{ color: "black" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "30vw",
                color: "black",
                backgroundColor: "white",
                margin: "2%",
                padding: "2%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "auto",
              }}
            >
              <img
                alt="Farm Rafiki"
                src={FarmerRafiki}
                style={{ height: "25vw", width: "20vw", borderRadius: 0 }}
              />
            </div>

            <div
              style={{
                height: "fit-content",
                width: "30vw",
                backgroundColor: "white",
                margin: "2%",
                padding: "2%",
              }}
            >
              <strong style={{ color: "black", fontSize: "2vw" }}>
                Password Reset
              </strong>
              {passVisible ? 
              
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="password">
                  <Form.Label style={{ marginTop: "5%", fontSize: "1.5vw" }}>
                    Set new password
                  </Form.Label>
                  <Form.Control
                    style={{ borderRadius: 0, fontSize: "1vw" }}
                    type="password"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="re_password">
                  <Form.Label style={{ marginTop: "5%", fontSize: "1.5vw" }}>
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    style={{ borderRadius: 0, fontSize: "1vw" }}
                    type="password"
                    placeholder="Enter password again"
                    value={re_password}
                    onChange={(e) => setRe_Password(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <div
                  style={{
                    marginTop: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button type="submit" style={SmallButtonStyle}>
                    Submit{" "}
                    <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                  </Button>

                </div>
              </Form>
              :
              <Form onSubmit={submitOtp}>
              <Form.Group controlId="otp">
                <Form.Label style={{ marginTop: "5%", fontSize: "1.5vw" }}>
                  Password Reset
                </Form.Label>
                <Form.Control
                  style={{ borderRadius: 0, fontSize: "1vw" }}
                  type="numeric"
                  placeholder="Enter otp"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                ></Form.Control>
              </Form.Group>

              <div
                style={{
                  marginTop: "6%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button type="submit" style={SmallButtonStyle}>
                  Verify{" "}
                  <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                </Button>

                <div>
                  <a style={{ fontSize: "1vw" }} href="/Register">
                    Don't have an Account? Create account
                  </a>
                </div>
              </div>
            </Form>
            }
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default ResetPasswordConfirmScreen;
