import React, { useState, useEffect } from "react";
import "./style/farmequiprent.css";
import { Button, Form } from "react-bootstrap";
import { MediumButtonStyle, FindButtonStyle } from "../components/ButtonStyle";
import Image from "../assets/soiltestbackground.png"; // Import using relative path
import Avatar from "@mui/material/Avatar";
import { Paper, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import IndiaIcon from "../assets/india.svg";
import MapIcon from "../assets/map.svg";
import MarketIcon from "../assets/market.svg";
import Loader from "../components/Loader";
import axios from 'axios';
import { API_URL_STATE, API_URL_DISTRICT, API_URL_SOIL_TEST_LAB_DETAILS } from "../screens/Registers/Constants";

function SoilTest({history}) {
  const [isLoading, setLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [district, setDistrict] = useState([]);
  const [market, setMarket] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(null);

  const CustomStyle ={
    rootimg: {
      minHeight: 251,
      background: `linear-gradient(90deg, rgba(0, 0, 0, 0.81) 36.36%, rgba(93, 167, 202, 0) 98.89%) , url(${Image})`,
      backgroundSize: "cover",
    },
    ERR: {
      color: "red",
    },
    LoadingStyle: {
      pointerEvents: 'none',
      opacity: 0.7,
    },
  }

  useEffect(()=>{
    setLoading(true)
    axios
    .get(`${API_URL_STATE}${1}`)
    .then((result) => {
      setStates(result.data);
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    });
  },[])
  
  const getDistrict= async (state)=>{
    setDistrict([]);
    setMarket([]);
    setSelectedMarket(null);
    setLoading(true)
    await axios
    .get(`${API_URL_DISTRICT}${state}`)
    .then((result) => {
      setDistrict(result.data);
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    });

  }

  const getMarket= async (district)=>{
    setMarket([]);
    setSelectedMarket(null);
    let data ={
      "district_id" : district
    }
    setLoading(true)
    await axios
    .post(`${API_URL_SOIL_TEST_LAB_DETAILS}`,data)
    .then((result) => {
      setMarket(result.data['SoilTestingLabDetail'])
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    });

  }

  const submitButton = async () => {
    if (selectedMarket!=='false' && Boolean(selectedMarket)){
        setLoading(true)
         history.push({
           pathname:'/soiltestresult',
           state:{market: selectedMarket}
         })
    }
    else{
      alert('please fill the fields correctly')
    }
  };

  return (
    <div style = {isLoading ? CustomStyle.LoadingStyle : null }>
      {isLoading ? (
        <view
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            zIndex: 1,
            width: "100%",
            height: "10%",
          }}
        >
          <Loader />
        </view>
      ) : null}

      <section id="section1">
        <Paper style={CustomStyle.rootimg}>
          <div style={{ padding: 60 }}>
            <Typography
              style={{
                fontSize: 36,
                color: "white",
                marginBottom: 3,
                fontWeight: "bold",
              }}
            >
              SOIL TESTING LABS
            </Typography>
            <Typography
              style={{ fontSize: 26, color: "white", marginBottom: 18 }}
            >
              know soil testing labs near you
            </Typography>
            
            <a href="#section3">
            <Button
              style={FindButtonStyle}
            >
            {"Find now"}
            </Button>
            </a>

          </div>
        </Paper>
      </section>
      <section id="section2">
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          style={{ marginTop: 16, marginBottom: 23 }}
        >
          <Typography
            style={{
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
              color: "black",
            }}
          >
            <Avatar sx={{ bgcolor: "#BFE2BA", marginRight: 1 }}>
              <Typography style={{ color: "black", fontWeight: "bold" }}>
                1
              </Typography>
            </Avatar>
            Select your state
            <span style={{ marginLeft: 6 }}>
              <img height={26} src={IndiaIcon} alt="" />
            </span>
          </Typography>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
            }}
          >
            <Avatar sx={{ bgcolor: "#BFE2BA", marginRight: 1 }}>
              <Typography style={{ color: "black", fontWeight: "bold" }}>
                2
              </Typography>
            </Avatar>
            Choose your district
            <span style={{ marginLeft: 6 }}>
              <img height={30} src={MapIcon} alt=""/>
            </span>
          </Typography>

          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
            }}
          >
            <Avatar sx={{ bgcolor: "#BFE2BA", marginRight: 1 }}>
              <Typography style={{ color: "black", fontWeight: "bold" }}>
                3
              </Typography>
            </Avatar>
            Choose your market
            <span style={{ marginLeft: 6 }}>
              <img height={26} src={MarketIcon} alt=""/>
            </span>
          </Typography>
        </Stack>
      </section>

      <section
        id="section3"
        style={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          marginTop: "6vh",
          marginBottom: "18vh",
        }}
      >
        <view id="view1">
          <div
            id="rentalFrom"
            style={{ border: "1px solid #5DA7CA", padding: 23 }}
          >
            <Form.Group
              controlId="State"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                flexDirection: "column",
              }}
              disabled={isLoading}
            >
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                }}
                as="select"
                placeholder="SELECT YOUR STATE*"
                onChange={(event) => getDistrict(event.target.value)}
              >
                <option value={false}>SELECT YOUR STATE*</option>
                {
                  states.map((value, index)=>(
                    <option key={index} value={value.id}>{value.state}</option>
                  ))
                }
              </Form.Control>
              {/* <text style={CustomStyle.ERR}></text> */}
            </Form.Group>
            <Form.Group
              controlId="District"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                flexDirection: "column",
              }}
              disabled={isLoading}
            >
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                }}
                as="select"
                onChange={(event) => getMarket(event.target.value)}
              >
                <option value={false}>SELECT YOUR DISTRICT*</option>
                {
                  district.map((value, index)=>(
                    <option key={index} value={value.id}>{value.district}</option>
                  ))
                }
              </Form.Control>
              {/* <text style={styles.ERR}>{values.stateErr}</text> */}
            </Form.Group>
            <Form.Group
              controlId="Category"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                flexDirection: "column",
              }}
              disabled={isLoading}
            >
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                }}
                as="select"
                placeholder="Select Category"
                onChange={(event) => setSelectedMarket(event.target.value)}
              >
                <option value={false}>SELECT YOUR MARKET*</option>
                {
                  market.map((value, index)=>(
                    <option key={index}>{value.Market}</option>
                  ))
                }
              </Form.Control>
              {/* <text style={styles.ERR}>{values.stateErr}</text> */}
            </Form.Group>

            <view style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                style={MediumButtonStyle}
                onClick={() => submitButton()}
                disabled={isLoading}
              >
                {"Submit"}
              </Button>
            </view>
          </div>
        </view>
      </section>
    </div>
  );
}

export default SoilTest;
