// Farmer MainForm

import * as React from "react";
import DemoFarmer from "./demofarmer";
import Farmer from "./farmerinfo";
import Confirmation from "./Confirmation";
import Success from "../Success";
import axios from "axios";
import {
  API_URL_CWCBYUSERID,
  API_URL_FARMERS,
  API_URL_USERS,
} from "../Constants";
import FarmerReg from "./FarmerReg";
import { connect } from "react-redux";
import { withRouter } from "../../../components/Router/withRouter";
class FarmerMainViaCWC extends React.Component {
  NetInfoSubscription = null;

  constructor(props) {
    super(props);
    const { user } = this.props.user;
    //console.log("user", user);

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
    };

    this.state = {
      config: config,
      userID: user.id,
      step: 1,
      state: "",
      district: "",
      block: "",
      society: "",
      village: "",
      _firstname: "",
      _lastname: "",
      _fathersname: "",
      _gender: "",
      _idcardtype: "",
      _farmeridcard: "",
      _mobile: "",
      _rakba: null,
      _rakbaunit: "",
      _landparcelnumber: "",
      _address1: "",
      _address2: "",
      _postal: null,
      _password: "",
      _confpassword: "",
      _referredBy: "",
      id: null,
      setLoading: false,
      connection_status: true,
      Postsucc: false,
      stateErr: "",
      districtErr: "",
      blockErr: "",
      societyErr: "",
      villageErr: "",
      _firstnameErr: "",
      _lastnameErr: "",
      _fathersnameErr: "",
      _genderErr: "",
      _idcardtypeErr: "",
      _farmeridcardErr: "",
      _mobileErr: "",
      _rakbaErr: "",
      _rakbaunitErr: "",
      _landparcelnumberErr: "",
      _address1Err: "",
      _address2Err: "",
      _postalErr: "",
    };
  }

  componentDidMount = async () => {
    await axios
      .get(
        `${API_URL_CWCBYUSERID}`, this.state.config
      )
      .then((res) => {
        this.setState({ setLoading: false });
        //console.log("response-get-id", res.data[0]["id"]);
        this.setState({ _referredBy: res.data[0]["id"] });
      })
      .catch((err) => {
        //console.log("response-get-id", err);
      });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (input, val) => {
    //console.log(input);
    //console.log(val);
    this.setState({ [input]: val });
  };

  // upload Farmer data to backend

  uploadData = async () => {
    //console.log("inside upload data");

    const data = {
      state: this.state.state,
      district: this.state.district,
      block: this.state.block,
      // society: this.state.society.society_hin,
      villTownWard: this.state.village,
      firstName: this.state._firstname,
      lastName: this.state._lastname,
      fathersName: this.state._fathersname,
      idType: this.state._idcardtype,
      idNo: this.state._farmeridcard,
      mobileNo: this.state._mobile,
      rakba: this.state._rakba,
      rakba_unit: this.state._rakbaunit,
      landParcelNo: this.state._landparcelnumber,
      address1: this.state._address1,
      address2: this.state._address2,
      postal_code: this.state._postal,
      gender: this.state._gender,
      password: this.state._password,
      userID: 1338,
      // Farmer_RefereeID: this.state._referredBy,
    };
    //console.log("this.state._referredBy", this.state._referredBy);

    if (this.state._referredBy) {
      data.CWCID = this.state._referredBy;
    }

    let response = {};

    //console.log(data);

    await axios
      .post(API_URL_FARMERS, data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false });
        response = res;
      })
      .catch((err) => {
        response = err;
      });

    return response;
  };

  handleBackLogin = () => {
    this.props.navigation.navigate("Login");
  };

  handleNavMarketplace = () => {
    this.props.navigation.navigate("Marketplace");
  };

  handleConnectivityChange = (state) => {
    this.setState({ connection_status: state.isConnected });
  };

  handle_signup = () => {
    this.setState({ setLoading: true });
    const { _firstname, _lastname, _mobile } = this.state;
    const data = {
      first_name: _firstname,
      last_name: _lastname,
      email: "",
      phone: _mobile,
      role: "farmer",
      username: "",
      password: "",
    };

    //console.log("SignUP_Prev" + JSON.stringify(data));
    fetch(`${API_URL_USERS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((json) => {
        //console.log(json);
        this.setState({ id: json.id });
        this.nextStep();
      })
      .catch((err) => console.log(""));
  };

  render() {
    const { step } = this.state;
    // const { _firstname,_lastname} = this.state;
    console.log("cwcid::", this.state._referredBy);
    const values = this.state;

    switch (step) {
      case 1:
        return (
          <view>
            <FarmerReg
              values={values}
              CWCID={this.state._referredBy}
              handleChange={this.handleChange}
              handle_signup={this.handle_signup}
            />
          </view>
        );
      case 2:
        return (
          <view>
            <DemoFarmer
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              handleBackLogin={this.handleBackLogin}
              values={values}
              uploadData={this.uploadData}
            />
          </view>
        );
      case 3:
        return (
          <view>
            <Farmer
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              handleChange={this.handleChange}
              values={values}
              uploadData={this.uploadData}
            />
          </view>
        );

      case 4:
        return (
          <view>
            <Confirmation
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              uploadData={this.uploadData}
              handleChange={this.handleChange}
              values={values}
            />
          </view>
        );
      case 5:
        return (
          <view>
            <Success
              handleNavMarketplace={this.handleNavMarketplace}
              handleBackLogin={this.handleBackLogin}
            />
          </view>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});
export default withRouter(connect(mapStateToProps)(FarmerMainViaCWC));
