import React, { useState, useEffect } from "react";
import productService from "../services/product.service";
import { DesktopSection } from "../components/ProductCategoryDesktopSection";
import Loader from "../components/Loader";
import { useLocation } from "react-router";

function SearchScreen(){
  const location = useLocation()
  const { query } = location?.state
  const [product, setProduct] = useState([])
  const [category, setCategory] = useState()
  const [brand, setBrand] = useState()
  const [page, setPage] = useState(1)
  const [filterConfig, setFilterConfig] = useState()
  const [brandConfig, setBrandConfig] = useState()
  const [sortConfig, setSortConfig] = useState()
  const [loading, setLoading] = useState(true)

  const getProduct=()=>{
    setLoading(true)
    productService.getProducts('latest', page)
    .then((res)=>{
        setProduct(res.data)
        setLoading(false)
    })
    .catch((error)=>{
      console.log("getHomepageProducts error :: ", error)
      setLoading(false)
    })  
  }

  const getFilterProduct=(filterConfig)=>{
    setLoading(true)
    setFilterConfig(filterConfig)
    productService.getFilterProducts([filterConfig, sortConfig, brandConfig], page)
    .then((res)=>{
        setProduct(res.data)
        setLoading(false)
    })
    .catch((error)=>{
      console.log("getFilterProducts error :: ", error)
      setLoading(false)
    }) 

    // get-brand
    getBrand(filterConfig)
  }

  const getCategory=async(level, parentLevel, parentId)=>{
    // check - already fetched or not (childLevel, parentLevel-currentLevel, parentId)
    if(category?.[level]?.[0]?.subcategory === parentId){
      const { sub2category, sub3category, ...updateData} = category
      setCategory(updateData)
      setFilterConfig()
      setBrandConfig()
      getProduct()
      getBrand()
    }
    else if(category?.[level]?.[0]?.subsubcategory === parentId){
      const { sub3category, ...updateData} = category
      setCategory(updateData)
      setBrandConfig()
      
      getFilterProduct({'lable':parentLevel, 'value':parentId})
    }
    else if(level==null){
      getFilterProduct({'lable':parentLevel, 'value':parentId})
    }
    // otherwise fetch category data
    else{
      await productService.getCategory(level, parentId)
      .then((res)=>{
          setCategory({...category, [level] : res.data})
          setBrandConfig()
      })
      .catch((error)=>{
        console.log("getCategory error :: ", error)
      })
    //  get product
    getFilterProduct({'lable':parentLevel, 'value':parentId})
    }
  }

  const getInitialCategory=async(level, parentId)=>{
    let {data} = await productService.getCategory(level, parentId)
    return data
  }

  const getBrand=(filterConfig)=>{
    productService.getBrand(filterConfig)
                  .then((res)=>{
                    setBrand(res.data)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
  }

  const brandList=(action, brandId)=>{
    if(action==='add'){
      let updateBrandList = brandConfig ? [...brandConfig?.value, brandId] : [brandId]
      setBrandConfig({lable:'brand', value: updateBrandList})
      setPage(1)
    }
    if(action==='remove'){
      let updateBrandList =  brandConfig?.value.filter(id=> id !==brandId)
      brandConfig?.value?.length===1 ? setBrandConfig() : setBrandConfig({lable:'brand', value: updateBrandList})
      setPage(1)
    }
  }

  const clearFilter=()=>{
    setFilterConfig()
    const { sub2category, sub3category, ...updateData} = category
    setCategory(updateData)
    setBrandConfig()
    setSortConfig()
    getProduct()
  }

  const handlerSearch=()=>{
        setLoading(true)
        productService.searchHandler(query, page)
        .then((res)=>{
          setProduct(res.data)
          setLoading(false)
        })
        .catch((error)=>{
          console.log(error)
          setLoading(false)
      })
  }

  useEffect(()=>{
    // loading
    // setLoading(true)
    // get product
    query && handlerSearch(query)
    // search && filterConfig || brandConfig || sortConfig ? getFilterProduct(filterConfig) : getProduct()

    // get category
    !brand && getBrand()
    !category && Promise.all([getInitialCategory('category'), getInitialCategory('subcategory', 8)])
    .then((res)=>{
      setCategory({'category':res[0], 'subcategory':res[1]})
      // setLoading(false)
    })
    .catch((error)=>{
      console.log(error)
      // setLoading(false)
    })
  },[page, brand, category, query])

  return (
    <div>
      {loading && <Loader/>}
        <DesktopSection
         loading={loading}
         getCategory={getCategory}
         category={category}
         brand={brand}
         product={product.data}
         totalProduct={product.total_product}
         setPage={setPage}
         page={page}
         setFilterConfig={setFilterConfig}
         setSortConfig={setSortConfig}
         sortConfig={sortConfig}
         filterConfig={filterConfig}
         brandList={brandList}
         brandConfig={brandConfig}
         clearFilter={clearFilter}
        />
    </div>
  );
}

export default SearchScreen;
