import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_COMPANYBRANCHBYUSERID,
  API_URL_COMPANYBRANCH,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function LogisticsCompanyBranchInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    inchargeFname: "",
    inchargeLname: "",
    inchargeDesignation: "",
    companyBranch_address1: "",
    companyBranch_address2: "",
    companyBranch_district: "",
    companyBranch_state: "",
    companyBranch_country: "",
    companyBranch_postalcode: "",
    contactnumber: "",
    email: "",

    // ////////////////////////////////
    // error : null,
  });

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_CompanyBranch = async () => {
    try {
      axios.get(`${API_URL_COMPANYBRANCHBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            // logisticID : res.data[0].id,
            // CompanyBranchID: res.data[0].id,
            inchargeFname: res.data[0].inchargeFname,
            inchargeLname: res.data[0].inchargeLname,
            inchargeDesignation: res.data[0].inchargeDesignation,
            companyBranch_address1: res.data[0].address1,
            companyBranch_address2: res.data[0].address2,
            companyBranch_district: res.data[0].district,
            companyBranch_state: res.data[0].state,
            companyBranch_country: res.data[0].country,
            companyBranch_postalcode: res.data[0].postalcode,
            contactnumber: res.data[0].contactnumber,
            email: res.data[0].email,
            // logisticID: user.id,
            //    ////////////////////////////////////////

            //    userID: res.data[0].userID,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // console.log("inside upload data")

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let country_field = values.companyBranch_country;
    let state_field = values.companyBranch_state;
    // let district_obj =
    let district_field = values.companyBranch_district;
    //   let form_data = new FormData();

    let data = {
      //"inchargeUID": null,
      inchargeFname: values.inchargeFname,
      inchargeLname: values.inchargeLname,
      inchargeDesignation: values.inchargeDesignation,
      address1: values.companyBranch_address1,
      address2: values.companyBranch_address2,
      district: district_field,
      state: state_field,
      country: country_field,
      postalcode: parseInt(values.companyBranch_postalcode),
      contactnumber: parseInt(values.contactnumber),
      email: values.email,
      // "logisticID": user.id,
      // "userID":  values.userID
    };

    //   ////////////////////////////////////////////////////////

    let response = [];

    // console.log("config", config)

    await axios
      .post(API_URL_COMPANYBRANCH, data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_CompanyBranch();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_CompanyBranch();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-inchargeFname"
            value={values.inchargeFname}
            onChange={handleChange("inchargeFname")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Incharge-First-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-inchargeLname"
            value={values.inchargeLname}
            onChange={handleChange("inchargeLname")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Incharge-Last-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-inchargeDesignation"
            value={values.inchargeDesignation}
            onChange={handleChange("inchargeDesignation")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Incharge-Designation")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-companyBranch_address1"
            value={values.companyBranch_address1}
            onChange={handleChange("companyBranch_address1")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Add-Line")}1</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-companyBranch_address2"
            value={values.companyBranch_address2}
            onChange={handleChange("companyBranch_address2")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Add-Line")}2</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-companyBranch_country"
            value={values.companyBranch_country}
            onChange={handleChange("companyBranch_country")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("country")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-companyBranch_state"
            value={values.companyBranch_state}
            onChange={handleChange("companyBranch_state")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("state")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-companyBranch_district"
            value={values.companyBranch_district}
            onChange={handleChange("companyBranch_district")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("District")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-companyBranch_postalcode"
            value={values.companyBranch_postalcode}
            onChange={handleChange("companyBranch_postalcode")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Postal-Code")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-contactnumber"
            value={values.contactnumber}
            onChange={handleChange("contactnumber")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Mobile-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-email"
            value={values.email}
            onChange={handleChange("email")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Email")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        {/* ///////////////////////////////////////////////////// */}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LogisticsCompanyBranchInfo;
