import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_CWCBYUSERID,
  API_URL_CWC,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function CWCPersonalInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    // states: "",
    //   district: "",
    //   villTownWard: "",
    //   firmName: "",
    //   designation: "",
    //   firmAddress1: "",
    //   firmAddress2: "",
    //   postalCode: "",
    //   error: null,
    firstName: "",
    lastName: "",
    fathername: "",
    gender: "",
    //   block: "",
    dob: "",
    email: "",
    mobileNo: null,
    altMobileNo: null,
    idnumber: "",
    idcardtype: "",
    //   CWCID: null,
    //   userID: user.id,
    //   config: config,
    //   gstnumber: "",
    //   gst: "",
    //   pannumber: "",

    // fresher: false,
    // loading: true
  });

  const [showEdit, setShowEdit] = useState(false);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_CWC = async () => {
    try {
      axios.get(`${API_URL_CWCBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            // CWCID: res.data[0].id,
            firstName: res.data[0].firstName,
            lastName: res.data[0].lastName,
            // firmName: res.data[0].firmName,
            fathername: res.data[0].fatherName,
            gender: res.data[0].gender,
            mobileNo: res.data[0].mobileNo,
            idcardtype: res.data[0].idType,
            idnumber: res.data[0].idNo,
            // postalCode: res.data[0].postalCode,
            // idImage: idImage,
            // CWCImage: CWCImage,
            // firmAddress1: res.data[0].firmAddress1,
            // firmAddress2: res.data[0].firmAddress2,
            // designation: res.data[0].designation,
            email: res.data[0].email,
            altMobileNo: res.data[0].altMobileNo,

            // pannumber: res.data[0].panNo,
            // gstnumber: res.data[0].gstNo,
            // gst: res.data[0].haveGST === true ? "True" : "False",
            // gstCert: gstCert,
            // storageSpace: res.data[0].storageArea,

            // _storagespace: res.data[0].storageArea,
            // storageAreaImage: storageAreaImage,
            // storeFrontImage: storeFrontImage,
            // CWCLiveImage: CWCLiveImage,
            // villTownWard: res.data[0].villTownWard,
            // // society: res.data[0].society,
            // block: res.data[0].block,
            // district: res.data[0].district,
            // // states: res.data[0].state,
            // _postal: res.data[0].postalCode,
            // password: password,
            // paymentMode: paymentMode,
            // id: res.data[0].userID,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // console.log("inside upload data")

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();

    form_data.append("firstName", values.firstName);
    form_data.append("lastName", values.lastName);
    form_data.append("gender", values.gender);

    if (values.email && values.email !== null) {
      form_data.append("email", values.email);
    }
    if (values.mobileNo && values.mobileNo !== null) {
      form_data.append("mobileNo", parseInt(values.mobileNo));
    }
    if (values.altMobileNo && values.altMobileNo !== null) {
      form_data.append("altMobileNo", values.altMobileNo);
    }

    // form_data.append("password", values."Dummy");
    form_data.append("idType", values.idcardtype);
    form_data.append("idNo", values.idnumber);
    form_data.append("fatherName", values.fathername);
    // form_data.append("society", "Kanker");

    let response = [];

    // console.log("config", config)

    await axios
      .post(API_URL_CWC, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_CWC();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_CWC();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firstName"
            value={values.firstName}
            onChange={handleChange("firstName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("FirstName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-lastName"
            value={values.lastName}
            onChange={handleChange("lastName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("LastName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-fathername"
            value={values.fathername}
            onChange={handleChange("fathername")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("FathersName")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-gender"
            value={values.gender}
            onChange={handleChange("gender")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Gender")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-mobileNo"
            value={values.mobileNo}
            onChange={handleChange("mobileNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Mobile-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-altMobileNo"
            value={values.altMobileNo}
            onChange={handleChange("altMobileNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Alt-Mobile")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-email"
            value={values.email}
            onChange={handleChange("email")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Email")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-idcardtype"
            value={values.idcardtype}
            onChange={handleChange("idcardtype")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Id-Type")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-idnumber"
            value={values.idnumber}
            onChange={handleChange("idnumber")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Id-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CWCPersonalInfo;
