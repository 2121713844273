import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import { MediumButtonStyle } from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";

import {
  API_URL_STATE,
  API_URL_DISTRICT,
  API_URL_BLOCK,
  API_URL_VILLAGE,
} from "../Constants";
import axios from "axios";

export default class VendorReg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: [],
      district: [],
      block: [],
      society: [],
      village: [],
    };
  }

  countrylist = [{ title: "India" }];

  statelist = [{ title: "Chhattisgarh" }];
  citylist = [{ title: "Raipur" }];

  flatProps = {
    options: this.countrylist.map((option) => option.title),
    options: this.statelist.map((option) => option.title),
    options: this.citylist.map((option) => option.title),
  };
  defaultProps1 = {
    options: this.countrylist,
    getOptionLabel: (option) => option.title,
  };
  defaultProps2 = {
    options: this.statelist,
    getOptionLabel: (option) => option.title,
  };
  defaultProps3 = {
    options: this.citylist,
    getOptionLabel: (option) => option.title,
  };

  componentDidMount() {
    //console.log("step:", this.state.step);
  }

  componentDidUpdate = () => {
    //console.log(this.props.values);
    // console.log()
  };

  saveandContinue = async (e) => {

    if(this.props.values.firmName === '') {
       this.props.handleChange("firmNameError", true)}
    else{  
    this.props.handleChange("firmNameError", false)
    await this.props.uploadData();
    this.props.nextStep();
    }
  };

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  stateget = async (val) => {
    this.props.handleChange("country", val);

    if (val) {
      await axios
        .get(`${API_URL_STATE}${1}`)
        .then((res) => {
          this.setState({ state: res.data.reverse() });
          //console.log(this.state.state);
        })
        .catch((error) => {
          //console.warn(error);
        });
    }
  };

  distget = async (val) => {
    //console.log("knddd");
    const Value = val.split(",");
    //console.log("inside dist get", Value[0]);

    this.props.handleChange("state", Value[0]);

    var ids = Number(Value[1]);
    //console.log("ids", ids);

    axios
      .get(`${API_URL_DISTRICT}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("district"));
        this.setState({ district: result_sorted });
        //  console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  blockget = async (val) => {
    //console.log("inside block get");

    const Value = val.split(",");

    this.props.handleChange("companyBranch_district", Value[0]);
    var ids = Number(Value[1]);
    // var idd=id;

    axios
      .get(`${API_URL_BLOCK}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("block"));
        this.setState({ block: result_sorted });
        //  console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  villageget = async (val) => {
    // console.log('inside Village get: '+id);
    const Value = val.split(",");

    this.props.handleChange("block", Value[0]);
    var ids = Number(Value[1]);
    // var idso=id;

    axios
      .get(`${API_URL_VILLAGE}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("vill_town"));
        this.setState({ village: result_sorted });
        //console.log(this.state.village);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  render() {
    const {
      error,
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 1050,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Create Account
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Fill the below form to create a new Vendor account.
                  </Form.Label>

                  <Form.Group
                    controlId="firmName"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="firmName"
                      placeholder="Name of Firm/Company to be registered"
                      defaultValue={this.props.values.firmName}
                      onChange={(e) =>
                        this.props.handleChange("firmName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                  
                  {this.props.values.firmNameError ? <text style={{color:'red', fontSize:13}}>Firm name can't be empty</text> : null}
                  
                  <Form.Group
                    controlId="firmAddress1"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                    >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="firmAddress1"
                      placeholder="Registered Office Address Line 1"
                      defaultValue={this.props.values.firmAddress1}
                      onChange={(e) =>
                        this.props.handleChange("firmAddress1", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="firmAddress2"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="firmAddress2"
                      placeholder="Registered Office Address Line 2"
                      defaultValue={this.props.values.firmAddress2}
                      onChange={(e) =>
                        this.props.handleChange("firmAddress2", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="firstName"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="firstName"
                      placeholder="Authorised Person Name"
                      defaultValue={this.props.values.firstName}
                      onChange={(e) =>
                        this.props.handleChange("firstName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="mobileNo"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="mobileNo"
                      placeholder="Mobile Number (+91)"
                      defaultValue={this.props.values.mobileNo}
                      onChange={(e) =>
                        this.props.handleChange("mobileNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="email"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="email"
                      placeholder="Email ID"
                      defaultValue={this.props.values.email}
                      onChange={(e) =>
                        this.props.handleChange("email", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="website"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="website"
                      placeholder="Website"
                      defaultValue={this.props.values.website}
                      onChange={(e) =>
                        this.props.handleChange("website", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="landlineNo"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="landlineNo"
                      placeholder="Landline Number (Optional)"
                      defaultValue={this.props.values.landlineNo}
                      onChange={(e) =>
                        this.props.handleChange("landlineNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="businessAddress1"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="businessAddress1"
                      placeholder="Business Address Line 1"
                      defaultValue={this.props.values.businessAddress1}
                      onChange={(e) =>
                        this.props.handleChange(
                          "businessAddress1",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="businessAddress2"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="businessAddress2"
                      placeholder="Business Address Line 2"
                      defaultValue={this.props.values.businessAddress2}
                      onChange={(e) =>
                        this.props.handleChange(
                          "businessAddress2",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  {/* <Autocomplete style={{marginTop:-25, marginBottom:-10}}
                {...this.defaultProps1}
                id="country"
                onInputChange = {(e,v) => this.props.handleChange("country",v) }   
                renderInput={(params) => <TextField {...params} label="Country" margin="normal" />}
   
                   /> */}

                  <Form.Group
                    controlId="Country"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      as="select"
                      placeholder="Country"
                      onChange={(e) => this.stateget(e.target.value)}
                    >
                      <option>Select Country*</option>
                      <option>India</option>
                    </Form.Control>
                  </Form.Group>

                  {/* <Autocomplete style={{marginTop:-20, marginBottom:-5, borderBottomColor:"#5DA7CA"}}
                {...this.defaultProps2}
                id="state"
                onInputChange = {(e,v) => this.props.handleChange("state",v) }   
                  renderInput={(params) => <TextField {...params} label="State" margin="normal" />}
   
                   /> */}

                  <Form.Group
                    controlId="State"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      as="select"
                      placeholder="State"
                      onChange={(e) => this.distget(e.target.value)}
                    >
                      <option>Select State*</option>
                      {this.state.state &&
                        this.state.state.map((res) => (
                          <option key={res.id} value={[res.state, res.id]}>
                            {res.state}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  {/* 
            <Autocomplete style={{marginTop:-20, marginBottom:-5, borderBottomColor:"#5DA7CA"}}
                {...this.defaultProps3}
                id="district"
                onInputChange = {(e,v) => this.props.handleChange("district",v) }   
                  renderInput={(params) => <TextField {...params} label="district" margin="normal" />}
   
                   /> */}

                  <Form.Group
                    controlId="District"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      // type="firmName"
                      as="select"
                      placeholder="District"
                      onChange={(e) =>
                        this.props.handleChange("district", e.target.value)
                      }
                    >
                      <option>Select District*</option>
                      {this.state.district &&
                        this.state.district.map((res) => (
                          <option key={res.id} value={res.district}>
                            {res.district}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="pincode"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="pincode"
                      placeholder="Pin code"
                      defaultValue={this.props.values.firstName}
                      onChange={(e) =>
                        this.props.handleChange("pincode", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {console.log('print1', this.props.values.firmName)}
                    <Button
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Save & Continue{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.props.prevStep}
                      style={MediumButtonStyle}
                    >
                      Back to Login{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
