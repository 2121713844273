import ReactGA from "react-ga4";

const signUp=(data)=>{
  window.gtag("event", "sign_up", {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone:data.phone,
    role:data.role,
  });
    return 'success'
}

const loginAction=()=>{
     ReactGA.event({
            action: "login_action",
            category: "authentication_and_authorization",
            label: "login",
            value: 0,
        })
        return 'success'
}

const loggedInAction=(data)=>{
    window.gtag("event", "logged_in", {
        user_id: data.id,
        first_name: data.first_name,
        phone:data.phone,
        email: data.email,
        role: data.role
      });
        return 'success'
}

const search=(data)=>{
    console.log("search clicked :: ", data)
    window.gtag("event", "search_query", {
        query: data
      });
    return 'success'
}

const selectItem=(data)=>{
    console.log("select item clicked :: ", data)
    window.gtag("event", "select_item", {
        user_id: data.user.id,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        phone: data.user.phone,
        email: data.user.email,
        item_list_id: data.item_list_id,
        item_list_name: data.item_list_name,
        items: data.items
      });
    return 'success'
}

const addToWishlistAction=(data)=>{
    console.log("wishlist clicked :: ", data)
    window.gtag("event", "add_to_wishlist", {
        item_list_id: data.item_list_id,
        item_list_name: data.item_list_name,
        items: data.items
      });
    return 'success'
}

const addToCartAction=(data)=>{
    console.log("add to cart clicked :: ", data)
    window.gtag("event", "add_to_cart", {
        user_id: data.user.id,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        phone: data.user.phone,
        email: data.user.email,
        item_list_id: data.item_list_id,
        items: data.items
      });
    return 'success'
}

const proceedTocheckoutAction=(data)=>{
    console.log("proceed To checkout Action clicked :: ", data)
    window.gtag("event", "begin_checkout", {
        user_id: data.user.id,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        phone: data.user.phone,
        email: data.user.email,
        items: data.items
      });
    return 'success'
}

const purchaseItem=()=>{
    console.log("puchase event clicked")
    window.gtag("event", "purchase", {
        transaction_id: "T_12345",
    affiliation: "Google Merchandise Store",
    value: 25.42,
    tax: 4.90,
    shipping: 5.99,
    currency: "USD",
    coupon: "SUMMER_SALE",
    items: [
            {
            item_id: "SKU_12345",
            item_name: "Stan and Friends Tee",
            affiliation: "Google Merchandise Store",
            coupon: "SUMMER_FUN",
            currency: "USD",
            discount: 2.22,
            index: 0,
            item_brand: "Google",
            item_category: "Apparel",
            item_category2: "Adult",
            item_category3: "Shirts",
            item_category4: "Crew",
            item_category5: "Short sleeve",
            item_list_id: "related_products",
            item_list_name: "Related Products",
            item_variant: "green",
            location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            price: 9.99,
            quantity: 1
            },
            {
            item_id: "SKU_12346",
            item_name: "Google Grey Women's Tee",
            affiliation: "Google Merchandise Store",
            coupon: "SUMMER_FUN",
            currency: "USD",
            discount: 3.33,
            index: 1,
            item_brand: "Google",
            item_category: "Apparel",
            item_category2: "Adult",
            item_category3: "Shirts",
            item_category4: "Crew",
            item_category5: "Short sleeve",
            item_list_id: "related_products",
            item_list_name: "Related Products",
            item_variant: "gray",
            location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            price: 20.99,
            promotion_id: "P_12345",
            promotion_name: "Summer Sale",
            quantity: 1
            }]
      });
    return 'success'
}

const diseasePrediction=(data)=>{
  window.gtag("event", "disease_prediction", {
    first_name:data.user.first_name,
    last_name:data.user.last_name,
    phone:data.user.phone,
    email:data.user.email,
    role:data.user.role,
    crop: data.prediction_result.crop,
    disease: data.prediction_result.disease,
    accuracy_percentage: data.prediction_result.percentage,
  });
    return 'success'
}

export default {
    signUp,
    loginAction,
    loggedInAction,
    search,
    selectItem,
    addToWishlistAction,
    addToCartAction,
    proceedTocheckoutAction,
    purchaseItem,
    diseasePrediction
}