import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_BANKDETAILS,
  API_URL_BANKDETAILSBYUSERID,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function VendorPersonalInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    accountHolderName: "",
    bankName: "",
    accountType: "",
    accountNumber: "",
    branch: "",
    ifscCode: "",
    micr: "",
    city: "",
    BankPostal: null,
    // storagespace: "",
    CanceledChequeImage: null,
    VendorID: null,
    CompanyBranchID: null,
  });

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  ////////////////////////////////////////////////////////////////////////////////

  const UpdateData = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();

    form_data.append("bankName", values.bankName);
    form_data.append("branch", values.branch);
    form_data.append("city", values.city);
    if (values.BankPostal && values.BankPostal !== null) {
      //console.log("bios", values.BankPostal);
      form_data.append("postalCode", parseInt(values.BankPostal));
    }
    if (values.accountNumber && values.accountNumber !== null) {
      form_data.append("accountNumber", parseInt(values.accountNumber));
    }
    form_data.append("accountType", values.accountType);
    form_data.append("ifscCode", values.ifscCode);
    form_data.append("micr", values.micr);
    form_data.append("accountHolderName", values.accountHolderName);
    //form_data.append("CompanyBranchID", 1);//values.CompanyBranchID);
    if (values.VendorID) {
      form_data.append("VendorID", values.VendorID);
    }

    let response = -[];

    await axios
      .post(API_URL_BANKDETAILS, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_BankDetails();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((error) => {
        response = error;
        setLoading(false);
      });

    return response;
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const retrieve_BankDetails = async () => {
    try {
      axios.get(`${API_URL_BANKDETAILSBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            bankName: res.data[0].bankName,
            branch: res.data[0].branch,
            city: res.data[0].city,
            BankPostal: res.data[0].postalCode,
            accountType: res.data[0].accountType,
            accountNumber: res.data[0].accountNumber,
            ifscCode: res.data[0].ifscCode,
            micr: res.data[0].micr,
            // CanceledChequeImage: CanceledChequeImage,
            accountHolderName: res.data[0].accountHolderName,

            // setLoading: false,
            // CompanyBranchID: res.data[0].CompanyBranchID,
            // VendorID: values.VendorID,
            // CWCID: res.data[0].CWCID,
            // userID: res.data[0].userID
            // userID: 131
            // userID: res.data[0].userID
          });
          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      retrieve_BankDetails();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-accountHolderName"
            value={values.accountHolderName}
            onChange={handleChange("accountHolderName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Account-Holder-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-bankName"
            value={values.bankName}
            onChange={handleChange("bankName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Bank-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-accountType"
            value={values.accountType}
            onChange={handleChange("accountType")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Account-Type")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-accountNumber"
            value={values.accountNumber}
            onChange={handleChange("accountNumber")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Account-No")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-branch"
            value={values.branch}
            onChange={handleChange("branch")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Branch")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-ifscCode"
            value={values.ifscCode}
            onChange={handleChange("ifscCode")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("IFSC-Code")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-micr"
            value={values.micr}
            onChange={handleChange("micr")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("MICR-Code")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-city"
            value={values.city}
            onChange={handleChange("city")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("City")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-BankPostal"
            value={values.BankPostal}
            onChange={handleChange("BankPostal")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Postal-Code")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default VendorPersonalInfo;
