import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import RegistrationPersonalInfo from "./RegistrationPersonalInfo";
import RegistrationBusinessInfo from "./RegistrationBusinessInfo";
import RegistrationUploadedDocs from "./RegistrationUploadedDocs";
import RegistrationBankDetails from "./RegistrationBankDetails";
import LogisticsVehiclesInfo from "./LogisticsVehiclesInfo";
import LogisticsCompanyBranchInfo from "./LogisticsCompanyBranchInfo";
import LogisticsPartnerInfo from "./LogisticsPartnerInfo";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function ChangeProfile() {
  const classes = useStyles();
  const [collapse, setCollapse] = useState(false);
  const [collapseBank, setCollapseBank] = useState(false);
  const [collapseDocs, setCollapseDocs] = useState(false);
  const [collapseBusiness, setCollapseBusiness] = useState(false);
  const [collapseVehicles, setCollapseVehicles] = useState(false);
  const [collapseComapanyBranch, setCollapseComapanyBranch] = useState(false);
  const [collapsePartner, setCollapsePartner] = useState(false);
  const { t } = useTranslation();

  const {user} = useSelector((state) => state.auth);

  return (
    <div className={classes.root}>
      {
        (user && user.role === "farmer") ||
        user.role === "coordinator" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{ paddingTop: "4%", paddingLeft: "4%" }}
            >
              <button onClick={() => setCollapse(!collapse)}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("Personal-Info")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapse ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapse ? <RegistrationPersonalInfo /> : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }

      {
        (user && user.role === "vendor") ||
        user.role === "logistics" ||
        user.role === "coordinator" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{ paddingTop: "4%", paddingLeft: "4%" }}
            >
              <button onClick={() => setCollapseBusiness(!collapseBusiness)}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("Business-Info")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapseBusiness ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapseBusiness ? (
                  // <VendorBussinessInfo />
                  <RegistrationBusinessInfo />
                ) : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }

      {
        (user && user.role === "vendor") ||
        user.role === "logistics" ||
        user.role === "coordinator" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{ paddingTop: "4%", paddingLeft: "4%" }}
            >
              <button onClick={() => setCollapseBank(!collapseBank)}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("Bank-Info")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapseBank ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
              {/* <button style={{paddingLeft:'0px'}}>Hello World</button> */}
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapseBank ? <RegistrationBankDetails /> : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }

      {
        (user && user.role === "vendor") ||
        user.role === "logistics" ||
        user.role === "coordinator" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{ paddingTop: "4%", paddingLeft: "4%" }}
            >
              <button onClick={() => setCollapseDocs(!collapseDocs)}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("Uploaded-Ducuments")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapseDocs ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
              {/* <button style={{paddingLeft:'0px'}}>Hello World</button> */}
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapseDocs ? <RegistrationUploadedDocs /> : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }

      {
        user.role === "logistics" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{ paddingTop: "4%", paddingLeft: "4%" }}
            >
              <button onClick={() => setCollapseVehicles(!collapseVehicles)}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("Vehicles-Information")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapseVehicles ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
              {/* <button style={{paddingLeft:'0px'}}>Hello World</button> */}
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapseVehicles ? <LogisticsVehiclesInfo /> : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }

      {
        user.role === "logistics" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{ paddingTop: "4%", paddingLeft: "4%" }}
            >
              <button
                onClick={() =>
                  setCollapseComapanyBranch(!collapseComapanyBranch)
                }
              >
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("CompanyBranch-Info")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapseComapanyBranch ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
              {/* <button style={{paddingLeft:'0px'}}>Hello World</button> */}
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapseComapanyBranch ? <LogisticsCompanyBranchInfo /> : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }

      {
        user.role === "logistics" ? ( //if
          <>
            <FormControl
              fullWidth
              style={{
                paddingTop: "4%",
                paddingLeft: "4%",
                paddingBottom: "4%",
              }}
            >
              <button onClick={() => setCollapsePartner(!collapsePartner)}>
                <Grid container spacing={4}>
                  <Grid item xs={10}>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <text>{t("Partner-Info")}</text>
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    {!collapsePartner ? (
                      <i className="fas fa-angle-down" />
                    ) : (
                      <i className="fas fa-angle-up" />
                    )}
                  </Grid>
                </Grid>
              </button>
              {/* <button style={{paddingLeft:'0px'}}>Hello World</button> */}
            </FormControl>
            <Grid container spacing={4}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                {collapsePartner ? <LogisticsPartnerInfo /> : null}
              </Grid>

              <Grid item xs={1}></Grid>
            </Grid>
          </>
        ) : null //else
      }
    </div>
  );
}
