import React from "react";
import companyLogo from "../HeaderImages/Logo.png";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Card } from "react-bootstrap";

function Sponsored({ SponsoredProduct, category }) {
  let offPercent = null; //set offer in percent. 10 = 10% off,
  let offPrice = null;
  let MRP = null;
  let price = null;

  const matches = useMediaQuery("(min-width:600px)");

  const setNewOffPrice = (MRPprice, sellPrice) => {
    // console.log(MRPprice)
    // console.log(sellPrice)
    // if company providing MRP and offer in percent
    // offPrice = (MRPprice/100) * offPercent
    // offPrice = offPrice.toFixed(2)
    // newPrice = (MRPprice-offPrice).toFixed(2)

    // if company provinding MRP and Selling price          offpercent = (offPrice/MRPprice) * 100
    offPrice = (MRPprice - sellPrice).toFixed(2);
    // console.log('offPrice',offPrice)
    offPercent = (offPrice / MRPprice) * 100;
    offPercent = Math.round(offPercent);
    // console.log('offPercent',offPercent)
  };

  return (
    <div
      style={
        matches
          ? {
              position: "relative",
              border: "1px solid gray",
              borderLeft: 0,
              width: "fit-content",
              padding: 15,
            }
          : {
              position: "relative",
              border: "1px solid gray",
              borderLeft: 0,
              width: "100vw",
              padding: 15,
              paddingBottom: "10vw",
            }
      }
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Card.Img
          alt="Logo"
          src={companyLogo}
          style={
            matches
              ? { width: "180px", height: "25px" }
              : { width: "45vw", height: "3.5vh", paddingLeft: "2%" }
          }
        />
        <p
          style={{
            height: 30,
            margin: "0 10px",
            border: "1px solid #D3D3D3",
            width: 0,
          }}
        ></p>
        <h3
          style={
            matches
              ? { flexWrap: "wrap" }
              : {
                  whiteSpace: "nowrap",
                  fontSize: "3vh",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
          }
        >
          {category}
        </h3>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", margin: 0, padding: 0 }}
      >
        {SponsoredProduct.length > 3
          ? SponsoredProduct.slice(-5, -2).map((product) => (
              <div
                style={
                  matches
                    ? { display: "flex", justifyContent: "center" }
                    : {
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "4%",
                      }
                }
              >
                <div
                  style={
                    matches
                      ? {
                          width: "150px",
                          height: "150px",
                          padding: "5px",
                          margin: "5px",
                        }
                      : {
                          width: "30vw",
                          height: "20vh",
                          padding: "1%",
                          margin: "2%",
                        }
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Img
                      alt={product.name}
                      src={product.image}
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <text
                        style={{
                          whiteSpace: "nowrap",
                          fontSize: 10,
                          color: "red",
                          margin: 0,
                          pading: 0,
                        }}
                      >
                        Limited time Deal
                      </text>
                      <Link
                        to={`/productdetail/${product._id}/${product.pdt_type}/${
                          product.varID === null ||
                          product.pdt_type === "farmproduct" ||
                          product.pdt_type === "quotaionproduct"
                            ? "novar"
                            : product.varID
                        }`}
                      >
                        <Card.Title
                          style={{
                            margin: 0,
                            pading: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 13,
                            textAlign: "left",
                          }}
                        >
                          {product.name}
                        </Card.Title>
                      </Link>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Card.Text
                          as="h5"
                          style={{
                            fontSize: 13,
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          {
                            product.pdt_type === "farmproduct" ? (
                              "₹" + product.price_max
                            ) : //Start of pdt variation Logic
                            product &&
                              product.variations &&
                              product.variations.length > 0 ? ( //if
                              product.variations[0].sellingPrice ? ( //if
                                <>
                                  {
                                    ((MRP = product.variations[0].price),
                                    (price =
                                      product.variations[0].sellingPrice),
                                    setNewOffPrice(MRP, price))
                                  }
                                  {/* {console.log("products2", product.variations[0].sellingPrice)} */}
                                  ₹{Number(product.variations[0].sellingPrice)}
                                </>
                              ) : product.variations[0].price ? ( //else
                                <> ₹ {Number(product.variations[0].price)} </> //if
                              ) : (
                                <>Coming soon</>
                              )
                            ) : //end of pdt variation Logic
                            product.sellingPrice ? ( //if
                              <>
                                <Card.Text
                                  as="h5"
                                  style={{ fontSize: 12, textAlign: "left" }}
                                >
                                  {MRP != null ? (
                                    <>
                                      <strike>
                                        MRP:₹{Number(product.price)}
                                      </strike>
                                      <strong
                                        style={{
                                          fontSize: 12,
                                          color: "green",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {" "}
                                        {offPercent}% off
                                      </strong>
                                    </>
                                  ) : null}
                                </Card.Text>
                                ₹{Number(product.sellingPrice)}
                              </>
                            ) : product.price ? ( //else
                              <> ₹ {Number(product.price)} </> //if
                            ) : (
                              <>Coming soon</>
                            ) //else
                          }
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : SponsoredProduct.map((product) => (
              <div
                style={
                  matches
                    ? { display: "flex", justifyContent: "center" }
                    : {
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "8%",
                      }
                }
              >
                <div
                  style={
                    matches
                      ? {
                          width: "150px",
                          height: "150px",
                          padding: "5px",
                          margin: "5px",
                        }
                      : {
                          width: "30vw",
                          height: "20vh",
                          padding: "1%",
                          margin: "2%",
                        }
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Card.Img
                      alt={product.name}
                      src={product.image}
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <div
                    style={{
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <text
                        style={{
                          whiteSpace: "nowrap",
                          fontSize: 10,
                          color: "red",
                          margin: 0,
                          pading: 0,
                        }}
                      >
                        Limited time Deal
                      </text>
                      <Link to={`/product/${product._id}/commercialproduct`}>
                        <Card.Title
                          style={{
                            margin: 0,
                            pading: 0,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontSize: 13,
                            textAlign: "left",
                          }}
                        >
                          {product.name}
                        </Card.Title>
                      </Link>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Card.Text
                          as="h5"
                          style={{
                            fontSize: 13,
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          {
                            product.pdt_type === "farmproduct" ? (
                              "₹" + product.price_max
                            ) : //Start of pdt variation Logic
                            product &&
                              product.variations &&
                              product.variations.length > 0 ? ( //if
                              product.variations[0].sellingPrice ? ( //if
                                <>
                                  {
                                    ((MRP = product.variations[0].price),
                                    (price =
                                      product.variations[0].sellingPrice),
                                    setNewOffPrice(MRP, price))
                                  }
                                  {/* {console.log("products2", product.variations[0].sellingPrice)} */}
                                  ₹{Number(product.variations[0].sellingPrice)}
                                </>
                              ) : product.variations[0].price ? ( //else
                                <> ₹ {Number(product.variations[0].price)} </> //if
                              ) : (
                                <>Coming soon</>
                              )
                            ) : //end of pdt variation Logic
                            product.sellingPrice ? ( //if
                              <>
                                <Card.Text
                                  as="h5"
                                  style={{ fontSize: 12, textAlign: "left" }}
                                >
                                  {MRP != null ? (
                                    <>
                                      <strike>
                                        MRP:₹{Number(product.price)}
                                      </strike>
                                      <strong
                                        style={{
                                          fontSize: 12,
                                          color: "green",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {" "}
                                        {offPercent}% off
                                      </strong>
                                    </>
                                  ) : null}
                                </Card.Text>
                                ₹{Number(product.sellingPrice)}
                              </>
                            ) : product.price ? ( //else
                              <> ₹ {Number(product.price)} </> //if
                            ) : (
                              <>Coming soon</>
                            ) //else
                          }
                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            right: 0,
            bottom: 0,
          }}
        >
          <span style={{ fontSize: 15 }}>Sponsored</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default Sponsored;
