export const buttonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
};

export const ExtraBigButtonStyleMobile = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  fontSize: 8,
  borderRadius: 20,
  width: 110,
  whiteSpace: "nowrap",
};

export const ExtraBigButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
  width: 250,
  whiteSpace: "nowrap",
};

export const BigButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      170deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  // padding: 30,
  borderRadius: 20,
  width: 250,
  marginBottom: 20,
};

export const MediumButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
  width: 210,
};

export const MediumUploadButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  borderRadius: 50,
  width: 200,
  height: 38,
};

export const FindButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
  width: 150,
};
export const editButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
  width: 242,
  height: 48,
};

export const EditSearchButtonStyle = {
  "--color-1": "#BFE2BA",
  "--color-2": "#BFE2BA",
  background: `
  linear-gradient(

    var(--color-1),
    var(--color-2) 
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
  width: 150,
};

export const SmallButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  // Unrelated styles:
  color: "white",
  padding: "1.3rem",
  fontSize: 15,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 30,
  width: "8rem",
  height: "1rem",
  whiteSpace: "nowrap",
  margin:'2%'
};

export const SmallButtonStyleMobile = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  // Unrelated styles:
  color: "white",
  padding: "3%",
  fontSize: "1.5vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 30,
  width: "27vw",
  height: "3vh",
  whiteSpace: "nowrap",
};

export const LoginButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  // Unrelated styles:
  color: "white",
  padding: "3%",
  fontSize: "3.7vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 30,
  width: "23vw",
  height: "8vw",
  whiteSpace: "nowrap",
};

export const ExtraSmallButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,

  // Unrelated styles:
  color: "white",
  textAlign: "center",
  borderRadius: 20,
  border: "0px",
  width: 100,
  height: 35,
};

export const ExtraSmallButtonStyleMobile = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,

  // Unrelated styles:
  color: "white",
  textAlign: "center",
  // padding: 30,
  borderRadius: 20,
  fontSize: "1.6vh",
  border: "0px",
  width: 97,
  height: 25,
};

export const ExtraExtraSmallButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  // padding: 30,
  borderRadius: 3,
};

export const PredictionResetButton = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  color: "white",
  textAlign: "center",
  width: "8.9rem",
  height: "1.75rem",
  // padding: 30,
  borderRadius: 30,
  marginTop: "1.5%",
  marginRight: "18px",
};

export const PredictionResetButtonb = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
    linear-gradient(
      90deg,
      var(--color-1),
      var(--color-2) 90%
    )
  `,
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  borderRadius: 30,
  width: "8.9rem",
  height: "1.75rem",
};
export const CarouselButtonStyle = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  // padding: 30,
  borderRadius: 10,
  width: "15vw",
  // height:'5vh',
  // fontSize:'1vw',
  whiteSpace: "nowrap",
};

export const ShowMoreButton = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: `
  linear-gradient(
    170deg,
    var(--color-1),
    var(--color-2) 90%
  )
`,
  // Unrelated styles:
  color: "white",
  textAlign: "center",
  // padding: 30,
  borderRadius: 20,
  width: 130,
  fontSize: 13,
};

export const UnselectedButton = {
  "--color-1": "rgba(191, 226, 186, 1)",
  "--color-2": "rgba(92,167,201,1)",
  background: "white",

  // Unrelated styles:
  color: "black",
  textAlign: "center",
  // padding: 30,
  borderRadius: 20,
  border: "2px solid #000000",
  width: 120,
};
