import authService from '../services/auth.service';
import { jwtDecode } from 'jwt-decode';

export const login=(username, password)=>async(dispatch)=>{
    dispatch({type:'LOGIN_REQUEST'})
    authService.login(username, password)
               .then((res)=>{
                    localStorage.setItem('user', JSON.stringify(res.data))
                    dispatch(runLogoutTimer())
                    dispatch({
                        type:'LOGIN_SUCCESS',
                        payload:res.data
                    })
               })
               .catch((error)=>{
                dispatch({
                    type:'LOGIN_FAILED',
                    payload: JSON.parse(JSON.stringify(error)).message
                })
               })
};

export const logout=()=>(dispatch)=>{
    dispatch({type:'LOGOUT_REQUEST'})
    localStorage.removeItem("user")
    dispatch({type:'LOGOUT_SUCCESS'})
};

export const runLogoutTimer=()=>async(dispatch)=>{
    let user = await JSON.parse(localStorage.getItem('user'))
    if(user){
        const timer = new Date(jwtDecode(user.access).exp * 1000) - Date.now()
        setTimeout(()=>{
            dispatch(logout())
        }, timer)
        return Promise.resolve();
    }
    else{
        return Promise.reject();
    }
}
  

