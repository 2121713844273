import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

export default function PaginationRounded({page, setPage, totalProduct}){
  let limit = 10
  const classes = useStyles();
  const pagecount = Math.ceil(totalProduct / limit);

  return (
    <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
      <Pagination
        classes={{ ul: classes.ul }}
        page={page}
        size="small"
        count={pagecount}
        shape="rounded"
        color="red"
        variant="outlined"
        onChange={(event, value)=>{setPage(value)}}
      />
    </div>
  );
}

// style
const useStyles = makeStyles((theme) => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background:"linear-gradient(170deg, rgba(191, 226, 186, 1) , rgba(92,167,201,1) 90%)",
    },
  },
}));
