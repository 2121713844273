import React, { useState } from "react";
import { Row, Col, ListGroup, Button, Form, Modal } from "react-bootstrap";

// import "react-dropdown-now/style.css";
import { SmallButtonStyle } from "../../../components/ButtonStyle";
import { ML_MODEL_URL } from "../../../constants/api";
// import { listQNA } from "../../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
import axiosAPI from "../../../components/AxiosAPI";

// import { useTranslation } from "react-i18next";

function PredictionCheckPopup(props) {
  const  t  = props.t;
  let pest_name = props.pest_name;
  let user_id = props.user_id;
  let file_name = props.file_name;
  // console.log("value3");

  const setPredictionCheckModal = props.setPredictionCheckModal;
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState(null);

  const style = {
    fontWeight: 1000,
    color: "red"
  }

  const message = t("pest-feedback-1") + pest_name + t("pest-feedback-2")  

  const message_highlight = message.replace(
    new RegExp(pest_name),
      `<span style="color: black; font-weight: bold; font-style: italic;">${pest_name}</span>`  
  )

  const SaveData = async (answer) => {
    const uploadData = new FormData();

    uploadData.append("user_id", user_id);
    uploadData.append("file_name", file_name);
    uploadData.append("answer", answer);

    await axiosAPI
      .post(`${ML_MODEL_URL}pest-predict-feedback`, uploadData)
      .then(
        (res) =>
          // dispatch(listQNA(pdt_id, pdt_type)),
          alert(`Thank you for your valuable feedback`),
        setPredictionCheckModal(false)

        // props.handleOffer()
      )
      .catch((error) => console.log(error));
  };

  // {console.log("price"+d)}
  return (
    <Modal
      {...props}
      size="lg"
      // aria-labelledby="contained-modal-title-vcenter"
      top
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
        // id="contained-modal-title-vcenter"
        >
          Feedback
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup.Item>
          <Row>
          <div dangerouslySetInnerHTML={{ __html: message_highlight }}></div>
          </Row>
          {/* <Row>
            <Form.Control
              as="textarea"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></Form.Control>
          </Row> */}
        </ListGroup.Item>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col className="mb-3 mx-3">
            <Button style={SmallButtonStyle} onClick={props.onHide}>
              Close
            </Button>
          </Col>
          <Col className="mb-3 mx-3">
            <Button style={SmallButtonStyle} onClick={() => SaveData("Yes")}>
              Yes
            </Button>
          </Col>
          <Col className="mb-3 mx-3">
            <Button style={SmallButtonStyle} onClick={() => SaveData("No")}>
              No
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default PredictionCheckPopup;
