import productService from '../services/product.service';

export const homePageProducts=()=>(dispatch)=>{
    dispatch({type : 'HOME_PAGE_PRODUCT_REQUEST'})

    let type = 'homepage'
    let page = 1
    
    productService.getProducts(type, page)
                  .then((res)=>{
                    dispatch({
                        type : 'HOME_PAGE_PRODUCT_SUCCESS',
                        payload : res.data
                    })
                  })
                  .catch((error)=>{
                    dispatch({
                        type : 'HOME_PAGE_PRODUCT_FAILED',
                        payload : error?.response?.statusText
                    })
                  })
  };