import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ListItem from "@material-ui/core/ListItem";
import LanguageIcon from "@material-ui/icons/Language";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { logout } from "../actions/auth.action";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: "powderblue",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    button: {
      backgroundColor: "transparent",
    },
  },
  customBadge: {
    backgroundColor: "#5DA7CA",
    color: "white",
  },
  dropDown: {
    "&:hover": {
      background:
        "linear-gradient(170deg, rgba(191, 226, 186, 1) , rgba(92,167,201,1) 90%)",
    },
  },
}));

export const DesktopToolbar=({count})=>{
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState(null);
  const { t, i18n } = useTranslation();
  const {user} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isMenuOpen = Boolean(anchorEl);
  const isLangOpen = Boolean(language);
  let navigate = useNavigate();
  const cartitemlist = useSelector((state) => state.cartitemlist);
  const { CartItems } = cartitemlist;
  const Products = CartItems;
  const wishlist = useSelector((state) => state.wishlist);
  const { WishlistItems } = wishlist;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLanguage = (event) => {
    setLanguage(event.currentTarget);
  };

  const handleMenuClose = () => {
    <Navigate to={`/${user?.role}dashboard`} />;
    setAnchorEl(null);
  };

  const handleLangClose = () => {
    setLanguage(null);
  };

  const redirectMyDashbord = () => {
      navigate('/dashboard');
  };
  const redirectMyAccount = () => {
    if (user) {
      navigate("/account");
    } else {
      navigate("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  };
  const wishlistHandler = () => {
      navigate("/wishlist")
  };

  const cartHandler = () => {
      navigate("/cart")
  };

  const logoutHandler = () => {
    dispatch(logout());
    <Navigate to="/" />;
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      user={user}
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} className={classes.dropDown}>
        <ListItemLink
          onClick={() => redirectMyDashbord()}
          disableTouchRipple="true"
          style={{ backgroundColor: "transparent" }}
        >
          {t("MyDashboard")}
        </ListItemLink>
      </MenuItem>
      <MenuItem onClick={handleMenuClose} className={classes.dropDown}>
        <ListItemLink
          onClick={() => redirectMyAccount()}
          disableTouchRipple="true"
          style={{ backgroundColor: "transparent" }}
        >
          {t("MyAccount")}
        </ListItemLink>
      </MenuItem>
      {user?.role === "coordinator" ? (
        <MenuItem onClick={handleMenuClose} className={classes.dropDown}>
          <ListItemLink
            onClick={() =>
              navigate(
                user?.role === "coordinator"
                  ? `/farmerViaCWC?id=${user?.id}`
                  : "/"
              )
            }
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          >
            {"Farmer Registration"}
          </ListItemLink>
        </MenuItem>
      ) : user?.role === "vendor" ? (
        <MenuItem onClick={handleMenuClose} className={classes.dropDown}>
          <ListItemLink
            href={
              user?.role === "vendor"
                ? "https://vendor-panel-dot-cropwaybackend.el.r.appspot.com/admin/"
                : null
            }
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          >
            {"Add Product"}
          </ListItemLink>
        </MenuItem>
      ) : null}
      <MenuItem onClick={handleMenuClose} className={classes.dropDown}>
        <ListItemLink
          onClick={() => wishlistHandler()}
          disableTouchRipple="true"
          style={{ backgroundColor: "transparent", textAlign: "center" }}
        >
          {t("Wishlist")}
        </ListItemLink>
      </MenuItem>
      <MenuItem onClick={logoutHandler} className={classes.dropDown}>
        <ListItemLink
          disableTouchRipple="true"
          style={{ backgroundColor: "transparent" }}
        >
          {t("Logout")}
        </ListItemLink>
      </MenuItem>
    </Menu>
  );

  const langId = "account-menu";
  const renderLang = (
    <Menu
      anchorEl={language}
      id={langId}
      open={isLangOpen}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleLangClose}
    >
      <MenuItem
        className={classes.dropDown}
        onClick={() => {
          i18n.changeLanguage("en");
          handleLangClose();
        }}
      >
        English
      </MenuItem>
      <MenuItem
        className={classes.dropDown}
        onClick={() => {
          i18n.changeLanguage("hi");
          handleLangClose();
        }}
      >
        हिंदी
      </MenuItem>
    </Menu>
  );

  function ListItemLink(props) {
    return (
      <ListItem
        classes={{ root: classes.root }}
        button
        component="a"
        {...props}
      />
    );
  }
  return (
    <div className={classes.sectionDesktop}>
      <Box style={{ width: '100%' }}>
        <IconButton
          disableFocusRipple="true"
          className={classes.gigi}
          edge="end"
          aria-label="account of current user"
          aria-controls={langId}
          aria-haspopup="true"
          onClick={handleLanguage}
          color="inherit"
          style={{ backgroundColor: "transparent" }}
          disableTouchRipple="true"
        >
          <Box>
            <LanguageIcon />
            <Typography> {t("Language")}</Typography>
          </Box>
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          style={{ backgroundColor: "transparent" }}
          disableFocusRipple="true"
          // disableRipple="true"
          disableTouchRipple="true"
        >
          <ListItemLink
            onClick={() => {
              cartHandler();
            }}
            style={{ backgroundColor: "transparent" }}
            disableTouchRipple="true"
          >
            <Box>
              {user ? (
                <Badge
                  badgeContent={count?.cart} //{cartitems}
                  classes={{ badge: classes.customBadge }}
                >
                  <ShoppingCartIcon />
                </Badge>
              ) : (
                <ShoppingCartIcon />
              )}

              <Typography>{t("Cart")}</Typography>
            </Box>
          </ListItemLink>
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          style={{
            backgroundColor: "transparent",
            alignItems: "center",
            textAlign: "center",
            alignContent: "center",
          }}
          disableTouchRipple="true"
        >
          <ListItemLink
            onClick={() => wishlistHandler()}
            style={{ backgroundColor: "transparent" }}
            disableTouchRipple="true"
          >
            <Box style={{ marginLeft: -20 }}>
              {user ? (
                <Badge
                  badgeContent={count?.wishlist}
                  classes={{ badge: classes.customBadge }}
                >
                  <FavoriteBorderIcon />
                </Badge>
              ) : (
                <FavoriteBorderIcon />
              )}

              <Typography>{t("Wishlist")}</Typography>
            </Box>
          </ListItemLink>
        </IconButton>

        {user ? (
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            style={{ backgroundColor: "transparent" }}
            disableTouchRipple="true"
          >
            <Box textAlign="start">
              <Box
                style={{
                  backgroundColor: "transparent",
                  textAlign: "center",
                }}
              >
                <AccountCircle />
                <Typography>{t("Hi") + ", " + user?.first_name}</Typography>
              </Box>
            </Box>
          </IconButton>
        ) : (
          <IconButton
            edge="end"
            color="inherit"
            style={{ backgroundColor: "transparent" }}
            disableTouchRipple="true"
          >
            <ListItemLink
              onClick={() => navigate('login')}
              style={{ backgroundColor: "transparent" }}
              disableTouchRipple="true"
            >
              <Box textAlign="start">
                <Box
                  style={{
                    backgroundColor: "transparent",
                    textAlign: "center",
                  }}
                >
                  <AccountCircle />
                  <Typography>{t("Login")}</Typography>
                </Box>
              </Box>
            </ListItemLink>
          </IconButton>
        )}
      </Box>
      {renderMenu}
      {renderLang}
    </div>
  );
};

//////////////////////////////////////////////////////////////
