import {
  FARMER_LISTED_PRODUCT_LIST_REQUEST,
  FARMER_LISTED_PRODUCT_LIST_SUCCESS,
  FARMER_LISTED_PRODUCT_LIST_FAIL,
  NEGOTIATION_LIST_REQUEST,
  NEGOTIATION_LIST_SUCCESS,
  NEGOTIATION_LIST_FAIL,
  QUOTATION_LIST_REQUEST,
  QUOTATION_LIST_SUCCESS,
  QUOTATION_LIST_FAIL,
  QUOTATION_VENDOR_AS_CUSTOMER_LIST_REQUEST,
  QUOTATION_VENDOR_AS_CUSTOMER_LIST_SUCCESS,
  QUOTATION_VENDOR_AS_CUSTOMER_LIST_FAIL,
  PREVIOUS_TRADE_LIST_REQUEST,
  PREVIOUS_TRADE_LIST_SUCCESS,
  PREVIOUS_TRADE_LIST_FAIL,
} from "../constants/DashboardConstants";

export const FarmerListedProductReducer = (
  state = { FarmerListedProducts: [] },
  action
) => {
  switch (action.type) {
    case FARMER_LISTED_PRODUCT_LIST_REQUEST:
      return { FarmerListedProductsLoading: true, FarmerListedProducts: [] };
    case FARMER_LISTED_PRODUCT_LIST_SUCCESS:
      return {
        FarmerListedProductsLoading: false,
        FarmerListedProducts: action.payload,
      };
    case FARMER_LISTED_PRODUCT_LIST_FAIL:
      return {
        FarmerListedProductsLoading: false,
        FarmerListedProductsError: action.payload,
      };
    default:
      return state;
  }
};

export const NegotiationListReducer = (
  state = { Negotiations: [] },
  action
) => {
  switch (action.type) {
    case NEGOTIATION_LIST_REQUEST:
      return { NegotiationsLoading: true, Negotiations: [] };
    case NEGOTIATION_LIST_SUCCESS:
      return {
        NegotiationsLoading: false,
        Negotiations: action.NegotiationData,
      };
    case NEGOTIATION_LIST_FAIL:
      return {
        NegotiationsLoading: false,
        NegotiationsError: action.NegotiationData,
      };
    default:
      return state;
  }
};

export const QuotationListReducer = (state = { Quotations: [] }, action) => {
  switch (action.type) {
    case QUOTATION_LIST_REQUEST:
      return { QuotationsLoading: true, Quotations: [] };
    case QUOTATION_LIST_SUCCESS:
      return { QuotationsLoading: false, Quotations: action.QuotationData };
    case QUOTATION_LIST_FAIL:
      return {
        QuotationsLoading: false,
        QuotationsError: action.QuotationData,
      };
    default:
      return state;
  }
};

export const QuotationVendorasCustomerListReducer = (
  state = { QuotationsVendorasCustomer: [] },
  action
) => {
  switch (action.type) {
    case QUOTATION_VENDOR_AS_CUSTOMER_LIST_REQUEST:
      return {
        QuotationsVendorasCustomerLoading: true,
        QuotationsVendorasCustomer: [],
      };
    case QUOTATION_VENDOR_AS_CUSTOMER_LIST_SUCCESS:
      return {
        QuotationsVendorasCustomerLoading: false,
        QuotationsVendorasCustomer: action.QuotationData,
      };
    case QUOTATION_VENDOR_AS_CUSTOMER_LIST_FAIL:
      return {
        QuotationsVendorasCustomerLoading: false,
        QuotationsVendorasCustomerError: action.QuotationData,
      };
    default:
      return state;
  }
};

export const PreviousTradesListReducer = (
  state = { PreviousTrades: [] },
  action
) => {
  switch (action.type) {
    case PREVIOUS_TRADE_LIST_REQUEST:
      return { PreviousTradesLoading: true, PreviousTrades: [] };
    case PREVIOUS_TRADE_LIST_SUCCESS:
      return {
        PreviousTradesLoading: false,
        PreviousTrades: action.PreviousTradesData,
      };
    case PREVIOUS_TRADE_LIST_FAIL:
      return {
        PreviousTradesLoading: false,
        PreviousTradesError: action.PreviousTradesData,
      };
    default:
      return state;
  }
};
