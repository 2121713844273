// order service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const postOrderData=(data)=>{
    return axios.post(`${API_URL}/orderentry`, data,
    {
      headers : authHeader()
    }
    )
  }

  const confirmOrderHandler=(orderId)=>{
    return axios.post(`${API_URL}/confirmorder`,{ orderId : orderId },
    {
      headers : authHeader()
    }
    )
  }
  
const failedOrderHandler=(orderId)=>{
    return axios.post(`${API_URL}/failedorder`,{ orderId : orderId },
    {
      headers : authHeader()
    }
    )
  }
  const updateOrderStatus=(data)=>{
    return axios.put(`${API_URL}/api/sellerstudio/updateorderstatus/`,data,
    {
      headers : authHeader()
    }
    )
  }

const getOrderDataByOrderId=(orderId)=>{
    return axios.get(`${API_URL}/orderentry`,
    {
      headers : authHeader(),
      params : {order_id : orderId}
    }
    )
  }

const getOrderDataById=(id)=>{
    return axios.get(`${API_URL}/orderentry`,
    {
      headers : authHeader(),
      params : {id : id}
    }
    )
  }

const getOrderDataByUser=(page)=>{
      return axios.get(`${API_URL}/orderentry`,
      {
        headers : authHeader(),
        params : {page : page}
      }
      )
    }
  
const exportedObject={
    postOrderData,
    confirmOrderHandler,
    failedOrderHandler,
    updateOrderStatus,
    getOrderDataByOrderId,
    getOrderDataById,
    getOrderDataByUser
  }

  export default exportedObject