// Farmer Page 1
import { Button, Form } from "react-bootstrap";
import {
  SmallButtonStyle,
} from "../../../components/ButtonStyle";
import React, { Component } from "react";
import axios from "axios";
import {
  API_URL_STATE,
  API_URL_DISTRICT,
  API_URL_BLOCK,
  API_URL_VILLAGE,
} from "../Constants";
import Loader from "../../../components/Loader";
class DemoFarmer extends Component {
  state = {
    state: [],
    district: [],
    block: [],
    society: [],
    village: [],
  };

  // saves the data @backend and jumps to next part

  saveAndContinue = async (e) => {
    //console.log("save and continue");
    e.preventDefault();

    this.setState({ isLoading: true });
    let response = await this.props.uploadData();
    //console.log(response);
    if (response.status == 201) {
      this.setState({ isLoading: false, toastVisible: true });
      this.props.nextStep();
    } else {
      alert("Please check the connectivity..!!");
    }
    // this.Validation();
  };

  // back = (e) => {
  //   console.log("back");

  //   e.preventDefault();
  //   this.props.prevStep();
  // };

  Validation = async () => {
    if (
      this.props.values.state == "" ||
      this.props.values.district == "" ||
      this.props.values.block == "" ||
      // this.props.values.society == "" ||
      this.props.values.village == ""
    ) {
      if (this.props.values.state == "") {
        this.props.handleChange("stateErr", "State field can't be empty");
      } else {
        this.props.handleChange("stateErr", "");
      }

      if (this.props.values.district == "") {
        this.props.handleChange("districtErr", "District field can't be empty");
      } else {
        this.props.handleChange("districtErr", "");
      }

      if (this.props.values.block == "") {
        this.props.handleChange("blockErr", "Block field can't be empty");
      } else {
        this.props.handleChange("blockErr", "");
      }

      if (this.props.values.village == "") {
        this.props.handleChange("villageErr", "Village field can't be empty");
      } else {
        this.props.handleChange("villageErr", "");
      }
    } else {
      //clearing all error field

      this.props.values.stateErr = "";
      this.props.values.districtErr = "";
      this.props.values.blockErr = "";
      this.props.values.societyErr = "";
      this.props.values.villageErr = "";

      this.setState({ isLoading: true });
      let response = await this.props.uploadData();
      //console.log(response);
      if (response.status == 201) {
        this.setState({ isLoading: false, toastVisible: true });
        this.props.nextStep();
      } else {
        alert("Please check the connectivity..!!");
      }
    }
  };

  componentDidMount = () => {
    this.stateget();
  };

  // utility function to sort the object as per prop (key)

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  // retrieves list of states from backend

  stateget = async () => {
    await axios
      .get(`${API_URL_STATE}${1}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("state"));
        this.setState({ state: result_sorted }, () => {
          // console.log("console: ", this.state.state);
          this.wait();
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  // waits till data already filled has not been retrieved

  wait = () => {
    if (!this.props.values.state) {
      if (!this.props.values.fresher) {
        setTimeout(this.wait, 100);
      } else {
        this.distget();
      }
    } else {
      this.distget();
    }
  };

  distget = async (val) => {
    //console.log("knddd", val);
    if (val) {
      const Value = await val.split(",");
      //console.log("inside dist get", Value[0]);
      this.props.handleChange("state", Value[0]);

      var ids = Number(Value[1]);

      axios
        .get(`${API_URL_DISTRICT}${ids}`)
        .then((result) => {
          let result_sorted = result.data.sort(this.sort_object("district"));
          this.setState({ district: result_sorted });
          //  console.log(this.state.district);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  blockget = async (val) => {
    //console.log("inside block get");

    const Value = val.split(",");

    this.props.handleChange("district", Value[0]);
    var ids = Number(Value[1]);
    // var idd=id;

    axios
      .get(`${API_URL_BLOCK}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("block"));
        this.setState({ block: result_sorted });
        //  console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  villageget = async (val) => {
    // console.log('inside Village get: '+id);
    const Value = val.split(",");

    this.props.handleChange("block", Value[0]);
    var ids = Number(Value[1]);
    // var idso=id;

    axios
      .get(`${API_URL_VILLAGE}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("vill_town"));
        this.setState({ village: result_sorted });
        //console.log(this.state.village);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  saveAndContinue = async (e) => {
    //console.log("save and continue");
    e.preventDefault();
    this.Validation();
  };

  render() {
    const { values } = this.props;
    return (
      <view style={styles.regfarmer}>
        {this.state.isLoading && <Loader />}
        <view style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%", marginTop: 15 }}>
            <text style={styles.header}>Create Account</text>
          </div>
          {/* <div style={{marginTop:25,display:'flex',}}>
                <Button type="submit" style={{...MediumButtonStyle,justifyContent:'center',alignItems:'center'}}>
                  Back to Login <i style={{marginLeft:5}} className="fas fa-angle-left"/>
                </Button> 
            </div> */}
        </view>

        <text
          style={{
            display: "flex",
            fontSize: 13,
            color: "#000",
            marginBottom: 50,
          }}
        >
          Fill the below form to create a new Farmer account.
        </text>

        <Form.Group
          controlId="State"
          style={{
            borderRadius: 0,
            marginTop: 20,
            border: 0,
            borderBottom: "1px solid #5DA7CA",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Control
            style={{
              width: 750,
              borderRadius: 0,
              fontSize: 15,
              boxShadow: "none",
              paddingLeft: 0,
              border: 0,
            }}
            // type="firmName"
            as="select"
            placeholder="Select State"
            // value={values.firmName}
            // defaultValue={this.props.values.firmName}
            onChange={(e) => this.distget(e.target.value)}
          >
            <option>Select *</option>
            {this.state.state.map((res) => (
              <option key={res.id} value={[res.state, res.id]}>
                {res.state}
              </option>
            ))}
          </Form.Control>
          <text style={styles.ERR}>{values.stateErr}</text>
        </Form.Group>

        <Form.Group
          controlId="District"
          style={{
            borderRadius: 0,
            marginTop: 20,
            border: 0,
            borderBottom: "1px solid #5DA7CA",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Control
            style={{
              width: 750,
              borderRadius: 0,
              fontSize: 15,
              boxShadow: "none",
              paddingLeft: 0,
              border: 0,
            }}
            // type="firmName"
            as="select"
            placeholder="Select City"
            // value={values.firmName}
            // defaultValue={this.props.values.firmName}
            onChange={(e) => this.blockget(e.target.value)}
          >
            <option>Select *</option>
            {this.state.district.map((res) => (
              <option key={res.id} value={[res.district, res.id]}>
                {res.district}
              </option>
            ))}
          </Form.Control>
          <text style={styles.ERR}>{values.districtErr}</text>
        </Form.Group>

        <Form.Group
          controlId="Block"
          style={{
            borderRadius: 0,
            marginTop: 20,
            border: 0,
            borderBottom: "1px solid #5DA7CA",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Control
            style={{
              width: 750,
              borderRadius: 0,
              fontSize: 15,
              boxShadow: "none",
              paddingLeft: 0,
              border: 0,
            }}
            // type="firmName"
            as="select"
            placeholder="Select Block"
            // value={values.firmName}
            // defaultValue={this.props.values.firmName}
            onChange={(e) => this.villageget(e.target.value)}
          >
            <option>Select *</option>
            {this.state.block.map((res) => (
              <option key={res.id} value={[res.block, res.id]}>
                {res.block}
              </option>
            ))}
          </Form.Control>
          <text style={styles.ERR}>{values.blockErr}</text>
        </Form.Group>

        <Form.Group
          controlId="Village"
          style={{
            borderRadius: 0,
            marginTop: 20,
            border: 0,
            borderBottom: "1px solid #5DA7CA",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Control
            style={{
              width: 750,
              borderRadius: 0,
              fontSize: 15,
              boxShadow: "none",
              paddingLeft: 0,
              border: 0,
            }}
            // type="firmName"
            as="select"
            placeholder="Village/Town/Ward"
            // value={values.firmName}
            // defaultValue={this.props.values.firmName}
            onChange={(e) => this.props.handleChange("village", e.target.value)}
          >
            <option>Select *</option>
            {this.state.village.map((res) => (
              <option key={res.id} value={res.vill_town}>
                {res.vill_town}
              </option>
            ))}
          </Form.Control>
          <text style={styles.ERR}>{values.villageErr}</text>
        </Form.Group>

        <div
          style={{
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            onClick={this.saveAndContinue}
            style={SmallButtonStyle}
          >
            Save And Continue{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
          </Button>
        </div>
        {/* <view>
            {this.state.toastVisible && <ToastDefined message="Data saved..!!!" />}
          </view> */}
      </view>
    );
  }
}

const styles = {
  regfarmer: {
    paddingTop: 50,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },

  header: {
    display: "flex",
    fontSize: 24,
    color: "#000",
  },

  itemborder: {
    borderBottomWidth: 2,
    borderBottomColor: "#bfe2ba",
  },

  pickerStyle: {
    height: 60,
    width: "80%",
    color: "black",
    alignSelf: "center",
  },

  LoginPgButtonStyle: {
    marginLeft: 0,
    width: 100,
    height: 30,
    alignSelf: "center",
    borderRadius: 50,
    backgroundColor: "#bfe2ba",
  },

  btntext: {
    marginTop: 5,
    height: 20,
    color: "#4D4D4D",
    textAlign: "center",
    fontWeight: "bold",
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: 350,
  },
  background2: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 15,
    height: 30,
  },

  background3: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 20,
    height: 40,
  },
  ERR: {
    color: "red",
  },
};

export default DemoFarmer;
