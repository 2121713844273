import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import {
  MediumButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
export default class FarmerReg3 extends React.Component {
  state = {
    firstname: "",
    lastname: "",
    fathername: "",
    gender: "",
    dob: "",
    phone: "",
    idtype: "",
    idnumber: "",
    rakba: "",
    error: null,
    address: "",
    states: "",
    district: "",
    block: "",
    village: "",
    freight: "",
    society: "",
    paymentmode: "",
  };

  saveandContinue = async (e) => {
    await this.props.uploadData();
    this.props.nextStep();
  };

  back = () => {
    this.props.prevStep();
  };

  render() {
    const {
      error,
    } = this.state;
    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 790,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Preview Summary{" "}
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Submit to create a new Farmer account.
                  </Form.Label>

                  <div style={{ marginTop: 25 }}>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>First Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.firstName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Last Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.lastName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Father Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.fathersName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Gender</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.gender}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Mobile</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.mobileNo}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Id Type</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.idType}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Id Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.idNo}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Rakba</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.rakba}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Address</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.address}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>State</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.states}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>District</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.district}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Block</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.block}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Village</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.villTownWard}
                      </strong>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Submit{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.back}
                      type="submit"
                      style={MediumButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 0,
    height: "auto",
    margin: 3,
    width: "45%",
  },

  confirmtext: {
    display: "flex",
    flexDirection: "row",
  },
};
