// Data service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const postWishlist=(id)=>{
    return axios.post(`${API_URL}/addtowishlist`, {variation_id : id},
      {
        headers : authHeader()
      },
      )
    }

const deleteWishlist=(id)=>{
    return axios.delete(`${API_URL}/deletewishlist`,
      {
        headers : authHeader(),
        params : {variation_id : id}
      },
      )
    }

const getWishlist=()=>{
    return axios.get(`${API_URL}/getwishlist`,
      {
        headers : authHeader()
      },
      )
    }


const exportedObject={
    postWishlist,
    deleteWishlist,
    getWishlist,
}

export default exportedObject