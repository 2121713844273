import React from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Opacity } from "@material-ui/icons";

// const widgetcode="dec99aaf59d027f6d42398f9c6c5b9aad52e77ee382a651631a82da86cc20d89";
// const values ="";
// const ready ="";

function Footer() {
  const showpageState = useSelector((state) => state.showPage);
  const { sidenav } = showpageState;
  const { t, i18n } = useTranslation();
  return (
    <div
      className={
        sidenav
          ? sidenav.length === 0
            ? "footer active"
            : "footer"
          : "footer active"
      }
    >
      <footer className="bg-dark text-center text-white">
        <div className="container p-4">
          <section className="mb-4">
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.facebook.com/Cropway-103542688746807/"
              rel="nofollow"
              role="button"
              style={{ opacity: 1, transition: 'none', color: 'blue' }}
            >
              <i className="fab fa-facebook-f"></i>
            </a>

            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://twitter.com/cropway_in"
              role="button"
              style={{ opacity: 1, transition: 'none', color: '#10eaf8' }}
            >
              <i className="fab fa-twitter"></i>
            </a>

            <a
              className="btn btn-outline-light btn-floating m-1"
              href="mailto:info@cropway.com"
              role="button"
              style={{ opacity: 1, transition: 'none', color: 'rgb(223 74 74)' }}
            >
              <i className="fab fa-google"></i>
            </a>

            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.instagram.com/cropway__in/"
              rel="noreferrer"
              target="_blank"
              role="button"
              style={{ opacity: 1, transition: 'none', color: '#e50909' }}
            >
              <i className="fab fa-instagram"></i>
            </a>

            {/* linked-in button */}
            <a
              className="btn btn-outline-light btn-floating m-1"
              href="https://www.linkedin.com/company/cropway/"
              rel="noreferrer"
              target="_blank"
              role="button"
              style={{ opacity: 1, transition: 'none', color: '#058ef7' }}
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </section>

          <section className="mb-4">
            <h1 style={{ fontSize: 16, color: "white", fontFamily: "Roboto" }}>
              {t("footertitle")}
            </h1>
          </section>

          <section className="">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">{t("Support")}</h5>

                <ul className="list-unstyled mb-0">
                  {/* <li>
                    <a href="/#!" className="text-white">
                      Customer Services
                    </a>
                  </li>
                  <li>
                    <a href="/#!" className="text-white">
                      How to Order
                    </a>
                  </li>
                  <li>
                    <a href="/#!" className="text-white">
                      Billing and payments
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.cropway.com/returnandrefundpolicy"
                      target="_blank"
                      className="text-white"
                    >
                      {t("ReturnsExchanges")}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">{t("Company")}</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                    //  href="https://www.cropway.com/about/"
                      href="https://www.cropway.com/cropway-marketplace/"
                      target="_blank"
                      className="text-white"
                    >
                      {t("AboutUs")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.cropway.com/contact-us/"
                      target="_blank"
                      className="text-white"
                      rel="noreferrer"
                    >
                      {t("ContactUs")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#!" className="text-white">
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="/#!" className="text-white">
                     Blog
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">{t("Legal")}</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      href="https://www.cropway.com/privacypolicy"
                      target="_blank"
                      className="text-white"
                      rel="noreferrer"
                    >
                      {t("PrivacyPolicy")}
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.cropway.com/termsandconditions"
                      target="_blank"
                      className="text-white"
                      rel="noreferrer"
                    >
                      {t("TermsAndConditions")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#!" className="text-white">
                      Link 3
                    </a>
                  </li>
                  <li>
                    <a href="/#!" className="text-white">
                      Link 4
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">{t("SellWithUs")}</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <Link to="https://www.cropway.com/contactus/" className="text-white">
                      {t("SellOnCropway")}
                    </Link>

                  </li>
                  <li>
                    <a
                      href=" https://www.cropway.com/agriculture-services/"
                      className="text-white"
                    >
                      {t("AvailServices")}
                    </a>
                  </li>
                  {/* <li>
                    <a href="/#!" className="text-white">
                      Link 3
                    </a>
                  </li>
                  <li>
                    <a href="/#!" className="text-white">
                      Link 4
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </section>
        </div>

        <div
          className="text-center p-3"
          styles="background-color: rgba(0, 0, 0, 0.2);"
        >
          {" "}
          © 2023,{" "}
          <a className="text-white" href="https://www.cropway.com/">
            Cropway.com
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

//  <script type="text/javascript" id="zsiqchat"> var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq ||
//   {widgetcode= "dec99aaf59d027f6d42398f9c6c5b9aad52e77ee382a651631a82da86cc20d89", values={},ready=function(){}};
//   var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;
//   s.src="https://salesiq.zoho.in/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);
// </script>

//    {/* chatbot   */}
//    <div>
//    <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
//      <df-messenger
//        intent="WELCOME"
//        chat-title="AGRI_BOT"
//        agent-id="507cc9db-7c0e-422b-bea4-b62920a8c750"
//        language-code="en">
//      </df-messenger>
//  </div>
