import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../components/message";
import {
  SmallButtonStyle,
} from "../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";
export default class CompanyBranch2 extends React.Component {
  state = {
    inchargeFname: "",
    inchargeLname: "",
    inchargeDesignation: "",
    address1: "",
    address2: "",
    district: "",
    state: "",
    country: "",
    postalcode: "",
    contactnumber: "",
    email: "",
    error: null,
    // inchargeUID: "",
    logisticID: null,
  };


  saveandContinue = async (e) => {
    await this.props.postBranchData();
    this.props.retrieve_BankDetails();
    this.props.nextStep();
  };

  back = () => {
    this.props.prevStep();
  };

  render() {
    const {
      error
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 600,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Preview Company Branch{" "}
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Submit to confirm your Company Branch details.
                  </Form.Label>

                  <div style={{ marginTop: 20 }}>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Incharge First Name
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.inchargeFname}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Incharge Last Name
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.inchargeLname}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Incharge Designation
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.inchargeDesignation}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Country</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.companyBranch_country}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>State</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.companyBranch_state}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>District</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.companyBranch_district}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Address 1</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.companyBranch_address1}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Address 2</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.companyBranch_address2}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Postal Code</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.companyBranch_postalcode}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Mobile Number(+91)
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.contactnumber}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Email</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.email}
                      </strong>
                    </div>
                    <div
                      style={{
                        marginTop: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        type="submit"
                        onClick={this.saveandContinue}
                        style={SmallButtonStyle}
                      >
                        Submit{" "}
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-right"
                        />
                      </Button>
                      <div style={{ marginTop: 10 }}></div>
                      <Button
                        type="submit"
                        onClick={this.back}
                        style={SmallButtonStyle}
                      >
                        Back
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-left"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 0,
    height: "auto",
    margin: 3,
    width: "45%",
  },

  confirmtext: {
    display: "flex",
    flexDirection: "row",
  },
};
