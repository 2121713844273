import React, { Suspense } from "react";
import { DropMenuHover } from "../components/DropMenuHover";
import { useTranslation } from "react-i18next";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChatBot from "../components/Chatbot";
// import ChatBot from "../components/Chatbot";
import { DesktopHomecard } from "../components/HomeCard.js";
import HomePageShowProductDesk from "../components/HomePageShowProductDesk";
///MobileHomecard
const ImgCarousel = React.lazy(() => import("../components/ImgCarousel"));
const ESuvidha = React.lazy(() => import("../components/Categories"));

function HomeDesktop({ HomePageProducts, loading, error }) {
  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.only("xs"));
  const small = useMediaQuery(theme.breakpoints.only("sm"));
  const medium = useMediaQuery(theme.breakpoints.only("md"));
  const large = useMediaQuery(theme.breakpoints.only("lg"));
  const xlarge = useMediaQuery(theme.breakpoints.only("xl"));
  const end = xsmall ? 2 : small ? 3 : medium ? 4 : large ? 5 : xlarge ? 5 : 5;
  const { t } = useTranslation();

  const productextstyle = {
    fontWeight: "Bold",
    fontSize: "2rem",
    color: "black",

  };
  
  const {latest_product, popular_product, agrikit_product, trending_product, innovative_product} =  HomePageProducts ?? {}

  // chatbot
  const script = document.createElement('script');
  script.setAttribute("type", "text/javascript");

  return (
      <div>
        <div style={{ margin: "0px 1%" }}>
          <ImgCarousel/>
        </div>
        <div>
          <HomePageShowProductDesk
            loading={loading}
            error={error}
            HeaderText={t("h-1")}
            productToShow={latest_product?.data}
            end={end}
            href={"productcategory"}
          />

          <HomePageShowProductDesk
            loading={loading}
            error={error}
            HeaderText={t("h-2")}
            productToShow={popular_product?.data}
            end={end}
            href={"productcategory"}
          />

          <HomePageShowProductDesk
            loading={loading}
            error={error}
            HeaderText={t("h-6")}
            productToShow={agrikit_product?.data}
            end={end}
            href={"productcategory"}
          />
        </div>

        <div style={DropMenuHover}>
          <div
            style={{
              width: "100%",
              height: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <text style={{ fontSize: "clamp(1.9rem, 3.5vw, 5rem)" }}>
                {t("Cropway-agrregates")}
                <br /> {t("your-problems")}
              </text>
            </div>
          </div>
        </div>

        <div>
          <div>
            <DesktopHomecard/>
          </div>

          <HomePageShowProductDesk
            loading={loading}
            error={error}
            HeaderText={t("Innovative Product")}
            productToShow={innovative_product?.data}
            end={end}
            href={"productcategory"}
          />
          
          <HomePageShowProductDesk
            loading={loading}
            error={error}
            HeaderText={t("h-3")}
            productToShow={trending_product?.data}
            end={end}
            href={"productcategory"}
          />

          <div style={{margin:10, padding:10}}>
            <text style={productextstyle}>{t("h-5")}</text>
            <ESuvidha/>
            {/* <ChatBot/> */}
          </div>
        </div>
      </div>
  );
}

export default HomeDesktop;
