import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ML_MODEL_URL } from "../../../constants/api";
import Loader from "../../../components/Loader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { NutrientDesktop } from "../../../components/NutrientPre/NutrientDesktop";
import { NutrientMobile } from "../../../components/NutrientPre/NutrientMobile";
import { useNavigate } from "react-router-dom";

function NutrientPredict() {
  const navigate = useNavigate()
  const [nitrogen, setNitrogen] = useState("");
  const [phosphorous, setPhosphorous] = useState("");
  const [pottasium, setPottasium] = useState("");
  const [sulfur, setSulfur] = useState("");
  const [zinc, setZinc] = useState("");
  const [magnesium, setMagnesium] = useState("");
  const [calcium, setCalcium] = useState("");
  const [boron, setBoron] = useState("");
  const [iron, setIron] = useState("");
  const [copper, setCopper] = useState("");
  const [manganese, setManganese] = useState("");
  const [ph_value, setPhValue] = useState("");
  const [cropname, setCropname] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState("");
  const theme = useTheme();
  const xsmall = useMediaQuery(theme.breakpoints.down("sm"));


  const SaveData = () => {
    const uploadData = new FormData();
    uploadData.append("crop_name", cropname);
    uploadData.append("nitrogen", nitrogen);
    uploadData.append("phosphorous", phosphorous);
    uploadData.append("pottasium", pottasium);
    uploadData.append("sulfur", sulfur);
    uploadData.append("zinc", zinc);
    uploadData.append("magnesium", magnesium);
    uploadData.append("calcium", calcium);
    uploadData.append("boron", boron);
    uploadData.append("iron", iron);
    uploadData.append("copper", copper);
    uploadData.append("manganese", manganese);
    uploadData.append("ph_value", ph_value);

    try {
      setLoading(true);
      fetch(`${ML_MODEL_URL}fertilizer-predict`, {
        method: "POST",
        body: uploadData,
      })
        .then(
          (response) => response.json()
        )
        .then((data) => {
          setLoading(false);
          navigate('/nutrition-result', {state: { result: data.data }})
        });
    } catch (error) {
      console.log("");
    }
  };
  
  return(
    <>
    {loading ? <Loader loading={loading} /> :
    xsmall ? 
    <NutrientMobile 
     cropname={cropname}
     nitrogen={nitrogen}
     phosphorous={phosphorous}
     sulfur={sulfur}
     pottasium={pottasium}
     zinc={zinc}
     magnesium={magnesium}
     calcium={calcium}
     boron={boron}
     iron={iron}
     copper={copper}
     manganese={manganese}
     ph_value={ph_value}
     SaveData={SaveData}
     setCropname={setCropname}
     setNitrogen={setNitrogen}
     setPhosphorous={setPhosphorous}
     setPottasium={setPottasium}
     setSulfur={setSulfur}
     setZinc={setZinc}
     setMagnesium={setMagnesium}
     setCalcium={setCalcium}
     setBoron={setBoron}
     setIron={setIron}
     setCopper={setCopper}
     setManganese={setManganese}
     setPhValue={setPhValue}
     t={t}
    />
    :
    <NutrientDesktop 
     cropname={cropname}
     nitrogen={nitrogen}
     phosphorous={phosphorous}
     sulfur={sulfur}
     pottasium={pottasium}
     zinc={zinc}
     magnesium={magnesium}
     calcium={calcium}
     boron={boron}
     iron={iron}
     copper={copper}
     manganese={manganese}
     ph_value={ph_value}
     SaveData={SaveData}
     setCropname={setCropname}
     setNitrogen={setNitrogen}
     setPhosphorous={setPhosphorous}
     setPottasium={setPottasium}
     setSulfur={setSulfur}
     setZinc={setZinc}
     setMagnesium={setMagnesium}
     setCalcium={setCalcium}
     setBoron={setBoron}
     setIron={setIron}
     setCopper={setCopper}
     setManganese={setManganese}
     setPhValue={setPhValue}
     t={t}
    />
    }
    </>
  )
}



export default NutrientPredict