import {
  SHOW_PAGE,
  SHOW_PAGE_FAIL,
  SHOW_IMG_SEARCH,
  SHOW_IMG_SEARCH_FAIL,
} from "../constants/ShowpageConstants";

export const showPageReducer = (state = { sidenav: [] }, action) => {
  // {console.log("reduce")}

  switch (action.type) {
    case SHOW_PAGE:
      return { sidenav: action.payload };
    case SHOW_PAGE_FAIL:
      return { error: action.payload };
    default:
      return state;
  }
};

export const ShowImgSearchReducers = (state = { ImageSearch: [] }, action) => {
  // {console.log("reduce")}

  switch (action.type) {
    case SHOW_IMG_SEARCH:
      return { ImageSearch: action.payload };
    case SHOW_IMG_SEARCH_FAIL:
      return { ImageSearchError: action.payload };
    default:
      return state;
  }
};
