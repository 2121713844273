import React, { useEffect } from "react";
import { Col, Row, Container, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ManageOrders from "../assets/Orders.png";
import ManageSetting from "../assets/settings.png";
import ManagePayment from "../assets/wallet.png";
import ManageSubs from "../assets/subscribe.png";
import TrainInsight from "../assets/Trainings.png";
import Help from "../assets/Help.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";

const MyAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const {user} = useSelector((state) => state.auth);
  const matches = useMediaQuery("(min-width:600px)");
  const imgtextStyle = {
    marginTop: -15,
    marginLeft: matches ? 28 : 55,
    color: "#4671C6",
    fontWeight: "normal",
    fontSize: "16px",
  };
  const MainRowStyle = { display: matches ? "flex" : "", flexDirection: "row" };
  const ImageContent = {
    display: "flex",
    flexDirection: "row",
    color: "black",
    fontWeight: 800,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 15,
  };

  const MainImgDiv = {
    display: "flex",
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const ImgDiv = {
    borderRadius: "25%",
    height: "200px",
    width: matches ? "70%" : "100%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    border: 0,
    // border: "1px solid black",
  };

  const ImgStyle = {
    // borderRadius: "25%",
    width: 150,
    height: 150,
    // background: "red",
    // display: "block",
    // marginLeft: 45,
    // boxShadow: "0 0 20px 0",
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  }, [user]);
  return (
    <Container>
      <h1 style={{ fontWeight: "Bold", marginBottom: "5%" }}>{t("Account")}</h1>
      <div style={MainRowStyle}>
        <Col className="mb-4">
          <Link to="/previous-orders">
            <div style={ImgDiv}>
              <div style={MainImgDiv}>
                <Image
                  src={ManageOrders}
                  alt={t("Manage-Profile")}
                  style={ImgStyle}
                />
              </div>
              <div style={ImageContent}>{t("Manage-Profile")}</div>
            </div>
            <div style={imgtextStyle}>
              <text>
                <br />
                {t("Previous-order-Tracking")}
                <br />
                {t("Wishlist-Buyagain")}
              </text>
            </div>
          </Link>

          {/* <p className="text-center">{"Previous orders, Tracking,\nWishlist, & Buyagain"}</p> */}
        </Col>
        <Col className="mb-4">
          <Link to="manage-profile">
            <div style={ImgDiv}>
              <div style={MainImgDiv}>
                <Image
                  src={ManageSetting}
                  alt={t("Manage-Settings")}
                  style={ImgStyle}
                />
              </div>
              <div style={ImageContent}>{t("Manage-Settings")}</div>
            </div>
            <div style={imgtextStyle}>
              <text>
                <br />
                {t("Edit-profile-Address")}
                <br />
                {t("Social-media-Security")}
              </text>
            </div>
          </Link>
        </Col>
        <Col className="mb-4">
          <Link to="#!">
            <div style={ImgDiv}>
              <div style={MainImgDiv}>
                <Image
                  src={ManagePayment}
                  alt={t("Manage-Payment")}
                  style={ImgStyle}
                />
              </div>
              <div style={ImageContent}>{t("Manage-Payment")}</div>
            </div>
            <div style={imgtextStyle}>
              <text>
                <br />
                {t("Payment-Management-Wallet")},<br />
                {t("Finance")}
              </text>
            </div>
          </Link>
        </Col>
      </div>
      <div style={MainRowStyle}>
        <Col className="mb-4">
          <Link to="#!">
            <div style={ImgDiv}>
              <div style={MainImgDiv}>
                <Image
                  src={ManageSubs}
                  alt={t("Manage-Subscriptions")}
                  style={ImgStyle}
                />
              </div>
              <div style={ImageContent}>{t("Manage-Subscriptions")}</div>
            </div>
            <div style={imgtextStyle}>
              <text>
                <br />
                {t("Rentals-Services-Subscriptions")}
              </text>
            </div>
          </Link>
        </Col>
        <Col className="mb-4">
          <Link to="#!">
            <div style={ImgDiv}>
              <div style={MainImgDiv}>
                <Image
                  src={TrainInsight}
                  alt={t("Training&Insights")}
                  style={ImgStyle}
                />
              </div>
              <div style={ImageContent}>{t("Training&Insights")}</div>
            </div>
            <div style={imgtextStyle}>
              <text>
                <br />
                {t("Dashboards-Insights-Notifications")}
                <br />
                {t("Trainings")}
              </text>
            </div>
          </Link>
        </Col>
        <Col className="mb-4">
            <div style={ImgDiv} onClick={()=>window.open('https://www.cropway.com/faqs/')}>
              <div style={MainImgDiv}>
                <Image src={Help} alt={t("Help-Desk")} style={ImgStyle} />
              </div>
              <div style={ImageContent}>{t("Help-Desk")}</div>
            </div>
            <div style={imgtextStyle}>
              <text>
                <br />
                {t("Chatbot-Customer-Care-Reviews")}
                <br />
                {t("Feedbacks")}
              </text>
            </div>
        </Col>
      </div>
    </Container>
  );
};

export default MyAccount;
