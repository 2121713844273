import React, { useState } from 'react';
import ChangeProfileDesktop  from '../components/ChangeProfileDesktop';
import ChangeProfileMobile  from '../components/ChangeProfileMobile';
import ChangeRegistrationProfile  from '../components/ChangeRegistrationProfile';
import ManageSettings from '../components/ManageSettings';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { 
    Row,
    Col
  } from "react-bootstrap";
import { useSelector } from 'react-redux';

export default function ManageProfile() {
  const [showEdit, setShowEdit]=useState(false)
  const matches = useMediaQuery("(min-width:600px)");
  const {user} = useSelector((state) => state.auth);

  const handleClickShowEdit =() => {
    setShowEdit(!showEdit);
  };

  return (
      <div>
          <div> 
          <ManageSettings step1/>
          </div>

    { matches ? //if
          <Row  className="justify-content-md-center">
              <Col md="9" >
         <ChangeProfileDesktop handleClickShowEdit={handleClickShowEdit} showEdit={showEdit}/>
         </Col>
         </Row>
         : //else
         <ChangeProfileMobile handleClickShowEdit={handleClickShowEdit} showEdit={showEdit}/>
    }         
         {user?  <Row  className="justify-content-md-center">
              <Col md="9" >
         <ChangeRegistrationProfile />
         </Col>
         </Row> : null}
       </div>
  );
}