import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/message";
import { password_reset } from "../actions/actionUser";
import { SmallButtonStyle, LoginButtonStyle } from "../components/ButtonStyle";
import FarmerRafiki from "../assets/FarmerRafiki.png";
// import Googleicon from "../assets/Google-icon.png";
// import Facebookicon from "../assets/facebook-icon.png";
import { BackgroundGradientStyle } from "../components/BackgoundGradient";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";

axios.defaults.withCredentials = true;

function ResetPasswordScreen({ location, history }) {
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width:600px)");
  const passwordReset = useSelector((state) => state.passwordReset);
  const { loading, error, success } = passwordReset;

  useEffect(() => {
    if (success) {
      alert(`OTP has been sent to your registered mobile number`);
      history.push({
        pathname: "/reset_password_confirm",
        state: {username: username}
      });
    }
  }, [history, success, username]);

  

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(password_reset(username));
  };

  return (
    <div>
      {matches ? (
        <DesktopSection
          error={error}
          loading={loading}
          submitHandler={submitHandler}
          username={username}
          setUsername={setUsername}
          success={success}
          // opt={otp}
          // setOtp={setOtp}
          // submitOtp={submitOtp}
        />
      ) : (
        <MobileSection
          error={error}
          loading={loading}
          submitHandler={submitHandler}
          username={username}
          setUsername={setUsername}
          success={success}
        />
      )}
    </div>
  );
}

export default ResetPasswordScreen;

const DesktopSection = ({ error, loading, submitHandler, username, setUsername }) => {
  return (
    <div id="root">
      <div style={BackgroundGradientStyle}>
        <div style={{ fontSize: 12 }}>
          {error && <Message variant="danger">{error}</Message>}
        </div>
        {loading && <Loader />}
        <div style={{ color: "black" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "30vw",
                color: "black",
                backgroundColor: "white",
                margin: "2%",
                padding: "2%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "auto",
              }}
            >
              <img
                alt="FarmerRafiki"
                src={FarmerRafiki}
                style={{ height: "25vw", width: "20vw", borderRadius: 0 }}
              />
            </div>

            <div
              style={{
                height: "fit-content",
                width: "30vw",
                backgroundColor: "white",
                margin: "2%",
                padding: "2%",
              }}
            >
              <strong style={{ color: "black", fontSize: "2vw" }}>
                Welcome Back
              </strong>
              

              <Form onSubmit={submitHandler}>
              <Form.Group controlId="username">
                <Form.Label style={{ marginTop: "5%", fontSize: "1.5vw" }}>
                  Password Reset
                </Form.Label>
                <Form.Control
                  style={{ borderRadius: 0, fontSize: "1vw" }}
                  type="text"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div
                style={{
                  marginTop: "6%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button type="submit" style={SmallButtonStyle}>
                  Reset{" "}
                  <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                </Button>

                <div>
                  <a style={{ fontSize: "1vw" }} href="/Register">
                    Don't have an Account? Create account
                  </a>
                </div>
              </div>
            </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileSection = ({ error, loading, submitHandler, username, setUsername }) => {
  return (
    <div id="root">
      <div style={BackgroundGradientStyle}>
        <div style={{ fontSize: 12 }}>
          {error && <Message variant="danger">{error}</Message>}
        </div>
        {loading && <Loader />}
        <div style={{ color: "black" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "fit-content",
                padding: "3%",
                width: "60vw",
                backgroundColor: "white",
                marginBottom: "10%",
                marginTop: "10%",
                borderRadius: "3px",
              }}
            >
              <strong style={{ color: "black" }}>Welcome Back</strong>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="phone">
                  <Form.Label style={{ marginTop: "5%" }}>
                    Password Reset
                  </Form.Label>
                  <Form.Control
                    style={{ borderRadius: 0, fontSize: "4vw" }}
                    type="numeric"
                    placeholder="Enter username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <div
                  style={{
                    marginTop: "10%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button type="submit" style={LoginButtonStyle}>
                    Reset{" "}
                    <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                  </Button>

                  <div>
                    <a style={{ fontSize: "2.3vw" }} href="/Register">
                      Don't have an Account? Create account
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
