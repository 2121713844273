import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./bootstrap.min.css"; // Downloaded bootstrap file from here https://bootswatch.com/
import "./index.scss";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import './i18next';
import Loader from "./components/Loader";
import UserDetails from "./components/userDetails";

ReactDOM.render(
  <Provider store={store}>
    <UserDetails/>
    <Suspense fallback={<Loader/>}>
      <App/>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
