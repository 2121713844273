const initialState=
    {
        loading:false,
        error:false,
        inCart:null,
        inWishlist:null,
        quotation:null,
        count:null
    }

export default function(state=initialState, action){
    switch (action.type){
      case 'CART_WISHLIST_DETAIL_REQUEST':
        return {loading:true, error:null, inCart:null, inWishlist:null, quotation:null, count:null};

      case 'CART_WISHLIST_DETAIL_SUCCESS':
        return {loading:false, error:null, inCart:action.payload?.in_cart, inWishlist:action.payload?.in_wishlist, quotation:action.payload?.quotation_detail, count:action.payload?.count};

      case 'CART_WISHLIST_DETAIL_FAILED':
        return {loading:false, error:action.payload, inCart:null, inWishlist:null, count:null, quotation:null};

      default:
        return state;
    }
  };
  