import axios from "axios";
import axiosAPI, { setNewHeaders } from "../components/AxiosAPI";
import { ORDER_LIST_MINE_RESET } from "../constants/OrderConstants";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_REQUEST,
} from "../constants/userLoginConstants";
import { BASE_URL_HOSTED } from "../constants/api";
import { API_CHANGE_PASS } from "../screens/Registers/Constants";

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  dispatch({
    type: USER_LOGOUT,
  });
  // dispatch({type: USER})
  dispatch({ type: ORDER_LIST_MINE_RESET });
};

export const profile = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axiosAPI.get(`${BASE_URL_HOSTED}core/${id}/`);

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    //console.log(config);

    const { data } = await axiosAPI.put(
      `${BASE_URL_HOSTED}core/profile/update/`,
      user,
      config
    );

    data.refresh = userInfo.refresh;
    data.access = userInfo.access;

    // console.log(data);

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const password_reset = (username) => async (dispatch) => {
  try {
    dispatch({ type: PASSWORD_RESET_REQUEST });

    const { config } = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const body = { username };
    // console.log(body);

    const { data } = await axios.post(
      `${BASE_URL_HOSTED}reset_pass/`,
      body,
      config
    );

    dispatch({
      type: PASSWORD_RESET_SUCCESS,
      payload: { data: "success" },
    });
  } catch (error) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const password_reset_confirm =
  (access, password) => async (dispatch) => {
    try {
      dispatch({ type: PASSWORD_RESET_CONFIRM_REQUEST });

      const auth_head = "Bearer "
      const config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `${auth_head}${access}` ,
          "Content-Type": "application/json",
        },
      };

      const body = { password };

      console.log(body);
      console.log()

      const { data } = await axios.put(
        `${API_CHANGE_PASS}`,
        body,
        config
      );

      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS,
        payload: { data: "success" },
      });
    } catch (error) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
