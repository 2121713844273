import React, { useState } from "react";
import { Button, Form, Card, Image } from "react-bootstrap";
import "react-dropdown-now/style.css";
import { BigButtonStyle } from "../components/ButtonStyle";
import { useTranslation } from "react-i18next";

function VisualSearch({ history }) {
  const { t } = useTranslation();
  const [uploadimage, setUploadimage] = useState("");
  const [preview, setPreview] = useState("");

  const setImg = (evt) => {
    setUploadimage(evt.target.files[0]);
    setPreview(URL.createObjectURL(evt.target.files[0]));
  };

  const SaveData = () => {
    // console.log("data", productcategory, "data2:", productname )
    const uploadData = new FormData();
    uploadData.append("file", uploadimage, uploadimage.name);
    fetch(`http://127.0.0.1:5000/Visual`, {
      method: "POST",
      body: uploadData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log(data);
        history.push({ pathname: `/VisualResult`, state: { result: data } });
      });
  };

  return (
    <Form>
      <h3>Disease Prediction</h3>

      <Form.Group controlId="uploadimage">
        <Form.Label>Upload Image</Form.Label>
        <input
          type="file"
          onChange={(evt) => setImg(evt)}
          style={{ marginLeft: 15 }}
        />
      </Form.Group>
      <Card>
        <Image src={preview} alt="previewImage" fluid />
      </Card>

      <Button style={BigButtonStyle} onClick={SaveData}>
        {t("Submit")}
      </Button>
    </Form>
  );
}

export default VisualSearch;
