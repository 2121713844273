import React, { useState } from "react";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { SmallButtonStyle } from "../components/ButtonStyle";

//-----------------------MobileSections------------------------
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";

import List from "@material-ui/core/List";
import { useDispatch } from "react-redux";
import {
  listBrand,
  listFilteredProducts,
  //   listFilteredQuotationProducts,
  listFiteringCategories,
  //   listFiteringQuotationCategories,
  //   listQuotationBrand,
  listFarmProductName,
  listFilteredFarmProducts,
  listFiteringSearchCategories,
  searchResultProducts,
  listSearchBrand,
} from "../actions/productActions";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PrductsCategory from "../../src/components/PrductsCategory";

import { Button, Card, Form } from "react-bootstrap";

//------------------------------->MobileSection

const useStyles = makeStyles({
  list: {
    width: 250,
    maxHeight: 300,
  },
  fullList: {
    width: "auto",
  },
});

export const MobileSection = ({
  t,
  match,
  set,
  category,
  catselect,
  filtCat,
  setFiltCat,
  setCatSelect,
  setCategory,
  FilteringCategories,
  BrandList,
  Slidervalue,
  rangeSelector,
  Rating,
  RateStar,
  currentPosts,
  Sponsored,
  SponsoredProduct,
  status,
  showCategory,
  pageloading,
  Pagination,
  postsPerPage,
  posts,
  paginate,
  Loader,
  MaxPrice,
  RecommandProduct,
  FilteredCategory_Total_Count,
  ScreenTpye,
}) => {
  ////////////////////////////////////////////

  let [brandarray, setArray] = useState([]);
  let [catarray, setCatArray] = useState([]);
  let [ratingArray, setRatingArray] = useState([]);
  let [priceArray, setPriceArray] = useState([]);
  let [sortAvgRateArray, setSortAvgRateArray] = useState([]);
  let [sortNewestArray, setSortNewestArray] = useState([]);
  let [sortLowtoHighArray, setSortLowtoHighArray] = useState([]);
  let [sortHightoLowArray, setSortHightoLowArray] = useState([]);
  let [finalArray, setFinalArray] = useState([]);
  let [statusFilt, setStatusFilt] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  var [isCheckedbrand, setIsCheckedBrand] = useState(null);
  var [isCheckedCat, setIsCheckedCat] = useState(null);
  var [isCheckedRating, setIsCheckedRating] = useState(
    new Array(4).fill(false)
  ); //taken 4 as length because we have 4 type rating filter

  //for seting the length of intial array of brand filter with false
  if (BrandList && BrandList.length > 0 && isCheckedbrand === null) {
    isCheckedbrand = new Array(BrandList.length + 1).fill(false);
  }

  //for seting the length of intial array of category filter filter with false
  if (
    FilteringCategories &&
    FilteringCategories.length > 0 &&
    isCheckedCat === null
  ) {
    isCheckedCat = new Array(FilteringCategories.length + 1).fill(false);
  }

  //For Resetting the  filters
  const ResetFilters = async () => {
    setIsCheckedBrand(null);
    setIsCheckedCat(null);
    isCheckedRating.fill(false);

    var showcatlength = showCategory.length; // when no subcategory is selcted
    showCategory.length = 1;

    setCatArray([]);
    setPriceArray([]);
    setRatingArray([]);
    setSortAvgRateArray([]);
    setSortNewestArray([]);
    setSortLowtoHighArray([]);
    setSortHightoLowArray([]);
    await setCatSelect(
      ScreenTpye === "SearchScreen"
        ? match.params.catselect
        : match.params.catgselect
    );
    await setCategory(
      ScreenTpye === "SearchScreen" ? match.params.query : match.params.category
    );

    // if condition is for when filst level is slected and you clear the selected filters other than category
    if (showcatlength === 1) {
      const PostingForm = {
        cat: [
          {
            cat:
              ScreenTpye === "SearchScreen"
                ? match.params.query
                : match.params.category,
            catselect:
              ScreenTpye === "SearchScreen"
                ? match.params.catselect
                : match.params.catgselect,
          },
        ],
        // catselect: "subsubbrand"
      };
      var ActualPostingObj = JSON.stringify(PostingForm);
      localStorage.setItem("pageNumberCatScreen", JSON.stringify(1));

      if (ScreenTpye === "CommerialScreen") {
        dispatch(listFilteredProducts(ActualPostingObj, 1));
        dispatch(listFiteringCategories(ActualPostingObj, "categories"));
        dispatch(listBrand(ActualPostingObj, "brands"));
      } else if (ScreenTpye === "FarmScreen") {
        dispatch(listFilteredFarmProducts(ActualPostingObj, 1));
        dispatch(listFarmProductName(ActualPostingObj, "categories"));
      } else if (ScreenTpye === "SearchScreen") {
        dispatch(searchResultProducts(ActualPostingObj, 1));
        dispatch(listFiteringSearchCategories(ActualPostingObj, "categories"));
        dispatch(listSearchBrand(ActualPostingObj, "brands"));
      }
    }
  };

  const handleCheck = async (catName, position, catsel) => {
    // await setStatus(false)
    var CatName = "";

    var updateCheckedState =
      catsel === "searchbrand" ||
      (await catsel) === "brand" ||
      catsel === "subbrand" ||
      catsel === "subsubbrand" ||
      catsel === "subsubsubbrand" ||
      catsel === "subsubsubsubbrand" ||
      catsel === "subsubsubsubsubbrand"
        ? isCheckedbrand &&
          isCheckedbrand.map((item, index) =>
            index === position ? !item : item
          )
        : (match.params.query && catsel === "category") ||
          catsel === "subcategory" ||
          catsel === "subsubcategory" ||
          catsel === "subsubsubcategory" ||
          catsel === "subsubsubsubcategory" ||
          catsel === "subsubsubsubsubcategory"
        ? isCheckedCat &&
          isCheckedCat.map((item, index) => (index === position ? !item : item))
        : catsel === "rating" ||
          catsel === "subrating" ||
          catsel === "subsubrating" ||
          catsel === "subsubsubrating" ||
          catsel === "subsubsubsubrating" ||
          catsel === "subsubsubsubsubrating" ||
          catsel === "searchrating"
        ? isCheckedRating &&
          isCheckedRating.map((item, index) =>
            index === position ? !item : false
          )
        : null;

    // CatArray[position]= updateCheckedState[position]

    if (position !== null) {
      CatName = updateCheckedState.reduce((value, currentState, index) => {
        if (updateCheckedState[position] === true) {
          return catName;
        }
      });
    }

    ///////////////////////////////////////////////////////////////////////
    //Start of Condtion checking for storing values in particular  brand filter array

    if (
      catsel === "rating" ||
      catsel === "subrating" ||
      catsel === "subsubrating" ||
      catsel === "subsubsubrating" ||
      catsel === "subsubsubsubrating" ||
      catsel === "subsubsubsubsubrating" ||
      catsel === "searchrating"
    ) {
      setIsCheckedRating(updateCheckedState);

      if (updateCheckedState[position] === true) {
        ratingArray[0] = {
          cat: catName,
          catselect: catsel,
        };
      } else {
        ratingArray.length = 0;
      }
    }

    if (
      catsel === "price" ||
      catsel === "subprice" ||
      catsel === "subsubprice" ||
      catsel === "subsubsubprice" ||
      catsel === "subsubsubsubprice" ||
      catsel === "subsubsubsubsubprice" ||
      catsel === "searchprice"
    ) {
      priceArray[0] = {
        cat: catName,
        catselect: catsel,
      };
    }

    if (catsel === "AvgRating") {
      sortAvgRateArray[0] = {
        cat: catName,
        catselect: catsel,
      };
    }

    if (catsel === "Newest") {
      sortNewestArray[0] = {
        cat: catName,
        catselect: catsel,
      };
    }

    if (catsel === "LowtoHigh") {
      sortLowtoHighArray[0] = {
        cat: catName,
        catselect: catsel,
      };
    }

    if (catsel === "HightoLow") {
      sortHightoLowArray[0] = {
        cat: catName,
        catselect: catsel,
      };
    }

    if (
      catsel === "searchbrand" ||
      catsel === "brand" ||
      catsel === "subbrand" ||
      catsel === "subsubbrand" ||
      catsel === "subsubsubbrand" ||
      catsel === "subsubsubsubbrand" ||
      catsel === "subsubsubsubsubbrand"
    ) {
      setIsCheckedBrand(updateCheckedState);
      brandarray[position] = {
        cat: CatName,
        catselect: catsel,
      };
    }

    if (
      (match.params.query && catsel === "category") ||
      catsel === "subcategory" ||
      catsel === "subsubcategory" ||
      catsel === "subsubsubcategory" ||
      catsel === "subsubsubsubcategory" ||
      catsel === "subsubsubsubsubcategory"
    ) {
      statusFilt = true; ///
      // await setStatus(false)  // for handling the status of the loading during switching
      showCategory.push({ catsel: catsel, catName: CatName }); // for Showing the path of the category selcted for filtring and also for filtering the categories in acurate way

      // set()
      await setFiltCat(true);
      await setCatSelect(catsel); //for storing the value of selected category during filter
      await setCategory(CatName); //for storing the value of category name during filter

      setIsCheckedCat(updateCheckedState);
      catarray[position] = {
        cat: CatName,
        catselect: catsel,
        filteringcat: match.params.category,
      };
    }

    ////////////////////////////////////////////////////////////////////////////////////////////
    //Start of pusing arrays to finl aray which will be sent

    setFinalArray([]);

    // finalArray=array

    // if(!statusFilt && brandarray.filter((stat)=> stat.cat!==undefined).length>0){  // this if condition is for sending extra conditions as parameters(cat, subcat, subsubcat .....) to backend for acurate filtering of the products in the page

    if (!statusFilt) {
      // this if condition is for sending extra conditions as parameters(cat, subcat, subsubcat .....) to backend for acurate filtering of the products in the page
      showCategory.map((ShowCat, index) => {
        // this loop is for assigning the subcat, cat, subsubcat, etc in array object form as we are sending data to the backend in array object form
        if (index !== 0) {
          catarray[index] = {
            cat: ShowCat.catName,
            catselect: ShowCat.catsel,
          };
        } else {
          catarray[0] = { cat: ShowCat.catName, catselect: ShowCat.catsel };
        }
      });

      finalArray.push(...catarray);
    } else {
      // statusFilt=true
      // setFilt(false)
      catarray.length = 0;
    }

    if (brandarray.length > 0) {
      //  await setFinalArray(array)
      finalArray.push(...brandarray);
    }

    if (ratingArray.length > 0) {
      // console.log('finalArray', finalArray)
      finalArray.push(...ratingArray);
    }

    if (priceArray.length > 0) {
      finalArray.push(...priceArray);
    }

    if (sortAvgRateArray.length > 0) {
      finalArray.push(...sortAvgRateArray);
    }

    if (sortNewestArray.length > 0) {
      finalArray.push(...sortNewestArray);
    }

    if (sortLowtoHighArray.length > 0) {
      finalArray.push(...sortLowtoHighArray);
    }

    if (sortHightoLowArray.length > 0) {
      finalArray.push(...sortHightoLowArray);
    }
    // finalArray.push(...catarray)

    ///////////////////////////////////////////////////////////////////////////

    //calling  set function to filter the products from backend of selected filters

    // for sorting by price togling
    if (catsel === "HightoLow" || catsel === "LowtoHigh") {
      set(finalArray, catName);
    }

    //for rest of all
    else {
      if (!statusFilt) {
        ///
        // console.log('finalArray01', finalArray)
        set(finalArray);
      } else {
        setIsCheckedBrand(null);
        setIsCheckedCat(null);
        isCheckedRating.fill(false);
        setCatArray([]);
        setPriceArray([]);
        ratingArray = [];
        setSortAvgRateArray([]);
        setSortNewestArray([]);
        setSortLowtoHighArray([]);
        setSortHightoLowArray([]);
        statusFilt = false;
      }
    }
  };

  // For getting unique brands and categories as we are calling api for both commercial and quotatation product
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const BackTrace = async (cat, index) => {
    // this is for  backtracing the categories and sub categories
    showCategory.length = index + 1;
    await setCategory(cat.catName);
    await setCatSelect(cat.catsel);
    setIsCheckedBrand(null);
    setIsCheckedCat(null);
    isCheckedRating.fill(false);
    setCatArray([]);
    setPriceArray([]);
    setRatingArray([]);
    setSortAvgRateArray([]);
    setSortNewestArray([]);
    setSortLowtoHighArray([]);
    setSortHightoLowArray([]);
  };

  const FirstCat = { fontWeight: "bold", color: "black" };
  const MidCat = { color: "black", fontSize: 15 };
  const LastCat = {
    fontWeight: "bold",
    color: "black",
    paddingLeft: "6%",
    fontSize: 15,
  };

  /////////////////////////////////////////////////////////////////////

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <div
          style={{
            border: "1px solid gray",
            color: "black",
            height: "fit-content",
          }}
        >
          <div style={{ position: "relative", margin: 5, padding: 3 }}>
            <view
              style={{
                padding: "10px 2px",
                fontSize: 20,
                fontWeight: "bold",
                color: "black",
              }}
            >
              {t("filters")}
            </view>
            <view style={{ position: "absolute", right: 0 }}>
              <a onClick={() => ResetFilters()}>{t("Clear-filters")}</a>
            </view>
          </div>
          <hr style={{ backgroundColor: "black" }} />

          <div style={{ padding: 10 }}>
            <div style={{ margin: "10px 0" }}>
              {filtCat ? (
                showCategory &&
                showCategory.map((cat, index) => (
                  <div
                    onClick={() => BackTrace(cat, index)}
                    style={
                      index === 0
                        ? FirstCat
                        : index === showCategory.length - 1
                        ? LastCat
                        : MidCat
                    }
                  >
                    {index !== 0 && index !== showCategory.length - 1 ? (
                      <>
                        <i className="fas fa-chevron-right"></i>
                        {cat.catName}
                      </>
                    ) : (
                      cat.catName
                    )}
                  </div>
                ))
              ) : (
                <div style={{ fontWeight: "bold", color: "black" }}>
                  {match.params.category}
                </div>
              )}
              <Form>
                {(filtCat && catselect === "subsubsubsubsubcategory") ||
                (!filtCat &&
                  match.params.catgselect === "subsubsubsubsubcategory") ///
                  ? null
                  : FilteringCategories.filter(onlyUnique).map(
                      (product, index) => (
                        <div key={`default-${product.id}`}>
                          <Form.Check
                            product={product}
                            id={
                              product[
                                match.params.query &&
                                catselect === "beforecategory"
                                  ? product.hasOwnProperty("category")
                                    ? "category"
                                    : "name"
                                  : product.hasOwnProperty("sub" + catselect)
                                  ? "sub" + catselect
                                  : "name"
                              ]
                            }
                            label={
                              product[
                                match.params.query &&
                                catselect === "beforecategory"
                                  ? product.hasOwnProperty("category")
                                    ? "category"
                                    : "name"
                                  : product.hasOwnProperty("sub" + catselect)
                                  ? "sub" + catselect
                                  : "name"
                              ]
                            }
                            inline
                            checked={isCheckedCat[index]}
                            value={
                              product[
                                match.params.query &&
                                catselect === "beforecategory"
                                  ? product.hasOwnProperty("category")
                                    ? "category"
                                    : "name"
                                  : product.hasOwnProperty("sub" + catselect)
                                  ? "sub" + catselect
                                  : "name"
                              ]
                            }
                            onClick={(e) =>
                              handleCheck(
                                product[
                                  match.params.query &&
                                  catselect === "beforecategory"
                                    ? product.hasOwnProperty("category")
                                      ? "category"
                                      : "name"
                                    : product.hasOwnProperty("sub" + catselect)
                                    ? "sub" + catselect
                                    : "name"
                                ],
                                index,
                                match.params.query &&
                                  catselect === "beforecategory"
                                  ? "category"
                                  : "sub" + catselect
                              )
                            }
                          />
                        </div>
                      )
                    )}
              </Form>
            </div>

            {BrandList.length > 0 ? (
              <div style={{ margin: "10px 0" }}>
                <Card.Subtitle style={{ fontWeight: "bold", color: "black" }}>
                  {t("Brand")}
                </Card.Subtitle>
                <Form>
                  {BrandList.filter(onlyUnique).map((product, index) => (
                    <div key={`default-${product.id}`}>
                      <Form.Check
                        product={product}
                        id={product.brand}
                        label={product.brand}
                        inline
                        checked={isCheckedbrand[index]}
                        // checked={product.brand === toggle ? true : false}
                        value={product.brand}
                        onClick={(e) =>
                          (filtCat && catselect === "category") ||
                          (!filtCat && match.params.catgselect === "category")
                            ? handleCheck(product.brand, index, "brand")
                            : (filtCat && catselect === "subcategory") ||
                              (!filtCat &&
                                match.params.catgselect === "subcategory") ///
                            ? handleCheck(product.brand, index, "subbrand")
                            : filtCat && catselect === "subsubcategory"
                            ? handleCheck(product.brand, index, "subsubbrand")
                            : filtCat && catselect === "subsubsubcategory"
                            ? handleCheck(
                                product.brand,
                                index,
                                "subsubsubbrand"
                              )
                            : filtCat && catselect === "subsubsubsubcategory"
                            ? handleCheck(
                                product.brand,
                                index,
                                "subsubsubsubbrand"
                              )
                            : filtCat && catselect === "subsubsubsubsubcategory"
                            ? handleCheck(
                                product.brand,
                                index,
                                "subsubsubsubsubbrand"
                              )
                            : match.params.query &&
                              catselect === "beforecategory"
                            ? handleCheck(product.brand, index, "searchbrand")
                            : null
                        }
                      />
                    </div>
                  ))}
                </Form>
              </div>
            ) : // else
            null}

            <div style={{ margin: "20px 0" }}>
              <Card.Subtitle style={{ fontWeight: "bold", color: "black" }}>
                {t("Price-Range")}
              </Card.Subtitle>
              <div
                style={{
                  display: "block",
                  width: 250,
                }}
              >
                <Typography>{t("Select-Price-Range")}</Typography>
                <Slider
                  min={0}
                  max={MaxPrice}
                  value={Slidervalue}
                  onChange={rangeSelector}
                  valueLabelDisplay="auto"
                />

                <Form>
                  {t("min-price")}
                  {Slidervalue[0]} /-{" "}
                </Form>
                <Form>
                  {t("max-price")}
                  {Slidervalue[1]} /-{" "}
                </Form>
                <div>
                  <Button
                    style={SmallButtonStyle}
                    onClick={(e) =>
                      (filtCat && catselect === "category") ||
                      (!filtCat && match.params.catgselect === "category")
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "price"
                          )
                        : (filtCat && catselect === "subcategory") ||
                          (!filtCat &&
                            match.params.catgselect === "subcategory")
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "subprice"
                          )
                        : filtCat && catselect === "subsubcategory"
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "subsubprice"
                          )
                        : filtCat && catselect === "subsubsubcategory"
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "subsubsubprice"
                          )
                        : filtCat && catselect === "subsubsubsubcategory"
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "subsubsubsubprice"
                          )
                        : filtCat && catselect === "subsubsubsubsubcategory"
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "subsubsubsubsubprice"
                          )
                        : match.params.query && catselect === "beforecategory"
                        ? handleCheck(
                            [Slidervalue[0], Slidervalue[1]],
                            null,
                            "searchprice"
                          )
                        : null
                    }
                  >
                    {t("Go")}
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <Card.Subtitle style={{ fontWeight: "bold", color: "black" }}>
                {t("Customer-Ratings")}
              </Card.Subtitle>
              <Form>
                {Rating.map((rating, index) => (
                  <div key={`default-${rating.id}`}>
                    <Form.Check
                      rating={rating}
                      id={rating.star}
                      label={RateStar(rating.star)}
                      inline
                      checked={isCheckedRating[index]}
                      // star={rating.brand}
                      onClick={(e) =>
                        (filtCat && catselect === "category") ||
                        (!filtCat && match.params.catgselect === "category")
                          ? handleCheck(rating.star, index, "rating")
                          : (filtCat && catselect === "subcategory") ||
                            (!filtCat &&
                              match.params.catgselect === "subcategory")
                          ? handleCheck(rating.star, index, "subrating")
                          : filtCat && catselect === "subsubcategory"
                          ? handleCheck(rating.star, index, "subsubrating")
                          : filtCat && catselect === "subsubsubcategory"
                          ? handleCheck(rating.star, index, "subsubsubrating")
                          : filtCat && catselect === "subsubsubsubcategory"
                          ? handleCheck(
                              rating.star,
                              index,
                              "subsubsubsubrating"
                            )
                          : filtCat && catselect === "subsubsubsubsubcategory"
                          ? handleCheck(
                              rating.star,
                              index,
                              "subsubsubsubsubrating"
                            )
                          : match.params.query && catselect === "beforecategory"
                          ? handleCheck(rating.star, index, "searchrating")
                          : null
                      }
                    />
                  </div>
                ))}
              </Form>
            </div>
          </div>
          {/* <hr style={{backgroundColor:'black'}}/> */}
        </div>
      </List>
      <Divider />
    </div>
  );
  return (
    <div>
      <div
        style={{
          display: "flex",
          border: "1px solid grey",
          minHeight: 45,
          justifyContent: "flex-end",
          alignContent: "center",
          alignItems: "center",
          paddingRight: "3%",
          fontWeight: "bold",
        }}
      >
        {["bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>Filter</Button>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
      <Grid container justify="center" alignItems="center">
        {pageloading === true ? (
          <div>
            <Loader />
          </div>
        ) : status === true && currentPosts.length > 0 ? (
          <div>
            <Sponsored
              SponsoredProduct={SponsoredProduct}
              category={category}
            />

            <div>
              <PrductsCategory posts={currentPosts} loading={pageloading} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={FilteredCategory_Total_Count}
                paginate={paginate}
              />
            </div>
          </div>
        ) : status === false ? (
          <div>
            <Loader />
          </div>
        ) : (
          <div
            style={{
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>No products to show !!</h2>
          </div>
        )}
      </Grid>
    </div>
  );
};
