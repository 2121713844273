export const GET_DISEASE_DETECTION_HISTORY_REQUEST =
  "GET_DISEASE_DETECTION_HISTORY_REQUEST";
export const GET_DISEASE_DETECTION_HISTORY_FAIL =
  "GET_DISEASE_DETECTION_HISTORY_FAIL";
export const GET_DISEASE_DETECTION_HISTORY_SUCCESS =
  "GET_DISEASE_DETECTION_HISTORY_SUCCESS";

export const GET_PEST_DETECTION_HISTORY_REQUEST =
  "GET_PEST_DETECTION_HISTORY_REQUEST";
export const GET_PEST_DETECTION_HISTORY_FAIL =
  "GET_PEST_DETECTION_HISTORY_FAIL";
export const GET_PEST_DETECTION_HISTORY_SUCCESS =
  "GET_PEST_DETECTION_HISTORY_SUCCESS";
