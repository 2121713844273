import React from "react";
import mathService from "../services/math.service";
import Wishlist from "../HeaderImages/Wishlist.png";
import Cart from "../HeaderImages/Cart.png"; 
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Posts = ({post}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(post?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
  let imageURL = (post?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
  let {offPercent} = mathService.discountRate(post?.stock?.mrp, post?.stock?.selling_price)

  const PriceSection=()=>{
    return(
      <div className="post-price" style={{height:'70%', display:'flex', flexDirection:'column', justifyContent:'center'}}>
      <div>
        <strike className="post-mrp">MRP : ₹ {post?.stock?.mrp}</strike>
        <strong className="post-offpercent">{offPercent && offPercent +'% off'}</strong>
      </div>
      <strong className="post-selling-price">₹ {post?.stock?.selling_price}</strong>
    </div>
    )
  }
  return (
     <div className="post">
       <div
        className="post-product-image" 
        onClick={()=>navigate(`/productdetail/${post?.product.id}`, {state:{productId : post?.product.id}})}
       >
         <img className="post-image" src={imageURL} style={{aspectRatio:1, objectFit:'contain'}}/>
       </div>
       <div className="post-product-detail">
           <div className="post-name-brand">
              <text className="post-product-name">{post?.product?.name}</text>
              <text className="post-product-brand">By {post?.product?.brand?.name}</text>
           </div>
           <div className="post-rating-price-cart-wishlist">
              <div className="post-rating-price">
                <div className="post-rating">
                  {
                    [1,1,1,1,1].map((rate, key)=>(
                      <i
                        key={key}
                        style={{ color:'gold' }}
                        className={"far fa-star"}
                      />
                    ))
                  }
                </div>
                {
                  post?.product?.product_type==1 ?
                   <PriceSection/> :
                   <div>Send Quote</div>                   
                }
                
              </div>
              <div className="post-cart-wishlist-icon">
                <img className="post-cart-icon" src={Cart} alt='cart-icon' onClick={()=>null}/>
                <img className="post-wishlist-icon" src={Wishlist} alt='postwishlist-icon' onClick={()=>null}/>
              </div>
           </div>
       </div>
     </div>
  );
};

export default Posts;
