import React from "react";
import Grid from '@material-ui/core/Grid';


function RegisterationTerms({ location, history }) {

const ParaStyle = {
    fontSize: '1rem',
    color:'black',
    paddingTop:25
}
const SpanStyle = {paddingRight:8}

const TextStyle={color:'black'}
const HeaderText={paddingLeft:10}
const HeaderDiv={ backgroundColor:'#E5E4E2', fontSize: '1.3rem', color:'black'}
const MainHeading={fontSize: '2rem', color:'black'}

return(
    <div>
<Grid container spacing={3}  style={{padding:15}}>
      
      <Grid item xs={3}  />
      <Grid item xs={6} >
        <text style={MainHeading}> 
        Agreement by Seller for
        terms of use 
        <br/>
        <a style={{paddingLeft:80}}>of the Cropway
        platform</a>
        </text>
    
      </Grid>
      <Grid item xs={3}  />
    </Grid>


<div>
    <div style={HeaderDiv}>
<text style={HeaderText}>1. Preambles </text>
</div>
<p align="justify" style={ParaStyle}>
This document is an electronic record in terms of Information Technology Act, 2000 ("IT Act, 2000"), the applicable rules
there under and the provisions pertaining to electronic records in various statutes as amended by the Information
Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or
digital signatures.
<br/>
<br/>
This document is published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries
Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access to
or usage of <a href="https://www.cropway.com/">www.cropway.com</a> website.
<br/>
<br/>
The domain name <a href="https://www.cropway.com/">www.cropway.com</a>, including the related mobile site and mobile application as well as the portal
seller.cropway.com (hereinafter referred to as "Platform") is owned and operated by Boaz Agri Analytics Pvt Ltd
(hereinafter referred to as 'Boaz'), a company incorporated under the Companies Act, 1956, with its registered office at
Boaz Agri Analytics Pvt Ltd. C/o Fajal Abbas Saify, Sadar Bazar, Raipur, Chhattisgarh, India. (492001)
<br/>
<br/>
For the purpose of the Terms of Use (hereinafter referred to as "Agreement"), wherever the context so requires, 'Seller'
shall relate to any natural or legal person who has agreed to become a seller on the Platform by providing registration
data while registering on the Platform using computer systems. The word 'user' shall collectively imply a seller, a buyer,
and any visitor on the Platform and the terms 'we', 'us' and 'our' shall mean Boaz.
<br/>
<br/>
Use of the Platform and the features, therein, is governed by the following terms and conditions (Agreement) including
applicable policies available on the Platform, notifications and communications sent to Seller on the Platform which are
incorporated herein by way of reference. If Seller transact on the Platform, Seller shall be subject to the policies that are
applicable to the Platform for such a transaction. By mere use of the Platform Seller shall be contracting with Boaz, and
these terms and conditions including the policies constitute your binding obligations to Boaz.
<br/>
<br/>
When Seller use any current or future services provided by us through the Platform, Seller will be subject to the rules,
guidelines, policies, terms and conditions applicable to such services and they shall be deemed incorporated into the
Agreement and considered a part and parcel of the Agreement. reserve the right, at our sole discretion, to change,
modify, add, or remove portions of the Agreement at any time. will notify Seller through any of the communication
modes as mentioned in this Agreement in case of any changes or updates to the Agreement that materially impact
seller's use of the Platform. Seller continued use of the Platform following the changes or updates will mean that Seller
accept and agree to the revisions. As long as Seller comply with the Agreement, grant Seller a personal, non-exclusive,
non-transferable, and limited privilege to enter and use the Platform.
<br/>
<br/>
ACCESSING, BROWSING, OR OTHERWISE USING THE PLATFORM INDICATES SELLER AGREEMENT WITH ALL
THE TERMS AND CONDITIONS UNDER THE AGREEMENT. PLEASE READ THE AGREEMENT CAREFULLY
BEFORE PROCEEDING. By impliedly or expressly accepting the Agreement, Seller also accept and agree to be bound
by all of Boaz's policies applicable to Seller, as amended, from time to time (Hyper link with Boazs policies; currently
linked with Boaz's). 
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>2. Seller Eligibility </text>
</div>
<p align="justify" style={ParaStyle}>
The use of the Platform as Seller is available only to persons who can form legally binding contracts under the Indian
Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872
including minors, un-discharged insolvents etc. are not eligible to use the Platform. If Seller are a minor i.e. under the
age of 18 years, Seller shall not register as a seller on the Platform, transact or use the Platform. Boaz reserves the
right to terminate Seller registration and/or refuse Seller access to the Platform if it is brought to Boaz's notice or
discovered that Seller are under the age of 18 years. If Seller register as a business entity, Seller represent that Seller
are duly authorized by the business entity to accept the Agreement and Seller have the authority to bind the business
entity to the Agreement.
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>3. Seller Account and Registration Obligations </text>
</div>
<p align="justify" style={ParaStyle}>
In the course of use of the Platform, Seller agree to furnish details and information as requested by Boaz from time to
time. Seller shall remain responsible for maintaining confidentiality of this information, as well as Seller display name,
login and password details. Seller agrees that if Seller provide any information which is untrue, inaccurate, not current,
or incomplete or have reasonable grounds to suspect that such information is untrue, inaccurate, not current,
incomplete, or not in accordance with the Agreement, Boaz shall have the right to suspend or terminate Seller account
on the Platform or indefinitely block Seller from accessing the Platform.
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>4. Seller Account Deactivation</text>
</div>
<p align="justify" style={ParaStyle}>
As per Boaz policy, if a seller requests for a closure of the account, the account will be put on hold for a period of 90
days to ensure smooth closure of transactions which have been made prior to his closure request and this will be
available to the said seller for downloading payment and taxation reports, if required. After 90 days, the seller would be
required to reach out to Boaz confirming that Seller has downloaded the reports and requesting for a deactivation again.
Upon receiving this confirmation, the seller's account will be deactivated provided there are no outstanding payments
due from the seller, with certain information being retained by Boaz at all times, such as registered mobile number,
registered email ID, GSTIN and other transaction related information. Such information is being retained for audit
purposes and to prevent fraudulent acts by the sellers in the future. If a seller decides to commence his business with
Boaz again, Seller will not be able to create a new account but the older account can be restored if it is required.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>5. Communications</text>
</div>
<p align="justify" style={ParaStyle}>
When Seller use the Platform or send emails, other data, information, or communication to Boaz, Seller agree and
understand that Seller is communicating with Boaz through electronic records and Seller consent to receive
communications via electronic records from Boaz periodically or as and when required. may communicate with Seller by
email or any other mode of communication, electronic or otherwise.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>6. Platform for Transaction and Communication</text>
</div>
<p align="justify" style={ParaStyle}>
The Platform is a platform that users utilize to independently meet and interact with one another for their transactions.
Boaz is not and cannot be a party to any transaction or dispute between users on the Platform. Consequently —
<br/>
<br/>
<ol>
	<li>All commercial/contractual terms are offered by Seller and agreed upon between Seller and buyers alone. The
commercial/contractual terms include (without limitation) price, shipping costs, payment methods and terms,
date, period, and mode of delivery, and warranties and after-sales services related to products and services.
Boaz does not determine, advice, have any control, or in any way involve itself in the offering or acceptance of
such commercial/contractual terms between Seller and buyers.
</li>
<br/>

	<li>Boaz does not make any representations or warranties regarding specifics (such as quality, value, and
saleability) of the products or services proposed to be sold, offered to be sold or purchased on the Platform.
Boaz does not implicitly or explicitly support or endorse the sale or purchase of any products and services on
the Platform. Boaz accepts no liability for any errors or omissions of third parties in relation to the products and
services.
</li>
<br/>
    <li>Boaz is not responsible for any non-performance or breach of any contract between Seller and buyers. Boaz
cannot and does not guarantee that Seller and buyers concerned will perform transaction(s) concluded on the
Platform. Boaz shall not and is not required to mediate or resolve disputes or disagreements between Seller
and buyers.
</li>
<br/>

    <li>Boaz does not make any representations or warranties regarding item-specifics (such as legal title,
creditworthiness, identity, etc.) of any of its users. Seller are advised to independently verify the bona fides of
any particular buyer, Seller choose to deal with on the Platform and use Seller best judgment in that regard.
</li>
<br/>

    <li>Boaz does not at any point in time during a transaction between Seller and a buyer on the Platform come into
or take possession of any of the products or services offered by Seller, gain title to or have any rights or claims
over the products or services offered by Seller to the buyer.
</li>
<br/>

    <li>At no time shall Boaz hold any right/title to or interest in the items nor have any obligations or liabilities with
respect to such a contract. Boaz is not responsible for unsatisfactory or delayed performance of services,
damages, or delays as a result of items which are out of stock, unavailable, or back-ordered.
</li>
<br/>

    <li>The Platform is only a platform that can be utilized by Seller to reach a larger customer base to sell items or
services. Boaz only provides a platform for communication and it is agreed that the contract for sale of any
products or services shall be a strictly bipartite contract between Seller and the buyer.
</li>
<br/>

    <li>Seller release and indemnify Boaz and/or any of its officers and representatives from any cost, damage,
liability or other consequence of any of the actions of the users on the Platform and specifically waive any
claims that Seller may have in this behalf under any applicable law. Notwithstanding its reasonable efforts on
that behalf, Boaz cannot control the information provided by other users which is made available on the
Platform. Seller may find other user's information to be offensive, harmful, inaccurate or deceptive. Please use
caution and practice safe trading when using the Platform. Please note that there may be risks in dealing with
underage persons or people acting under false pretence.
</li>
<br/>
</ol>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>7. Use of the Platform</text>
</div>
<p align="justify" style={ParaStyle}>
Seller agrees and understand that Boaz and the Platform merely provide hosting services to its registered users and
persons browsing/visiting the Platform. All items advertised / listed and the contents therein are advertised and listed by
registered users and are third party user generated contents. Boaz shall bear no responsibility or liability in relation to or
arising out of third-party user generated content. Boaz neither originates nor initiates the transmission nor selects the
sender and receiver of the transmission nor selects nor modifies the information contained in the transmission. Boaz is
merely an intermediary and does not interfere in the transaction between buyers and sellers.
<br/>
<br/>
Seller agrees, undertakes and confirms that Seller use of the Platform shall be strictly governed by the following binding
principles:
<br/>
<br/>

<ol>
    <li>
        Seller shall not host, display, upload, modify, publish, transmit, update or share any information or image
    which:
    </li>
    <br/>
       <ul>

        <li>
        <span style={SpanStyle}>(a)</span> 
        belongs to another person and over which Seller have no right; 
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(b)</span> 
        is grossly harmful, harassing, blasphemous, defamatory, bigotry, obscene, pornographic,
        paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable,
        disparaging, relating to or encouraging money laundering or gambling, or otherwise unlawful in any
        manner whatever, or unlawfully threatening or harassing, including but not limited to 'indecent
        representation of women' within the meaning of the Indecent Representation of Women (Prohibition)
        Act, 1986;
        </li>
        <br/>


        <li>
        <span style={SpanStyle}>(c)</span> 
        is false, inaccurate or misleading in any way;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(d)</span> 
        is potently offensive to the online community, such as sexually explicit content or content that
        promotes obscenity, paedophilia, racism, bigotry, hatred, or physical harm of any kind against any
        group or individual;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(e)</span> 
        harasses or advocates harassment of another person;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(f)</span> 
        involves the transmission of 'junk mail', 'chain letters', unsolicited mass mailing, or 'spamming';
        including spamming by ways of unrelated feedbacks on surveys 
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(g)</span> 
        promotes illegal activity or conduct that is abusive, threatening, obscene, defamatory, or libellous;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(h)</span> 
        infringes upon or violates any third party's rights [including but not limited to intellectual property
        rights, rights of privacy (including without limitation unauthorized disclosure of a person's name,
        email address, physical address, or phone number) or rights of publicity]; 
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(i)</span> 
        promotes an illegal or unauthorized copy of another person's copyrighted work (see "Copyright
        complaint" below for instructions on how to lodge a complaint about uploaded copyrighted material)
        such as providing pirated computer programs or links, information to circumvent manufacturerinstalled copy-protect devices, or pirated music or links to pirated music files, Research Papers,
        Literature of Agri tech, etc;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(j)</span> 
        contains restricted or password-only access pages, hidden pages or images or URLs leading to any
        other pages (those not linked to or from another accessible page); 
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(k)</span> 
        provides material that exploits people in a sexual, violent or otherwise inappropriate manner or
        solicits personal information from anyone;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(l)</span> 
        provides instructional information about illegal activities such as making or buying illegal weapons,
        violating someone's privacy, providing or creating computer viruses; 
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(m)</span> 
        contains unauthorized videos, photographs or images of another person (whether a minor or an
        adult);
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(n)</span> 
        tries to gain unauthorized access or exceeds the scope of authorized access to the Platform,
        profiles, blogs, communities, account information, bulletins, friend requests, or other areas of the
        Platform, or solicits passwords or personal identifying information for commercial or unlawful
        purposes from other users on the Platform;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(o)</span> 
        engages in commercial activities and/or sales such as contests, sweepstakes, barter, advertising,
        pyramid schemes, or the buying or selling of 'virtual' items related to the Platform without prior
        written consent of Boaz.
        <br/>
        <br/>
        Throughout the Agreement, Boaz's prior written consent means a communication coming from
        Boaz's Legal Department in response to Seller request and specifically addressing the activities or
        conduct for which Seller have sought authorization;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(p)</span> 
        solicits gambling or engages in any gambling activity which Boaz, at our sole discretion, believe is or
        could be construed as being illegal;
        </li>
        <br/>


        <li>
        <span style={SpanStyle}>(q)</span> 
        interferes with another's use and enjoyment of the Platform;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(r)</span> 
        refers to any website/URL which, at Boaz's sole discretion, contains material that is inappropriate for
        the Platform or any other website and content that is prohibited or violates the letter and spirit of
        Agreement;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(s)</span> 
        harms minors in any way;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(t)</span> 
        infringes any patent, trademark, copyright, proprietary rights, third-party's trade secrets, rights of
        publicity, or privacy, is fraudulent, or involves the sale of counterfeit or stolen items; 
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(u)</span> 
        violates any law for the time being in force;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(v)</span> 
        deceives or misleads the addressee/ users about the origin of messages or communicates any
        information which is grossly offensive or menacing in nature;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(w)</span> 
        impersonates another person;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(x)</span> 
        contains software viruses or any other computer codes, files, or programs designed to interrupt,
        destroy, or limit the functionality of any computer resource; or contains any trojan horses, worms,
        time bombs, cancelbots, easter eggs, or other computer programming routines that may damage,
        detrimentally interfere with, diminish value of, surreptitiously intercept, or expropriate any system,
        data, or personal information;

        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(y)</span> 
        threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign
        states, or public order or causes incitement to the commission of any offence or prevents
        investigation of any offence or is insulting any other nation; offends the religious and national
        sentiments of the nation as included in 'Zero Tolerance to Profanity, Hurting National and Religious
        Sentiments' policy.
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(z)</span> 
        shall, directly or indirectly, offer or attempt to offer trade or attempt to trade in any item which is
        prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or
        guideline for the time being in force;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>(aa)</span> 
        shall create liability for Boaz or cause Boaz to lose (in whole or part) the services of Boaz's Internet
        Service Provider ("ISPs") or other suppliers.
        </li>
        <br/>
 
        </ul>
    

    <li>
    Seller shall not use any 'deep-link', 'page-scrape', 'robot', 'spider', automatic device, program, algorithm,
    methodology, or any similar or equivalent manual process to access, acquire, copy, monitor any portion of the
    Platform or content or in any way reproduce, or circumvent the navigational structure, presentation of the
    Platform, or any content to obtain or attempt to obtain any material, documents, or information through any
    means not purposely made available through the Platform. Boaz reserve our right to bar any such activities.
    </li>
    <br/>

    <li>
    Seller shall not attempt to gain unauthorized access to any portion or feature of the Platform, other systems,
    networks connected to the Platform, server, computer, network, or the services offered on or through the
    Platform by hacking, password 'mining', or any other illegitimate means.
    </li>
    <br/>

    <li>
    Seller shall not probe, scan or test the vulnerability of the Platform or any network connected to the Platform or
    breach the security, authentication measures on the Platform or any network connected to the Platform. Seller
    may not reverse look-up, trace or seek to trace information on any other user of or visitor to Platform (including
    any account on the Platform that is not owned by Seller) or to its source or exploit the Platform, any service,
    information made available, or offered by or through the Platform in any way where the purpose is to reveal
    any information (including but not limited to personal identification or information other than Seller own
    information) provided by the Platform.
    </li>
    <br/>

    <li>
    Seller shall not make any negative, denigrating, or defamatory statement(s)/comment(s) about Boaz, the
    brand name or domain name used by Boaz, including the terms Boaz, Crop Way, cropway.in , cropway.co.in ,
    or otherwise engage in any conduct or action that might tarnish the image or reputation of Boaz or sellers on
    the platform or otherwise tarnish or dilute any Boaz trademark, service marks, trade name and/or goodwill
    associated with such trade, service marks or trade name as may be owned or used by Boaz. Seller agree that
    it will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of
    the Platform or Boaz's systems, networks, or any systems or networks connected to Boaz.
    </li>
    <br/>

    <li>
    Seller agree not to use any device, software or routine to interfere or attempt to interfere with the proper
    working of the Platform, any transaction being conducted on the Platform or any other person's use of the
    Platform.
    </li>
    <br/>

    <li>
     Seller shall not forge headers or otherwise manipulate identifiers in order to disguise the origin of any
    message, transmittal Seller send to Boaz on or through the Platform, or any service offered on or through the
    Platform. Seller may not pretend that Seller are or represent someone else or impersonate any other
    individual or entity.
    </li>
    <br/>

    <li>
    Seller may not use the Platform or any content for any purpose that is unlawful or prohibited by the Agreement
    or to solicit the performance of any illegal activity or other activity which infringes the rights of Boaz and/or
    others.
    </li>
    <br/>

    <li>
    Seller shall at all times ensure full compliance with the applicable provisions of the Information Technology
    Act, 2000, and the other rules there under as applicable and amended from time to time and also all
    applicable domestic laws, rules and regulations (including the provisions of any applicable Exchange Control
    Laws or Regulations in force) and International Laws, Foreign Exchange Laws, Statutes, Ordinances and
    Regulations (including, but not limited to Integrated Goods and Services Tax Act, Central Goods and Services
    Tax Act, Food Safety and Standards Authority of India (FSSAI), relevant State Goods and Services Tax Act or
    Union Territories Goods and Services Tax Act and Custom Duty, Local Levies as may be applicable) and
    obtain the necessary licences and permits regarding Seller use of our Platform, service and/or tools and Seller
    listing, purchase, solicitation of offers to purchase, and sale of items or services. Seller shall not engage in any
    transaction in an item or service, which is prohibited by the provisions of any applicable law including
    exchange control laws or regulations for the time being in force.  
    </li>
    <br/>

    <li>
    Seller shall strictly comply with the OFAC Regulations and Other Applicable Sanctions Regulations as may be
    amended from time to time. The extant Trade Compliance Policy Statement is available at the Seller Learning
    Centre.
    </li>
    <br/>

    <li>
    Solely to enable Boaz to use the information Seller provide Boaz with and so that Boaz do not violate any
    rights Seller might have in Seller information, Seller agree to grant Boaz a non-exclusive, worldwide,
    perpetual, irrevocable, royalty-free, and sub-licensable (through multiple-tiers) right to exercise the copyright,
    publicity or database rights or any other rights Seller have in Seller information for any current or future
    promotional or advertising related activities and any business purposes of Boaz.
    </li>
    <br/>

    <li>
    From time-to-time Seller shall be responsible for providing information relating to the items or services
    proposed to be sold by Seller. In this connection, Seller undertake that all such information shall be accurate
    in all respects. Seller shall not exaggerate or over emphasize the attributes of such items or services so as to
    mislead other users on the Platform in any manner.
    </li>
    <br/>

    <li>
    Seller shall not engage in advertising or solicitation of other sellers on the Platform to buy or sell any products
    or services, including but not limited to products or services related to what is displayed on the Platform. Seller
    may not transmit any chain letters or unsolicited commercial or junk email to other users acquired/via the
    Platform. It shall be a violation of the Agreement to use any information obtained from the Platform in order to
    harass, abuse, or harm others or contact, advertise and sell to or solicit persons other than those who have
    chosen to buy from Seller. Seller understand that Boaz have the right at all times to disclose any information
    (including the identity of the persons who have provided information or material on the Platform) as necessary
    to satisfy any law, regulation, or valid governmental request. This may include, without limitation, disclosure of
    the information in connection with the investigation of an alleged illegal activity or its solicitation and/or
    response to a lawful court order or subpoena. In addition, Boaz can (and Seller hereby expressly authorized
    Boaz to) disclose any information about Seller to law enforcement or other government officials as Boaz, at
    our sole discretion, deem necessary or appropriate in connection with the investigation and/or resolution of
    possible crimes, especially those that may involve personal injury. Boaz reserve the right, but have no
    obligation, to monitor the material posted on the Platform. Boaz shall have the right, at its sole discretion, to
    remove any content that violates or is alleged to violate any applicable law or either the spirit or letter of the
    Agreement. Notwithstanding this right, SELLER REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF
    THE MATERIAL SELLER POST ON THE WEBSITE AND Seller INDEPENDENT COMMUNICATION WITH
    THE BUYERS AND OTHER SELLERS, REGARDLESS OF FORM. Please be advised that such content
    posted does not reflect Boaz's views. In no event shall Boaz assume or have any responsibility or liability for
    any content posted on the Platform or claims, damages, or losses resulting from its use and/or appearance of
    it on the Platform. Seller hereby represent and warrant that Seller have necessary rights to all the content
    Seller provide and all information it contains and that such content shall not infringe any proprietary or other
    rights of third parties or contain any misleading, libellous, tortuous, or otherwise unlawful information.
    </li>
    <br/>

    <li>
    Seller correspondence or business dealings with or participation in the promotion of advertisers on or through
    the Platform (including payment and delivery of related products or services, any other terms, conditions,
    warranties, or representations associated with such dealings) are solely between Seller and such advertisers.
    Boaz shall not be responsible or liable for any loss or damage of any sort incurred as a result of such dealings
    or the presence of such advertisers on the Platform.
    </li>
    <br/>

    <li>
    It is possible that other users (including unauthorized persons or 'hackers') may post or transmit offensive or
    obscene material on the Platform and that Seller may be involuntarily exposed to such material. It is also
    possible for others to obtain personal information about Seller due to Seller use of the Platform and use such
    information to harass or injure Seller. Boaz do not approve of such unauthorized uses but by using the
    Platform, Seller acknowledge and agree that Boaz is not responsible for the use of any personal information
    that Seller publicly disclose or share with others on the Platform. Please carefully select the type of information
    that Seller publicly disclose or share with others on the Platform.
    </li>
    <br/>

    <li>
    Boaz shall have all the rights to take necessary action and claim damages that may occur due to Seller
    involvement/participation in any way on Seller's own or through group(s) of people, intentionally or
    unintentionally, in DoS (denial of service) / DDoS (Distributed Denial of Services).
    </li>
    <br/>

    <li>
    Boaz and/or its affiliates may from time to time in partnership/association with its partners and/or third-party
    sponsors, organise/enable promotional campaigns on the Platform for the benefits of the customers/Sellers.
    Partners/third party sponsors may prescribe certain objective qualifying criteria to identify Sellers who will be
    eligible for the benefits of the campaign. All Sellers who qualify based on the objective criteria provided by
    such sponsors of the campaign will be auto opted-in and be able to obtain the benefits of such promotional
    campaign. In the event any Seller does not qualify based on the objective criteria, Sellers may choose to
    participate in such campaigns by expressly opting-in for them. In the event a Seller opts in for such
    promotional campaigns that they are not eligible for to enhance their sales, they will have to bear the cost of
    such promotional campaigns, if any, limited to the sales of their products. For avoidance of any doubt, some
    Sellers may qualify, and some may not, based on the qualifying criteria set for each individual campaign.
    Please refer to the campaign communication/notifications for more details, as may be communicated to Seller
    from time to time.
    </li>
    <br/>
    
</ol>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>8. Selling</text>
</div>
<p align="justify" style={ParaStyle}>
As a registered Seller, shall list item(s) for sale on the Platform in accordance with the policies which are incorporated
by way of reference in this Agreement. Seller must be legally able to sell the item(s) Seller list for sale on Boaz Platform
and must have all the necessary licences and permits required for such sale. Seller must ensure that the listed items do
not infringe upon the intellectual property, trade secret or other proprietary rights or rights of publicity or privacy rights of
third parties. Listings may only include text descriptions, graphics, pictures or videos that describe Seller item for sale.
All items must be listed in an appropriate category on the Platform. All listed items must be kept in stock for successful
fulfilment of sales. The listing description of the item must not be misleading and must describe the actual condition of
the product. If the item description does not match the actual condition of the item, Seller agree to refund any amount
that Seller may have received from the buyer. Seller agree not to list a single product in multiple quantities across
various categories on the Platform. Boaz reserves the right to delete such multiple listings of the same product listed by
Seller in various categories. Boaz reserves the right to restrict the selling of products originating from certain countries.
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>9. Compliance on selling of goods/services</text>
</div>
<p align="justify" style={ParaStyle}>
Seller shall also ensure full compliance with the provisions of Integrated Goods and Services Tax (IGST), Central
Goods and Services Tax (CGST) and Union Territory Goods and Services Tax (UTGST) or State Goods and Services
Tax (SGST) in respect of the goods/services supplied by Seller.
<br/>
<br/>
It is Seller responsibility to charge appropriate goods and services taxes on the supplies affected and remittance of the
same to the Government. Boaz shall not be responsible for any deficiency and/ or omission on Seller part. Boaz do not
mandate that any Products of seller should be sold exclusively on the Platform.
<br/>
<br/>
Pursuant to the tax collection at source provisions under IGST, CGST and/UTGST or SGST, the portal would collect tax
collection at source at applicable rates on net value of taxable supplies made through the portal and remit to the
appropriate Government.
<br/>
<br/>
In case of any mismatches on account of tax collection at source, Seller shall be required to provide all relevant
information to Boaz to correspond with the relevant authorities and also in case of any liability accruing on account of
omission shall be Seller obligation to pay such deficit.
<br/>
<br/>
Seller shall be required to provide the corresponding Harmonised System Nomenclature (HSN) code number for every
product listing. In the event that Seller do not provide the HSN code number that particular product will be delisted and
Seller will no longer be able to sell the product on our platform.
<br/>
<br/>
Seller shall also be required to provide Seller GSTIN, without which Boaz will not be able to raise an invoice on Seller.
In the event that Seller do not provide Seller GSTIN number, transactions on Seller account will be blocked and orders
will not be processed on Seller account. In the event of Seller providing Seller Input Service Distributor Registration
Number, Boaz would be issuing an invoice to the ISD GST registration number as furnished by Seller. It is Seller
responsibility to undertake the necessary compliance required in respect of the said ISD registration number.
<br/>
<br/>
In the event of any conflict between the terms of this clause and any other clause in these terms of use, the provisions
of this clause will prevail.
<br/>
<br/>
As per Section 194-O of the Finance Act, 2020, Boaz will deduct TDS on the gross amount of sale (excluding GST) of
goods or provision of services provided through the Platform at 1%.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>10. Content Posted on the Platform</text>
</div>
<p align="justify" style={ParaStyle}>
All text, graphics, seller interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and artwork, notes,
messages, emails, billboard postings, drawings, profiles, opinions, ideas, images, videos, audio files, other material or
information (collectively 'Content') are third-party generated Content, Boaz has no responsibility or liability over such
third-party generated Content as Boaz is merely an intermediary for the purposes of this Agreement. Except as
expressly provided in the Agreement, no part of the Platform including the Content may be copied, reproduced,
republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including
'mirroring') to any other computer, server, website or other medium for publication, distribution or any commercial
enterprise without Boaz's prior written consent.
<br/>
<br/>
Seller may use the information on the products and services made available on the Platform for downloading, provided
Seller:
<br/>
<br/>

<ol>

<li>
do not remove any proprietary notice language in all copies of such Content;
</li>
<br/>

<li>
use such Content only for Seller personal, non-commercial informational purpose and do not copy or post
such information on any networked computer or broadcast it to any media;
</li>
<br/>

<li>
make no modifications to any Content; and
</li>
<br/>

<li>
do not make any additional representations or warranties relating to the Content.
</li>
<br/>

</ol>
Seller shall be responsible for the Content posted or transmitted on the Platform by Seller. The Content will become
Boaz property and Seller grant Boaz the worldwide, perpetual, royalty free and transferable rights in such content. Boaz
shall be entitled to, consistent with its privacy policy as adopted in accordance with applicable law, use the Content or
any of its elements for any type of purpose forever, including, but not limited to, promotional and advertising purposes in
any media, whether now known or hereafter devised or the creation of derivative work. Seller agree that any content
Seller post may be used by Boaz, consistent with this AGREEMENT, and Seller are not entitled to any payment or other
compensation for such use.
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>11. Limited License</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz grants sellers a limited, non-transferable, non-exclusive, non-sub licensable, non-assignable and personal license
to use "Powered by Cropway" and/or "cropway.com" name and/or logo on seller's invoice for transactions concluded on
the Platform. Further, Boaz grants sellers a limited, non-transferable, non-exclusive, non-sublicensable, non-assignable
and personal license to use "cropway.com" name and/or logo on packing material used by sellers for delivery of
Products sold on the Platform. – To be discussed internally with Logistic Team
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>12. Types of Sellers</text>
</div>
<p align="justify" style={ParaStyle}>
All registered sellers are categorized into following tiers (or sub-tiers as it may deem fit in future):
<br/>
<br/>
<ol>
    <li>Gold</li>
    <li>Silver</li>
    <li>Bronze</li>
</ol>
This is done basis following criteria
<br/>
<br/>
<dl>
    <li>sales achieved by sellers (revenues and units)</li>
    <li>fulfilment experience provided to customers (breaches and cancellations)</li>
    <li>Percentage of local/zonal shipment out of Seller overall shipment</li>
    <li>percentage of overall shipments supplied in the same zone to customers by the seller</li>
    <li>customer feedback against the products sold by such sellers (returns and ratings), etc.</li>
</dl>
The thresholds for each of these criteria are transparently listed on https://seller.Boaz.com. Basis the tier, sellers get
benefits including the following:
<br/>
<br/>
<dl>
    <li>Reduced rate card for the shipping fees.</li>
    <li>Account management support</li>
    <li>Faster payments</li>
</dl>
Boaz reserves the right to revise the categories, criteria, and benefits to promote healthy competition amongst the
sellers so that their performance lead to better customer experience on the Platform.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>13. Payment</text>
</div>
<p align="justify" style={ParaStyle}>
<ol>
    <li>
    Seller shall be required to dispatch the products and/or services for every transaction to the buyer within the
    time period as provided in the AGREEMENT to ensure that the products and/or services are delivered in a
    timely manner. Further, Seller will solely be responsible for undertaking transit insurance for products sold by
    Seller on the Platform. For avoidance of doubt, Boaz will not be responsible for undertaking any insurance(s)
    for products sold by sellers on the Platform.
    </li>

    <li>
    Seller shall provide dispatch details and details of after-sales services related to products and services listed
    by it on the Platform to Boaz in such a manner and within a time period as provided in the policies, failing
    which the transaction shall stand cancelled.
    </li>

    <li>
    Seller shall dispatch the products and/or services using only an approved delivery channel which provides
    appropriate 'proof of dispatch' & 'proof of delivery' (PoDs) documentation. Such PoD documentation relating to
    delivery should be maintained by a seller for a period of 3 (three) years from the date of dispatch. The PoDs
    should be furnished to Boaz on demand within the time frame as notified from time to time.
    </li>

    <li>
    Seller agrees that the dispatch details shall be true, correct, and duly authorized and shall not be misleading,
    fraudulent, false, unauthorized, illegal and shall not contain any misrepresentation of facts.
    </li>

    <li>
    In case a seller fails to provide dispatch details or provides dispatch details not complying with policies, it shall
    result in consequences as more specifically stated in the AGREEMENT and may lead to suspension and/or
    termination of seller account.
    </li>

    <li>
    Seller agrees that the transaction price paid by a buyer will be remitted to a seller's bank account contingent
    upon the following events:
    </li>
    
    <ul>
        <li>
        <span style={SpanStyle}>a)</span>
        Buyer confirms the delivery of products and/or services in the transaction;
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>b)</span>
        Buyer does not take any action on payment facility to confirm delivery within such a time period as
        provided in the policies despite confirmation of dispatch of products and/or services by a seller to the
        buyer
        </li>
        <br/>

        <li>
        <span style={SpanStyle}>c)</span>
        Buyer's refund claim is rejected by Boaz due to any breach of the Agreement, policies, and any
        applicable law;
        <br/>
        <br/>
        Once the transaction is completed by the Seller, the remittance to seller shall be made in accordance with
        RBI Intermediary Guidelines.
        </li>
        <br/>
    </ul>

    <li>
    Seller are required to route all shipments/consignments through the Logistic Partner, unless otherwise
    specified. 'Logistic Partner' shall mean a logistic service provider as approved by Boaz.
    </li>
</ol>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>14. Prepaid Payment Instruments</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz may, either itself or through third-party service providers, offer prepaid instruments as a payment option for
transactions on the Platform to users. Any purchases by buyers on the Platform using the prepaid instruments shall be
governed by the following terms and conditions:
<br/>
<br/>
<ol>
    <li>
    Such prepaid instruments may be used to make payments for the products and/or services purchased on the
    Platform
    </li>
    <br/>

    <li>
    Such prepaid instruments can be redeemed by buyers by selecting the payment mode as may be provided on
    the Platform.
    </li>
    <br/>

    <li>
    Such prepaid instruments cannot be used to purchase other prepaid instruments or gift vouchers.
    </li>
    <br/>

    <li>
    If the order value exceeds the amount of such prepaid instruments, the balance must be paid by the
    respective buyer via Credit Card/Debit Card /Internet Banking. COD shall not be available as payment option
    for such transactions.
    </li>
    <br/>

    <li>
    If the order value is less than the amount of such prepaid instruments, the outstanding balance (after
    deduction of order value) will reflect as credit balance for such prepaid instruments.
    </li>
    <br/>

    <li>
    Prepaid instruments and any unused balance of such prepaid instruments shall expire 1 year from the date of
    their issue.
    </li>
    <br/>

    <li>
    Prepaid instruments cannot be redeemed for cash.
    </li>
    <br/>

    <li>
    Boaz is not responsible if prepaid instruments are lost, stolen, or used without permission.
    </li>
    <br/>

    <li>
    Buyers can combine and use a maximum of 3 prepaid instruments per order. They can be combined with
promotional codes.
    </li>
    <br/>

    <li>
    Purchases of prepaid instruments are not eligible for cashback offers.
    </li>
    <br/>

    <li>
    Purchases of prepaid instruments are not eligible for cashback offers.
    </li>
    <br/>

    <li>
    Boaz will make payments to sellers whose products and/or services have been purchased by buyers
    redeeming electronic gift vouchers (EGV) as per the guidelines issued by the Reserve Bank of India from time
    to time.
    </li>
</ol>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>15. Financial Facilities for customers</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz has operationalized tie-ups with multiple partners for providing financial assistance to customers for purchasing
products on the platform and thereby enhancing the transaction capability of customers. In pursuance of the same, in
the event of the customers opting for the financial assistance program and Seller duly receiving such amounts from the
partners (on behalf of the customers), Seller hereby agree to assign all receivables from the customer for such
transaction. Therefore, Seller hereby agree that without any further action on Seller part, upon receipt of the amount due
from such partners, any and all receivables due from the customers shall stand assigned in favour of the partner. Seller
hereby authorize Boaz to enter into any documentation on Seller behalf to complete and perfect the assignment. Upon
such assignment, the financing partner shall have all the rights as the seller may have had in relation to the collection of
receivables from the customer and the customer shall be required to repay the financing partner directly. The lending
partner further reserves the right to assign and transfer such receivables under these terms and conditions to an
NBFC/financial institution as it may deem fit.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>16. Charges</text>
</div>
<p align="justify" style={ParaStyle}>
Registration on the Platform is free. Boaz does not charge any fee for browsing/registering on the Platform. However,
before Seller list a product or service for sale through the Platform, Boaz request Seller to review our fee policy, which is
hereby incorporated by reference into this Agreement. Boaz reserves the right to change its fee policy from time to time.
In particular, Boaz may, at its sole discretion, introduce new services and modify some or all of the existing services
offered on the Platform. In such an event, Boaz reserves the right to introduce fees for the new services offered or
amend/introduce fees for existing services, as the case may be. Changes to the fee policy shall be posted on the
Platform and such changes shall automatically become effective immediately after they are posted on the Platform.
Unless otherwise stated, all fees shall be quoted in Indian Rupees (INR) and payable to Boaz. Seller shall be solely
responsible for compliance with all applicable laws for making payments to Boaz. Seller hereby agree that Boaz shall
have the right to set off any amounts due and payable by Seller to Boaz against any payments due from Boaz to Seller.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>17. Applicable Taxes including GST /Taxes</text>
</div>
<p align="justify" style={ParaStyle}>
Seller is responsible for paying all fees associated with the use of the Platform and charges collected in respect of such
usage. Seller agrees to bear any and all applicable taxes, charges, cesses levied thereon (including
CGST+SGST/IGST/CGST+UGST and GST cess as may be applicable to the transaction).
<br/>
<br/>
<ol>
    <li>
    Seller agrees that Boaz may offer to provide express remittance to eligible sellers as per payments settlement
    policy. Express Remittance shall be subject to RBI Intermediary Guidelines and arrangements/directions of the
    nodal bank. Boaz, at its sole discretion, may make such an offer to eligible sellers and the same shall not be
    construed as a right but only as a privilege. Eligible sellers understand that Boaz reserves the right to withdraw
    Express Remittance at any time for any violation of the Agreement or Boaz's policies and/or failure by the
    eligible sellers to maintain or comply with the parameters as may be decided by Boaz from time to time.
    </li>
    <br/>
    
    <li>
    Invoice generation: A seller expressly agrees that issuing correct and complete invoice is the sole and primary
    responsibility of a seller. Boaz will assist Seller with this process by generating an invoice on Seller behalf. For
    Boaz to generate these invoices, Boaz would need a digital image of Seller signature which will be affixed on
    the invoice. The invoice shall then be generated and sent to the seller. The seller shall be required to
    physically sign the invoice, print the invoice and affix the same on the consignment. The invoice generated by
    Boaz shall be affixed by the seller on the consignment. Notwithstanding anything else contained in these
    terms of use, the seller shall be solely liable for any liability which may be imposed by taxation authorities for
    any discrepancy in the invoices. 

    <br/>
    <br/>
    A seller expressly agrees that issuing the correct and complete invoice is the sole and primary responsibility of
    a seller. Furthermore, the seller shall ensure that invoices state 'Powered by Boaz' and failing to do so, a seller
    shall be liable to chargebacks (as applicable).
    </li>
    <br/>

    <li>
    In case of any chargebacks levied by the bank, Boaz shall have the right to deduct such chargebacks from
    seller remittances, present and future, and a seller's only remedy will be to discuss and resolve the same with
    the bank. A seller hereby agrees to extend full co-operation in resolving the chargeback disputes raised by a
    buyer through the bank and shall provide necessary documentation regarding the transaction to the complete
    satisfaction of the bank. In case the chargeback is ruled against a seller, Boaz shall be entitled and authorized
    to recover the same from the seller to its fullest extent and the bank's decision shall be final and binding in this
    regard. In the event Boaz has made any excess payment to the seller inadvertently, such excess payments
    shall be set-off from any future payments payable by Boaz to the seller.
    </li>
    <br/>

    <li>
    Boaz may delay notifying the payment confirmation, i.e. informing the seller to dispatch if Boaz deems
    suspicious or a buyer conducts high transaction volumes to ensure safety of the transaction and transaction
    price. In addition, Boaz may hold transaction price and not inform seller to dispatch or remit transaction price
    to law enforcement officials (instead of refunding the same to a buyer) at the request of law enforcement
    officials or in the event of a buyer being engaged in any form of illegal activity.
    </li>
    <br/>

    <li>
    Sellers acknowledge that Boaz will not be liable for any damages, interests, claims etc. resulting from not
    processing a transaction/transaction price or any delay in processing a transaction/transaction price that is
    beyond the control of Boaz.
    </li>
    <br/>

    <li>
    Boaz shall make payments into the bank account provided by a seller during the seller registration process.
    Once Boaz has made payments into such a bank account number, Boaz shall be discharged of any/all
    liabilities towards the seller and the seller shall not be eligible for any claims whatsoever.
    </li>
    <br/>

</ol>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>18. Compliance with Laws</text>
</div>
<p align="justify" style={ParaStyle}>
    <ol>
        <li>
        Seller undertakes that they have obtained and will continue to maintain in force all the necessary licenses,
        permissions, authorizations, and permits needed to distribute, market, supply and sell the [*] ("Products") on
        www.cropway.com under applicable laws, from time to time, including, the Food Safety and Standards Act,
        2006, Food safety and standards (licensing and registration of food business), Regulations, 2011 and all
        applicable legislations under FoodSafety and Standards Regulations, to sell his products, in each case, as
        amended from time to time. For all times during which the seller may advertise, distribute, market, supply or
        sell the Products on www.cropway.com, the undertaking remains true and correct in all respects. Further, the
        seller shall immediately notify Boaz Agri Analytics Pvt Limited, in writing, upon the lapse of the FSSAI or any
        other applicable License and/or in case of receipt of any order, demand, warrant or document or any
        regulatory action with respect to the FSSAI License. The seller acknowledges and agrees that Boaz (Boaz
        Agri Analytics Pvt Ltd) shall be permitted to assign or otherwise furnish the undertaking to any authority or
        person as may be required for official purposes. Seller shall also comply with the Legal Metrology Act, 2009
        and the related rules and regulations while listing products on the platform cropway.com. Seller shall be
        required to enter the mandatory attributes while listing products on the platform (including but not limited to the
        country of origin of the product) as provided under the Legal Metrology Packaged Commodity Rules and the
        related amendments.
        </li>
        <br/>

        <li>
        Seller shall comply with all laws and regulations applicable, including but not limited to anti-money laundering
        (including 'know Seller customer' and 'customer due diligence'), and economic sanctions laws and regulations.
        Neither party will engage in a transaction pursuant to this Agreement that will cause the other party to violate
        such regulations.
        </li>
        <br/>

        <li>
        Seller shall ensure that no products are sourced or used in the manufacturing or in the provision of services
        which originate wholly or in part, from any of the prohibited countries stated in the 'OFAC Regulations and
        Other Applicable Sanctions Regulations'.
        </li>
        <br/>

        <li>
        If and to the extent that Seller collect, access, use, store, record, or otherwise process (collectively "Process")
        any personally identified or identifiable information such as name, age, gender, email address, physical
        address, phone number, in any form that can be linked to a specific individual ("Personal Information")
        received by Seller from or on behalf of Boaz (or any Boaz group company) employees, contractors, users,
        partners, or other third parties or otherwise obtained in connection with performing Seller obligations under
        this Agreement, Seller agree to:
        </li>
        <br/>

        <ol type='i'>
            <li>
            comply with applicable data protection laws, rules and regulations governing the collection, use,
            protection, breach notification, retention, disclosure of Personal Information including but not limited
            to Information Technology Act, 2000 and the Information Technology (Reasonable Security practices
            and procedures and sensitive personal data or information) Rules, 2011 ("Applicable Data Protection
            Law"), including any requirements applicable to storage or cross-border transfer of Personal
            Information outside India;
            </li>
            <br/>

            <li>
            keep and maintain all Boaz's Personal Information in strict confidence and the obligation to protect
            Personal Information shall survive in perpetuity; and
            </li>
            <br/>

            <li>
            process Boaz's Personal Information solely to fulfil Seller obligations under this Agreement and not
            sell, rent, trade, lease, use for its own advertisement or marketing purposes, or otherwise make an
            unauthorized disclosure of Boaz's Personal Information to any third party.
            </li>
            <br/>
        </ol>

        <li>
        If and to the extent that Seller provide Sellers or others' Personal Information to Boaz for processing, Seller
        warrant that such Personal Information was collected lawfully, and there is no restriction on Seller under the
        Applicable Data Protection Law, from providing such Personal Information to Boaz or any processing by Boaz
        under this Agreement. Boaz will only use information provided by Seller in accordance with this Agreement
        and our Privacy Policy available at <a href='https://www.cropway.com/'>www.cropway.com</a>.
        </li>
        <br/>

        <li>
        Seller undertake sole and exclusive responsibility to ensure that the products Seller want to list
        on <a href='https://www.cropway.com/'>cropway.com</a> for Sales are permissible for such a Sale and are not prohibited or restricted per one or many
        conditions imposed by the extant laws of the land, Boaz's Prohibited and Restricted Items Policy, or other
        Boaz policies/Indian Laws.
        </li>
        <br/>

        <li>
        Products must adhere to the legal metrology laws and Indian Labelling requirements by ensuring that the
        indicated weights and measurement indicated in the products and/or its components are commensurate with
        the mandatory and legal requirements of the same which make the product and/or components accurate,
        authentic and secure for consumption/utilisation by the customer. Boaz does not assume any responsibility for
        the proceedings undertaken by an aggrieved customer/consumer against the seller in the event of a nonadherence. However, in the event of a complaint from a consumer/customer, the seller is obligated to answer
        queries on the non-adherence to the Legal Metrology Laws, failing which Boaz can temporarily/indefinitely
        suspend/terminate/block/with-hold Seller account at its own discretion.

        </li>
        <br/>
    </ol>

</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>19. Product Description</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz does not warrant that product description or other content on the Platform is accurate, complete, reliable, current,
or error-free and assumes no liability in this regard.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>20. Audits</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz shall have the right to inspect and audit seller's records and premises / place of business through itself or through
Boaz approved third party testing agencies. Cost of such an audit shall solely be borne by Boaz unless the audit reflects
discrepancy in seller accounts / non-compliance with Boaz's seller policies, in which case the cost of audit shall be
borne by the seller.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>21. Breach</text>
</div>
<p align="justify" style={ParaStyle}>
Without limiting other remedies, Boaz may limit Seller activity, immediately remove Seller information, warn other users
of Seller actions immediately, temporarily/indefinitely suspend/terminate/block Seller account and/or refuse Seller
access to the Platform, or put Seller account on hold, in the event of, including but not limited to, the following:
<br/>
<br/>
<ol>
    <li>
    if Seller breach the Agreement, privacy policy or other policies (if any);
    </li>
    <br/>

    <li>
    if Boaz are unable to verify or authenticate any information Seller provide;
    </li>
    <br/>

    <li>
    if it is believed that Seller actions may cause legal liability for Seller, other users, or Boaz; or
    </li>
    <br/>

    <li>
    if Seller do not produce the legal requirement documents such as, the documents required for product sales in
    the Drugs and Cosmetics category, the BIS license documents, the Brand Authorisation letter, or a Trademark
    registration proof, as may be required by Boaz Authorities.
    </li>
    <br/> 
</ol>
Boaz may at any time, at our sole discretion, reinstate suspended sellers. A seller that has been suspended or blocked
may not register or attempt to register with Boaz or use the Platform (through itself or any other entity or legal form) in
any manner whatsoever until such time that such a seller is reinstated by Boaz. Notwithstanding the foregoing, if Seller
breach the Agreement or other rules and policies, Boaz reserve the right to recover any amounts due and owed by
Seller to Boaz and take strict legal action, including but not limited to a referral to the appropriate police or other
authorities for initiating criminal or other proceedings against Seller.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>22. Indemnity</text>
</div>
<p align="justify" style={ParaStyle}>
Seller shall indemnify and hold harmless Boaz its owner, licensee, affiliates, subsidiaries, group companies (as
applicable) and their respective officers, directors, agents, and employees from any claim, demand, or actions including
reasonable attorneys' fees made by any third party or penalty imposed due to or arising out of Seller breach of the
Agreement, privacy policy and other policies or Seller violation of any law, rules, regulations or the rights (including
infringement of intellectual property rights) of a third party.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>23. Trademark complaint</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz respects the intellectual property of others. In case Seller feel that Seller trademark has been infringed, Seller can
write to Boaz at <a href="mailto:info@cropway.com">info@cropway.com</a>.
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>24. Copyright complaint</text>
</div>
<p align="justify" style={ParaStyle}>
Boaz respects the intellectual property of others. In case Seller feel that Seller work has been copied in any way that
constitutes copyright infringement Seller can write to Boaz at <a href="mailto:info@cropway.com">info@cropway.com</a> or raise an incident from Seller
dashboard/panel.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>25. Trademark, Copyright and Restriction</text>
</div>
<p align="justify" style={ParaStyle}>
The Website is controlled and operated by Boaz and products are sold by respective registered sellers. All material on
the Platform, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks and
other intellectual property rights. Seller must not copy, reproduce, republish, upload, post, transmit, or distribute Boaz's
or other sellers' material in any way, including by email or other electronic means and whether, directly or indirectly,
Seller must not assist any other person to do so. Without the prior written consent of the owner, modification or use of
the material on any other website/networked computer environment or for any purpose other than personal, noncommercial use is a violation of the copyrights, trademarks, and other proprietary rights is prohibited. Any use for which
Seller receive any remuneration, whether money or otherwise, is a commercial use for the purposes of this clause.
<br/>
<br/>
</p>
</div>


<div>
<div style={HeaderDiv}>
<text style={HeaderText}>26. Limitation of Liability</text>
</div>
<p align="justify" style={ParaStyle}>
IN NO EVENT SHALL BOAZ BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL
DAMAGES OF ANY KIND IN CONNECTION WITH THE AGREEMENT, EVEN IF BOAZ HAS BEEN INFORMED IN
ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>27. Applicable Law</text>
</div>
<p align="justify" style={ParaStyle}>
The Agreement shall be governed, interpreted, and construed in accordance with the laws of India. The place of
jurisdiction shall exclusively be Raipur.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>28. Jurisdictional Issues/Sale in India Only</text>
</div>
<p align="justify" style={ParaStyle}>
Unless otherwise specified, the material on the Website is presented solely for the purpose of sale in India. Boaz makes
no representation that the material on the Website is appropriate or available for use in other locations/countries other
than India. Those who choose to access the Website from other locations/countries other than India do so on their own
initiative and Boaz is not responsible for supply of products/refund for the products ordered from other
locations/countries other than India and compliance with local laws, if and to the extent local laws are applicable.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>29. Contact Boaz</text>
</div>
<p align="justify" style={ParaStyle}>
Seller is required to send any/all comments or questions, including all enquiries not related to trademark and copyright
infringement, by raising an incident using Seller dashboard/panel.
<br/>
<br/>
</p>
</div>

<div>
<div style={HeaderDiv}>
<text style={HeaderText}>30. Grievance Officer</text>
</div>
<p align="justify" style={ParaStyle}>
In accordance with the IT Act, 2000, and the rules there under, the name and contact details of the grievance officer are
provided below:
</p>
</div>


<div>
      <Grid container spacing={3}  style={{paddingTop:10}}>
      
        <Grid item xs={8}  />
        <Grid item xs={4} >
          <text style={TextStyle}>Boaz Agri Analytics Pvt Ltd</text>
          <br/>
          <text style={TextStyle}>Address - P-22, Sector-1, opp. CSEB SubStation, Avanti Vihar, Raipur, 492001, Chhattisgarh</text>
            <br/>
          <text style={TextStyle}>
          Phone: 7566320000
          </text>
          <br/>
          <text style={TextStyle}>Email: <a href="mailto:shlok.agrawal@cropway.com">shlok.agrawal@cropway.com</a></text>
          <text style={TextStyle}>Time: Mon – Sat (9:00 – 18:00)</text>
        </Grid>
      </Grid>
    </div>


    </div>
)
}

export default RegisterationTerms;
