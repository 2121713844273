import axios from "axios";
import axiosAPI from "../components/AxiosAPI";

import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAYMENT_SUCCESS,
  ORDER_PAYMENT_REQUEST,
  ORDER_PAYMENT_FAIL,
  ORDER_LIST_MINE_REQUEST,
  ORDER_LIST_MINE_SUCCESS,
  ORDER_LIST_MINE_FAIL,
  ORDER_PAYMENT_CREATE_REQUEST,
  ORDER_PAYMENT_CREATE_SUCCESS,
  ORDER_PAYMENT_CREATE_FAIL,
} from "../constants/OrderConstants";

import { CART_CLEAR_ITEMS } from "../constants/CartConstants";
import { BASE_URL_HOSTED } from "../constants/api";

export const createOrder = (order) => async (dispatch, getState) => {
  let cartClearItem = ''
    dispatch({ type: ORDER_CREATE_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    // //console.log(`Bearer ${userInfo.access}`);

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    // //console.log("config");

    await axiosAPI.post(`${BASE_URL_HOSTED}api/orders/`, order,
      config
    )
    .then((response)=>{
      cartClearItem = response.data.data
      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: response.data.data,
      });
    })
    .catch((error)=>{
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    })

    // //console.log(data);

    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: cartClearItem,
    });


 
};

export const getOrderDetails = (order_id) => async (dispatch, getState) => {

    dispatch({ type: ORDER_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    await axiosAPI.get(
      `${BASE_URL_HOSTED}api/orders/${order_id}`,
      config
    )
    .then((response)=>{
      dispatch({
        type: ORDER_DETAILS_SUCCESS,
        payload: response.data.data,
      });

    })
    .catch((error)=>{
      dispatch({
        type: ORDER_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    })

};

export const paymentOrder =
  (order_id, user_id, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_PAYMENT_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      //console.log(`Bearer ${userInfo.access}`);

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };
      //console.log(user_id);
      //console.log(order_id);
      const { data } = await axiosAPI.get(
        `${BASE_URL_HOSTED}api/orders/${user_id}/${order_id}/pay/`,
        config
      );

      //console.log("paymentOrder::", data);

      dispatch({
        type: ORDER_PAYMENT_SUCCESS,
        payload: data,
      });

      //   dispatch({
      //     type: CART_CLEAR_ITEMS,
      //     payload: data,
      //   });
    } catch (error) {
      dispatch({
        type: ORDER_PAYMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const mineOrder = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_LIST_MINE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    //console.log(`Bearer ${userInfo.access}`);

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    ////console.log(config);

    const { data } = await axiosAPI.get(
      `${BASE_URL_HOSTED}api/myorders/`,
      config
    );

    //console.log("OrderData: ", data);

    dispatch({
      type: ORDER_LIST_MINE_SUCCESS,
      payload: data,
    });

    //   dispatch({
    //     type: CART_CLEAR_ITEMS,
    //     payload: data,
    //   });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_MINE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const OrderPayment = (RZdata) => async (dispatch) => {
  try {
    dispatch({ type: ORDER_PAYMENT_CREATE_REQUEST });

    let { data } = await axios.post(
      `http://127.0.0.1:8000/api/razorpay/`,
      RZdata
    );
    // //console.log(data);

    dispatch({
      type: ORDER_PAYMENT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PAYMENT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UpdateOrderItem = (id, status) => {
  //  //console.log(id);
  //  //console.log(status)
  const uploadData = new FormData();
  uploadData.append("status", status);

  fetch(`${BASE_URL_HOSTED}api/UpdateOrderIt/${id}/`, {
    method: "PUT",
    body: uploadData,
  });
  // .catch((error) => //console.log(error));
};
