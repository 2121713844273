import React, { useEffect } from "react";
import HomeDesktop from "../screens/HomeDesktop"
import Loader from "../components/Loader";
import TagManager from "react-gtm-module";
import '../components/Style/posts.css';
import { useDispatch, useSelector } from "react-redux";
import { homePageProducts } from "../actions/product.action";
import Error from "../components/Error";

const tagManagerArgs = {
  gtmId: "GTM-NNVDS54",
};
TagManager.initialize(tagManagerArgs);

function HomeScreen(){
  const dispatch = useDispatch()
  const {loading, error, homePageProduct } = useSelector(state=>state.homepageProduct)
  const getProduct=()=>{
    dispatch(homePageProducts())
  }

  useEffect(()=>{
    !homePageProduct && getProduct()
  },[]);

  return (
    <div>
      <Loader loading={loading}/>
      <Error error={error}/>
      <HomeDesktop HomePageProducts={homePageProduct} loading={loading} error={error}/>
    </div>
  );
}

export default HomeScreen;
