import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DesktopSection } from "../components/ProductCategoryDesktopSection";
import { MobileSection } from "../components/ProductCategoryMobileSection";
import { useDispatch, useSelector } from "react-redux";
import {
  listFarmProductName,
  listFilteredFarmProducts,
} from "../actions/productActions";
import Pagination from "../components/Pagination";
import Posts from "../components/Posts";
import { DropMenuHover } from "../components/DropMenuHover";
import RecommandProduct from "../components/RecommandProduct";
import Sponsored from "../components/SponsoredProductCategory";
import Loader from "../components/Loader";
import { listBrowsingHistory } from "../actions/BrowsingHistoryActions";

function FarmProductBuyingScreen({ match, history }) {
  const matches = useMediaQuery("(min-width:600px)");
  const [catselect, setCatSelect] = useState(match.params.catgselect); ///
  const [category, setCategory] = useState();
  const [filtCat, setFiltCat] = useState(false);
  const [Slidervalue, setSliderValue] = React.useState([50, 150]);

  let [showCategory, setShowCategory] = useState([
    { catsel: match.params.catgselect, catName: match.params.category },
  ]); ///
  const [filt, setFilt] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [posts, setPosts] = useState([]);
  const [status, setStatus] = useState(false);
  const [screenType, setScreenType] = useState("FarmScreen");
  const [postsPerPage] = useState(12); ////** 2 is the no. of products per page.you can  ///

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const FarmProductNameList = useSelector((state) => state.FarmProductNameList);
  const { FarmProductNameError, FarmProductNameLoading, FarmProductName } =
    FarmProductNameList;

  const [pagiPostingForm, setPagiPostingForm] = useState();

  const BrowserHistoryList = useSelector((state) => state.BrowserHistoryList);
  const { browse_his_error, browse_his_loading, browse_history } =
    BrowserHistoryList;

  const FilteredFarmProducts = useSelector(
    (state) => state.FilteredFarmProducts
  );
  const {
    FilteredFarmProductError,
    FilteredFarmProductLoading,
    FilteredFarmProduct,
    FilteredFarmProduct_Max_Price,
    FilteredFarmProduct_Total_Count,
  } = FilteredFarmProducts;
  const [MaxPrice, setMaxPrice] = useState(0);

  const {user} = useSelector((state) => state.auth);

  //Tis use effect is added because of issue raised by the languge change.
  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    setCatSelect(match.params.catgselect);
    setCategory(match.params.category);
    setShowCategory([
      { catsel: match.params.catgselect, catName: match.params.category },
    ]);
  }, [match.params.catgselect, match.params.category]);
  //////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(async () => {
    var catarray = [];
    if (user) {
      showCategory.map((ShowCat, index) => {
        if (index !== 0) {
          catarray.push({
            cat: ShowCat.catName,
            catselect: ShowCat.catsel,
            filteringcat: showCategory[index - 1].catName,
          });
        } else {
          catarray.push({ cat: ShowCat.catName, catselect: ShowCat.catsel });
        }
      });
      const PostingForm = {
        cat: catarray,
        // catselect: "subsubbrand"
      };
      var ActualPostingObj = JSON.stringify(PostingForm);
      //console.log("cattt", catarray);
      if (catarray.length > 0) {
        localStorage.setItem("pageNumberCatScreen", JSON.stringify(1));
        setPagiPostingForm(ActualPostingObj);
        dispatch(listFilteredFarmProducts(ActualPostingObj, 1));
        setFilt(true);
      }
      if (catarray.length > 0) {
        dispatch(listFarmProductName(ActualPostingObj, "categories"));
      }

      dispatch(listBrowsingHistory("less"));
    } else {
      history.push("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  }, [dispatch, category, catselect, user]);

  const set = (MultiFilterArrObj, SortbyToggle) => {
    setToggle(SortbyToggle);

    var cat_obj = MultiFilterArrObj.filter(
      (cat) => cat && cat.cat !== undefined && cat !== "" && cat !== undefined
    );

    //PostingForm is used for corverting the arr obj in the form which can sent to backend
    const PostingForm = {
      cat: cat_obj,
      // catselect: "subsubbrand"
    };
    var ActualPostingObj = JSON.stringify(PostingForm); //used for posting data in json format
    setPagiPostingForm(ActualPostingObj);
    //console.log("catobj", cat_obj);

    if (cat_obj.length > 0) {
      setFilt(true);
      dispatch(listFilteredFarmProducts(ActualPostingObj, 1));
    } else {
      setFilt(false);
    }
  };

  const Rating = [
    {
      id: 1,
      star: 4,
    },

    {
      id: 2,
      star: 3,
    },

    {
      id: 3,
      star: 2,
    },

    {
      id: 4,
      star: 1,
    },
  ];

  const RateStar = (star) => {
    return (
      <span>
        {[...Array(5).keys()].map((x) => (
          <i
            style={{ color: "#f8e825" }}
            className={star < x + 1 ? "far fa-star" : "fas fa-star"}
          />
        ))}
        <span style={{ marginLeft: 5 }}>{"& above"}</span>
      </span>
    );
  };

  // pagination

  useEffect(() => {
    const pagi = async () => {
      setPosts(FilteredFarmProduct);
      if (FilteredFarmProductLoading === false) {
        setStatus(true);
        setMaxPrice(FilteredFarmProduct_Max_Price);
        setSliderValue([0, FilteredFarmProduct_Max_Price]);
      } else {
        setStatus(false);
      }
    };
    pagi();
  }, [FilteredFarmProductLoading]);

  // Get current posts

  const currentPosts = posts;
  // Change page
  const paginate = (pageNumber) => {
    localStorage.setItem("pageNumberCatScreen", JSON.stringify(pageNumber));
    dispatch(listFilteredFarmProducts(pagiPostingForm, pageNumber));
  };
  // pagination

  const rangeSelector = (event, newValue) => {
    setSliderValue(newValue);
    // console.log("newValue",Slidervalue);
  };

  const [hover, setHover] = useState(0);

  return (
    <div>
      {matches ? (
        <DesktopSection
          t={t}
          setHover={setHover}
          hover={hover}
          DropMenuHover={DropMenuHover}
          match={match}
          toggle={toggle}
          setToggle={setToggle}
          set={set}
          category={category} //
          catselect={catselect}
          filtCat={filtCat}
          setFiltCat={setFiltCat}
          filt={filt}
          setFilt={setFilt}
          setCatSelect={setCatSelect}
          setCategory={setCategory} //
          showCategory={showCategory}
          FilteringCategories={FarmProductName}
          BrandList={[]} //Because farm produce doesn't contain any brands till now
          Slidervalue={Slidervalue}
          rangeSelector={rangeSelector}
          RateStar={RateStar}
          Rating={Rating}
          currentPosts={currentPosts}
          Sponsored={Sponsored}
          SponsoredProduct={currentPosts}
          Posts={Posts}
          MaxPrice={MaxPrice}
          status={status}
          setStatus={setStatus}
          pageloading={FilteredFarmProductLoading}
          Pagination={Pagination}
          postsPerPage={postsPerPage}
          posts={posts}
          paginate={paginate}
          Loader={Loader}
          RecommandProduct={RecommandProduct}
          FilteredCategory_Total_Count={FilteredFarmProduct_Total_Count}
          ScreenTpye={screenType}
          browse_history={browse_history}
          browse_his_loading={browse_his_loading}
          history={history}
        />
      ) : (
        <MobileSection
          t={t}
          setHover={setHover}
          hover={hover}
          DropMenuHover={DropMenuHover}
          match={match}
          toggle={toggle}
          setToggle={setToggle}
          set={set}
          category={category} //
          catselect={catselect}
          filtCat={filtCat}
          setFiltCat={setFiltCat}
          filt={filt}
          setFilt={setFilt}
          setCatSelect={setCatSelect}
          setCategory={setCategory} //
          showCategory={showCategory}
          FilteringCategories={FarmProductName}
          BrandList={[]} //Because farm produce doesn't contain any brands till now
          Slidervalue={Slidervalue}
          rangeSelector={rangeSelector}
          RateStar={RateStar}
          Rating={Rating}
          currentPosts={currentPosts}
          Sponsored={Sponsored}
          SponsoredProduct={currentPosts}
          Posts={Posts}
          status={status}
          setStatus={setStatus}
          pageloading={FilteredFarmProductLoading}
          Pagination={Pagination}
          postsPerPage={postsPerPage}
          posts={posts}
          MaxPrice={MaxPrice}
          paginate={paginate}
          Loader={Loader}
          RecommandProduct={RecommandProduct}
          FilteredCategory_Total_Count={FilteredFarmProduct_Total_Count}
          ScreenTpye={screenType}
        />
      )}
    </div>
  );
}

export default FarmProductBuyingScreen;
