// Data service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const getProducts=async(type, page)=>{
    return axios.get(`${API_URL}/getallproduct`,
    {
      params:{
          page : page,
          type : type
      }
    }
    )           
  }

const getFilterProducts=async(filter, page)=>{
    return axios.post(`${API_URL}/filterproduct`, filter,
    {
      params:{
          page : page,
      }
    }
    )           
  }

const getProductDetail=async(productId)=>{
    return axios.get(`${API_URL}/getproductdetail`,
    {
      params:{
        product_id : productId,
      }
    }
    )           
  }

const getCategory=(level, parentId)=>{
    return axios.get(`${API_URL}/category`,
    {
        params:{
        level : level,
        parent_id : parentId
        }
    })
}

const getBrand=(filterConfig)=>{
return axios.get(`${API_URL}/brand`,
{
    params:{filter_config : filterConfig}
})
}

const searchHandler=async(search, page)=>{
    // console.log('search :: ',  search, page)
    return axios.get(`${API_URL}/searchproduct`,
    {
      params:{
        page:page,
        search_text:search
      }
    }
    )
}

const exportedObject={
    getProducts,
    getFilterProducts,
    getProductDetail,
    getCategory,
    getBrand,
    searchHandler
}

export default exportedObject