import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import {
  MediumButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
import {
  API_URL_BLOCK,
  API_URL_DISTRICT,
  API_URL_STATE,
  API_URL_VILLAGE,
} from "../Constants";
import axios from "axios";

export default class FarmerReg2 extends React.Component {
  state = {
    state: [],
    district: [],
    block: [],
    society: [],
    village: [],
  };

  componentDidMount = async () => {
    axios
      .get(`${API_URL_STATE}${1}`)
      .then((res) => {
        this.setState({ state: res.data.reverse() });
        //console.log(this.state.state);
      })
      .catch((error) => {
        //console.warn(error);
      });
  };

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  distget = async (val) => {
    //console.log("knddd");
    const Value = val.split(",");
    //console.log("inside dist get", Value[0]);

    this.props.handleChange("states", Value[0]);

    var ids = Number(Value[1]);

    axios
      .get(`${API_URL_DISTRICT}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("district"));
        this.setState({ district: result_sorted });
        // console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  blockget = async (val) => {
    //console.log("inside block get");

    const Value = val.split(",");

    this.props.handleChange("district", Value[0]);
    var ids = Number(Value[1]);
    // var idd=id;

    axios.get(`${API_URL_BLOCK}${ids}`).then((result) => {
      let result_sorted = result.data.sort(this.sort_object("block"));
      this.setState({ block: result_sorted });
      // console.log(this.state.district);
    });
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  villageget = async (val) => {
    // console.log('inside Village get: '+id);
    const Value = val.split(",");

    this.props.handleChange("block", Value[0]);
    var ids = Number(Value[1]);
    // var idso=id;

    axios
      .get(`${API_URL_VILLAGE}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("vill_town"));
        this.setState({ village: result_sorted });
        //console.log(this.state.village);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  saveandContinue = async (e) => {
    await this.props.uploadData();
    this.props.nextStep();
  };

  render() {
    const {
      error,
    } = this.state;
    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 770,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Create Account
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Fill the below form to create a new Farmer account.
                  </Form.Label>

                  <Form.Group
                    controlId="address"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Enter Address"
                      // value={values.address}
                      defaultValue={this.props.values.address}
                      onChange={(e) =>
                        this.props.handleChange("address", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="State"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      // type="firmName"
                      as="select"
                      placeholder="State"
                      // value={values.firmName}
                      // defaultValue={this.props.values.firmName}
                      onChange={(e) => this.distget(e.target.value)}
                    >
                      <option>Select *</option>
                      {this.state.state.map((res) => (
                        <option key={res.id} value={[res.state, res.id]}>
                          {res.state}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="District"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      // type="firmName"
                      as="select"
                      placeholder="City"
                      // value={values.firmName}
                      // defaultValue={this.props.values.firmName}
                      onChange={(e) => this.blockget(e.target.value)}
                    >
                      <option>Select *</option>
                      {this.state.district.map((res) => (
                        <option key={res.id} value={[res.district, res.id]}>
                          {res.district}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="Block"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      // type="firmName"
                      as="select"
                      placeholder="Block"
                      // value={values.firmName}
                      // defaultValue={this.props.values.firmName}
                      onChange={(e) => this.villageget(e.target.value)}
                    >
                      <option>Select *</option>
                      {this.state.block.map((res) => (
                        <option key={res.id} value={[res.block, res.id]}>
                          {res.block}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="Village"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      // type="firmName"
                      as="select"
                      placeholder="Village/Town/Ward"
                      // value={values.firmName}
                      // defaultValue={this.props.values.firmName}
                      onChange={(e) =>
                        this.props.handleChange("villTownWard", e.target.value)
                      }
                    >
                      <option>Select *</option>
                      {this.state.village.map((res) => (
                        <option key={res.id} value={res.vill_town}>
                          {res.vill_town}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  {/* <Form.Group controlId="freight" style={{borderRadius:0, marginTop:20, border:0, borderBottom:"1px solid #5DA7CA", display:"flex", direction:"row", }}>
                <Form.Control
                  style={{width:750, borderRadius:0,fontSize:15, boxShadow:"none", paddingLeft:0, border:0,}}
                  type="freight"
                  placeholder="Enter freight Rate"
                  // value={values.freight}
                  defaultValue={this.props.values.freight}
                  onChange={(e) => this.props.handleChange("freight", e.target.value)}
                ></Form.Control>
              </Form.Group>
   */}

                  {/* <Form.Group controlId="society" style={{borderRadius:0, marginTop:20, border:0, borderBottom:"1px solid #5DA7CA", display:"flex", direction:"row", }}>
                <Form.Control
                  style={{width:750, borderRadius:0,fontSize:15, boxShadow:"none", paddingLeft:0, border:0,}}
                  type="society"
                  placeholder="Enter Society"
                  // value={values.society}
                  defaultValue={this.props.values.society}
                  onChange={(e) => this.props.handleChange("society", e.target.value)}
                ></Form.Control>
              </Form.Group> */}

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Save & Preview{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      type="submit"
                      onClick={this.props.prevStep}
                      style={MediumButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
