import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MobileSection } from "../components/RegistrationMobileSection";
import { DesktopSection } from "../components/RegistrationDesktopSection";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL_OTP_SEND } from "./Registers/Constants";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";

function Register({ location }) {
  const navigate = useNavigate()
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [role, setRole] = useState("");
  const matches = useMediaQuery("(min-width:600px)");
  const redirect = location?.search ? location?.search?.split("=")[1] : "/";
  const {user, loading, error} = useSelector((state) => state.auth);
  const [agreeCheckbox , setAgree] = useState(false)
  const { t } = useTranslation();

  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(redirect);
    }
  }, [user, redirect]);

  const sendOPT = () => {
    let phone_number = "91" + mobile;
    axios
      .post(`${API_URL_OTP_SEND}`, {
        phone_number: phone_number,
        // via: 'sms',
      })
      .then((res) => {
        let Data = JSON.parse(res.data);
        if (Data.type === "success") {
          alert(`OTP has been sent to ${phone_number}`);
        }
      })
  };

  const validation = (event) => {
    // check if all the fields in the form have valid input
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log("isValid", isValidMobileNumber())
    }
    setValidated(true);
    console.log("isValid_InTrue", isValidMobileNumber())
  }

  const isValidMobileNumber = () => {
    // Check if the mobile number is in the correct format, e.g. +91XXXXXXXXXX or 91XXXXXXXXXX
    let formattedPhoneNumber = mobile;

    let isNumber = /^\d+$/;

    if (mobile.startsWith("+91")) {
      formattedPhoneNumber = mobile.slice(3);
      setMobile(formattedPhoneNumber)
    } else if (mobile.startsWith("91") && mobile.length === 12) {
      formattedPhoneNumber = mobile.slice(2);
      setMobile(formattedPhoneNumber)
    } else if (mobile.startsWith("0")) {
      formattedPhoneNumber = mobile.slice(1)
      setMobile(formattedPhoneNumber)
    }

    const regex = /^\d{10}$/;
    return regex.test(formattedPhoneNumber) && isNumber.test(formattedPhoneNumber);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (role === "") {
      alert("Please select role");
    } else {
      validation(e);
      if(isValidMobileNumber() && validated){
        sendOPT();
        console.log("role :: ", role)
        navigate("/otp", {state:{fName:firstname, lName:lastname, email:email, mobile:mobile, role:role, matches:matches}});
      }
    }
  };

  const HandleImgBorder = (id, img, selRole) => {
    var styleId = document.getElementById(id);

    styleId.style.border = "2px solid #5DA7CA";
    styleId.style.borderRadius = "0";
    styleId.style.width = "130";

    setRole(selRole);

    if (img !== "img1") {
      var destyleId = document.getElementById("farmer-Img1");

      destyleId.style.border = "0";
      destyleId.style.borderRadius = "0";
      destyleId.style.width = "130";
    }

    if (img !== "img2") {
      var destyleId = document.getElementById("coordinator-Img2");

      destyleId.style.border = "0";
      destyleId.style.borderRadius = "0";
      destyleId.style.width = "130";
    }

    if (img !== "img3") {
      var destyleId = document.getElementById("logistics-Img3");

      destyleId.style.border = "0";
      destyleId.style.borderRadius = "0";
      destyleId.style.width = "130";
    }
    if (img !== "img4") {
      var destyleId = document.getElementById("vendor-Img4");

      destyleId.style.border = "0";
      destyleId.style.borderRadius = "0";
      destyleId.style.width = "130";
    }
    if (img !== "img5") {
      var destyleId = document.getElementById("Fpo-Img5");

      destyleId.style.border = "0";
      destyleId.style.borderRadius = "0";
      destyleId.style.width = "130";
    }
  };

  return (
    <div>
      {matches ? (
        <DesktopSection
          error={error}
          loading={loading}
          firstname={firstname}
          setFirstname={setFirstname}
          lastname={lastname}
          setLastname={setLastname}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setMobile={setMobile}
          HandleImgBorder={HandleImgBorder}
          submitHandler={submitHandler}
          setAgree={setAgree}
          agreeCheckbox={agreeCheckbox}
          t={t}
          role={role}
          validated={validated}
          isValidMobileNumber={isValidMobileNumber}
        />
      ) : (
        <MobileSection
          error={error}
          loading={loading}
          firstname={firstname}
          setFirstname={setFirstname}
          lastname={lastname}
          setLastname={setLastname}
          email={email}
          setEmail={setEmail}
          mobile={mobile}
          setMobile={setMobile}
          HandleImgBorder={HandleImgBorder}
          submitHandler={submitHandler}
          setAgree={setAgree}
          agreeCheckbox={agreeCheckbox}
          t={t}
          role={role}
          validated={validated}
          isValidMobileNumber={isValidMobileNumber}
        />
      )}
    </div>
  );
}

export default Register;
