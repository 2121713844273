export const crop_arr = [
  'Beans',
  'Beet root',
  'Broccoli',
  'Brussels sprout',
  'Cabbage',
  'Carrot',
  'Cauliflower',
  'Celery/Coriander',
  'Chives/Onions',
  'Cucumber',
  'Garlic',
  'Lettuce',
  'Peas',
  'Capsicum',
  'Corn/Maize',
  'Forage crops',
  'Sugarcane',
  'Guava',
  'Mango',
  'Papaya',
  'Potato',
  'Pumpkin',
  'Radish',
  'Spinach',
  'Squash',
  'Tomato',
  'Chilli',
  'Soybeans',
  'Wheat',
  'Paddy',
  'Muskmelons',
  'Marigold',
  'Watermelon',
  'Sunflower',
  'Groundnut',
  'Pomegranate',
  'Moringa/Drumstick',
  'Grams',
  'Cotton',
  'Peanuts',
  'sweet potato',
  'apple',
  'tomato',
  'gooseberry',
  'pineapple',
  'watermelon',
  'basil',
  'apricot',
  'cherry',
  'lemon',
  'Grapes',
  'litchi',
  'peach',
  'plum',
  'millet',
  'banana',
  'strawberry',
  'garlic',
  'lentil',
  'ginger'
]