export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const ORDER_PAYMENT_REQUEST = "ORDER_PAYMENT_REQUEST";
export const ORDER_PAYMENT_SUCCESS = "ORDER_PAYMENT_SUCCESS";
export const ORDER_PAYMENT_FAIL = "ORDER_PAYMENT_FAIL";
export const ORDER_PAYMENT_RESET = "ORDER_PAYMENT_RESET";

export const ORDER_LIST_MINE_REQUEST = "ORDER_LIST_MINE_REQUEST";
export const ORDER_LIST_MINE_SUCCESS = "ORDER_LIST_MINE_SUCCESS";
export const ORDER_LIST_MINE_FAIL = "ORDER_LIST_MINE_FAIL";
export const ORDER_LIST_MINE_RESET = "ORDER_LIST_MINE_RESET";

export const ORDER_PAYMENT_CREATE_REQUEST = "ORDER_PAYMENT_CREATE_REQUEST";
export const ORDER_PAYMENT_CREATE_SUCCESS = "ORDER_PAYMENT_CREATE_SUCCESS";
export const ORDER_PAYMENT_CREATE_FAIL = "ORDER_PAYMENT_CREATE_FAIL";

  