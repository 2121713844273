import {
  GET_SEARCH_HISTORY_REQUEST,
  GET_SEARCH_HISTORY_FAIL,
  GET_SEARCH_HISTORY_SUCCESS,
} from "../constants/SearchHistoryConstants";

import { BASE_URL_HOSTED } from "../constants/api";
import axiosAPI from "../components/AxiosAPI";

export const PushSearchHistory = async (text_searched) => {
  const uploadData = new FormData();
  uploadData.append("text_searched", text_searched);
  var status;

  await axiosAPI
    .post(`${BASE_URL_HOSTED}api/PostSearchHistory/`, uploadData)
    .then((res) => (status = "success"))
    .catch((error) => (status = error));

  return status;
};

//Search History lists
////////////////////////////////////////////////////////////

export const listSearchHistory = (searches) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: GET_SEARCH_HISTORY_REQUEST });

    const { data } = await axiosAPI.get(
      `${BASE_URL_HOSTED}api/GetSearchHistory/${searches}`
    );

    dispatch({
      type: GET_SEARCH_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SEARCH_HISTORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};
