import wishlistService from '../services/wishlist.service';
import { getWishlistCartDetails } from './cart.action';

export const addToWishlist=(id)=>(dispatch)=>{
    dispatch({type : 'POST_WISHLIST_REQUEST'})
    wishlistService.postWishlist(id)
                  .then((res)=>{
                    dispatch({
                        type : 'POST_WISHLIST_SUCCESS',
                        payload : res.data
                    })
                    dispatch(getWishlistCartDetails(id))
                  })
                  .catch((error)=>{
                    dispatch({
                        type : 'POST_WISHLIST_FAILED',
                        payload : error?.response?.statusText
                    })
                  })
};

export const removeFromWishlist=(id)=>(dispatch)=>{
    dispatch({type : 'DELETE_WISHLIST_REQUEST'})
    wishlistService.deleteWishlist(id)
                  .then((res)=>{
                    dispatch({
                        type : 'DELETE_WISHLIST_SUCCESS',
                        payload : res.data
                    })
                    dispatch(getWishlistCartDetails(id))
                  })
                  .catch((error)=>{
                    dispatch({
                        type : 'DELETE_WISHLIST_FAILED',
                        payload : error?.response?.statusText
                    })
                  })
};

export const getWishlist=()=>(dispatch)=>{
    dispatch({type : 'GET_WISHLIST_REQUEST'})
    wishlistService.getWishlist()
                  .then((res)=>{
                    dispatch({
                        type : 'GET_WISHLIST_SUCCESS',
                        payload : res.data
                    })
                  })
                  .catch((error)=>{
                    dispatch({
                        type : 'GET_WISHLIST_FAILED',
                        payload : error?.response?.statusText
                    })
                  })
};