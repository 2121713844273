import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import { MediumButtonStyle } from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
// import Dropdown from 'react-bootstrap/Dropdown';
import axios from "axios";
import { BASE_URL_HOSTED, FRONTEND_URL } from "../../../constants/api";
import Loader from "../../../components/Loader";

var order_id = "";

export default class RegistrationPayment extends React.Component {
  state = {
    error: null,
    paymentmode: "",
    id: this.props.user.id,
    role: this.props.user.role,
    RegistrationFee: "",
    isPaid: null,
  };

  // // ********************************Razorpay**********************
  componentDidMount = async () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    const data = {
      id: this.state.id,
      role: this.state.role,
    };

    await axios
      .post(`${BASE_URL_HOSTED}api/getregistrationfee/`, data)
      .then(async (res) => {
        //console.log("post", res.data);
        this.setState({ role: res.data["role"] });
        this.setState({ RegistrationFee: res.data["registrationFee"] });
      })
      .catch((err) => {
        //console.log(err);
      });

    await axios
      .get(
        `${BASE_URL_HOSTED}api/getRegistrationPaymentDeatils/${this.state.id}`
      )
      .then(async (res) => {
        this.setState({
          isPaid: res.data["isReg"],
        });
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };
  // // ********************************Razorpay**********************

  paymentSuccess = (status) => {
    //console.log(status);
    this.props.navigate("/");
  };
  back = () => {
    this.props.prevStep();
  };

  skip = () => {
    this.props.navigate("/");
  };

  RZpay = async () => {
    const user = this.props.user;
    const RZdata = {
      user: user,
      amount: this.state.RegistrationFee * 100,
      transectionType: "regFee",
    };

    await axios.post(`${BASE_URL_HOSTED}api/razorpay/`, RZdata).then((res) => {
      //console.log("order_id: ", res.data["order_id"]);
      order_id = res.data["order_id"];
    });
    // dispatch(OrderPayment(RZdata))
    //  console.log("asdfghjkl: ",tryorderID)

    let redirect_url = "";

    const options = {
      key: "rzp_live_8rmQw3gzzAebdn", // "rzp_test_4opchK3A4XmgrN", //'rzp_test_JOC0wRKpLH1cVW', //"rzp_live_8rmQw3gzzAebdn" ,
      amount: RZdata.amount * 100, //  = INR 1
      name: user.username,
      description: "Registration Fee payment",
      image: "https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_large.png",
      order_id: order_id,
      handler: async function (response) {
        //console.log("Response", response);
        let razordata = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          order_id: order_id,
          user: user,
        };

        let status = false;
        await axios
          .post(`${BASE_URL_HOSTED}api/razorpay/status/`, razordata)
          .then((res) => {
            //console.log("post", res.data);
            status = res.data["status"];
          })
          .catch((err) => {
            console.log(err);
          });
        // typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1
        if (status === true) {
          redirect_url = `${FRONTEND_URL}regpayment/?order_id=` + order_id;
          window.location.href = redirect_url;
        } else {
          redirect_url = `${FRONTEND_URL}regpayment/` + "Error";
          window.location.href = redirect_url;
        }

        //console.log(redirect_url);
      },
      prefill: {
        name: RZdata.name,
        contact: user.phone,

        email: user.email,
      },
      notes: {
        address: "Billing Address",
      },
      theme: {
        color: "black",
        hide_topbar: false,
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  render() {
    const { error } = this.state;
    if (this.state.isPaid === null) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </div>
      );
    } else if (!this.state.isPaid) {
      return (
        <div id="root">
          <div style={BackgroundGradientStyle}>
            <div style={{ fontSize: 12 }}>
              {error && <Message variant="danger">{error}</Message>}
            </div>
            {/* {loading && <Loader/>} */}
            <div
              id="root"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px 10px",
                    margin: "20px 20px 20px 20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 360,
                      height: 790,
                      padding: "10px 25px",
                    }}
                  >
                    <strong
                      style={{
                        color: "black",
                        fontSize: 24,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                      }}
                    >
                      Registration Payment{" "}
                    </strong>
                    {/* <Form onSubmit={submitHandler}> */}
                    <Form.Label
                      style={{
                        marginTop: 5,
                        fontSize: 13,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                        color: "black",
                      }}
                    >
                      Pay and complete your registration
                    </Form.Label>
                    <div>
                      {this.state.role ? (
                        <>
                          <>Role : {this.state.role}</>
                          <br />
                          <>
                            Registration Fees : Rs. {this.state.RegistrationFee}
                          </>
                        </>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ marginTop: 10 }}></div>
                      <Button
                        onClick={this.RZpay}
                        type="submit"
                        style={MediumButtonStyle}
                      >
                        Pay{" "}
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-right"
                        />
                      </Button>
                    </div>

                    <div
                      style={{
                        marginTop: 25,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ marginTop: 10 }}></div>
                      <Button
                        onClick={this.back}
                        type="submit"
                        style={MediumButtonStyle}
                      >
                        Back
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-left"
                        />
                      </Button>
                      <div style={{ marginTop: 10 }}></div>

                      <Button
                        onClick={this.skip}
                        type="submit"
                        style={MediumButtonStyle}
                      >
                        Skip{" "}
                        <i
                          style={{ marginLeft: 5 }}
                          className="fas fa-angle-right"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (this.state.isPaid) {
      return (
        <>
          <Form.Label
            style={{
              marginTop: 5,
              fontSize: 18,
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              justifyContent: "left",
              color: "green",
            }}
          >
            Registration Fees is already paid.
          </Form.Label>
          <div style={{ marginTop: 10 }}></div>
          <Button onClick={this.paymentSuccess} style={MediumButtonStyle}>
            Go to Home{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
          </Button>
        </>
      );
    }
  }
}

