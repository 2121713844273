import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { login } from "../actions/actionUser";
import { login } from "../actions/auth.action";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GA4 from "../components/GoogleAnalytics4/GA-4";
import { LoginDesktopSection } from "../components/Login/LoginDesktop";
import { LoginMobileSection } from "../components/Login/LoginMobile";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()
  const redirect = location?.state?.from
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const {user, isLoggedIn, loading, error} = useSelector(state=>state.auth)
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(()=>{
    if (isLoggedIn) {
      if (user?.isPassUpdated === true)
        navigate(redirect?redirect:'/')
      else {
        navigate(`/Changepass`)
      }
    }
  }, [isLoggedIn, redirect]);

  const submitHandler=(e)=>{
    e.preventDefault();
    dispatch(login(username, password))
  };

  return (
    <div>
    {matches ? (
        <LoginDesktopSection
          loading={loading}
          submitHandler={submitHandler}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          error={error}
          t={t}
        />
      ) : (
        <LoginMobileSection
          loading={loading}
          submitHandler={submitHandler}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          error={error}
          t={t}
        />
      )}
    </div>
  );
}

export default LoginScreen;
