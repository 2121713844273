import React from "react";
import { LoginButtonStyle } from "../ButtonStyle";
import { BackgroundGradientStyle } from "../BackgoundGradient";
import Loader from "../Loader";
import Message from "../message";
import { Form, Button } from "react-bootstrap";

export const LoginMobileSection = ({
  loading,
  submitHandler,
  username,
  setUsername,
  password,
  setPassword,
  error,
  t,
}) => {
  return (
    <div style={BackgroundGradientStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Loader loading={loading}/>
        <div style={{ fontSize: 12 }}>
          {error && <Message variant="danger">{error}</Message>}
        </div>
          <div style={{ color: "black" }}>
            <div
              style={{
                height: "fit-content",
                padding: "3%",
                width: "60vw",
                backgroundColor: "white",
                marginBottom: "10%",
                marginTop: "10%",
                borderRadius: "3px",
              }}
            >
              <strong style={{ color: "black" }}>{t("Welcome-Back")}</strong>
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="username">
                  <Form.Label style={{ marginTop: "5%" }}>
                    {t("Login-Continue")}
                  </Form.Label>
                  <Form.Control
                    style={{ borderRadius: 0, fontSize: "4vw" }}
                    type="username"
                    placeholder={t("Enter-Username")}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Control
                    style={{ borderRadius: 0, fontSize: "4vw" }}
                    type="password"
                    placeholder={t("Enter-Pass")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <div style={{ textAlign: "right", fontSize: "2.5vw" }}>
                  <a a href="/reset_password">
                    {t("Forget-Pass")}
                  </a>
                </div>

                <div
                  style={{
                    marginTop: "6%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Button type="submit" style={LoginButtonStyle}>
                    {t("Sign-In")}{" "}
                    <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                  </Button>

                  <div>
                    <a style={{ fontSize: "2.3vw" }} href="/Register">
                      {t("Create-Acc")}
                    </a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
      </div>
      {/* confirm template */}
    </div>
  );
};
