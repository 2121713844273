//regpayment seccess
import React from "react";
import { Form, Button} from "react-bootstrap";
import {
  MediumButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
import axios from "axios";
import { BASE_URL_HOSTED } from "../../../constants/api";
import Loader from "../../../components/Loader";
import { withRouter } from "../../../components/Router/withRouter";
const queryParams = new URLSearchParams(window.location.search);
class RegPaymentSuccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PaymentDetails: null,
    };
  }
  componentDidMount = async () => {
    await axios
      .get(
        `${BASE_URL_HOSTED}api/razorpay/status/${queryParams.get("order_id")}`
      )
      .then((res) => {
        //console.log("post", res.data);
        this.setState({ PaymentDetails: res.data["message"]["isPaid"] });
        //console.log("post2 ::", this.state.PaymentDetails);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  paymentSuccess() {
    window.location.href = "/";
  }
  render() {
    if (this.state.PaymentDetails === null) {
      return <Loader />;
    }
    if (this.state.PaymentDetails) {
      return (
        <div id="root">
          {/* {console.log(this.state.PaymentDetails)} */}

          <div style={BackgroundGradientStyle}>
            {/* {loading && <Loader/>} */}
            <div
              id="root"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px 10px",
                    margin: "20px 20px 20px 20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 360,
                      height: 790,
                      padding: "10px 25px",
                    }}
                  >
                    <strong
                      style={{
                        color: "black",
                        fontSize: 24,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                      }}
                    >
                      Registration Payment
                    </strong>
                    {/* <Form onSubmit={submitHandler}> */}
                    <Form.Label
                      style={{
                        marginTop: 5,
                        fontSize: 13,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                        color: "black",
                      }}
                    >
                      Payment success
                    </Form.Label>
                    <text style={{ color: "green" }}>Payment success</text>

                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.paymentSuccess}
                      style={MediumButtonStyle}
                    >
                      Go to Home{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } 
    if (!this.state.PaymentDetails) {
      return (
        <div id="root">
          <div style={BackgroundGradientStyle}>
            <div
              id="root"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: "20px 10px",
                    margin: "20px 20px 20px 20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      width: 360,
                      height: 790,
                      padding: "10px 25px",
                    }}
                  >
                    <strong
                      style={{
                        color: "black",
                        fontSize: 24,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                      }}
                    >
                      Registration Payment
                    </strong>
                    {/* <Form onSubmit={submitHandler}> */}
                    <Form.Label
                      style={{
                        marginTop: 5,
                        fontSize: 13,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "left",
                        justifyContent: "left",
                        color: "black",
                      }}
                    >
                      Something Went Wrong!!!
                    </Form.Label>
                    <text style={{ color: "red" }}>Payment Failed</text>
                    <br />
                    <text style={{ color: "blue", fontSize: 12 }}>
                      If amount is deducated from your account, Please Contact
                      us.
                    </text>

                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.paymentSuccess}
                      style={MediumButtonStyle}
                    >
                      Go to Home{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(RegPaymentSuccess);
