import React, { useEffect } from "react";
import { Button, Row, Col, Image } from "react-bootstrap";
import Product from "../components/Product";
import { useDispatch, useSelector } from "react-redux";
import { listProducts } from "../actions/productActions";
import { SmallButtonStyle } from "../components/ButtonStyle";
import Preprocessing from '../assets/Preprocessing.png';
import CollCentres from '../assets/CollCentre.png';
import TransStorage from '../assets/TransportationStorage.png';
import BuyerCat from '../assets/BuyerCat.png';
import BuyerDir from '../assets/BuyerDir.png';
import GenSrev from '../assets/generalservice.png';
import {div1Style} from "../components/AgriOpBackgroundGradient";

function AgriOpScreen() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { products } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  const imgtextStyle={
    marginTop: -15, 
    marginLeft:55,
    color:'#4671C6',
    fontWeight: 'normal',
    fontSize: '16px'
  }
  const ImgStyle={
    borderRadius: "25%",
    width: 200,
    height: 200,
    display: "block",
    marginLeft: 45,
  }

  return (
    <div>
        <div>
         <h1 style={{fontWeight:'Bold', margin: "10px 0", paddingLeft:50}}>Agriculture Output</h1>
        </div>
 
  <div style={div1Style}>
  
      
      <div style={{backgroundColor: "white", margin:"0 15%", borderTopLeftRadius:25, borderBottomRightRadius:25}}>
        {/* <div style={{backgroundColor: "green", margin:"4px 0"}}> */}
      <div style={{display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "left",flexWrap: "wrap"}}>
        <div style={{display: "flex", justifyContent: "center", flexDirection:"column", paddingRight: "5%",alignItems: "center"}}>
          <a href="/PreviousOrders">
            <Image
              src={Preprocessing}
              alt="Manage orders"
              style={ImgStyle}
            />
            <div style={imgtextStyle}>
            <text><br/>Preprocessing<br/>Agencies/Arrangements<br/>Quality, Testing, & Grading</text>
           </div>
          </a>
          
          {/* <p className="text-center">{"Previous orders, Tracking,\nWishlist, & Buyagain"}</p> */}
        </div>
        <div style={{display: "flex", justifyContent: "center", flexDirection:"column", paddingRight: "5%",alignItems: "center"}}>
          <a href="/#">
            <Image
             src={CollCentres}
              alt="Manage Settings"
              style={ImgStyle}
            />
            <div style={imgtextStyle}>
            <text><br/>Collection Centres,<br/>Weighing, Quotes, &<br/>Packaging</text>    
           </div>
          </a>
          
        </div>
        <div style={{display: "flex", justifyContent: "center", flexDirection:"column",alignItems: "center", paddingRight: "5%"}}>
          <a href="/#">
            <Image
              src={TransStorage}
              alt="Manage Payment"
              style={ImgStyle}
            />
            <div style={imgtextStyle}>
            <text><br/>Local, Bulk, Cold Chain &<br/>Storage Transportation</text>
            </div>
          </a>
         
        </div>
      </div>

      <div style={{display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center", flexWrap: "wrap"}}>
        <div style={{display: "flex", justifyContent: "center", flexDirection:"column", paddingRight: "5%",alignItems: "center"}}>
          <a href="/PreviousOrders">
            <Image
              src={BuyerCat}
              alt="Manage orders"
              style={ImgStyle}
            />
            <div style={imgtextStyle}>
            <text><br/>Sell/Buy Farm Produce<br/><br/><br/></text>
           </div>
          </a>
          
          {/* <p className="text-center">{"Previous orders, Tracking,\nWishlist, & Buyagain"}</p> */}
        </div>
        <div style={{display: "flex", justifyContent: "center", flexDirection:"column", paddingRight: "5%",alignItems: "center"}}>
          <a href="/#">
            <Image
             src={BuyerDir}
              alt="Manage Settings"
              style={ImgStyle}
            />
            <div style={imgtextStyle}>
            <text><br/>Procurement Agencies,<br/>Local/National Chain &<br/>Points, & Exports</text>    
           </div>
          </a>
          
        </div>
        <div style={{display: "flex", justifyContent: "center", flexDirection:"column",alignItems: "center", paddingRight: "5%"}}>
          <a href="/#">
            <Image
              src={GenSrev}
              alt="Manage Payment"
              style={ImgStyle}
            />
            <div style={imgtextStyle}>
            <text><br/>Finacial, Legal, Loading,<br/>Unloading Assistance<br/></text>
            </div>
          </a>
         
        </div>
      </div>
      </div>
    </div>

        <div style={{margin:'0px 60px'}} >
       <div style={{padding:20}}>
       <div>
            <Row style={{ marginBottom: "2%" }}>
              <Col>
            <h3 style={{fontWeight:'Bold', fontSize:'clamp(1.8rem, 2.5vw, 3rem)', color:'black'}}>Explore Processing Solutions</h3>
            </Col>
            <Col xs ={{span: 6, offset:1}} style={{marginTop:9}} >
            <div style={{marginLeft:'clamp(-8rem, -7.5vw, 3rem)', marginBottom: 50}}>
              <Button style={SmallButtonStyle}>
                SHOW MORE<i style={{marginLeft: 5}} className="fas fa-angle-right"/>
              </Button>
            </div>
            </Col>
            </Row>
            <Row style={{marginBottom:'8%'}}>
              {products.length > 5 ? products.slice(-6,-1).map((product) => (
                <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                  <Product product={product} />
                </div>
              )):
              products.map((product) => (
                <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                  <Product product={product} />
                </div>
              ))}
            </Row>
       </div> 

       <div>
            <Row style={{ marginBottom: "2%" }}>
                    <Col>
                  <h3 style={{fontWeight:'Bold', fontSize:'clamp(1.8rem, 2.5vw, 3rem)', color:'black'}}>Find Collections Centres Nearby</h3>
                  </Col>
                  <Col xs ={{span: 6, offset:1}} style={{marginTop:9}} >
                  <div style={{marginLeft:'clamp(-8rem, -7.5vw, 3rem)'}}><Button style={SmallButtonStyle}>SHOW MORE<i style={{marginLeft: 5}} className="fas fa-angle-right"/></Button></div>
                  </Col>
                  </Row>
                  <Row style={{marginBottom:'8%'}}>
                    {products.length > 5 ? products.slice(-6,-1).map((product) => (
                      <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                        <Product product={product} />
                      </div>
                    )):
                    products.map((product) => (
                      <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                        <Product product={product} />
                      </div>
                    ))}
                  </Row>
       </div>   
       </div>
  
    </div>

    <div style={{margin:'0px 60px'}} >
        {/* <div><HomepageCard/></div> */}
       <div style={{padding:20}}>
       <div>
            <Row style={{ marginBottom: "2%" }}>
              <Col>
            <h3 style={{fontWeight:'Bold', fontSize:'clamp(1.8rem, 2.5vw, 3rem)', color:'black'}}>Explore Transport & Storage Solutions</h3>
            </Col>
            <Col xs ={{span: 6, offset:1}} style={{marginTop:9}} >
            <div style={{marginLeft:'clamp(-8rem, -7.5vw, 3rem)', marginBottom: 50}}>
              <Button style={SmallButtonStyle}>
                SHOW MORE<i style={{marginLeft: 5}} className="fas fa-angle-right"/>
              </Button>
            </div>
            </Col>
            </Row>
            <Row style={{marginBottom:'8%'}}>
              {products.length > 5 ? products.slice(-6,-1).map((product) => (
                <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                  <Product product={product} />
                </div>
              )):
              products.map((product) => (
                <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                  <Product product={product} />
                </div>
              ))}
            </Row>
       </div> 

       <div>
            <Row style={{ marginBottom: "2%" }}>
                    <Col>
                  <h3 style={{fontWeight:'Bold', fontSize:'clamp(1.8rem, 2.5vw, 3rem)', color:'black'}}>Explore Commodity Trade</h3>
                  </Col>
                  <Col xs ={{span: 6, offset:1}} style={{marginTop:9}} >
                  <div style={{marginLeft:'clamp(-8rem, -7.5vw, 3rem)'}}><Button style={SmallButtonStyle}>SHOW MORE<i style={{marginLeft: 5}} className="fas fa-angle-right"/></Button></div>
                  </Col>
                  </Row>
                  <Row style={{marginBottom:'8%'}}>
                    {products.length > 5 ? products.slice(-6,-1).map((product) => (
                      <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                        <Product product={product} />
                      </div>
                    )):
                    products.map((product) => (
                      <div className="col" key={product._id} sm={12} md={6} Lg={4} xL={3}>
                        <Product product={product} />
                      </div>
                    ))}
                  </Row>
       </div>   
       </div>
  
    </div>

    </div>
  );
}

export default AgriOpScreen;