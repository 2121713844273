import React from "react";
import './Style/loader.css';

function Loader({loading}) {
  return (
    <div className={loading? 'loader' :'loader-false'}>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
      <div className="dot-circle"/>
    </div>
  );
}

export default Loader;
