export const HOME_PAGE_PRODUCT_LIST_REQUEST = "HOME_PAGE_PRODUCT_LIST_REQUEST";
export const HOME_PAGE_PRODUCT_LIST_SUCCESS = "HOME_PAGE_PRODUCT_LIST_SUCCESS";
export const HOME_PAGE_PRODUCT_LIST_FAIL = "HOME_PAGE_PRODUCT_LIST_FAIL";
export const HOME_PAGE_PRODUCT_DETAILS_REQUEST =
  "HOME_PAGE_PRODUCT_DETAILS_REQUEST";
export const HOME_PAGE_PRODUCT_DETAILS_SUCCESS =
  "HOME_PAGE_PRODUCT_DETAILS_SUCCESS";
export const HOME_PAGE_PRODUCT_DETAILS_FAIL = "HOME_PAGE_PRODUCT_DETAILS_FAIL";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const FEATURED_PRODUCT_LIST_REQUEST = "FEATURED_PRODUCT_LIST_REQUEST";
export const FEATURED_PRODUCT_LIST_SUCCESS = "FEATURED_PRODUCT_LIST_SUCCESS";
export const FEATURED_PRODUCT_LIST_FAIL = "FEATURED_PRODUCT_LIST_FAIL";
export const FEATURED_PRODUCT_DETAILS_REQUEST =
  "FEATURED_PRODUCT_DETAILS_REQUEST";
export const FEATURED_PRODUCT_DETAILS_SUCCESS =
  "FEATURED_PRODUCT_DETAILS_SUCCESS";
export const FEATURED_PRODUCT_DETAILS_FAIL = "FEATURED_PRODUCT_DETAILS_FAIL";

export const PRODUCT_CATEGORY_LIST_REQUEST = "PRODUCT_CATEGORY_LIST_REQUEST";
export const PRODUCT_CATEGORY_LIST_SUCCESS = "PRODUCT_CATEGORY_LIST_SUCCESS";
export const PRODUCT_CATEGORY_LIST_FAIL = "PRODUCT_CATEGORY_LIST_FAIL";
// export const PRODUCT_CATEGORY_DETAILS_REQUEST = "PRODUCT_CATEGORY_DETAILS_REQUEST";
// export const PRODUCT_CATEGORY_DETAILS_SUCCESS = "PRODUCT_CATEGORY_DETAILS_SUCCESS";
// export const PRODUCT_CATEGORY_DETAILS_FAIL = "FEATURED_PRODUCT_DETAILS_FAIL";

// export const FILTERED_CATEGORY_LIST_REQUEST = "FILTERED_CATEGORY_LIST_REQUEST";
// export const FILTERED_CATEGORY_LIST_SUCCESS = "FILTERED_CATEGORY_LIST_SUCCESS";
// export const FILTERED_CATEGORY_LIST_FAIL = "FILTERED_CATEGORY_LIST_FAIL";

export const QUOTATION_BRAND_LIST_REQUEST = "QUOTATION_BRAND_LIST_REQUEST";
export const QUOTATION_BRAND_LIST_SUCCESS = "QUOTATION_BRAND_LIST_SUCCESS";
export const QUOTATION_BRAND_LIST_FAIL = "QUOTATION_BRAND_LIST_FAIL";

export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const SEARCH_BRAND_LIST_REQUEST = "SEARCH_BRAND_LIST_REQUEST";
export const SEARCH_BRAND_LIST_SUCCESS = "SEARCH_BRAND_LIST_SUCCESS";
export const SEARCH_BRAND_LIST_FAIL = "SEARCH_BRAND_LIST_FAIL";

export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST";
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS";
export const SUBCATEGORY_LIST_FAIL = "SUBCATEGORY_LIST_FAIL";

export const SUBSUBCATEGORY_LIST_REQUEST = "SUBSUBCATEGORY_LIST_REQUEST";
export const SUBSUBCATEGORY_LIST_SUCCESS = "SUBSUBCATEGORY_LIST_SUCCESS";
export const SUBSUBCATEGORY_LIST_FAIL = "SUBSUBCATEGORY_LIST_FAIL";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const FARM_PRODUCT_LIST_REQUEST = "FARM_PRODUCT_LIST_REQUEST";
export const FARM_PRODUCT_LIST_SUCCESS = "FARM_PRODUCT_LIST_SUCCESS";
export const FARM_PRODUCT_LIST_FAIL = "FARM_PRODUCT_LIST_FAIL";

export const FARM_PRODUCT_DETAILS_REQUEST = "FARM_PRODUCT_DETAILS_REQUEST";
export const FARM_PRODUCT_DETAILS_SUCCESS = "FARM_PRODUCT_DETAILS_SUCCESS";
export const FARM_PRODUCT_DETAILS_FAIL = "FARM_PRODUCT_DETAILS_FAIL";

export const FARM_PRODUCT_NAME_REQUEST = "FARM_PRODUCT_NAME_REQUEST";
export const FARM_PRODUCT_NAME_SUCCESS = "FARM_PRODUCT_NAME_SUCCESS";
export const FARM_PRODUCT_NAME_FAIL = "FARM_PRODUCT_NAME_FAIL";

export const QUOTATION_PRODUCT_DETAILS_REQUEST =
  "QUOTATION_PRODUCT_DETAILS_REQUEST";
export const QUOTATION_PRODUCT_DETAILS_SUCCESS =
  "QUOTATION_PRODUCT_DETAILS_SUCCESS";
export const QUOTATION_PRODUCT_DETAILS_FAIL = "QUOTATION_PRODUCT_DETAILS_FAIL";

export const FILTERED_FARMPRODUCT_LIST_REQUEST =
  "FILTERED_FARMPRODUCT_LIST_REQUEST";
export const FILTERED_FARMPRODUCT_LIST_SUCCESS =
  "FILTERED_FARMPRODUCT_LIST_SUCCESS";
export const FILTERED_FARMPRODUCT_LIST_FAIL = "FILTERED_FARMPRODUCT_LIST_FAIL";

export const FILTERED_CATEGORY_LIST_REQUEST = "FILTERED_CATEGORY_LIST_REQUEST";
export const FILTERED_CATEGORY_LIST_SUCCESS = "FILTERED_CATEGORY_LIST_SUCCESS";
export const FILTERED_CATEGORY_LIST_FAIL = "FILTERED_CATEGORY_LIST_FAIL";

export const INNOVATIVE_CATEGORY_LIST_REQUEST = "INNOVATIVE_CATEGORY_LIST_REQUEST";
export const INNOVATIVE_CATEGORY_LIST_SUCCESS = "INNOVATIVE_CATEGORY_LIST_SUCCESS";
export const INNOVATIVE_CATEGORY_LIST_FAIL = "INNOVATIVE_CATEGORY_LIST_FAIL";


export const FILTERED_QUOTATION_CATEGORY_LIST_REQUEST =
  "FILTERED_QUOTATION_CATEGORY_LIST_REQUEST";
export const FILTERED_QUOTATION_CATEGORY_LIST_SUCCESS =
  "FILTERED_QUOTATION_CATEGORY_LIST_SUCCESS";
export const FILTERED_QUOTATION_CATEGORY_LIST_FAIL =
  "FILTERED_QUOTATION_CATEGORY_LIST_FAIL";

export const PRODUCT_DETAILS_VARIATIONS_REQUEST =
  "PRODUCT_DETAILS_VARIATIONS_REQUEST";
export const PRODUCT_DETAILS_VARIATIONS_SUCCESS =
  "PRODUCT_DETAILS_VARIATIONS_SUCCESS";
export const PRODUCT_DETAILS_VARIATIONS_FAIL =
  "PRODUCT_DETAILS_VARIATIONS_FAIL";

export const PRODUCT_SEARCH_LIST_REQUEST = "PRODUCT_SEARCH_LIST_REQUEST";
export const PRODUCT_SEARCH_LIST_SUCCESS = "PRODUCT_SEARCH_LIST_SUCCESS";
export const PRODUCT_SEARCH_LIST_FAIL = "PRODUCT_SEARCH_LIST_FAIL";

export const FILTERING_CATEGORIES_LIST_REQUEST =
  "FILTERING_CATEGORIES_LIST_REQUEST";
export const FILTERING_CATEGORIES_LIST_SUCCESS =
  "FILTERING_CATEGORIES_LIST_SUCCESS";
export const FILTERING_CATEGORIES_LIST_FAIL = "FILTERING_CATEGORIES_LIST_FAIL";

export const FILTERING_QUOTATION_CATEGORIES_LIST_REQUEST =
  "FILTERING_QUOTATION_CATEGORIES_LIST_REQUEST";
export const FILTERING_QUOTATION_CATEGORIES_LIST_SUCCESS =
  "FILTERING_QUOTATION_CATEGORIES_LIST_SUCCESS";
export const FILTERING_QUOTATION_CATEGORIES_LIST_FAIL =
  "FILTERING_QUOTATION_CATEGORIES_LIST_FAIL";

export const FILTERING_SEARCH_CATEGORIES_LIST_REQUEST =
  "FILTERING_SEARCH_CATEGORIES_LIST_REQUEST";
export const FILTERING_SEARCH_CATEGORIES_LIST_SUCCESS =
  "FILTERING_SEARCH_CATEGORIES_LIST_SUCCESS";
export const FILTERING_SEARCH_CATEGORIES_LIST_FAIL =
  "FILTERING_SEARCH_CATEGORIES_LIST_FAIL";

export const FARMSUBCATEGORY_LIST_REQUEST = "FARMSUBCATEGORY_LIST_REQUEST";
export const FARMSUBCATEGORY_LIST_SUCCESS = "FARMSUBCATEGORY_LIST_SUCCESS";
export const FARMSUBCATEGORY_LIST_FAIL = "FARMSUBCATEGORY_LIST_FAIL";

export const REVIEW_LIST_REQUEST = "REVIEW_LIST_REQUEST";
export const REVIEW_LIST_SUCCESS = "REVIEW_LIST_SUCCESS";
export const REVIEW_LIST_FAIL = "REVIEW_LIST_FAIL";

export const QNA_LIST_REQUEST = "QNA_LIST_REQUEST";
export const QNA_LIST_SUCCESS = "QNA_LIST_SUCCESS";
export const QNA_LIST_FAIL = "QNA_LIST_FAIL";