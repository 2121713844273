import React from 'react';
import { useSelector } from 'react-redux';

const UserDetails = () => {
  const {user} = useSelector((state) => state.auth);
  let data = {
    'acc':'asdf'
  }
  return (
    <div id="userDetails" className="userDetails" style={{ display: 'none' }}>
        <text>
        {JSON.stringify(data)}
        </text>
    </div>
  );
};

export default UserDetails;