export const FARMER_LISTED_PRODUCT_LIST_REQUEST =
  "FARMER_LISTED_PRODUCT_LIST_REQUEST";
export const FARMER_LISTED_PRODUCT_LIST_SUCCESS =
  "FARMER_LISTED_PRODUCT_LIST_SUCCESS";
export const FARMER_LISTED_PRODUCT_LIST_FAIL =
  "FARMER_LISTED_PRODUCT_LIST_FAIL";

export const NEGOTIATION_LIST_REQUEST = "NEGOTIATION_LIST_REQUEST";
export const NEGOTIATION_LIST_SUCCESS = "NEGOTIATION_LIST_SUCCESS";
export const NEGOTIATION_LIST_FAIL = "NEGOTIATION_LIST_FAIL";

export const QUOTATION_LIST_REQUEST = "QUOTATION_LIST_REQUEST";
export const QUOTATION_LIST_SUCCESS = "QUOTATION_LIST_SUCCESS";
export const QUOTATION_LIST_FAIL = "QUOTATION_LIST_FAIL";

export const QUOTATION_VENDOR_AS_CUSTOMER_LIST_REQUEST =
  "QUOTATION_VENDOR_AS_CUSTOMER_LIST_REQUEST";
export const QUOTATION_VENDOR_AS_CUSTOMER_LIST_SUCCESS =
  "QUOTATION_VENDOR_AS_CUSTOMER_LIST_SUCCESS";
export const QUOTATION_VENDOR_AS_CUSTOMER_LIST_FAIL =
  "QUOTATION_VENDOR_AS_CUSTOMER_LIST_FAIL";

export const PREVIOUS_TRADE_LIST_REQUEST = "PREVIOUS_TRADE_LIST_REQUEST";
export const PREVIOUS_TRADE_LIST_SUCCESS = "PREVIOUS_TRADE_LIST_SUCCESS";
export const PREVIOUS_TRADE_LIST_FAIL = "PREVIOUS_TRADE_LIST_FAIL";
