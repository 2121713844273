import { Button } from "react-bootstrap";
import React, { Component } from "react";
import { MediumButtonStyle } from "../../components/ButtonStyle";
import ImgGift from "../../assets/gift.png";

class Success extends Component {
  success = () => {
    //console.log("success");
    this.props.history.push("/");
  };
  render() {
    //const {values} = this.props;
    return (
      <div style={{ backgroundColor: "#f3c065" }}>
        <view style={styles.success}>
          <text style={{ alignSelf: "center", fontSize: 24 }}>
            Welcome to Cropway
          </text>
          <img style={styles.ImgGift} src={ImgGift} alt="" />
        </view>
        <div
          style={{
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            onClick={this.success}
            style={MediumButtonStyle}
          >
            Go to Home{" "}
            <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
          </Button>
          <div style={{ marginTop: 10 }}></div>
          {/* <Button type="submit" style={MediumButtonStyle}>
                    Back to Login <i style={{marginLeft: 5}} className="fas fa-angle-left"/>
                    </Button>  */}
        </div>
      </div>
    );
  }
}
const styles = {
  success: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 50,
  },

  BtnTextStyle: {
    color: "#fff",
    textAlign: "center",
  },

  Btn: {
    alignSelf: "center",
    marginTop: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 35,
    paddingLeft: 35,
    marginLeft: 2,
    backgroundColor: "#59cbbd",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "transparent",
  },

  background3: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 20,
    height: 40,
  },

  ImgGift: {
    width: 200,
  },
};

export default Success;
