import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CartDesktopSection } from "../components/CartDesktopSection";
import cartService from "../services/cart.service";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getCart, getWishlistCartDetails } from "../actions/cart.action"

export default function SimpleTabs() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {cart, loading, error} = useSelector(state=>state.carts)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const proceedToCheckout=()=>{
    if(cart?.length>0){
      navigate('/shipping');
    }
 }

 const updateCart=(cartId, quantity)=>{
   let data={
     cart_id:cartId,
     quantity:quantity
   }
   cartService.updateCartHandler(data)
              .then(()=>{
                getCartProduct()
              })
              .catch((error)=>{
                console.log('updateCartHandler-error :: ',error)
              })
 }

 const confirmDelete=(cartId)=>{
   cartService.removeFromCartHandler(cartId)
              .then(()=>{
                getCartProduct()
                dispatch(getWishlistCartDetails())
                setDeleteModalVisible()
              })
              .catch((error)=>{
                console.log('removeFromCartHandler :: ' ,error)
              })
 }
 const removeFromCart=(cartId)=>{
   setDeleteModalVisible(cartId)
 }

 const getCartProduct=()=>{
  dispatch(getCart())
 }

  useEffect(() => {
     getCartProduct()
  }, []);

  // -----------------component-------------------------------
  const Modal = ({ deleteModalVisible, setDeleteModalVisible, handleConfirm }) => {
    return (
      <div style={{ display: deleteModalVisible ? 'block' : 'none', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)' }}>
          <h2>Confirmation</h2>
          <p>Are you sure you want to delete?</p>
          <div style={{ textAlign: 'center' }}>
            <button onClick={()=>confirmDelete(deleteModalVisible)} style={{ borderRadius:5 ,margin: 10, padding: 10, cursor: 'pointer' }}>OK</button>
            <button onClick={() => setDeleteModalVisible()} style={{ borderRadius:5 ,margin: 10, padding: 10, cursor: 'pointer' }}>Cancel</button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Loader loading={loading}/>
      <Modal
        deleteModalVisible={deleteModalVisible}
        setDeleteModalVisible={setDeleteModalVisible}
        confirmDelete={confirmDelete}
      />
      <CartDesktopSection
          cart={cart}
          proceedToCheckout={proceedToCheckout}
          updateCart={updateCart}
          removeFromCart={removeFromCart}
      />
    </>
  );
}
