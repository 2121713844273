import React from "react";
import { Nav } from "react-bootstrap";
// import { LinkContainer, Img } from "react-router-bootstrap";
import checkoutIcon from "../assets/checkoutIcon.png";
import checkoutIconBW from "../assets/checkoutIconBW.png";
import paymentIcon from "../assets/paymentIcon.png";
import paymentIconBW from "../assets/paymentIconBW.png";
import confirmIcon from "../assets/confirmIcon.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import confirmIconBW from "../assets/confirmIconBW.png";
import { useTranslation } from "react-i18next";

function CheckoutSteps({ step1, step2, step3 }) {
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();
  return (
    <Nav style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Nav.Item
          style={
            matches
              ? { margin: "0 15%", padding: "3%" }
              : { margin: "0 11%", padding: "1%" }
          }
        >
          {step1 ? (
            < >
              <Nav.Link style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={matches ? { width: "3vw" } : { width: "4vw" }}
                    src={checkoutIcon}
                    alt="checkout-icon"
                  />
                </div>
                <text
                  style={
                    matches
                      ? { whiteSpace: "nowrap", fontSize: "1vw" }
                      : { whiteSpace: "nowrap", fontSize: "1.5vh" }
                  }
                >
                  1. {t("Checkout")}
                </text>
              </Nav.Link>
            </>
          ) : (
            <Nav.Link disabled>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={matches ? { width: "3vw" } : { width: "4vw" }}
                  src={checkoutIconBW}
                  alt="checkout-icon"
                />
              </div>
              <text
                style={
                  matches
                    ? { whiteSpace: "nowrap", fontSize: "1vw" }
                    : { whiteSpace: "nowrap", fontSize: "1.5vh" }
                }
              >
                1. {t("Checkout")}
              </text>
            </Nav.Link>
          )}
        </Nav.Item>

        <Nav.Item
          style={
            matches
              ? { margin: "0 15%", padding: "3%" }
              : { margin: "0 11%", padding: "1%" }
          }
        >
          {step2 ? (
            <>
              <Nav.Link
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    padding: "3%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={matches ? { width: "3vw" } : { width: "4vw" }}
                    src={paymentIcon}
                    alt="checkout-icon"
                  />
                </div>
                <text
                  style={
                    matches
                      ? { whiteSpace: "nowrap", fontSize: "1vw" }
                      : { whiteSpace: "nowrap", fontSize: "1.5vh" }
                  }
                >
                  2. {t("Payment")}
                </text>
              </Nav.Link>
            </>
          ) : (
            <Nav.Link disabled>
              <div
                style={{
                  padding: "2%",
                  margin: "2%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={matches ? { width: "3vw" } : { width: "4vw" }}
                  src={paymentIconBW}
                  alt="checkout-icon"
                />
              </div>
              <text
                style={
                  matches
                    ? { whiteSpace: "nowrap", fontSize: "1vw" }
                    : { whiteSpace: "nowrap", fontSize: "1.5vh" }
                }
              >
                2. {t("Payment")}
              </text>
            </Nav.Link>
          )}
        </Nav.Item>

        <Nav.Item
          style={
            matches
              ? { margin: "0 15%", padding: "3%" }
              : { margin: "0 11%", padding: "1%" }
          }
        >
          {step3 ? (
            <>
              <Nav.Link
                style={{
                  whiteSpace: "nowrap",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    style={matches ? { width: "3vw" } : { width: "4vw" }}
                    src={confirmIcon}
                    alt="checkout-icon"
                  />
                </div>
                <text
                  style={
                    matches
                      ? { whiteSpace: "nowrap", fontSize: "1vw" }
                      : { whiteSpace: "nowrap", fontSize: "1.5vh" }
                  }
                >
                  3. {t("Confirmation")}
                </text>
              </Nav.Link>
            </>
          ) : (
            <Nav.Link disabled>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  style={matches ? { width: "3vw" } : { width: "4vw" }}
                  src={confirmIconBW}
                  alt="checkout-icon"
                />
              </div>

              <text
                style={
                  matches
                    ? { whiteSpace: "nowrap", fontSize: "1vw" }
                    : { whiteSpace: "nowrap", fontSize: "1.5vh" }
                }
              >
                3. {t("Confirmation")}
              </text>
            </Nav.Link>
          )}
        </Nav.Item>
      </div>
    </Nav>
  );
}

export default CheckoutSteps;
