export const div1Style = {
    '--color-1': 'rgba(191, 226, 186, 0.3)',
    '--color-2': 'rgba(93, 167, 202, 0.17)',
    background: `
      linear-gradient(
        90deg,
        var(--color-1) 33%,
        var(--color-2) 88%
      )
    `,
    fontFamily: 'Roboto',
    paddingBottom:50,
    paddingTop:50,

}