import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useLocation } from "react-router";
import orderService from "../services/order.service";

function OrderScreen() {
  const location = useLocation()
  const {order_item_id} = location?.state;
  const { t } = useTranslation();
  const [order, setOrder] = useState()
  // let status = ['Order Confirmed', 'Shipped', 'Out for delivery', 'Delivered', 'Failed', 'Cancelled']
  let cancelableStatus = ['Shipped', 'Out for delivery']
  let isCancelable = !cancelableStatus?.includes(order?.status?.name)

  const cancelOrder=(id)=>{
    console.log('cancle-order :: ', id)
  };

  const getOrderDetail=()=>{
    orderService.getOrderDataById(order_item_id)
                  .then((res)=>{
                    console.log(res.data)
                    setOrder(res.data)
                  })
                  .catch((error)=>{
                    console.log('order details error :: ', error)
                  })
  }

  useEffect(()=>{
    !order && getOrderDetail()
  },[order_item_id])

  const SummaryText=({title, value, style})=>{
    return(
      <div style={{display:'flex', justifyContent:'space-between', width:'70%', margin:10}}>
        <text style={{fontSize:20, ...style}}>{title}</text>
        <text style={{fontSize:20, ...style}}>{value}</text>
      </div>
    )
  }
  
  return (
    <div style={{display:'flex', justifyContent:'space-evenly', flexWrap:'wrap'}}>
      <div className="order-status" style={{margin:10, padding:10, width:'100%'}}>
      <text style={{fontSize:'1rem'}}># {order?.order?.order_id}</text>
        <h3>Status : {order?.status?.name}</h3>
        {
          order?.order_item_transection?.map((item)=>(
            <SummaryText title={item?.status?.name} value={new Date(item?.created_at).toLocaleString('en-GB',{ dateStyle: "short" })}/>
          ))
        }
      </div>

      <hr width={'90%'}/>

      <div className="order-detail" style={{ margin:10, padding:10, width:'100%'}}>
        <text style={{fontSize:'1rem'}}># {order?.order?.order_id}</text>
        <h6>{new Date(order?.created_at).toLocaleString('en-GB',{ dateStyle: "short" })}</h6>
          <img
            alt=""
            src={'https://ik.imagekit.io/oet53wxls7nq/'+(order?.product?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")}
            style={{height:200, width:200, aspectRatio:1, objectFit:'contain'}}
          />
          <div style={{display:'flex', flexDirection:'column', margin:10, width:'50%'}}>
            <text style={{fontSize:25}}>
              {order?.product?.product?.name}
            </text>
            <text>{order?.product?.product?.brand?.name}</text>
            <SummaryText title={'Seller'} value={order?.product?.product?.seller?.name} style={{fontSize:16, margin:0}}/>
            <SummaryText title={'Product Amount'} value={order?.product_amount} style={{fontSize:16, margin:0}}/>
            <SummaryText title={'Quantity'} value={order?.quantity} style={{fontSize:16, margin:0}}/>
            <SummaryText title={'Paid Amount'} value={order?.order?.total_amount} style={{fontSize:25, color:'black'}}/>
          </div>

          <h4>Shipping Address</h4>
          <div style={{display:'flex', flexWrap:'wrap', margin:10, width:'50%'}}>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.address+', '}</text>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.apartment+', '}</text>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.city+', '}</text>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.state+', '}</text>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.pincode+', '}</text>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.country+', '}</text>
            <text style={{margin:2}}>{order?.order?.shipping_detail?.shipping_address?.mobile}</text>
          </div>
      </div>
      <hr width={'90%'}/>
      <div className="order-action" style={{margin:10, padding:10, width:'100%'}}>
        <Button
          disabled={!isCancelable}
          style={{color:'#fff', background:'red', border:'none', margin:10, outline:'none', cursor:isCancelable? 'default' : 'not-allowed'}}
          onClick={()=>cancelOrder(order_item_id)}
        >
          Cancle
        </Button>
      </div>
    </div>
  );
}

export default OrderScreen;
