import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import ManageOrders from "../components/ManageOrders";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SmallButtonStyle } from "../components/ButtonStyle";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import orderService from "../services/order.service";

export default function PreviousOrder(){
  const navigate = useNavigate()
  const matches = useMediaQuery("(min-width:600px)");
  const [page, setPage] = useState(1)
  const [orders, setOrders] = useState()
  const { t } = useTranslation();

  useEffect(() => {
      orderService.getOrderDataByUser(page)
                    .then((res)=>{
                      setOrders(res.data)
                    })
                    .catch((error)=>{
                      console.log(error)
                    })
  },[page]);

  const handleDetails=(order_item_id)=>{
    navigate('/order-detail', {state:{order_item_id: order_item_id}})
  };

  const OrderTable=()=>{
    return(
      <div
        style={{
          border:'1px solid gray',
          backgroundColor: '#fff',
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 10,
          margin: 10,
          }}
      >
      <table
      style={{
        borderCollapse: 'separate !important',
        borderSpacing: '0.8vmin 0.5vmin',
        width: '100%',
      }}
        >
        <thead>
          <tr>
            <td colSpan={7} style={{fontSize:20, padding:10}} >Orders</td>
          </tr>
        </thead>
        <tbody>
        <tr 
         style={{
          backgroundColor: '#fff',
          textAlign: 'center',
          fontSize: '1.5vmin',
          borderRadius: '5px',
        }}
        >
          <th style={{minWidth:'20%', borderRadius: 5}}>{'#'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Product'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Name'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Details'}</th>
        </tr>
        {!orders &&
          <tr 
            style={{
              backgroundColor: '#fff',
              textAlign: 'center',
              fontSize: '1.5vmin',
              borderRadius: '5px',
            }}
          >
            <th colSpan={8} style={{minWidth:'20%', height:50, borderRadius:5, background:'rgba(244, 246, 251, 1)'}}>
              {'Shopping bag is empty'}
            </th>
          </tr>
        }
        {
          orders?.map((item, key)=>{
            // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(item?.product?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
            let imageURL = (item?.product?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
            return(
              <tr key={key} style={{height:40}}>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.order?.order_id}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <img src={imageURL} style={{aspectRatio:1, objectFit:'cover', height:80, width:80, margin:10, borderRadius:5}} alt="product"/>
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product?.product?.name}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <text style={{cursor:'pointer'}} onClick={()=>handleDetails(item.id)}>{'Detail'}</text>
              </td>
            </tr>
            )
          })
        }
        </tbody>
      </table>
      </div>
    )
  }

  return (
    <div
      style={{
        paddingLeft: matches ? 255 : 0,
        paddingRight: matches ? 255 : 0,
      }}
    >
      <div>
        <ManageOrders step1 t={t}/>
        <OrderTable/>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 15,
          }}
        >
          <Button style={SmallButtonStyle} onClick={()=>setPage(page+1)}>
            {t("Show-More")}
            <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
          </Button>
        </div>
      </div>
    </div>
  );
}
