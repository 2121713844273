import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../components/message";
import {
  MediumButtonStyle
} from "../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";
// import Dropdown from 'react-bootstrap/Dropdown';

export default class BankDetail2 extends React.Component {
  state = {
    uploadimage: "",
    preview: "",
    accountHolderName: "",
    bankName: "",
    accountType: "",
    accountNumber: "",
    branch: "",
    ifscCode: "",
    micr: "",
    city: "",
    postalCode: "",
    // storagespace: "",
    CanceledChequeImage: null,
    VendorID: "",
    CWCID: "",
    CompanyBranchID: "",
  };


  saveandContinue = async (e) => {
    await this.props.postBankDetails();
    if(!(this.props.LastPage)){ //temporary because fees is not decided for vendor
    this.props.nextStep();
    }
    else{ //temporary because fees is not decided for vendor
      this.props.history.push("/");
    }
  };

  back = () => {
    this.props.prevStep();
  };

  render() {
    const {
      error
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 500,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Preview Bank Summary{" "}
                  </strong>
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Submit to confirm you bank details.
                  </Form.Label>

                  <div style={{ marginTop: 25 }}>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Account Holder Name
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.accountHolderName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Bank Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.bankName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Account Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.accountNumber}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Account Type</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.accountType}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Bank Branch</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.branch}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>IFSC Code</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.ifscCode}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>MICR Code</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.micr}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>City</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.city}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Postal Code</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.BankPostal}
                      </strong>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Submit{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.back}
                      style={MediumButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 0,
    height: "auto",
    margin: 3,
    width: "45%",
  },

  confirmtext: {
    display: "flex",
    flexDirection: "row",
  },
};
