import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from './reducers/index.reducer';
import {
  productListReducer,
  productDetailsReducer,
  FeaturedProductListReducer,
  FeaturedProductDetailsReducer,
  productCategoryListReducer,
  FilteredCategoryListReducer,
  InnovativeCategoryListReducer,
  FilteredQuotationCategoryListReducer,
  productSearchListReducer,
  /////////////////////////////////////////////////////
  BrandListReducer,
  QuotationBrandListReducer,
  SearchBrandListReducer,
  SubcategoryListReducer,
  SubSubcategoryListReducer,
  CategoryListReducer,
  FarmProductListReducer,
  FarmProductDetailsReducer,
  QuotationProductDetailsReducer,
  FarmProductNameReducer,
  FilteredFarmProductListReducer,
  FilteringCategoriesListReducer,
  FilteringQuotationCategoriesListReducer,
  FilteringSearchCategoriesListReducer,
  FarmProduceSubcategoryListReducer,
  ////////////////////////////////////////////////////////
  productDetailsWithVariationsReducer,
  ReviewListReducer,
  QNAListReducer,
} from "./reducers/productReducers";

import {
  DiseaseDetectionHistoryListReducer,
  PestDetectionHistoryListReducer,
} from "./reducers/MlReducers";

import {
  FarmerListedProductReducer,
  NegotiationListReducer,
  QuotationListReducer,
  QuotationVendorasCustomerListReducer,
  PreviousTradesListReducer,
} from "./reducers/DashboardReducers";
import {
  showPageReducer,
  ShowImgSearchReducers,
} from "./reducers/ShowpageReducer";

import { BrowsingHistoryListReducer } from "./reducers/BrowsingHistoryReducers";

import { SearchHistoryListReducer } from "./reducers/SearchHistoryReducers";

import { cartReducer, CartItemsList } from "./reducers/cartReducers";

import {
  userLoginReducer,
  userProfileReducer,
  updateUserProfileReducer,
  password_reset_reducer,
  password_reset_confirm_reducer,
} from "./reducers/userReducers";

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPaymentReducer,
  orderMineReducer,
} from "./reducers/orderReducers";

import { WishlistItemsList } from "./reducers/wishlistReducers";

import productReducer from './reducers/product.reducer';
import authReducer from "./reducers/auth.reducer";
import cartReducer2 from "./reducers/cart.reducer";
import wishlistReducer from "./reducers/wishlist.reducer";
import wishlistCartDetailReducer from "./reducers/wishlistCartDetail.reducer";
import { runLogoutTimer } from "./actions/auth.action";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userProfile: userProfileReducer,
  updateUserProfile: updateUserProfileReducer,
  passwordReset: password_reset_reducer,
  passwordResetConfirm: password_reset_confirm_reducer,
  brandList: BrandListReducer,
  QuotationbrandList: QuotationBrandListReducer,
  SearchbrandList: SearchBrandListReducer,
  categorylist: CategoryListReducer,
  subcategoryList: SubcategoryListReducer,
  subsubcategoryList: SubSubcategoryListReducer,
  FilteringCategoriesList: FilteringCategoriesListReducer,
  FilteringQuotationCategoriesList: FilteringQuotationCategoriesListReducer,
  FilteringSearchCategoriesList: FilteringSearchCategoriesListReducer,
  FarmsubcategoryList: FarmProduceSubcategoryListReducer,
  productList: productListReducer,
  productSearchList: productSearchListReducer,
  productDetails: productDetailsReducer,
  productCategoryList: productCategoryListReducer,
  filteredCategoryList: FilteredCategoryListReducer,
  innovativeCategoryList:InnovativeCategoryListReducer,
  filteredQuotationCategoryList: FilteredQuotationCategoryListReducer,
  FeaturedproductList: FeaturedProductListReducer,
  FeaturedproductDetails: FeaturedProductDetailsReducer,
  FarmProductList: FarmProductListReducer,
  FarmProductDetails: FarmProductDetailsReducer,
  QuotationProductDetails: QuotationProductDetailsReducer,
  FarmProductNameList: FarmProductNameReducer,
  FilteredFarmProducts: FilteredFarmProductListReducer,
  farmerlistedproducts: FarmerListedProductReducer,
  negotiationlist: NegotiationListReducer,
  Quotationlist: QuotationListReducer,
  QuotationVendorasCustomerlist: QuotationVendorasCustomerListReducer,
  PreviousTrades: PreviousTradesListReducer,
  showPage: showPageReducer,
  ImageSearchDrop: ShowImgSearchReducers,
  cart: cartReducer,
  cartitemlist: CartItemsList,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPayment: orderPaymentReducer,
  orderMine: orderMineReducer,
  wishlist: WishlistItemsList,
  productDetailsWithVariation: productDetailsWithVariationsReducer,
  orderPaymentID: orderPaymentReducer,
  ReviewList: ReviewListReducer,
  QNAList: QNAListReducer,
  BrowserHistoryList: BrowsingHistoryListReducer,
  SearchHistoryList: SearchHistoryListReducer,
  DiseaseDetectionHistory: DiseaseDetectionHistoryListReducer,
  PestDetectionHistory: PestDetectionHistoryListReducer,
  // -------------------------new redux--------------------------
  homepageProduct: productReducer,
  auth: authReducer,
  carts: cartReducer2,
  wishlists: wishlistReducer,
  wishlistCartDetail: wishlistCartDetailReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
  ? JSON.parse(localStorage.getItem("paymentMethod"))
  : {};

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
  cart: {
    shippingAddress: shippingAddressFromStorage,
    paymentMethod: paymentMethodFromStorage,
  },
};

// Comment this when debugging in local( this for production)
// const store = createStore(reducer,initialState, applyMiddleware(thunk));

// Uncomment this when debugging in local

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

(async()=>{
  await store.dispatch(runLogoutTimer()).then(()=>{console.log(':: store-logout :: ')}).catch((error)=>{});
})();

export default store;
