import cartService from '../services/cart.service';

export const getCart=()=>(dispatch)=>{
    dispatch({type : 'CART_REQUEST'})
    cartService.getCart()
                  .then((res)=>{
                    dispatch({
                        type : 'CART_SUCCESS',
                        payload : res.data
                    })
                  })
                  .catch((error)=>{
                    dispatch({
                        type : 'CART_FAILED',
                        payload : error?.response?.statusText
                    })
                  })
};

export const getWishlistCartDetails=(variationId)=>(dispatch)=>{
    dispatch({type : 'CART_WISHLIST_DETAIL_REQUEST'})
    cartService.getWishlistCartDetails(variationId)
                  .then((res)=>{
                    dispatch({
                        type : 'CART_WISHLIST_DETAIL_SUCCESS',
                        payload : res.data
                    })
                  })
                  .catch((error)=>{
                    dispatch({
                        type : 'CART_WISHLIST_DETAIL_FAILED',
                        payload : error?.response?.statusText
                    })
                  })
};