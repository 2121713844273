import React from 'react'
import { Form, Button, Container, Col, Row } from 'react-bootstrap'
import { MediumButtonStyle } from '../../../components/ButtonStyle'
import { BackgroundGradientStyle } from '../../../components/BackgoundGradient'
import axios from 'axios'
import {
  API_URL_BLOCK,
  API_URL_DISTRICT,
  API_URL_STATE,
  API_URL_VILLAGE,
} from '../Constants'
export default class FpoReg extends React.Component {
  state = {
    state: [],
    district: [],
    block: [],
    society: [],
    village: [],

    stateErr: '',
    districtErr: '',
    blockErr: '',
    villageErr: '',
    firmNameErr: '',
    designationErr: '',
    firmAdd1Err: '',
    firmAdd2Err: '',
    postalErr: '',
  }

  validation = async () => {
    if (
      this.props.values.states == '' ||
      this.props.values.states == 'Select State*' ||
      this.props.values.district == '' ||
      this.props.values.district == 'Select District*' ||
      this.props.values.block == '' ||
      this.props.values.block == 'Select Block*' ||
      this.props.values.villTownWard == '' ||
      this.props.values.villTownWard == 'Select Village*' ||
      this.props.values.postalCode.length > 6 ||
      this.props.values.postalCode.length < 6
    ) {
      if (
        this.props.values.states == '' ||
        this.props.values.states == 'Select State*'
      ) {
        this.setState({ stateErr: 'Please select the state!' })
      } else {
        this.setState({ stateErr: '' })
      }

      if (
        this.props.values.district == '' ||
        this.props.values.district == 'Select District*'
      ) {
        this.setState({ districtErr: 'Please select the District!' })
      } else {
        this.setState({ districtErr: '' })
      }

      if (
        this.props.values.block == '' ||
        this.props.values.block == 'Select Block*'
      ) {
        this.setState({ blockErr: 'Please select the Block!' })
      } else {
        this.setState({ blockErr: '' })
      }

      if (
        this.props.values.villTownWard == '' ||
        this.props.values.villTownWard == 'Select Village*'
      ) {
        this.setState({ villageErr: 'Please select the Village!' })
      } else {
        this.setState({ villageErr: '' })
      }

      if (
        this.props.values.postalCode.length > 6 ||
        this.props.values.postalCode.length < 6
      ) {
        this.setState({ postalErr: 'Please enter valid postalCode!' })
      } else {
        this.setState({ postalErr: '' })
      }
    } else {
      this.setState({ stateErr: '' })
      this.setState({ districtErr: '' })
      this.setState({ blockErr: '' })
      this.setState({ villageErr: '' })
      this.setState({ postalErr: '' })

      await this.props.postData()
      this.props.nextStep()
    }
  }

  saveandContinue = async (e) => {
    // await this.props.postData();
    this.props.nextStep()
  }

  componentDidMount = async () => {
    axios
      .get(`${API_URL_STATE}${1}`)
      .then((res) => {
        this.setState({ state: res.data.reverse() })
        //console.log(this.state.state);
      })
      .catch((error) => {
        //console.warn(error);
      })
  }

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1
      } else if (a[prop] < b[prop]) {
        return -1
      }
      return 0
    }
  }

  distget = async (val) => {
    //console.log("knddd");
    const Value = val.split(',')
    //console.log("inside dist get", Value[0]);

    this.props.handleChange('states', Value[0])

    var ids = Number(Value[1])

    axios
      .get(`${API_URL_DISTRICT}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object('district'))
        this.setState({ district: result_sorted })
        // console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      })
  }

  blockget = async (val) => {
    //console.log("inside block get");

    const Value = val.split(',')

    this.props.handleChange('district', Value[0])
    var ids = Number(Value[1])
    // var idd=id;

    axios
      .get(`${API_URL_BLOCK}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object('block'))
        this.setState({ block: result_sorted })
        //  console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      })
  }

  villageget = async (val) => {
    // console.log('inside Village get: '+id);
    const Value = val.split(',')

    this.props.handleChange('block', Value[0])
    var ids = Number(Value[1])
    // var idso=id;

    axios
      .get(`${API_URL_VILLAGE}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object('vill_town'))
        this.setState({ village: result_sorted })
        //console.log(this.state.village);
      })
      .catch((err) => {
        //console.log(err);
      })
  }

  render() {
    const formStyle = {
      formGroupStyle: {
        borderRadius: 0,
        marginTop: 20,
        border: 0,
        borderBottom: '1px solid #5DA7CA',
        alignItems: 'right',
      },

      formControlStyle: {
        borderRadius: 0,
        fontSize: 15,
        boxShadow: 'none',
        paddingLeft: 0,
        border: 0,
      },

      formErrorTextStyle: {
        color: 'red',
        position: 'absolute',
        left: 'auto',
      },
    }

    return (
      <Container>
        {/* {error && 
       <Message width="100%" variant="danger">
         'something is wrong can you please check the fields, are they correctly filled or not?'
       </Message>} */}
        <Col
          style={{
            ...BackgroundGradientStyle,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row>
            <Form
              className="px-2 py-2"
              style={{ width: 300, backgroundColor: 'white' }}
            >
              <strong
                style={{
                  color: 'black',
                  fontSize: 24,
                  display: 'flex',
                  alignItems: 'left',
                }}
              >
                Create Account
              </strong>
              <text
                style={{
                  display: 'flex',
                  alignItems: 'left',
                }}
              >
                Fill the below form to create a new Cropway FPO account.
              </text>

              <Form.Group controlId="State" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  as="select"
                  placeholder="State"
                  onChange={(e) => this.distget(e.target.value)}
                >
                  <option>Select State*</option>
                  {this.state.state.map((res) => (
                    <option key={res.id} value={[res.state, res.id]}>
                      {res.state}
                    </option>
                  ))}
                </Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.stateErr}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="District" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  as="select"
                  placeholder="City"
                  onChange={(e) => this.blockget(e.target.value)}
                >
                  <option>Select District*</option>
                  {this.state.district.map((res) => (
                    <option key={res.id} value={[res.district, res.id]}>
                      {res.district}
                    </option>
                  ))}
                </Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.districtErr}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="Block" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  as="select"
                  placeholder="Block"
                  onChange={(e) => this.villageget(e.target.value)}
                >
                  <option>Select Block*</option>
                  {this.state.block.map((res) => (
                    <option key={res.id} value={[res.block, res.id]}>
                      {res.block}
                    </option>
                  ))}
                </Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.blockErr}
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="Village" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  as="select"
                  placeholder="Village/Town/Ward"
                  onChange={(e) =>
                    this.props.handleChange('villTownWard', e.target.value)
                  }
                >
                  <option>Select Village*</option>
                  {this.state.village.map((res) => (
                    <option key={res.id} value={res.vill_town}>
                      {res.vill_town}
                    </option>
                  ))}
                </Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.villageErr}
                </Form.Text>
              </Form.Group>
              {/* form start */}
              <Form.Group controlId="firmName" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="text"
                  placeholder="Firm Name/Society Name/Individual"
                  defaultValue={this.props.values.firmName}
                  onChange={(e) =>
                    this.props.handleChange('firmName', e.target.value)
                  }
                ></Form.Control>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.firmNameErr}</Form.Text> */}
              </Form.Group>

              <Form.Group
                controlId="designation"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="text"
                  placeholder="Designation"
                  defaultValue={this.props.values.designation}
                  onChange={(e) =>
                    this.props.handleChange('designation', e.target.value)
                  }
                ></Form.Control>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.designationErr}</Form.Text> */}
              </Form.Group>

              <Form.Group
                controlId="firmAddress1"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="text"
                  placeholder="Firm Address 1"
                  defaultValue={this.props.values.firmAddress1}
                  onChange={(e) =>
                    this.props.handleChange('firmAddress1', e.target.value)
                  }
                ></Form.Control>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.firmAdd1Err}</Form.Text> */}
              </Form.Group>

              <Form.Group
                controlId="firmAddress2"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="text"
                  placeholder="Firm Address 2"
                  defaultValue={this.props.values.firmAddress2}
                  onChange={(e) =>
                    this.props.handleChange('firmAddress2', e.target.value)
                  }
                ></Form.Control>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.firmAdd2Err}</Form.Text> */}
              </Form.Group>

              <Form.Group
                controlId="postalCode"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="number"
                  placeholder="Postal Code"
                  defaultValue={this.props.values.postalCode}
                  onChange={(e) =>
                    this.props.handleChange('postalCode', e.target.value)
                  }
                ></Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.postalErr}
                </Form.Text>
              </Form.Group>
              {/* end form */}
              <div
                style={{
                  marginTop: 25,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button
                  className="my-2"
                  onClick={this.validation}
                  style={MediumButtonStyle}
                >
                  Save & Continue
                  <i className="fas fa-angle-right mx-2" />
                </Button>

                <Button className="my-2" style={MediumButtonStyle}>
                  Back to Login
                  <i className="fas fa-angle-left mx-2" />
                </Button>
              </div>
            </Form>
          </Row>
        </Col>
      </Container>
    )
  }
}
