import React from 'react'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import Message from '../../../components/message'
import { MediumButtonStyle } from '../../../components/ButtonStyle'
import { BackgroundGradientStyle } from '../../../components/BackgoundGradient'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default class FpoReg2 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',

      gender: '',
      DOB: '',
      email: '',
      mobileNo: '',
      altMobileNo: '',

      mobileNoErr: '',
      altMobileNoErr: '',
    }
  }

  genderlist = [{ title: 'Male' }, { title: 'Female' }]

  defaultProps1 = {
    options: this.genderlist,
    getOptionLabel: (option) => option.title,
  }

  validation = async () => {
    let x = this.props.values.email

    let atposition = x.indexOf('@')
    let dotposition = x.lastIndexOf('.')

    if (
      this.props.values.mobileNo.length > 10 ||
      this.props.values.mobileNo.length < 10 ||
      this.props.values.altMobileNo.length > 10 ||
      this.props.values.altMobileNo.length < 10
    ) {
      if (
        this.props.values.mobileNo.length > 10 ||
        this.props.values.mobileNo.length < 10
      ) {
        this.setState({ mobileNoErr: 'Please enter valid Mobile number' })
      } else {
        console.log("else")

        this.setState({ mobileNoErr: '' })
      }

      if (
        this.props.values.altMobileNo.length > 10 ||
        this.props.values.altMobileNo.length < 10
      ) {
        this.setState({
          altMobileNoErr: 'Please enter valid alternative Mobile number',
        })
      } else {
        console.log("else")

        this.setState({ altMobileNoErr: '' })
      }
    } else if (
      atposition < 1 ||
      dotposition < atposition + 2 ||
      dotposition + 2 >= x.length
    ) {
      this.setState({
        altEmailErr: 'Please enter valid Email',
      })
    } else {
      console.log("else")
      this.setState({ mobileNoErr: '' })
      this.setState({ altMobileNoErr: '' })
      this.setState({ altEmailErr: '' })

      await this.props.postData()
      this.props.nextStep()
    }
  }

  saveandContinue = async (e) => {
    await this.props.postData();
    this.props.nextStep()
  }

  render() {
    const formStyle = {
      formGroupStyle: {
        borderRadius: 0,
        marginTop: 20,
        border: 0,
        borderBottom: '1px solid #5DA7CA',
        alignItems: 'right',
      },

      formControlStyle: {
        borderRadius: 0,
        fontSize: 15,
        boxShadow: 'none',
        paddingLeft: 0,
        border: 0,
      },

      formErrorTextStyle: {
        color: 'red',
        position: 'absolute',
        left: 'auto',
      },
    }

    const { error } = this.state
    return (
      <Container>
        {error && (
          <Message width="100%" variant="danger">
            'something is wrong can you please check the fields, are they
            correctly filled or not?'
          </Message>
        )}
        <Col
          style={{
            ...BackgroundGradientStyle,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row>
            <Form
              className="px-2 py-2"
              style={{ width: 300, backgroundColor: 'white' }}
            >
              <strong
                style={{
                  color: 'black',
                  fontSize: 24,
                  display: 'flex',
                  alignItems: 'left',
                }}
              >
                Create Account
              </strong>
              <text
                style={{
                  display: 'flex',
                  alignItems: 'left',
                }}
              >
                Fill the below form to create a new Cropway FPO account.
              </text>
              {/* start comment  default value*/}
              <Form.Group
                controlId="firstname"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="firstName"
                  placeholder="Enter First Name"
                  // value={values.firstName}
                  // defaultValue={this.props.values.firstName}
                  onChange={(e) =>
                    this.props.handleChange('firstName', e.target.value)
                  }
                ></Form.Control>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.stateErr}</Form.Text> */}
              </Form.Group>

              <Form.Group controlId="lastName" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="lastName"
                  placeholder="Enter Last Name"
                  // value={values.lastName}
                  // defaultValue={this.props.values.lastName}
                  onChange={(e) =>
                    this.props.handleChange('lastName', e.target.value)
                  }
                ></Form.Control>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.stateErr}</Form.Text> */}
              </Form.Group>
              {/* start comment */}

              <Autocomplete
                style={{ marginTop: -20, marginBottom: -5 }}
                {...this.defaultProps1}
                // defaultValue={this.props.values.gender}
                id="gender"
                onInputChange={(e, v) => this.props.handleChange('gender', v)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Gender"
                    margin="normal"
                  />
                )}
              />

              <Form.Group controlId="email" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="email"
                  placeholder="Enter Email"
                  // value={values.email}
                  // defaultValue={this.props.values.email}
                  onChange={(e) =>
                    this.props.handleChange('email', e.target.value)
                  }
                ></Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.altEmailErr}
                </Form.Text>
                {/* <Form.Text style={formStyle.formErrorTextStyle}>{this.state.stateErr}</Form.Text> */}
              </Form.Group>

              <Form.Group controlId="mobileNo" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="number"
                  placeholder="Enter Mobile Number"
                  // defaultValue={this.props.values.mobileNo}
                  onChange={(e) =>
                    this.props.handleChange('mobileNo', e.target.value)
                  }
                ></Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.mobileNoErr}
                </Form.Text>
              </Form.Group>

              <Form.Group
                controlId="altMobileNo"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="numeric"
                  placeholder="Enter Alternative Mobile Number"
                  // defaultValue={this.props.values.altMobileNo}
                  onChange={(e) =>
                    this.props.handleChange('altMobileNo', e.target.value)
                  }
                ></Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.altMobileNoErr}
                </Form.Text>
              </Form.Group>
              {/* comment end */}
              <div
                style={{
                  marginTop: 25,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button
                  className="my-2"
                  onClick={this.saveandContinue}
                  style={MediumButtonStyle}
                >
                  Save & Continue
                  <i className="fas fa-angle-right mx-2" />
                </Button>

                <Button
                  className="my-2"
                  onClick={this.props.prevStep}
                  style={MediumButtonStyle}
                >
                  Back to Login
                  <i className="fas fa-angle-left mx-2" />
                </Button>
              </div>
            </Form>
          </Row>
        </Col>
      </Container>
    )
  }
}
