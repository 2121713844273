// auth service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const login=(username, password)=>{
  return axios.post(`${HOST}/core/login/`, {username, password},
  );
}

const exportedObject={
    login,
}

export default exportedObject