import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import {
  API_URL_CWCBYUSERID,
  API_URL_CWC,
  API_URL_BANKDETAILSBYUSERID,
  API_URL_BANKDETAILS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function CWCUploadedDocs({ history }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    idImg: null,
    gstImage: null,
    photoImg: null,
    storageareaImg: null,
    shopfrontImg: null,
    CWCLiveImg: null,
    haveGst: null,
  });

  const [PreviewGst, setPreviewGst] = useState(null);
  const [PreviewIdImg, setPreviewIdImg] = useState(null);
  const [PreviewPhotoImg, setPreviewPhotoImg] = useState(null);
  const [PreviewStorageAreaImg, setPreviewStorageAreaImg] = useState(null);
  const [PreviewShopFrontImg, setPreviewShopFrontImg] = useState(null);
  const [PreviewCWCLiveImg, setPreviewCWCLiveImg] = useState(null);
  const [PreviewCancelledCheueueImage, setPreviewCancelledCheueueImage] = useState(null);
  const [_uploadgst, setUploadgst] = useState("");
  const [_idupload, setIdUpload] = useState("");
  const [_photoupload, setPhotoUpload] = useState("");
  const [_storageareaupload, setStorageAreaUpload] = useState("");
  const [_shopfrontupload, setShopFrontUpload] = useState("");
  const [_CWCLiveUpload, setCWCLiveUpload] = useState("");
  const [CancelledCheueueImage, setCancelledCheueueImage] = useState(null);
  const [_uploadCancelledCheueueImage, setUploadCancelledCheueueImage] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  ////////////////////////////////////////////////////////////////////////////////

  const UpdateData = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();
    let LivePhoto = _CWCLiveUpload;
    let IDImage = _idupload;
    let PassportImage = _photoupload;
    let StorageArea = _storageareaupload;
    let StorageFrontImage = _shopfrontupload;
    let GSTCertificate = _uploadgst;

    // console.log("++++++++++ \n" + JSON.stringify(LivePhoto) + "\n+++++++");

    if (IDImage !== "") {
      form_data.append("idImage", IDImage, IDImage.name);
    }

    if (PassportImage !== "") {
      form_data.append("CWCImage", PassportImage, PassportImage.name);
    }

    if (LivePhoto !== "") {
      form_data.append("CWCLiveImage", LivePhoto, LivePhoto.name);
    }

    if (StorageArea !== "") {
      form_data.append("storageAreaImage", StorageArea, StorageArea.name);
    }

    if (StorageFrontImage !== "") {
      form_data.append(
        "storeFrontImage",
        StorageFrontImage,
        StorageFrontImage.name
      );
    }

    if (GSTCertificate !== "") {
      form_data.append("gstCert", GSTCertificate, GSTCertificate.name);
    }

    let response = -[];

    await axios
      .post(API_URL_CWC, form_data, config)
      .then((res) => {
        // console.log(res)
        response = res.response;
        retrieve_CWC_Doc();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((error) => {
        response = error;
      });

    return response;
  };

  // //////////////////////////////////////////////////////////////////

  const UpdateBankData = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();
    let Cheque = _uploadCancelledCheueueImage;
    //console.log("cheque---->: " + JSON.stringify(Cheque));

    if (Cheque && Cheque != null) {
      form_data.append("CanceledChequeImage", Cheque, Cheque.name);
    }

    let response = -[];

    await axios
      .post(API_URL_BANKDETAILS, form_data, config)
      .then((res) => {
        // console.log(res)
        response = res.response;
        retrieve_Bank_Doc();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((error) => {
        response = error;
      });

    return response;
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const retrieve_CWC_Doc = async () => {
    try {
      axios.get(`${API_URL_CWCBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            // loading: false,
            haveGst: res.data[0].haveGST,
            // userID: res.data[0].userID
            idImg: res.data[0].idImage,
            gstImage: res.data[0].gstCert,
            photoImg: res.data[0].CWCImage,
            storageareaImg: res.data[0].storageAreaImage,
            shopfrontImg: res.data[0].storeFrontImage,
            CWCLiveImg: res.data[0].CWCLiveImage,
          });
        } else {
        }
      });
    } catch (error) {
      //console.log(error);
    }
  };

  const retrieve_Bank_Doc = async () => {
    try {
      axios.get(`${API_URL_BANKDETAILSBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          // CanceledChequeImage: CanceledChequeImage,
          setCancelledCheueueImage(res.data[0].CanceledChequeImage);

        } else {
        }
      });
    } catch (error) {
      //console.log(error);
    }
  };

  // const setImg = (evt, prop) => {
  //   setValues({ ...values, [prop]: URL.createObjectURL(evt.target.files[0]) });
  // };

  const handleDoc2 = async (evt) => {
    try {
      setUploadgst(evt.target.files[0]);
      setPreviewGst(URL.createObjectURL(evt.target.files[0]));
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDoc1 = async (evt) => {
    try {
      setIdUpload(evt.target.files[0]);
      setPreviewIdImg(URL.createObjectURL(evt.target.files[0]));
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDoc3 = async (evt) => {
    try {
      setUploadCancelledCheueueImage(evt.target.files[0]);
      setPreviewCancelledCheueueImage(URL.createObjectURL(evt.target.files[0]));
      // retrieve_Have_Gst()
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDoc4 = async (evt) => {
    try {
      setPhotoUpload(evt.target.files[0]);
      setPreviewPhotoImg(URL.createObjectURL(evt.target.files[0]));
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDoc5 = async (evt) => {
    try {
      setStorageAreaUpload(evt.target.files[0]);
      setPreviewStorageAreaImg(URL.createObjectURL(evt.target.files[0]));
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDoc6 = async (evt) => {
    try {
      setShopFrontUpload(evt.target.files[0]);
      setPreviewShopFrontImg(URL.createObjectURL(evt.target.files[0]));
    } catch (error) {
      //console.log(error);
    }
  };

  const handleDoc7 = async (evt) => {
    try {
      setCWCLiveUpload(evt.target.files[0]);
      setPreviewCWCLiveImg(URL.createObjectURL(evt.target.files[0]));
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      retrieve_CWC_Doc();
      retrieve_Bank_Doc();
    }
  }, [user]);

  return (
    <div>
      <div>
        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          {/* {console.log('deva', values._uploadgst)}
        {console.log('deva1',PreviewGst)}
        {console.log('deva2',values.haveGst)} */}

          {
            values.haveGst && values.haveGst ? ( //if
              <ImageListItem key={PreviewGst ? PreviewGst : values.gstImage}>
                <img
                  src={
                    PreviewGst
                      ? PreviewGst
                      : `${values.gstImage}?w=248&fit=crop&auto=format&dpr=2 2x`
                  }
                  alt={t("GST-Certificate")}
                  // loading="lazy"
                />

                <ImageListItemBar
                  title={t("GST-Certificate")}
                  subtitle={
                    <input
                      type="file"
                      disabled={showEdit ? false : true}
                      // hidden
                      // onChange={(evt)=>handleChangPreviewGst')}
                      onChange={(evt) => handleDoc2(evt)}
                    />
                  }
                  position="below"
                />
              </ImageListItem>
            ) : null //else
          }

          <ImageListItem key={PreviewIdImg ? PreviewIdImg : values.idImg}>
            <img
              // src={`${values.idImg}?w=248&fit=crop&auto=format`}
              src={
                PreviewIdImg
                  ? PreviewIdImg
                  : `${values.idImg}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${values.idImg}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t("Photo-ID")}
              // loading="lazy"
            />
            <ImageListItemBar
              title={t("Photo-ID")}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc1(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          <ImageListItem
            key={PreviewPhotoImg ? PreviewPhotoImg : values.photoImg}
          >
            <img
              // src={`${values.photoImg}?w=248&fit=crop&auto=format`}
              src={
                PreviewPhotoImg
                  ? PreviewPhotoImg
                  : `${values.photoImg}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${values.photoImg}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t("Passport-Photo-of-CWC")}
              // loading="lazy"
            />
            <ImageListItemBar
              title={t("Passport-Photo-of-CWC")}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc4(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          <ImageListItem
            key={
              PreviewStorageAreaImg
                ? PreviewStorageAreaImg
                : values.storageareaImg
            }
          >
            <img
              // src={`${values.storageareaImg}?w=248&fit=crop&auto=format`}
              src={
                PreviewStorageAreaImg
                  ? PreviewStorageAreaImg
                  : `${values.storageareaImg}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${values.storageareaImg}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t("Storage-Area-Photo")}
              // loading="lazy"
            />
            <ImageListItemBar
              title={t("Storage-Area-Photo")}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc5(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          <ImageListItem
            key={
              PreviewShopFrontImg ? PreviewShopFrontImg : values.shopfrontImg
            }
          >
            <img
              // src={`${values.shopfrontImg}?w=248&fit=crop&auto=format`}
              src={
                PreviewShopFrontImg
                  ? PreviewShopFrontImg
                  : `${values.shopfrontImg}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${values.storageareaImg}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={"Shop-Front-Photo"}
              // loading="lazy"
            />
            <ImageListItemBar
              title={"Shop-Front-Photo"}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc6(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          <ImageListItem
            key={PreviewCWCLiveImg ? PreviewCWCLiveImg : values.CWCLiveImg}
          >
            <img
              // src={`${values.CWCLiveImg}?w=248&fit=crop&auto=format`}
              src={
                PreviewCWCLiveImg
                  ? PreviewCWCLiveImg
                  : `${values.CWCLiveImg}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${values.storageareaImg}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t("CWC-Live-Image")}
              // loading="lazy"
            />
            <ImageListItemBar
              title={t("CWC-Live-Image")}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc7(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <ImageList
          sx={{ width: "80%", height: "15%", marginLeft: "11%" }}
          cols={1}
          gap={8}
        >
          <ImageListItem
            key={
              PreviewCancelledCheueueImage
                ? PreviewCancelledCheueueImage
                : CancelledCheueueImage
            }
          >
            <img
              // src={`${CancelledCheueueImage}?w=248&fit=crop&auto=format`}
              src={
                PreviewCancelledCheueueImage
                  ? PreviewCancelledCheueueImage
                  : `${CancelledCheueueImage}?w=248&fit=crop&auto=format&dpr=2 2x`
              }
              // srcSet={`${CancelledCheueueImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
              alt={t("Cancelled-Cheque")}
              // loading="lazy"
            />
            <ImageListItemBar
              title={t("Cancelled-Cheque")}
              subtitle={
                <input
                  type="file"
                  disabled={showEdit ? false : true}
                  // hidden
                  onChange={(evt) => handleDoc3(evt)}
                />
              }
              position="below"
            />
          </ImageListItem>
        </ImageList>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() =>
              PreviewCancelledCheueueImage ? UpdateBankData() : UpdateData()
            }
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CWCUploadedDocs;
