export const WeatherCard = ({ temperature, wind, humidity,weatherCondition }) => (
    <div className="card-dashboard">
      <div className="card-dashboard-icon">
        <i className="fas fa-sun"></i>
      </div>
      <div className="card-dashboard-content">
        <h3>Weather</h3>
        <p>Temperature: {temperature}°C</p>
        <p>Wind: {wind} mph</p>
        <p>Humidity: {humidity}%</p>
        <p>Weather Condition: {weatherCondition}</p>
      </div>
    </div>
  );