import {
    GET_BROWSING_HISTORY_REQUEST,
    GET_BROWSING_HISTORY_FAIL,
    GET_BROWSING_HISTORY_SUCCESS,
  } from "../constants/BrowsingHistoryConstants";



// Browsing History LIST
////////////////////////////////////////////////////////////////////////

export const BrowsingHistoryListReducer = (
    state = { browse_history: [] },
    action
  ) => {
    switch (action.type) {
      case GET_BROWSING_HISTORY_REQUEST:
        return { browse_his_loading: true, browse_history: [] };
      case GET_BROWSING_HISTORY_SUCCESS:
        return { browse_his_loading: false, browse_history: action.payload };
      case GET_BROWSING_HISTORY_FAIL:
        return { browse_his_loading: false, browse_his_error: action.payload };
      default:
        return state;
    }
  };
  
  /////////////////////////////////////////////////