import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  FEATURED_PRODUCT_LIST_REQUEST,
  FEATURED_PRODUCT_LIST_SUCCESS,
  FEATURED_PRODUCT_LIST_FAIL,
  FEATURED_PRODUCT_DETAILS_REQUEST,
  FEATURED_PRODUCT_DETAILS_SUCCESS,
  FEATURED_PRODUCT_DETAILS_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  FILTERED_CATEGORY_LIST_REQUEST,
  FILTERED_CATEGORY_LIST_SUCCESS,
  FILTERED_CATEGORY_LIST_FAIL,
  INNOVATIVE_CATEGORY_LIST_REQUEST,
  INNOVATIVE_CATEGORY_LIST_SUCCESS,
  INNOVATIVE_CATEGORY_LIST_FAIL,
  FILTERED_QUOTATION_CATEGORY_LIST_REQUEST,
  FILTERED_QUOTATION_CATEGORY_LIST_SUCCESS,
  FILTERED_QUOTATION_CATEGORY_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_LIST_FAIL,
  QUOTATION_BRAND_LIST_REQUEST,
  QUOTATION_BRAND_LIST_SUCCESS,
  QUOTATION_BRAND_LIST_FAIL,
  SEARCH_BRAND_LIST_REQUEST,
  SEARCH_BRAND_LIST_SUCCESS,
  SEARCH_BRAND_LIST_FAIL,
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_LIST_FAIL,
  SUBSUBCATEGORY_LIST_REQUEST,
  SUBSUBCATEGORY_LIST_SUCCESS,
  SUBSUBCATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  FILTERING_CATEGORIES_LIST_REQUEST,
  FILTERING_CATEGORIES_LIST_SUCCESS,
  FILTERING_CATEGORIES_LIST_FAIL,
  FILTERING_QUOTATION_CATEGORIES_LIST_REQUEST,
  FILTERING_QUOTATION_CATEGORIES_LIST_SUCCESS,
  FILTERING_QUOTATION_CATEGORIES_LIST_FAIL,
  FILTERING_SEARCH_CATEGORIES_LIST_REQUEST,
  FILTERING_SEARCH_CATEGORIES_LIST_SUCCESS,
  FILTERING_SEARCH_CATEGORIES_LIST_FAIL,
  FARM_PRODUCT_LIST_REQUEST,
  FARM_PRODUCT_LIST_SUCCESS,
  FARM_PRODUCT_LIST_FAIL,
  FARM_PRODUCT_DETAILS_REQUEST,
  FARM_PRODUCT_DETAILS_SUCCESS,
  FARM_PRODUCT_DETAILS_FAIL,
  QUOTATION_PRODUCT_DETAILS_REQUEST,
  QUOTATION_PRODUCT_DETAILS_SUCCESS,
  QUOTATION_PRODUCT_DETAILS_FAIL,
  FARM_PRODUCT_NAME_REQUEST,
  FARM_PRODUCT_NAME_SUCCESS,
  FARM_PRODUCT_NAME_FAIL,
  FILTERED_FARMPRODUCT_LIST_REQUEST,
  FILTERED_FARMPRODUCT_LIST_SUCCESS,
  FILTERED_FARMPRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_VARIATIONS_SUCCESS,
  PRODUCT_DETAILS_VARIATIONS_REQUEST,
  PRODUCT_DETAILS_VARIATIONS_FAIL,
  PRODUCT_SEARCH_LIST_SUCCESS,
  PRODUCT_SEARCH_LIST_REQUEST,
  PRODUCT_SEARCH_LIST_FAIL,
  FARMSUBCATEGORY_LIST_REQUEST,
  FARMSUBCATEGORY_LIST_SUCCESS,
  FARMSUBCATEGORY_LIST_FAIL,
  HOME_PAGE_PRODUCT_LIST_REQUEST,
  HOME_PAGE_PRODUCT_LIST_SUCCESS,
  HOME_PAGE_PRODUCT_LIST_FAIL,
  REVIEW_LIST_REQUEST,
  REVIEW_LIST_SUCCESS,
  REVIEW_LIST_FAIL,
  QNA_LIST_REQUEST,
  QNA_LIST_SUCCESS,
  QNA_LIST_FAIL,
} from "../constants/productConstants";

export const HomePageProductListReducer = (
  state = { HomePageProducts: [] },
  action
) => {
  switch (action.type) {
    case HOME_PAGE_PRODUCT_LIST_REQUEST:
      return { loading: true, HomePageProducts: [] };
    case HOME_PAGE_PRODUCT_LIST_SUCCESS:
      return { loading: false, HomePageProducts: action.payload };
    case HOME_PAGE_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//FEATURED PRODUCTS REDUCER
/////////////////////////////////////////////////////////////

export const FeaturedProductListReducer = (
  state = { featuredproducts: [] },
  action
) => {
  switch (action.type) {
    case FEATURED_PRODUCT_LIST_REQUEST:
      return { Featuredloading: true, featuredproducts: [] };
    case FEATURED_PRODUCT_LIST_SUCCESS:
      return { Featuredloading: false, featuredproducts: action.payload };
    case FEATURED_PRODUCT_LIST_FAIL:
      return { Featuredloading: false, Featurederror: action.payload };
    default:
      return state;
  }
};

export const FeaturedProductDetailsReducer = (
  state = { featuredproduct: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case FEATURED_PRODUCT_DETAILS_REQUEST:
      return { Featuredloading: true, ...state };
    case FEATURED_PRODUCT_DETAILS_SUCCESS:
      return { Featuredloading: false, featuredproduct: action.payload };
    case FEATURED_PRODUCT_DETAILS_FAIL:
      return { Featuredloading: false, Featurederror: action.payload };
    default:
      return state;
  }
};

// PRODUCT CATEGORY
////////////////////////////////////////////////////////////////////////

export const productCategoryListReducer = (
  state = { productsCategory: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_LIST_REQUEST:
      return { loading: true, productsCategory: [] };
    case PRODUCT_CATEGORY_LIST_SUCCESS:
      return { loading: false, productsCategory: action.payload };
    case PRODUCT_CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

//Filtered CATEGORY
////////////////////////////////////////////////////////////////////////

export const FilteredCategoryListReducer = (
  state = { FilteredCategory: [] },
  action
) => {
  switch (action.type) {
    case FILTERED_CATEGORY_LIST_REQUEST:
      return { Loading: true, FilteredCategory: [] };
    case FILTERED_CATEGORY_LIST_SUCCESS:
      return {
        Loading: false,
        FilteredCategory: action.payload,
        FilteredCategory_Max_Price: action.maxPrice,
        FilteredCategory_Total_Count: action.totalCount,
      };
    case FILTERED_CATEGORY_LIST_FAIL:
      return { Loading: false, Error: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

// Innovative Category List Product /////////

export const InnovativeCategoryListReducer = (
  state = { InnovativeCategory: [] },
  action
) => {
  switch (action.type) {
    case INNOVATIVE_CATEGORY_LIST_REQUEST:
      return { Loading: true, InnovativeCategory: [] };
    case INNOVATIVE_CATEGORY_LIST_SUCCESS:
      return {
        Loading: false,
        InnovativeCategory: action.payload,
        InnovativeCategory_Max_Price: action.maxPrice,
        InnovativeCategory_Total_Count: action.totalCount,
      };
    case INNOVATIVE_CATEGORY_LIST_FAIL:
      return { ILoading: false, IError: action.payload };
    default:
      return state;
  }
};


//////////////////////////////////////////////////

//Filtered CATEGORY for quotation product
////////////////////////////////////////////////////////////////////////

export const FilteredQuotationCategoryListReducer = (
  state = { FilteredQuotationCategory: [] },
  action
) => {
  switch (action.type) {
    case FILTERED_QUOTATION_CATEGORY_LIST_REQUEST:
      return { QuotationLoading: true, FilteredQuotationCategory: [] };
    case FILTERED_QUOTATION_CATEGORY_LIST_SUCCESS:
      return {
        QuotationLoading: false,
        FilteredQuotationCategory: action.payload,
        // FilteredQuotationCategory_Max_Price: action.maxPrice,
      };
    case FILTERED_QUOTATION_CATEGORY_LIST_FAIL:
      return { QuotationLoading: false, QuotationError: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

//Brand Reducer for quotation product filtering list (by deovrat)
////////////////////////////////////////////////////////////////////////

export const QuotationBrandListReducer = (
  state = { QuotationBrandList: [] },
  action
) => {
  switch (action.type) {
    case QUOTATION_BRAND_LIST_REQUEST:
      return { QuotationBrandLoading: true, QuotationBrandList: [] };
    case QUOTATION_BRAND_LIST_SUCCESS:
      return {
        QuotationBrandLoading: false,
        QuotationBrandList: action.payload,
      };
    case QUOTATION_BRAND_LIST_FAIL:
      return {
        QuotationBrandLoading: false,
        QuotationBrandError: action.payload,
      };
    default:
      return state;
  }
};

//Brand Reducer for ffiltering list (by deovrat)
////////////////////////////////////////////////////////////////////////

export const BrandListReducer = (state = { BrandList: [] }, action) => {
  switch (action.type) {
    case BRAND_LIST_REQUEST:
      return { BrandLoading: true, BrandList: [] };
    case BRAND_LIST_SUCCESS:
      return { BrandLoading: false, BrandList: action.payload };
    case BRAND_LIST_FAIL:
      return { BrandLoading: false, BrandError: action.payload };
    default:
      return state;
  }
};

//Searchbar Brand Reducer for ffiltering list (by deovrat)
////////////////////////////////////////////////////////////////////////

export const SearchBrandListReducer = (
  state = { SearchBrandList: [] },
  action
) => {
  switch (action.type) {
    case SEARCH_BRAND_LIST_REQUEST:
      return { SearchBrandLoading: true, SearchBrandList: [] };
    case SEARCH_BRAND_LIST_SUCCESS:
      return { SearchBrandLoading: false, SearchBrandList: action.payload };
    case SEARCH_BRAND_LIST_FAIL:
      return { SearchBrandLoading: false, SearchBrandError: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

//Subcategory Reducer for filtering list
////////////////////////////////////////////////////////////////////////

export const SubcategoryListReducer = (state = { Subcategory: [] }, action) => {
  switch (action.type) {
    case SUBCATEGORY_LIST_REQUEST:
      return { SubcategoryLoading: true, Subcategory: [] };
    case SUBCATEGORY_LIST_SUCCESS:
      return { SubcategoryLoading: false, Subcategory: action.payload };
    case SUBCATEGORY_LIST_FAIL:
      return {
        FarmSubcategoryLoading: false,
        FarmSubcategoryError: action.payload,
      };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

//SubSubcategory Reducer for filtering list
////////////////////////////////////////////////////////////////////////

export const SubSubcategoryListReducer = (
  state = { SubSubcategory: [] },
  action
) => {
  switch (action.type) {
    case SUBSUBCATEGORY_LIST_REQUEST:
      return { SubSubcategoryLoading: true, SubSubcategory: [] };
    case SUBSUBCATEGORY_LIST_SUCCESS:
      return { SubSubcategoryLoading: false, SubSubcategory: action.payload };
    case SUBSUBCATEGORY_LIST_FAIL:
      return {
        SubSubcategoryLoading: false,
        SubSubcategoryError: action.payload,
      };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

//Category Reducer for filtering list
////////////////////////////////////////////////////////////////////////

export const CategoryListReducer = (state = { Category: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { CategoryLoading: true, Category: [] };
    case CATEGORY_LIST_SUCCESS:
      return { CategoryLoading: false, Category: action.payload };
    case CATEGORY_LIST_FAIL:
      return { CategoryLoading: false, CategoryError: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

// //SubsubSubcategory Reducer for filtering list
// ////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////

//Farm Product Reducer
////////////////////////////////////////////////////////////////////////

export const FarmProductListReducer = (state = { FarmProduct: [] }, action) => {
  switch (action.type) {
    case FARM_PRODUCT_LIST_REQUEST:
      return { FarmProductLoading: true, FarmProduct: [] };
    case FARM_PRODUCT_LIST_SUCCESS:
      return { FarmProductLoading: false, FarmProduct: action.payload };
    case FARM_PRODUCT_LIST_FAIL:
      return { FarmProductLoading: false, FarmProductError: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

//Quotation Product Details Reducer
/////////////////////////////////////////////

export const QuotationProductDetailsReducer = (
  state = { QuotationProductDetail: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case QUOTATION_PRODUCT_DETAILS_REQUEST:
      return { QuotationProductDetailLoading: true, ...state };
    case QUOTATION_PRODUCT_DETAILS_SUCCESS:
      return {
        QuotationProductDetailLoading: false,
        QuotationProductDetail: action.payload,
      };
    case QUOTATION_PRODUCT_DETAILS_FAIL:
      return {
        QuotationProductDetailLoading: false,
        QuotationProductDetailError: action.payload,
      };
    default:
      return state;
  }
};

//////////////////////////////////////////////

//Farm Product Details Reducer
/////////////////////////////////////////////

export const FarmProductDetailsReducer = (
  state = { FarmProductDetail: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case FARM_PRODUCT_DETAILS_REQUEST:
      return { FarmProductDetailLoading: true, ...state };
    case FARM_PRODUCT_DETAILS_SUCCESS:
      return {
        FarmProductDetailLoading: false,
        FarmProductDetail: action.payload,
      };
    case FARM_PRODUCT_DETAILS_FAIL:
      return {
        FarmProductDetailLoading: false,
        FarmProductDetailError: action.payload,
      };
    default:
      return state;
  }
};

//////////////////////////////////////////////

//Farm Product Details Reducer for filter list
/////////////////////////////////////////////

export const FarmProductNameReducer = (
  state = { FarmProductName: [] },
  action
) => {
  switch (action.type) {
    case FARM_PRODUCT_NAME_REQUEST:
      return { FarmProductNameLoading: true, ...state };
    case FARM_PRODUCT_NAME_SUCCESS:
      return { FarmProductNameLoading: false, FarmProductName: action.payload };
    case FARM_PRODUCT_NAME_FAIL:
      return {
        FarmProductNameLoading: false,
        FarmProductNameError: action.payload,
      };
    default:
      return state;
  }
};

//////////////////////////////////////////////

//Filtered CATEGORY
////////////////////////////////////////////////////////////////////////

export const FilteredFarmProductListReducer = (
  state = { FilteredFarmProduct: [] },
  action
) => {
  switch (action.type) {
    case FILTERED_FARMPRODUCT_LIST_REQUEST:
      return { FilteredFarmProductLoading: true, FilteredFarmProduct: [] };
    case FILTERED_FARMPRODUCT_LIST_SUCCESS:
      return {
        FilteredFarmProductLoading: false,
        FilteredFarmProduct: action.payload,
        FilteredFarmProduct_Max_Price: action.maxPrice,
        FilteredFarmProduct_Total_Count: action.totalCount,
      };
    case FILTERED_FARMPRODUCT_LIST_FAIL:
      return {
        FilteredFarmProductLoading: false,
        FilteredFarmProductError: action.payload,
      };
    default:
      return state;
  }
};

/////////////////////////////////////////////////////

export const productDetailsWithVariationsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_VARIATIONS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_DETAILS_VARIATIONS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_VARIATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productSearchListReducer = (
  state = { Searchproducts: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_SEARCH_LIST_REQUEST:
      return { SearchproductsLoading: true, Searchproducts: [] };
    case PRODUCT_SEARCH_LIST_SUCCESS:
      return {
        SearchproductsLoading: false,
        Searchproducts: action.payload,
        FilteredCategory_Max_Price: action.maxPrice,
        FilteredCategory_Total_Count: action.totalCount
      };
    case PRODUCT_SEARCH_LIST_FAIL:
      return {
        SearchproductsLoading: false,
        SearchproductsError: action.payload,
      };
    default:
      return state;
  }
};

//Filtering Categoties Reducer for the categorie subcategories and so on
////////////////////////////////////////////////////////////////////////

export const FilteringCategoriesListReducer = (
  state = { FilteringCategories: [] },
  action
) => {
  switch (action.type) {
    case FILTERING_CATEGORIES_LIST_REQUEST:
      return { FilteringCategoriesLoading: true, FilteringCategories: [] };
    case FILTERING_CATEGORIES_LIST_SUCCESS:
      return {
        FilteringCategoriesLoading: false,
        FilteringCategories: action.payload,
      };
    case FILTERING_CATEGORIES_LIST_FAIL:
      return {
        FilteringCategoriesLoading: false,
        FilteringCategoriesError: action.payload,
      };
    default:
      return state;
  }
};

//Filterin Quotation CategoRies Reducer for the categorieS subcategories and so on
////////////////////////////////////////////////////////////////////////

export const FilteringQuotationCategoriesListReducer = (
  state = { FilteringQuotationCategories: [] },
  action
) => {
  switch (action.type) {
    case FILTERING_QUOTATION_CATEGORIES_LIST_REQUEST:
      return {
        FilteringQuotationCategoriesLoading: true,
        FilteringQuotationCategories: [],
      };
    case FILTERING_QUOTATION_CATEGORIES_LIST_SUCCESS:
      return {
        FilteringQuotationCategoriesLoading: false,
        FilteringQuotationCategories: action.payload,
      };
    case FILTERING_QUOTATION_CATEGORIES_LIST_FAIL:
      return {
        FilteringQuotationCategoriesLoading: false,
        FilteringQuotationCategoriesError: action.payload,
      };
    default:
      return state;
  }
};

//Filtering Search Categoties Reducer for the categorie subcategories and so on
////////////////////////////////////////////////////////////////////////

export const FilteringSearchCategoriesListReducer = (
  state = { FilteringSearchCategories: [] },
  action
) => {
  switch (action.type) {
    case FILTERING_SEARCH_CATEGORIES_LIST_REQUEST:
      return {
        FilteringSearchCategoriesLoading: true,
        FilteringSearchCategories: [],
      };
    case FILTERING_SEARCH_CATEGORIES_LIST_SUCCESS:
      return {
        FilteringSearchCategoriesLoading: false,
        FilteringSearchCategories: action.payload,
      };
    case FILTERING_SEARCH_CATEGORIES_LIST_FAIL:
      return {
        FilteringSearchCategoriesLoading: false,
        FilteringSearchCategoriesError: action.payload,
      };
    default:
      return state;
  }
};

////////////////////////////////////////////////////////
//For Farm Produce

export const FarmProduceSubcategoryListReducer = (
  state = { FarmSubcategory: [] },
  action
) => {
  switch (action.type) {
    case FARMSUBCATEGORY_LIST_REQUEST:
      return { FarmSubcategoryLoading: true, FarmSubcategory: [] };
    case FARMSUBCATEGORY_LIST_SUCCESS:
      return { FarmSubcategoryLoading: false, FarmSubcategory: action.payload };
    case FARMSUBCATEGORY_LIST_FAIL:
      return { SubcategoryLoading: false, SubcategoryError: action.payload };
    default:
      return state;
  }
};
/////////////////////////////////////////////////////////////////////////

// REVIEW LIST
////////////////////////////////////////////////////////////////////////

export const ReviewListReducer = (
  state = { reviews: [] },
  action
) => {
  switch (action.type) {
    case REVIEW_LIST_REQUEST:
      return { review_loading: true, reviews: [] };
    case REVIEW_LIST_SUCCESS:
      return { review_loading: false, reviews: action.payload };
    case REVIEW_LIST_FAIL:
      return { review_loading: false, review_error: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////

// QNA LIST
////////////////////////////////////////////////////////////////////////

export const QNAListReducer = (
  state = { qnas: [] },
  action
) => {
  switch (action.type) {
    case QNA_LIST_REQUEST:
      return { qna_loading: true, qnas: [] };
    case QNA_LIST_SUCCESS:
      return { qna_loading: false, qnas: action.payload };
    case QNA_LIST_FAIL:
      return { qna_loading: false, qna_error: action.payload };
    default:
      return state;
  }
};

/////////////////////////////////////////////////