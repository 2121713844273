import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import {
  MediumButtonStyle,
  SmallButtonStyle,
} from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
export default class LogisticsReg3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // noofdriver:"",
      // noofvehicles:"",
      Owned_Self_Vehicles: "",
      Associate_Vehicles: "",
      No_of_MGV: "",
      No_of_LMV: "",
      No_of_HMV: "",
      No_of_HGMV: "",
      No_of_HTV: "",
      Load_Unload_Facility: "",
      Small_Package_Facility: "",
    };
  }

  lodingunloadlist = [
    { title: "At Branch" },
    { title: "Door Step" },
    { title: "Both of the Above" },
  ];

  smallpackageslist = [{ title: "Yes" }, { title: "No" }];

  flatProps = {
    options: this.lodingunloadlist.map((option) => option.title),
    options: this.smallpackageslist.map((option) => option.title),
  };

  defaultProps1 = {
    options: this.lodingunloadlist,
    getOptionLabel: (option) => option.title,
  };

  defaultProps2 = {
    options: this.smallpackageslist,
    getOptionLabel: (option) => option.title,
  };

  saveandContinue = async (e) => {
    this.props.nextStep();
  };

  render() {
    const {
      error,
    } = this.state;
    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 390,
                    height: 810,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Create Account
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Fill the form to create a new logistics account.
                  </Form.Label>

                  <Form.Group
                    controlId="noofdriver"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="numeric"
                      placeholder="Number of Drivers"
                      defaultValue={this.props.values.No_of_Driver}
                      onChange={(e) =>
                        this.props.handleChange("No_of_Driver", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="noofvehicles"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Number of Vehicles"
                      defaultValue={this.props.values.No_of_Vehicles}
                      onChange={(e) =>
                        this.props.handleChange(
                          "No_of_Vehicles",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="Owned_Self_Vehicles"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="Owned_Self_Vehicles"
                      placeholder="Vehicles owned by self (in number)"
                      defaultValue={this.props.values.Owned_Self_Vehicles}
                      onChange={(e) =>
                        this.props.handleChange(
                          "Owned_Self_Vehicles",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="Associate_Vehicles"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="Associate_Vehicles"
                      placeholder="Vehicles owned by Associate (in number)"
                      defaultValue={this.props.values.Associate_Vehicles}
                      onChange={(e) =>
                        this.props.handleChange(
                          "Associate_Vehicles",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="No_of_MGV"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="No_of_MGV"
                      placeholder="Number of MGV (Medium goods vehicle)"
                      defaultValue={this.props.values.No_of_MGV}
                      onChange={(e) =>
                        this.props.handleChange("No_of_MGV", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="No_of_LMV"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="No_of_LMV"
                      placeholder="Number of LMV (Light Motor Vehicles)"
                      defaultValue={this.props.values.No_of_LMV}
                      onChange={(e) =>
                        this.props.handleChange("No_of_LMV", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="No_of_HMV"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="No_of_HMV"
                      placeholder="Number of HMV (Heavy Motor Vehicles)"
                      defaultValue={this.props.values.No_of_HMV}
                      onChange={(e) =>
                        this.props.handleChange("No_of_HMV", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="No_of_HGMV"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="No_of_HGMV"
                      placeholder="Number of HGMV (Heavy Goods Motor Vehicles)"
                      defaultValue={this.props.values.No_of_HGMV}
                      onChange={(e) =>
                        this.props.handleChange("No_of_HGMV", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="No_of_HTV"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="No_of_HTV"
                      placeholder="Number of HTV (Heavy Transport Vehicle)"
                      defaultValue={this.props.values.No_of_HTV}
                      onChange={(e) =>
                        this.props.handleChange("No_of_HTV", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Autocomplete
                    style={{ marginTop: -20, marginBottom: -5 }}
                    {...this.defaultProps1}
                    id="Load_Unload_Facility"
                    onInputChange={(e, v) =>
                      this.props.handleChange("Load_Unload_Facility", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facilities of Loading/Unloading"
                        margin="normal"
                      />
                    )}
                  />

                  <Autocomplete
                    style={{ marginTop: -20, marginBottom: -5 }}
                    {...this.defaultProps2}
                    id="Small_Package_Facility"
                    onInputChange={(e, v) =>
                      this.props.handleChange("Small_Package_Facility", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Do you provide facilities for small packages"
                        margin="normal"
                      />
                    )}
                  />

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={this.saveandContinue}
                      type="submit"
                      style={MediumButtonStyle}
                    >
                      Save & Preview{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      type="submit"
                      onClick={this.props.prevStep}
                      style={SmallButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
