// MainForm.jsx
import React, { Component } from "react";
import LogisticsReg from "./LogisticsReg";
import LogisticsReg2 from "./LogisticsReg2";
import LogisticsReg3 from "./LogisticsReg3";
import LogisticsReg4 from "./LogisticsReg4";
import BankDetail from "../BankDetail";
import BankDetail2 from "../BankDetailCWC";
import CompanyBranch from "../CompanyBranch";
import CompanyBranch2 from "../CompanyBranch2";
import Partner from "../Partner";
import { connect } from "react-redux";
import Partner2 from "../Partner2";
import {
  API_URL_LOGISTICS,
  API_URL_BANKDETAILS,
  API_URL_PARTNERS,
  API_URL_COMPANYBRANCH,
  API_URL_LOGISTICSBYUSERID,
  API_URL_COMPANYBRANCHBYUSERID,
  API_URL_BANKDETAILSBYUSERID,
  API_URL_PARTNERBYUSERID,
} from "../Constants";

import axios from "axios";
import Loader from "../../../components/Loader";
import { withRouter } from "../../../components/Router/withRouter";
class MainLogistics extends Component {
  constructor(props) {
    super(props);

    const { navigate } = this.props;
    const { user } = this.props.user;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    const configAxios = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
    };

    this.state = {
      step: 1,
      id: 1,
      CRegNo: "",
      CName: "",
      PANNo: "",
      GSTIN: "",
      IBARegNo: "",
      EstablishmentYrs: null,
      error: null,
      MaterialMovRoute: "",
      Full_Load_Freight_Rate: null,
      Small_Package_Freight_Rate: null,
      MaterialMovFreq: "",
      DeliverInsured: "",
      RouteMap: null,
      Owned_Self_Vehicles: null,
      Associate_Vehicles: null,
      No_of_Driver: null,
      No_of_Vehicles: null,
      No_of_MGV: null,
      No_of_LMV: null,
      No_of_HMV: null,
      No_of_HGMV: null,
      No_of_HTV: null,
      Load_Unload_Facility: "",
      Small_Package_Facility: "",

      // Banks values-----------
      accountHolderName: "",
      bankName: "",
      accountType: "",
      accountNumber: "",
      branch: "",
      ifscCode: "",
      micr: "",
      city: "",
      BankPostal: "",
      BankPostalErr: '',

      // storagespace: "",
      CanceledChequeImage: null,
      VendorID: null,
      CompanyBranchID: null,

      //Company Branchhh ---------
      inchargeFname: "",
      inchargeLname: "",
      inchargeDesignation: "",
      companyBranch_address1: "",
      companyBranch_address2: "",
      companyBranch_district: "",
      companyBranch_state: "",
      companyBranch_country: "",
      companyBranch_postalcode: "",
      contactnumber: "",
      email: "",
      // inchargeUID: "",
      logisticID: "",

      //  Partner---------
      fName: "",
      lName: "",
      designation: "",
      phone: "",
      altPhoneNo: "",
      partner_address1: "",
      partner_address2: "",
      partner_country: "",
      partner_state: "",
      partner_district: "",
      partner_postalCode: "",
      panNo: "",

      // states
      userID: user.id,
      config: config,
      configAxios: configAxios,
      navigate: navigate,
      role: user.role,
      setLoading: true,
    };
  }

  componentDidMount = () => {
    this.retrieve_Logistics();
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (input, value) => {
    this.setState({ [input]: value });
  };

  handleDoc = (CanceledChequeImage, evt) => {
    try {
      this.setState({ CanceledChequeImage: evt.target.files[0] });
    } catch {
      //console.log("error");
    }
  };

  handleDoc5 = async (evt) => {
    //console.log("j,bjfbjfb");
    try {
      this.setState({ CanceledChequeImage: evt.target.files[0] });
      //console.log("j,bjfbjfb", this.state.CanceledChequeImage);
    } catch (error) {
      //console.log(error);
    }
  };

  uploadData = async () => {

    let form_data = new FormData();

    form_data.append("CRegNo", this.state.CRegNo);
    form_data.append("CName", this.state.CName);
    form_data.append("PANNo", this.state.PANNo);
    form_data.append("GSTIN", this.state.GSTIN);
    form_data.append("IBARegNo", this.state.IBARegNo);
    if (this.state.EstablishmentYrs) {
      form_data.append("EstablishmentYrs", this.state.EstablishmentYrs);
    }
    if (this.state.No_of_Driver) {
      form_data.append("No_of_Driver", this.state.No_of_Driver);
    }
    if (this.state.No_of_Vehicles) {
      form_data.append("No_of_Vehicles", this.state.No_of_Vehicles);
    }
    if (this.state.Owned_Self_Vehicles) {
      form_data.append("Owned_Self_Vehicles", this.state.Owned_Self_Vehicles);
    }
    if (this.state.Associate_Vehicles) {
      form_data.append("Associate_Vehicles", this.state.Associate_Vehicles);
    }
    if (this.state.No_of_MGV) {
      form_data.append("No_of_MGV", this.state.No_of_MGV);
    }
    if (this.state.No_of_LMV) {
      form_data.append("No_of_LMV", this.state.No_of_LMV);
    }
    if (this.state.No_of_HMV) {
      form_data.append("No_of_HMV", this.state.No_of_HMV);
    }
    if (this.state.No_of_HGMV) {
      form_data.append("No_of_HGMV", this.state.No_of_HGMV);
    }
    if (this.state.No_of_HTV) {
      form_data.append("No_of_HTV", this.state.No_of_HTV);
    }
    if (this.state.MaterialMovRoute) {
      form_data.append("MaterialMovRoute", this.state.MaterialMovRoute);
    }

    if (this.state.MaterialMovFreq) {
      form_data.append("MaterialMovFreq", this.state.MaterialMovFreq);
    }
    form_data.append("Load_Unload_Facility", this.state.Load_Unload_Facility);
    form_data.append(
      "Small_Package_Facility",
      this.state.Small_Package_Facility
    );
    if (this.state.Full_Load_Freight_Rate) {
      form_data.append(
        "Full_Load_Freight_Rate",
        this.state.Full_Load_Freight_Rate
      );
    }
    if (this.state.Small_Package_Freight_Rate) {
      form_data.append(
        "Small_Package_Freight_Rate",
        this.state.Small_Package_Freight_Rate
      );
    }
    form_data.append("DeliverInsured", this.state.DeliverInsured);
    form_data.append("Routemap_Text", this.state.RouteMap);


    let response = [];

    await axios
      .post(API_URL_LOGISTICS, form_data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false, logisticID: res.data.id }); //res.data.id
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        response = error;
        //console.log(response);
      });

    return response;
  };

  postBankDetails = async () => {

    let form_data = new FormData();
    let Cheque = this.state.CanceledChequeImage;
    // console.log("cheque---->: " ,this.state.CanceledChequeImage);

    if (Cheque && Cheque !== null) {
      form_data.append("CanceledChequeImage", Cheque, Cheque.name);
    }

    form_data.append("bankName", this.state.bankName);
    form_data.append("branch", this.state.branch);
    form_data.append("city", this.state.city);
    if (this.state.BankPostal) {
      form_data.append("postalCode", parseInt(this.state.BankPostal));
    }
    if (this.state.accountNumber) {
      form_data.append("accountNumber", parseInt(this.state.accountNumber));
    }
    form_data.append("accountType", this.state.accountType);
    form_data.append("ifscCode", this.state.ifscCode);
    form_data.append("micr", this.state.micr);
    form_data.append("accountHolderName", this.state.accountHolderName);
    if (this.state.CompanyBranchID) {
      form_data.append("CompanyBranchID", this.state.CompanyBranchID); //this.state.CompanyBranchID);
    }
    let response = [];

    await axios
      .post(API_URL_BANKDETAILS, form_data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false });
        response = res;
        //console.log("response", response);
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        response = error;
      });

    return response;
  };

  postBranchData = async () => {
    //console.log("branch data uploading..");
    let country_field = this.state.companyBranch_country;
    let state_field = this.state.companyBranch_state;
    // let district_obj =
    let district_field = this.state.companyBranch_district;

    let data = {
      //"inchargeUID": null,
      inchargeFname: this.state.inchargeFname,
      inchargeLname: this.state.inchargeLname,
      inchargeDesignation: this.state.inchargeDesignation,
      address1: this.state.companyBranch_address1,
      address2: this.state.companyBranch_address2,
      district: district_field,
      state: state_field,
      country: country_field,
      postalcode: parseInt(this.state.companyBranch_postalcode),
      contactnumber: parseInt(this.state.contactnumber),
      email: this.state.email,
      logisticID: this.state.logisticID,
      // "userID": this.state.userID
    };

    let response = [];

    await axios
      .post(API_URL_COMPANYBRANCH, data, this.state.configAxios)
      .then((res) => {
        this.setState({ setLoading: false, CompanyBranchID: res.data.id });
        response = res;
        //console.log(response);
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        response = error;
      });

    return response;
  };

  uploadDataPartner = async () => {
 
    let form_data = new FormData();

    form_data.append("fName", this.state.fName);
    form_data.append("lName", this.state.lName);
    form_data.append("designation", this.state.designation);
    if (this.state.phone) {
      form_data.append("phone", this.state.phone);
    }
    if (this.state.altPhoneNo) {
      form_data.append("altPhoneNo", this.state.altPhoneNo);
    }
    form_data.append("address1", this.state.partner_address1);
    // form_data.append("", this.state._numdriv);
    // form_data.append("", this.state._numvehicle);
    form_data.append("address2", this.state.partner_address2);
    form_data.append("country", this.state.partner_country);
    form_data.append("state", this.state.partner_state);
    form_data.append("district", this.state.partner_district);
    if (this.state.partner_postalCode) {
      form_data.append("postalCode", this.state.partner_postalCode);
    }
    form_data.append("panNo", this.state.panNo);
    if (this.state.logisticID) {
      form_data.append("LogisticID", this.state.logisticID);
    }
    let response = [];

    await axios
      .post(API_URL_PARTNERS, form_data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false });
        response = res;
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        response = error;
      });

    return response;
  };

  retrieve_Logistics = async () => {
    try {
      axios
        .get(`${API_URL_LOGISTICSBYUSERID}`, this.state.configAxios)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].status) {
              this.retrieve_CompanyBranch();
              this.setState({
                step: 5,
                setLoading: true,
                logisticID: res.data[0].id,
              });
            } else {
              this.setState({
                logisticID: res.data[0].id,
                CRegNo: res.data[0].CRegNo,
                CName: res.data[0].CName,
                EstablishmentYrs: res.data[0].EstablishmentYrs,
                PANNo: res.data[0].PANNo,
                GSTIN: res.data[0].GSTIN,
                IBARegNo: res.data[0].IBARegNo,
                No_of_Driver: res.data[0].No_of_Driver,
                No_of_Vehicles: res.data[0].No_of_Vehicles,
                Owned_Self_Vehicles: res.data[0].Owned_Self_Vehicles,
                Associate_Vehicles: res.data[0].Associate_Vehicles,
                No_of_MGV: res.data[0].No_of_MGV,
                No_of_LMV: res.data[0].No_of_LMV,
                No_of_HMV: res.data[0].No_of_HMV,
                No_of_HGMV: res.data[0].No_of_HGMV,
                No_of_HTV: res.data[0].No_of_HTV,
                Load_Unload_Facility: res.data[0].Load_Unload_Facility,
                Small_Package_Facility: res.data[0].Small_Package_Facility,
                Full_Load_Freight_Rate: res.data[0].Full_Load_Freight_Rate,
                Small_Package_Freight_Rate:
                  res.data[0].Small_Package_Freight_Rate,
                MaterialMovRoute: res.data[0].MaterialMovRoute,
                MaterialMovFreq: res.data[0].MaterialMovFreq,
                DeliverInsured: res.data[0].DeliverInsured,
                RouteMap: res.data[0].RouteMap,
                routemap_Text: res.data[0].Routemap_Text,
                userID: res.data[0].userID,

                setLoading: false,
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  retrieve_CompanyBranch = async () => {
    try {
      axios
        .get(`${API_URL_COMPANYBRANCHBYUSERID}`, this.state.configAxios)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].status) {
              //console.log("Completed the task");
              //console.log(res.status);
              this.retrieve_BankDetails();
              this.setState({
                step: 7,
                setLoading: true,
                logisticID: res.data[0].logisticID,
                CompanyBranchID: res.data[0].id,
              });
            } else {
              this.setState({
                CompanyBranchID: res.data[0].id,
                inchargeFname: res.data[0].inchargeFname,
                inchargeLname: res.data[0].inchargeLname,
                inchargeDesignation: res.data[0].inchargeDesignation,
                companyBranch_address1: res.data[0].address1,
                companyBranch_address2: res.data[0].address2,
                companyBranch_district: res.data[0].district,
                companyBranch_state: res.data[0].state,
                companyBranch_country: res.data[0].country,
                companyBranch_postalcode: res.data[0].postalcode,
                contactnumber: res.data[0].contactnumber,
                email: res.data[0].email,
                logisticID: this.state.logisticID,

                setLoading: false,
                // userID: 131
                // userID: res.data[0].userID
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  retrieve_BankDetails = async () => {
    try {
      axios
        .get(`${API_URL_BANKDETAILSBYUSERID}`, this.state.configAxios)
        .then((res) => {
          //console.log(res.data);
          if (res.data.length > 0) {
            if (res.data[0].status) {
              this.retrieve_Partner();
              this.setState({
                BankDetailsID: res.data[0].id,
                step: 9,
                setLoading: true,
                CompanyBranchID: res.data[0].CompanyBranchID,
              });
              // this.retrieve_Partner()
            } else {
              this.setState({
                // BankDetailsID : res.data[0].id,
                bankName: res.data[0].bankName,
                branch: res.data[0].branch,
                city: res.data[0].city,
                BankPostal: res.data[0].postalCode,
                accountType: res.data[0].accountType,
                accountNumber: res.data[0].accountNumber,
                ifscCode: res.data[0].ifscCode,
                micr: res.data[0].micr,
                // CanceledChequeImage: CanceledChequeImage,
                accountHolderName: res.data[0].accountHolderName,
                CompanyBranchID: this.state.CompanyBranchID,

                setLoading: false,
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log("ERROR..!!!");
      alert(error.response);
    }
  };

  retrieve_Partner = async () => {

    try {
      axios
        .get(`${API_URL_PARTNERBYUSERID}`, this.state.configAxios)
        .then((res) => {
          //console.log(res.data);
          if (res.data.length > 0) {
            if (res.data[0].status) {
              // this.retrieve_Partner();
              this.setState({
                setLoading: true,
                partnerID: res.data[0].id,
                logisticID: res.data[0].LogisticID,
              });

              this.state.navigate("/");
              // this.retrieve_Partner()
            } else {
              this.setState({
                partnerID: res.data[0].id,
                fName: res.data[0].fName,
                lName: res.data[0].lName,
                designation: res.data[0].designation,
                phone: res.data[0].phone,
                altPhoneNo: res.data[0].altPhoneNo,
                partner_address1: res.data[0].address1,
                partner_address2: res.data[0].address2,
                partner_state: res.data[0].state,
                partner_district: res.data[0].district,
                partner_country: res.data[0].country,
                partner_postalCode: res.data[0].postalCode,
                panNo: res.data[0].panNo,

                setLoading: false,
                logisticID: this.state.logisticID,
                // userID: userID
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log("ERROR..!!!");
      alert(error.response);
    }
  };

  render() {
    const { navigate } = this.state;
    const { step } = this.state;
    const {
      error,
      CRegNo,
      CName,
      PANNo,
      GSTIN,
      IBARegNo,
      EstablishmentYrs,
      MaterialMovRoute,
      Full_Load_Freight_Rate,
      Small_Package_Freight_Rate,
      MaterialMovFreq,
      DeliverInsured,
      RouteMap,
      Owned_Self_Vehicles,
      Associate_Vehicles,
      No_of_Driver,
      No_of_Vehicles,
      No_of_MGV,
      No_of_LMV,
      No_of_HMV,
      No_of_HGMV,
      No_of_HTV,
      Load_Unload_Facility,
      Small_Package_Facility,
      accountHolderName,
      bankName,
      accountType,
      accountNumber,
      branch,
      ifscCode,
      micr,
      city,
      BankPostal,
      BankPostalErr,
      storagespace,
      CanceledChequeImage,
      VendorID,
      CWCID,
      CompanyBranchID,
      inchargeFname,
      inchargeLname,
      inchargeDesignation,
      companyBranch_address1,
      companyBranch_address2,
      companyBranch_district,
      companyBranch_state,
      companyBranch_country,
      companyBranch_postalcode,
      contactnumber,
      email,
      logisticID,
      fName,
      lName,
      designation,
      phone,
      altPhoneNo,
      panNo,
      partner_address1,
      partner_address2,
      partner_country,
      partner_district,
      partner_state,
      partner_postalCode,
    } = this.state;
    const values = {
      error,
      CRegNo,
      CName,
      PANNo,
      GSTIN,
      IBARegNo,
      EstablishmentYrs,
      MaterialMovRoute,
      Full_Load_Freight_Rate,
      Small_Package_Freight_Rate,
      MaterialMovFreq,
      DeliverInsured,
      RouteMap,
      Owned_Self_Vehicles,
      Associate_Vehicles,
      No_of_Driver,
      No_of_Vehicles,
      No_of_MGV,
      No_of_LMV,
      No_of_HMV,
      No_of_HGMV,
      No_of_HTV,
      Load_Unload_Facility,
      Small_Package_Facility,
      accountHolderName,
      bankName,
      accountType,
      accountNumber,
      branch,
      ifscCode,
      micr,
      city,
      BankPostal,
      BankPostalErr,
      storagespace,
      CanceledChequeImage,
      VendorID,
      CWCID,
      CompanyBranchID,
      inchargeFname,
      inchargeLname,
      inchargeDesignation,
      companyBranch_address1,
      companyBranch_address2,
      companyBranch_district,
      companyBranch_state,
      companyBranch_country,
      companyBranch_postalcode,
      contactnumber,
      email,
      logisticID,
      fName,
      lName,
      designation,
      phone,
      altPhoneNo,
      partner_address1,
      partner_address2,
      partner_country,
      partner_district,
      partner_state,
      partner_postalCode,
      panNo,
    };
    switch (step) {
      case 1:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <LogisticsReg
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
          />
        );
      case 2:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <LogisticsReg2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
          />
        );
      case 3:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <LogisticsReg3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
          />
        );
      case 4:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <LogisticsReg4
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            uploadData={this.uploadData}
            retrieve_CompanyBranch={this.retrieve_CompanyBranch}
          />
        );
      case 5:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <CompanyBranch
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            postBranchData={this.postBranchData}
            //uploadData= {this.uploadCompanyDetails}
          />
        );
      case 6:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <CompanyBranch2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            postBranchData={this.postBranchData}
            retrieve_BankDetails={this.retrieve_BankDetails}
          />
        );
      case 7:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <BankDetail
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            handleDoc5={this.handleDoc5}
            postBankDetails={this.postBankDetails}
          />
        );
      case 8:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <BankDetail2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            postBankDetails={this.postBankDetails}
            // role={this.role}
            retrieve_Partner={this.retrieve_Partner}
            // navigate={this.navigate}
          />
        );
      case 9:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <Partner
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            //uploadData= {this.uploadPartnersDetails}
          />
        );
      case 10:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <Partner2
            //nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            uploadDataPartner={this.uploadDataPartner}
            navigate={navigate}
          />
        );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default connect(mapStateToProps)(MainLogistics);

// export default MainLogistics;
