export const BASE_URL_HOSTED = "http://127.0.0.1:8000/";
export const FRONTEND_URL = "http://localhost:3000/";
export const LOGIN_API_URL = `${BASE_URL_HOSTED}login/`;
export const PROFILE_API_URL = `${BASE_URL_HOSTED}profile/`;
export const PROFILE_UPDATE_API_URL = `${BASE_URL_HOSTED}profile/update/`;
export const PRODUCTS_API_URL = `${BASE_URL_HOSTED}api/products/`;
export const ORDER_API_URL = `${BASE_URL_HOSTED}api/orders/`;
export const MYORDER_API_URL = `${BASE_URL_HOSTED}api/myorders/`;
export const ML_MODEL_URL = "https://prod-v1---mlmodel-jkl2gczn2a-uc.a.run.app/";

const max_qty = 5

export const qty_validation = (inStockQty) => {

  if (inStockQty < max_qty) {
    return inStockQty
  } else {
    return max_qty
  }
}