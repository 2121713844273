import {
  GET_WISHLIST_ITEM_REQUEST,
  GET_WISHLIST_ITEM_SUCCESS,
  GET_WISHLIST_ITEM_FAIL,
} from "../constants/WishlistConstants";

import { BASE_URL_HOSTED } from "../constants/api";

import axios from "axios";
export const PushToWishlist = (
  pdt_id,
  pdt_name,
  image,
  price,
  countInStock,
  pdt_type,
  qty,
  user_id,
  variation_arr
) => {
  //  console.log(id);
  //  console.log(status)
  const uploadData = new FormData();
  uploadData.append("pdt_id", pdt_id);
  uploadData.append("pdt_name", pdt_name);
  uploadData.append("image", image);
  uploadData.append("price", price);
  uploadData.append("countInStock", countInStock);
  uploadData.append("pdt_type", pdt_type);
  uploadData.append("quantity", qty);
  uploadData.append("user_id", user_id);
  uploadData.append("pdt_variation", variation_arr);

  fetch(`${BASE_URL_HOSTED}api/PostWishlist/`, {
    method: "POST",
    body: uploadData,
  })
    .then((res) => console.log(""))
    // // alert(`Your request for negotiating in ₹${price} has been sent to the farmer if he accept your offer we will notify you`),

    // // history.push("/vendordashboard/"),
    // )
    .catch((error) => console.log(""));
};

// GET WISHLIST ITEMS
/////////////////////////////////////////////////

export const GetWishlistItems = (id) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: GET_WISHLIST_ITEM_REQUEST });
    const { data } = await axios.get(`${BASE_URL_HOSTED}api/getWishlist/${id}`);
    // console.log("data")
    // console.log(data)

    dispatch({
      type: GET_WISHLIST_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_WISHLIST_ITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Delete Wishlist Items
////////////////////////////////////////////////////////////////

export const RemoveFromWishlist = (id) => (dispatch)=>{

  axios.delete(`${BASE_URL_HOSTED}api/getWishlist/${id}`)
       .then((response)=>{
        dispatch({
          type: GET_WISHLIST_ITEM_SUCCESS,
          payload: response.data.data,
        });
       })
       .catch((error) => console.log(""));
};
