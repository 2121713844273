import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col } from "react-bootstrap";
//////Desktophomecard
import { Card, CardDeck } from "react-bootstrap";
import Card1image from "../assets/AgricultureInputs.png";
import Card2image from "../assets/AgricultureOutputbutton.png";
import Card3image from "../assets/AgricultureServices.png";
import { useNavigate } from "react-router-dom";

export const DesktopHomecard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div style={{display:'flex', justifyContent:'space-around', flexWrap:'wrap'}}>
      <div>
        <img src={Card1image} alt="homecard" onClick={()=>navigate('productcategory')} style={{margin:10, width:425}}/>
      </div>
      <div>
        <img src={Card2image} alt="homecard" style={{margin:10, width:425}}/>
      </div>
      <div>
        <img src={Card3image} alt="homecard" style={{margin:10, width:425}}/>
      </div>
    </div>
    // <CardDeck style={{ marginBottom: "5%" }}>
    //   <Card
    //     style={{
    //       padding: 15,
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       border: 0,
    //     }}
    //   >
    //     <Row>
    //       <Col style={{ padding: 10 }}>
    //         <Card
    //           style={{
    //             width: "clamp(17rem, 22.5vw, 25rem)",
    //             right: "clamp(0rem, 1vw, 5rem)",
    //             border: 0,
    //             boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //           }}
    //         >
    //           <Button
    //             onClick={() =>
    //               navigate("productcategory/Agriculture Input/category")
    //             }
    //             style={{
    //               backgroundColor: "white",
    //               padding: 0,
    //               borderRadius: 20,
    //               border: 0,
    //             }}
    //           >
    //             <Card.Img alt="Card Image1" src={Card1image} />
    //           </Button>
    //           <text style={ImageContent}>{t("Browse-Agriculture-Input")}</text>
    //         </Card>
    //       </Col>

    //       <Col style={{ padding: 10 }}>
    //         <Card
    //           style={{
    //             width: "clamp(17rem, 22.5vw, 25rem)",
    //             height: "222px",
    //             paddingTop: 70,
    //             right: "clamp(0rem, 1vw, 5rem)",
    //             boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //             border: 0,
    //           }}
    //         >
    //           <Button
    //             href="https://www.cropway.com/seller-studio/"
    //             style={{
    //               backgroundColor: "white",
    //               padding: 0,
    //               borderRadius: 20,
    //               border: 0,
    //             }}
    //           >
    //             <Card.Img alt="Card Image3" src={Card3image} />
    //           </Button>
    //           <text style={ImageContent}>{t("Browse-Agriculture-Output")}</text>
    //         </Card>
    //       </Col>

    //       <Col style={{ padding: 10 }}>
    //         <Card
    //           style={{
    //             width: "clamp(17rem, 22.5vw, 25rem)",
    //             right: "clamp(0rem, 1vw, 5rem)",
    //             boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    //             border: 0,
    //           }}
    //         >
    //           <Button
    //             href="https://www.cropway.com/agriculture-services/"
    //             style={{
    //               backgroundColor: "white",
    //               padding: 0,
    //               borderRadius: 20,
    //               border: 0,
    //             }}
    //           >
    //             <Card.Img alt="Card Image4" src={Card4image} />
    //           </Button>
    //           <text style={ImageContent}>
    //             {t("Browse-Agriculture-Services")}
    //           </text>
    //         </Card>
    //       </Col>
    //     </Row>
    //   </Card>
    // </CardDeck>
  );
};
