import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../components/message";
import {MediumButtonStyle, SmallButtonStyle,} from "../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";
import axios from "axios";
import {
  API_URL_STATE,
  API_URL_DISTRICT,
  API_URL_BLOCK,
  API_URL_VILLAGE,
} from "./Constants";

export default class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fName: "",
      lName: "",
      designation: "",
      phone: "",
      altPhoneNo: "",
      address1: "",
      address2: "",
      country: "",
      state: "",
      district: "",
      postalCode: "",
      panNo: "",
      LogisticID: null,
    };
  }

  countrylist = [{ title: "India" }];
  statelist = [{ title: "Chhattisgarh" }];
  districtlist = [{ title: "Raipur" }];
  flatProps = {
    options: this.countrylist.map((option) => option.title),
    options: this.statelist.map((option) => option.title),
    options: this.districtlist.map((option) => option.title),
  };

  defaultProps1 = {
    options: this.countrylist,
    getOptionLabel: (option) => option.title,
  };

  defaultProps2 = {
    options: this.statelist,
    getOptionLabel: (option) => option.title,
  };

  defaultProps3 = {
    options: this.districtlist,
    getOptionLabel: (option) => option.title,
  };

  componentDidUpdate = () => {
    //console.log(this.props.values);
  };

  saveandContinue = async (e) => {
    //  await this.props.uploadData();
    this.props.nextStep();
  };

  state = {
    state: [],
    district: [],
    block: [],
    society: [],
    village: [],
  };

  stateget = async (val) => {
    this.props.handleChange("partner_country", val);

    if (val) {
      await axios
        .get(`${API_URL_STATE}${1}`)
        .then((res) => {
          this.setState({ state: res.data.reverse() });
          //console.log(this.state.state);
        })
        .catch((error) => {
          //console.warn(error);
        });
    }
  };

  distget = async (val) => {
    //console.log("knddd");
    const Value = val.split(",");
    //console.log("inside dist get", Value[1]);

    this.props.handleChange("partner_state", Value[0]);

    var ids = Number(Value[1]);

    axios.get(`${API_URL_DISTRICT}${ids}`).then((result) => {
      let result_sorted = result.data.sort(this.sort_object("district"));
      this.setState({ district: result_sorted });
      // console.log(this.state.district);
    });
    // .catch((err) => {
    //   console.log(err);
    // });
  };

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  blockget = async (val) => {
    //console.log("inside block get");

    const Value = val.split(",");

    this.props.handleChange("partner_district", Value[0]);
    var ids = Number(Value[1]);
    // var idd=id;

    axios
      .get(`${API_URL_BLOCK}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("block"));
        this.setState({ block: result_sorted });
        //  console.log(this.state.district);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  villageget = async (val) => {
    // console.log('inside Village get: '+id);
    const Value = val.split(",");

    this.props.handleChange("block", Value[0]);
    var ids = Number(Value[1]);
    // var idso=id;

    axios
      .get(`${API_URL_VILLAGE}${ids}`)
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("vill_town"));
        this.setState({ village: result_sorted });
        //console.log(this.state.village);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  render() {
    const {
      error
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 850,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Fill Partner Details :
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Fill the below partner details form to create account.
                  </Form.Label>

                  <Form.Group
                    controlId="fName"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="First Name"
                      // value={values.fName}
                      defaultValue={this.props.values.fName}
                      onChange={(e) =>
                        this.props.handleChange("fName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="lName"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Last Name"
                      // value={values.lName}
                      defaultValue={this.props.values.lName}
                      onChange={(e) =>
                        this.props.handleChange("lName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="designation"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="Designation"
                      // value={values.designation}
                      defaultValue={this.props.values.designation}
                      onChange={(e) =>
                        this.props.handleChange("designation", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="phone"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="phone"
                      placeholder="Mobile Number (+91)"
                      // value={values.phone}
                      defaultValue={this.props.values.phone}
                      onChange={(e) =>
                        this.props.handleChange("phone", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="altPhoneNo"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="altPhoneNo"
                      placeholder="Alternate Mobile Number (+91)"
                      // value={values.altPhoneNo}
                      defaultValue={this.props.values.altPhoneNo}
                      onChange={(e) =>
                        this.props.handleChange("altPhoneNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="address1"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="address1"
                      placeholder="Address Line 1"
                      // value={values.partner_address1}
                      defaultValue={this.props.values.partner_address1}
                      onChange={(e) =>
                        this.props.handleChange(
                          "partner_address1",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="address2"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="address2"
                      placeholder="Address Line 2"
                      // value={values.partner_address2}
                      defaultValue={this.props.values.partner_address2}
                      onChange={(e) =>
                        this.props.handleChange(
                          "partner_address2",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  {/* <Autocomplete style={{marginTop:-25, marginBottom:-10}}
                {...this.defaultProps1}
                id="country"
                onInputChange = {(e,v) => this.props.handleChange("partner_country",v) }   
                renderInput={(params) => <TextField {...params} label="Country" margin="normal" />}
   
                   />
             <Autocomplete style={{marginTop:-20, marginBottom:-5, borderBottomColor:"#5DA7CA"}}
                {...this.defaultProps2}
                id="state"
                onInputChange = {(e,v) => this.props.handleChange("partner_state",v) }   
                  renderInput={(params) => <TextField {...params} label="State" margin="normal" />}
   
                   />
            <Autocomplete style={{marginTop:-20, marginBottom:-5, borderBottomColor:"#5DA7CA"}}
                {...this.defaultProps3}
                id="district"
                onInputChange = {(e,v) => this.props.handleChange("partner_district",v) }   
                  renderInput={(params) => <TextField {...params} label="District" margin="normal" />}
   
                   /> */}
                  <Form.Group
                    controlId="Country"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      as="select"
                      placeholder="Country"
                      onChange={(e) => this.stateget(e.target.value)}
                    >
                      <option>Select Country*</option>
                      <option>India</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="State"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      as="select"
                      placeholder="State"
                      onChange={(e) => this.distget(e.target.value)}
                    >
                      <option>Select State*</option>
                      {this.state.state &&
                        this.state.state.map((res) => (
                          <option key={res.id} value={[res.state, res.id]}>
                            {res.state}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="District"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      // type="firmName"
                      as="select"
                      placeholder="District"
                      onChange={(e) => this.blockget(e.target.value)}
                    >
                      <option>Select District*</option>
                      {this.state.district &&
                        this.state.district.map((res) => (
                          <option key={res.id} value={[res.district, res.id]}>
                            {res.district}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="postalCode"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="postalCode"
                      placeholder="Pin Code"
                      // value={values.partner_postalCode}
                      defaultValue={this.props.values.partner_postalCode}
                      onChange={(e) =>
                        this.props.handleChange(
                          "partner_postalCode",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="panNo"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="panNo"
                      placeholder="PAN Number"
                      // value={values.panNo}
                      defaultValue={this.props.values.panNo}
                      onChange={(e) =>
                        this.props.handleChange("panNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Save & Preview{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      type="submit"
                      onClick={this.props.prevStep}
                      style={SmallButtonStyle}
                    >
                      Back{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
