import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_RESET,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  PASSWORD_RESET_CONFIRM_REQUEST,
} from "../constants/userLoginConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};

    default:
      return state;
  }
};

export const userProfileReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return { ...state, loading: true };

    case USER_PROFILE_SUCCESS:
      return { loading: false, user: action.payload };

    case USER_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const updateUserProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};

    default:
      return state;
  }
};

export function accessToken(state) {
  if (state.access) {
      return  state.access.token
  }
}
  
export function refreshToken(state) {
  if (state.refresh) {
      return  state.refresh.token
  }
}
  
export function isAccessTokenExpired(state) {
if (state.access && state.access.exp) {
  return 1000 * state.access.exp - (new Date()).getTime() < 5000
}
return true
}
export function isRefreshTokenExpired(state) {
if (state.refresh && state.refresh.exp) {
  return 1000 * state.refresh.exp - (new Date()).getTime() < 50000
}
return true
}
export function isAuthenticated(state) {
return !isRefreshTokenExpired(state)
}
export function errors(state) {
 return  state.errors
}

export const password_reset_reducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return { loading: true };

    case PASSWORD_RESET_SUCCESS:
      return { loading: false, success: action.payload };

    case PASSWORD_RESET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const password_reset_confirm_reducer = (state = {}, action) => {
  switch (action.type) {

    case PASSWORD_RESET_CONFIRM_REQUEST:
      return { loading: true };

      case PASSWORD_RESET_CONFIRM_SUCCESS:
        return{ loading: false, success: action.payload};

      case PASSWORD_RESET_CONFIRM_FAIL:
        return{
          loading: false, error: action.payload
        }

    default:
      return state;
  }
};


