const discountRate=(MRP, sellingPrice)=>{
    if(Boolean(MRP) && sellingPrice){
        let offPrice = (MRP - sellingPrice).toFixed(2)
        let offPercent = Math.floor((offPrice/MRP) * 100)
        return offPercent ? {offPercent: offPercent, offPrice:offPrice} : {offPercent: null, offPrice: null};
    }
    else{
        return {offPercent : null, offPrice: null};
    }
}

const totalCartAmount=(cartProduct, deliveryCharge)=>{
    let totalSellingAmount = 0
    let totalMRP = 0
    cartProduct.forEach(element => {
        totalMRP+= Number(element?.product_variation?.stock?.mrp) * element?.quantity
        totalSellingAmount+= element?.product_variation?.stock.selling_price * element?.quantity
    });

    if(deliveryCharge){
        totalSellingAmount = totalSellingAmount + deliveryCharge
    }
    return {totalMRP: totalMRP.toFixed(2), totalSellingAmount: totalSellingAmount.toFixed(2)}
}

const exportedObject=({
    discountRate,
    totalCartAmount
})
export default exportedObject