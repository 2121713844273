import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import productService from "../services/product.service";

export default function ButtonAppBar({child, setChild}) {
  const navigate = useNavigate()
  const [hover2, setHover2] = useState();
  const [subcategory, setSubcategory] = useState()

  useEffect(() => {
    !subcategory && productService.getCategory('subcategory', 8)
                  .then((res)=>{
                    setSubcategory(res.data)
                  })
                  .catch((error)=>{
                    console.log(error)
                  })
  }, [child]);

  return (
      <div onMouseLeave={()=>setChild()} style={{transition:'opacity 0.5s ease, visibility 0.5s ease', opacity:child?1:0, position:'absolute', width:'100%', zIndex:1, visibility:child? 'visible' :'hidden'}}>
        <ul style={{width:'100%', height:70, display:'flex', flexDirection:'row', justifyContent:'space-around', background:'#62ABC9', margin:0}}>
          {
            subcategory?.map((res, key)=>(
              <li key={key} style={{alignItems:'center', display:'flex', cursor:'pointer'}}
                onMouseEnter={()=>setHover2(key)}
                onMouseLeave={()=>setHover2()}
                onClick={()=>(navigate('/productcategory', {state:{filter:{lable:'subcategory', value:res.id}}}), setChild())}
              >
                <text style={{color:hover2==key?'#fff':'black', borderRadius:5, padding:10, background:hover2==key?'black':'none'}}>
                  {res?.name}
                </text>
              </li>
            ))
          }
        </ul>
      </div>
  );
}
