import {
  GET_WISHLIST_ITEM_REQUEST,
  GET_WISHLIST_ITEM_SUCCESS,
  GET_WISHLIST_ITEM_FAIL,
} from "../constants/WishlistConstants";

export const WishlistItemsList = (state = { WishlistItems: [] }, action) => {
  switch (action.type) {
    case GET_WISHLIST_ITEM_REQUEST:
      return { WishlistItemsLoading: true, WishlistItems: [] };
    case GET_WISHLIST_ITEM_SUCCESS:
      return { WishlistItemsLoading: false, WishlistItems: action.payload };
    case GET_WISHLIST_ITEM_FAIL:
      return { WishlistItemsLoading: false, WishlistItemsError: action.payload };
    default:
      return state;
  }
};