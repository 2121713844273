import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Rating from "./Rating";
import Wishlist from "../HeaderImages/Wishlist.png";
import Cart from "../HeaderImages/Cart.png";
import { PushToCart } from "../actions/cartAction";
import { PushToWishlist } from "../actions/wishlistAction";
import { useSelector } from "react-redux";

const Product = ({ product, history }) => {
  const {user} = useSelector((state) => state.auth);
  // const location = useLocation();
  // const history = useHistory();

  const addToWishlistHandler = (post) => {
    if (post.pdt_type === "farmproduct") {
      if (user) {
        PushToWishlist(
          post._id,
          post.name,
          post.image,
          post.price_max,
          post.quantity,
          post.pdt_type,
          1,
          user.id
        );
        history.push(`/wishlist/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    } else {
      if (user) {
        PushToWishlist(
          post._id,
          post.name,
          post.image,
          post.price,
          post.countInStock,
          post.post_type,
          1,
          user.id
        );
        history.push(`/wishlist/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    }
  };

  const addToCartHandler = (post) => {
    //     let obj = { name: "mohan" };
    if (post.pdt_type === "farmproduct") {
      // (match.params.id, pdt.name, pdt.image, pdt.price_max, pdt.quantity, pdt.pdt_type, qty, user.id)
      if (user) {
        PushToCart(
          post._id,
          post.name,
          post.image,
          post.price_max,
          post.quantity,
          post.pdt_type,
          1,
          user.id
        );
        history.push(`/cart/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    } else {
      if (user) {
        PushToCart(
          post._id,
          post.name,
          post.image,
          post.price,
          post.countInStock,
          post.post_type,
          1,
          user.id
        );

        history.push(`/cart/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    }
  };

  return (
    <Card
      classsName="my-3 p-3 rounded"
      //style={{ width: "10rem", height: "23rem", border: 0}}

      style={{
        margin: "2%",
        display: "inline-block",
        // width: "20%",
        maxWidth:170,
        // minWidth: 170,
        maxHeight: "150",
        border: 0,
        textAlign: "left",
      }}
    >
      <Link to={`/productdetail/${product._id}/${product.pdt_type}`}>
        <Card.Img alt={product.name} src={product.image} width="200" height="200" />
      </Link>

      <Card.Body
        style={{
          paddingTop: "1.25rem!important",
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Link to={`/productdetail/${product._id}/${product.pdt_type}`}>
          <Card.Title
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: 16,
              textAlign: "left",
            }}
          >
            {product.name}
          </Card.Title>
        </Link>

        <Card.Text
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: 16,
            textAlign: "left",
            margin: 0,
          }}
        >
          By {product.brand}
        </Card.Text>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Card.Text as="div">
            <div className="my-3">
              <Rating
                value={product.rating}
                text={`${product.numReviews} reviews`}
                color={"#f8e825"}
                path={product._id}
              />
            </div>
          </Card.Text>
          <div>
            <img
              className="d-block"
              onClick={() => addToCartHandler(product)}
              src={Cart}
              alt="Cart Icon"
              style={{ marginTop: 20, marginRight: 15 }}
            />
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Card.Text as="h5" style={{ fontSize: 20, textAlign: "left" }}>
                {  
                  product.sellingPrice ?                             //if
                    <>
                        <Card.Text as="h5" style={{ fontSize: 12, textAlign: "left" }}>
                        <strike>MRP:₹{Number(product.price)}</strike>
                        {/* <strong style={{ fontSize:12, color:'green',whiteSpace:'nowrap'}}> {offPercent}% off</strong> */}
                        </Card.Text>
                        ₹{ Number(product.sellingPrice) }
                    </>
                  : product.price                                   //else
                      ? <> ₹ {Number(product.price)} </>      //if
                      : <>Coming soon</>                   //else
                
                }
                
              </Card.Text>
          <div>
            <img
              className="d-block"
              onClick={() => addToWishlistHandler(product)}
              src={Wishlist}
              alt="Los Angeles"
              style={{ marginTop: 1, marginRight: 15 }}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Product;
