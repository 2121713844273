import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Stack from "@mui/material/Stack";
import { Typography } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import TestTubeGIF from "../assets/test-tube.gif";
import MicroscopeGIF from "../assets/microscope.gif";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PhoneIcon from "../assets/Phone.svg";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { editButtonStyle } from "../components/ButtonStyle";
import VectorIcon from "../assets/Vector.svg";
import axios from "axios";
import { API_URL_SOIL_TEST_LAB_DETAILS } from "../screens/Registers/Constants";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loader from "../components/Loader";

function SoilTestResult({ history }) {
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [LabDetails, setLabDetails] = useState([]);

  useEffect(()=>{
    let data = {
      market: location?.state?.market,
    };
    setLoading(true)
    axios
      .post(`${API_URL_SOIL_TEST_LAB_DETAILS}`, data)
      .then((result) => {
        setLabDetails(result.data["SoilTestingLabDetail"]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [location]);
  
  const CustomStyle ={
    LoadingStyle: {
      pointerEvents: 'none',
      opacity: 0.7,
    },
  }
  return (
    <div style = {isLoading ? CustomStyle.LoadingStyle : null }>

       {isLoading ? (
        <view
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: "50%",
            zIndex: 1,
            width: "100%",
            height: "10%",
          }}
        >
          <Loader />
        </view>
      ) : null}

      <section id="section1">
        <Stack
          spacing={2}
          direction="row"
          justifyContent="center"
          style={{ marginTop: 16, marginBottom: 23 }}
        >
          <Typography>
            <span style={{ color: "#000000", fontWeight: "bold" }}>
              Market{" "}
            </span>{" "}
            :{" "}
            <span style={{ color: "#000000", fontWeight: 400 }}>
              {location.state.market}
            </span>
          </Typography>
        </Stack>
      </section>
      <section id="section2" style={{ paddingTop: 3, paddingBottom: 18 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            style={editButtonStyle}
            onClick={() => history.push("/soiltest")}
            endIcon={
              <Avatar sx={{ bgcolor: "#FFFFFF" }}>
                <img height={18} src={VectorIcon} alt=""/>
              </Avatar>
            }
          >
            edit search
          </Button>
        </div>
      </section>

      <section
        id="section3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Grid
          cointainer
          display={"flex"}
          justifyContent={smallSize ? "center" : "flex-start"}
          flexWrap={"wrap"}
          width={1080}
        >
          {LabDetails.map((result, index) => (
            <Grid item key={index}>
              <Box
                sx={{
                  display: "flex",
                  "& > :not(style)": {
                    m: 1,
                    width: 242,
                    height: 360,
                  },
                }}
              >
                <Paper variant="outlined" square>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      padding: 28,
                    }}
                  >
                    <img
                    alt=""
                      height={111}
                      src={
                        result.Lab_Type==="Mini Lab"
                          ? TestTubeGIF
                          : MicroscopeGIF
                      }
                    />
                  </div>

                  <Divider
                    textAlign="center"
                    variant="middle"
                    style={{
                      background: `linear-gradient( #BFE2BA 36.36%,#5DA7CA 98.89%)`,
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      padding: 25,
                    }}
                  >
                    <div>
                      <Typography>
                        <span style={{ color: "#FF0000", fontWeight: "bold" }}>
                          {result.Lab_Name}
                        </span>
                      </Typography>
                      <Typography>Lab Type: {result.Lab_Type}</Typography>
                      <Typography>
                        <img height={15} src={PhoneIcon} alt=""/>{" "}
                        <span style={{ marginLeft: 3 }}>
                          {result.Mobile ? result.Mobile : "Not Available"}
                        </span>
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Box>
            </Grid>
          ))}
        </Grid>
      </section>
      {/* <section id="section4" style={{ padding: 18 }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination count={10} />
        </div>
      </section> */}
    </div>
  );
}

export default SoilTestResult;
