import {
    GET_SEARCH_HISTORY_REQUEST,
    GET_SEARCH_HISTORY_FAIL,
    GET_SEARCH_HISTORY_SUCCESS,
  } from "../constants/SearchHistoryConstants";


// Search History LIST
////////////////////////////////////////////////////////////////////////

export const SearchHistoryListReducer = (
    state = { search_history: [] },
    action
  ) => {
    switch (action.type) {
      case GET_SEARCH_HISTORY_REQUEST:
        return { search_his_loading: true, search_history: [] };
      case GET_SEARCH_HISTORY_SUCCESS:
        return { search_his_loading: false, search_history: action.payload };
      case GET_SEARCH_HISTORY_FAIL:
        return { search_his_loading: false, search_his_error: action.payload };
      default:
        return state;
    }
  };
  
  /////////////////////////////////////////////////