// Data service
import axios from "axios";
import authHeader from "./auth.header";
import { HOST } from "./host.header";
const API_URL = HOST+"/api/marketplace";

const getShippingAddressByUser=()=>{
    return axios.get(`${API_URL}/getaddress`,
      {
        headers : authHeader()
      },
    )
  }

const getDeliveryCharge=(pincode)=>{
    return axios.get(`${API_URL}/getlogisticsrate`,
    {
        headers : authHeader(),
        params: {pincode : Number(pincode)}
    }
    )
}

const addAddressHandler=(data)=>{
    return axios.post(`${API_URL}/addaddress`,data,
    {
      headers : authHeader()
    }
    )
  }


const exportedObject={
    getShippingAddressByUser,
    getDeliveryCharge,
    addAddressHandler
}

export default exportedObject