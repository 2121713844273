// Farmer Page 2
import { Button, Form } from "react-bootstrap";
import { SmallButtonStyle } from "../../../components/ButtonStyle";
import React, { Component } from "react";
import axios from "axios";
import Loader from "../../../components/Loader";
import { API_URL_FARMERREFEREE, API_FARMER_DATA_RET } from "../Constants";

class Farmer extends Component {
  state = {
    farmerdet: "",
  };

  saveAndContinue = async (e) => {
    //console.log("save and continue");
    // e.preventDefault();
    this.Validation();
  };

  back = (e) => {
    //console.log("back");

    e.preventDefault();
    this.props.prevStep();
  };

  Validation = async () => {
    if (
      this.props.values._fathersname == "" ||
      this.props.values._gender == "" ||
      this.props.values._idcardtype == "" ||
      this.props.values._farmeridcard == "" ||
      this.props.values._rakba == "" ||
      this.props.values._rakba == null ||
      this.props.values._rakbaunit == "" ||
      this.props.values._landparcelnumber == "" ||
      this.props.values._address1 == "" ||
      this.props.values._address2 == "" ||
      this.props.values._postal == "" ||
      this.props.values._postal == null
    ) {
      if (this.props.values._fathersname == "") {
        this.props.handleChange(
          "_fathersnameErr",
          "fathers name field can't be empty"
        );
      } else {
        this.props.handleChange("_fathersnameErr", "");
      }

      if (this.props.values._genderErr == "") {
        this.props.handleChange("_genderErr", "gender field can't be empty");
      } else {
        this.props.handleChange("_genderErr", "");
      }

      if (this.props.values._idcardtype == "") {
        this.props.handleChange(
          "_idcardtypeErr",
          "idcard type field can't be empty"
        );
      } else {
        this.props.handleChange("_idcardtypeErr", "");
      }

      if (this.props.values._farmeridcard == "") {
        this.props.handleChange(
          "_farmeridcardErr",
          "farmerid card field can't be empty"
        );
      } else {
        this.props.handleChange("_farmeridcardErr", "");
      }

      if (this.props.values._rakba == "" || this.props.values._rakba == null) {
        this.props.handleChange("_rakbaErr", "rakba field can't be empty");
      } else {
        this.props.handleChange("_rakbaErr", "");
      }

      if (this.props.values._rakbaunit == "") {
        this.props.handleChange(
          "_rakbaunitErr",
          "rakbaunit field can't be empty"
        );
      } else {
        this.props.handleChange("_rakbaunitErr", "");
      }

      if (this.props.values._landparcelnumber == "") {
        this.props.handleChange(
          "_landparcelnumberErr",
          "landparcelnumber field can't be empty"
        );
      } else {
        this.props.handleChange("_landparcelnumberErr", "");
      }

      if (this.props.values._address1 == "") {
        this.props.handleChange(
          "_address1Err",
          "address 1 field can't be empty"
        );
      } else {
        this.props.handleChange("_address1Err", "");
      }

      if (this.props.values._address2 == "") {
        this.props.handleChange(
          "_address2Err",
          "_address 2 field can't be empty"
        );
      } else {
        this.props.handleChange("_address2Err", "");
      }

      if (
        this.props.values._postal == "" ||
        this.props.values._postal == null
      ) {
        this.props.handleChange("_postalErr", "postal field can't be empty");
      } else {
        this.props.handleChange("_postalErr", "");
      }
    } else {
      //clearing all error field

      this.props.values._fathersnameErr = "";
      this.props.values.__genderErr = "";
      this.props.values._idcardtypeErr = "";
      this.props.values._mobileErr = "";
      this.props.values._farmeridcardErr = "";
      this.props.values._rakbaErr = "";
      this.props.values._rakbaunitErr = "";
      this.props.values._landparcelnumberErr = "";
      this.props.values._address1Err = "";
      this.props.values._address2Err = "";
      this.props.values._postalErr = "";

      this.setState({ isLoading: true });
      let response = await this.props.uploadData();
      //console.log(response);
      if (response.status == 201) {
        this.setState({ isLoading: false, toastVisible: true });
        this.props.nextStep();
      } else {
        alert("Please check the connectivity..!!");
      }
    }
  };

  componentDidMount = () => {
    axios
      .get(
        `${API_FARMER_DATA_RET}?state=${this.props.values.state.state}&block=${this.props.values.block.block}&village=${this.props.values.village.village_hin}&fname=${this.props.values._firstname}`
      )
      .then((result) => {
        // console.log(result.data)
        result.data.map((res) => {
          this.props.handleChange("_fathersname", res.fathersName);
          this.props.handleChange("_mobile", res.mobileNo.toString());
          this.props.handleChange("_rakba", res.rakba);
          this.props.handleChange("_rakbaunit", res.rakba_unit);
        });
      })
      .catch((err) => {
        //console.log(err);
      });

    // this.getFarmerReferee()
  };

  sort_object = (prop) => {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  };

  getFarmerReferee = () => {
    this.setState({ isLoading: true });
    axios
      .get(`${API_URL_FARMERREFEREE}`, {
        params: { block: this.props.values.block },
      })
      .then((result) => {
        let result_sorted = result.data.sort(this.sort_object("firstName"));
        this.setState({ farmerReferee: result_sorted }, () => {
          //console.log("console: ", this.state.farmerReferee);
          this.setState({ isLoading: false });
        });
      })
      .catch((error) => {
        console.warn(error);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { values } = this.props;
    return (
      <view style={styles.farmerinfo}>
        {this.state.isLoading && <Loader />}
        <text style={styles.header}>👨🏼‍🌾 Farmer Registration</text>

        <view>
          <Form.Group
            controlId="Father's Name"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Father's Name :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="Father's Name"
              placeholder="Enter Father's Name"
              // value={values._fathersname}
              defaultValue={this.props.values._fathersname}
              onChange={(e) =>
                this.props.handleChange("_fathersname", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._fathersnameErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="Gender"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Gender :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                paddingLeft: 0,
                border: 0,
              }}
              // type="firmName"
              as="select"
              placeholder="Select Gender"
              // value={values.firmName}
              // defaultValue={this.props.values.firmName}
              onChange={(e) =>
                this.props.handleChange("_gender", e.target.value)
              }
            >
              <option>Select *</option>
              <option>Male</option>
              <option>Female</option>
            </Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._genderErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="ID Card Type"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>ID Card Type : </Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                paddingLeft: 0,
                border: 0,
              }}
              // type="firmName"
              as="select"
              placeholder="Select Id Card Type"
              // value={values.firmName}
              // defaultValue={this.props.values.firmName}
              onChange={(e) =>
                this.props.handleChange("_idcardtype", e.target.value)
              }
            >
              <option>Select *</option>
              <option>Adhar Card</option>
              <option>Pan Card</option>
              <option>Voter Id</option>
            </Form.Control>
          </Form.Group>

          {<text style={styles.ERR}>{values._idcardtypeErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="ID card number"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>ID card number :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="text"
              placeholder="Enter ID card number"
              // value={values._farmeridcard}
              defaultValue={this.props.values._farmeridcard}
              onChange={(e) =>
                this.props.handleChange("_farmeridcard", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._farmeridcardErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="_rakba"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Rakba :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="text"
              placeholder="Enter Rakba"
              // value={values._rakba}
              defaultValue={this.props.values._rakba}
              onChange={(e) =>
                this.props.handleChange("_rakba", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._rakbaErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="Unit of Rakba"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Unit of Rakba :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                paddingLeft: 0,
                border: 0,
              }}
              // type="firmName"
              as="select"
              placeholder="Select Rakba Unit"
              // value={values.firmName}
              // defaultValue={this.props.values.firmName}
              onChange={(e) =>
                this.props.handleChange("_rakbaunit", e.target.value)
              }
            >
              <option>Select *</option>
              <option>Acre</option>
              <option>Hectare</option>
            </Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._rakbaunitErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="Land Parcel no."
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Land Parcel no. :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="text"
              placeholder="Enter Land Parcel no."
              // value={values._landparcelnumber}
              defaultValue={this.props.values._landparcelnumber}
              onChange={(e) =>
                this.props.handleChange("_landparcelnumber", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._landparcelnumberErr}</text>}
        </view>

        <view>
          <Form.Group
            controlId="Address 1"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Address 1 :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="text"
              placeholder="Enter Address 1"
              // value={values._address1}
              defaultValue={this.props.values._address1}
              onChange={(e) =>
                this.props.handleChange("_address1", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._address1Err}</text>}
        </view>

        <view>
          <Form.Group
            controlId="Address 2"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Address 2:</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="text"
              placeholder="Enter Address 2"
              // value={values._address2}
              defaultValue={this.props.values._address2}
              onChange={(e) =>
                this.props.handleChange("_address2", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._address2Err}</text>}
        </view>

        <view>
          <Form.Group
            controlId="Postal Code"
            style={{
              borderRadius: 0,
              marginTop: 20,
              border: 0,
              borderBottom: "1px solid #5DA7CA",
              display: "flex",
              direction: "row",
            }}
          >
            <Form.Label style={{ paddingTop: 5 }}>Postal Code :</Form.Label>
            <Form.Control
              style={{
                width: 750,
                borderRadius: 0,
                fontSize: 15,
                boxShadow: "none",
                border: 0,
              }}
              type="numeric"
              placeholder="Enter Postal Code"
              // value={values._postal}
              defaultValue={this.props.values._postal}
              onChange={(e) =>
                this.props.handleChange("_postal", e.target.value)
              }
            ></Form.Control>
          </Form.Group>
          {<text style={styles.ERR}>{values._postalErr}</text>}
        </view>

        <div
          style={{
            marginTop: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            onClick={this.saveAndContinue}
            style={SmallButtonStyle}
          >
            Submit <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
          </Button>
          <div style={{ marginTop: 10 }}></div>
          <Button type="submit" onClick={this.back} style={SmallButtonStyle}>
            Back <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
          </Button>
        </div>
        {/* <view>
              {this.state.toastVisible && (
                <ToastDefined message="Data saved..!!!" />
              )}
            </view> */}
      </view>
    );
  }
}

const styles = {
  farmerinfo: {
    padding: 10,
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
  },

  header: {
    marginTop: 50,
    marginBottom: 10,
    textAlign: "center",
    fontSize: 24,
    color: "#000",
    width: 250,
  },
  textinput: {
    alignSelf: "center",
    height: 45,
    color: "#000",
    backgroundColor: "transparent",
  },

  itemborder: {
    width: "100%",
    borderBottomWidth: 2,
    borderBottomColor: "#bfe2ba",
  },

  pickerStyle: {
    height: 60,
    width: "50%",
    color: "#4D4D4D",
    alignSelf: "center",
  },

  background2: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 20,
    height: 40,
  },

  ERR: {
    color: "red",
    marginTop: -5,
    marginBottom: 8,
  },
};

export default Farmer;
