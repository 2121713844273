// MainForm.jsx
import React, { Component } from "react";
import VendorReg from "./VendorReg";
import VendorReg2 from "./VendorReg2";
import VendorReg3 from "./VendorReg3";
import BankDetail from "../BankDetail";
import BankDetail2 from "../BankDetail2";
import axios from "axios";
import {
  API_URL_BANKDETAILS,
  API_URL_BANKDETAILSBYUSERID,
  API_URL_VENDORBYUSERID,
  API_URL_VENDORS,
} from "../Constants";
import { connect } from "react-redux";
import Loader from "../../../components/Loader";
import { withRouter } from "../../../components/Router/withRouter";
class MainVendor extends Component {
  constructor(props) {
    super(props);
    const { navigate } = this.props;
    const { user } = this.props.user;

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    this.state = {
      step: 0,
      navigate: navigate,
      firstName: user.first_name + ' ' + user.last_name,
      firmAddress1: "",
      firmAddress2: "",
      firmName: "",
      firmNameError: false,
      mobileNo: null,
      email: "",
      website: "",
      landlineNo: null,
      businessAddress1: "",
      businessAddress2: "",
      pincode: null,
      state: "",
      country: "",
      district: "",

      startYear: null,
      annualSales: null,
      // productsell: "",
      panNo: "",
      gstNo: "",
      gstImage: null,
      regParicularImage: null,
      haveGst: "",
      ownershipInfo: "",
      natureBusiness: "",
      CWCID: null,
      userID: user.id,
      config: config,
      role: user.role,
      _uploadregpart: "",
      _uploadgst: "",
      preview: "",

      // Banks values
      accountHolderName: "",
      bankName: "",
      accountType: "",
      accountNumber: "",
      branch: "",
      ifscCode: "",
      micr: "",
      city: "",
      BankPostal: null,
      BankPostalErr: '',
      // storagespace: "",
      CanceledChequeImage: null,
      VendorID: null,
      CompanyBranchID: null,

      ///Loaoder
      setLoading: true,
    };
  }

  componentDidMount = () => {
    this.retrieve_Vendor();
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  handleChange = (input, value) => {
    this.setState({ [input]: value });
  };

  handleDoc1 = async (evt) => {
    try {
      this.setState({ _uploadregpart: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc2 = async (evt) => {
    try {
      this.setState({ _uploadgst: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  handleDoc5 = async (evt) => {
    try {
      this.setState({ CanceledChequeImage: evt.target.files[0] });
    } catch (error) {
      //console.log(error);
    }
  };

  retrieve_Vendor = async () => {
    try {
      axios
        .get(`${API_URL_VENDORBYUSERID}`, this.state.config)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].status) {
              //console.log("Completed the task");
              //console.log(res.status);
              this.retrieve_BankDetails();
              this.setState({
                step: 4,
                setLoading: true,
                VendorID: res.data[0].id,
              });
            } else {
              this.setState({
                vendorID: res.data[0].id,
                firstName: res.data[0].firstName,
                // lastName: lastName,
                firmName: res.data[0].firmName,
                mobileNo: res.data[0].mobileNo,
                firmAddress1: res.data[0].firmAddress1,
                firmAddress2: res.data[0].firmAddress2,
                email: res.data[0].email,
                website: res.data[0].website,
                landlineNo: res.data[0].landlineNo,
                businessAddress1: res.data[0].businessAddress1,
                businessAddress2: res.data[0].businessAddress2,
                ownershipInfo: res.data[0].ownershipInfo,
                natureBusiness: res.data[0].natureBusiness,
                startYear: res.data[0].startYear,
                annualSales: res.data[0].annualSales,
                // _NoofProduct: res.data[0].qtyProduct,
                // _website_sell: res.data[0].isOtherWeb1,
                // isOtherWeb1: isOtherWeb1,
                // altMobileNo: altMobileNo,
                panNo: res.data[0].panNo,
                gstNo: res.data[0].gstNo,
                haveGst: res.data[0].haveGST === true ? "True" : "False",
                // _city: villTownWard,
                district: res.data[0].district,
                state: res.data[0].state,
                country: res.data[0].country,
                BankPostal: res.data[0].pincode,

                setLoading: false,
                // password: password,
                ParticularImage: res.data[0].regParicularImage,
                gstImage: res.data[0].gstImage,
                userID: res.data[0].userID,
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  retrieve_BankDetails = async () => {

    try {
      axios
        .get(`${API_URL_BANKDETAILSBYUSERID}`, this.state.config)
        .then((res) => {
          //console.log(res.data);
          if (res.data.length > 0) {
            if (res.data[0].status) {
              this.setState({
                BankDetailsID: res.data[0].id,
                setLoading: true,
                VendorID: res.data[0].VendorID,
              });
              this.state.navigate("/");
              // this.retrieve_Partner()
            } else {
              this.setState({
                // BankDetailsID : res.data[0].id,
                bankName: res.data[0].bankName,
                branch: res.data[0].branch,
                city: res.data[0].city,
                BankPostal: res.data[0].postalCode,
                accountType: res.data[0].accountType,
                accountNumber: res.data[0].accountNumber,
                ifscCode: res.data[0].ifscCode,
                micr: res.data[0].micr,
                // CanceledChequeImage: CanceledChequeImage,
                accountHolderName: res.data[0].accountHolderName,

                setLoading: false,
                // CompanyBranchID: res.data[0].CompanyBranchID,
                VendorID: this.state.VendorID,
                // CWCID: res.data[0].CWCID,
                // userID: res.data[0].userID
                // userID: 131
                // userID: res.data[0].userID
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      alert(error.response);
    }
  };

  postBankDetails = async () => {
 
    let form_data = new FormData();
    let Cheque = this.state.CanceledChequeImage;
    //console.log("cheque---->: " + JSON.stringify(Cheque));

    if (Cheque && Cheque != null) {
      form_data.append("CanceledChequeImage", Cheque, Cheque.name);
    }

    form_data.append("bankName", this.state.bankName);
    form_data.append("branch", this.state.branch);
    form_data.append("city", this.state.city);
    if (this.state.BankPostal && this.state.BankPostal !== null) {
      //console.log("bios", this.state.BankPostal);
      form_data.append("postalCode", parseInt(this.state.BankPostal));
    }
    if (this.state.accountNumber && this.state.accountNumber !== null) {
      form_data.append("accountNumber", parseInt(this.state.accountNumber));
    }
    form_data.append("accountType", this.state.accountType);
    form_data.append("ifscCode", this.state.ifscCode);
    form_data.append("micr", this.state.micr);
    form_data.append("accountHolderName", this.state.accountHolderName);
    //form_data.append("CompanyBranchID", 1);//this.state.CompanyBranchID);
    if (this.state.VendorID) {
      form_data.append("VendorID", this.state.VendorID);
    }
    let response = [];

    await axios
      .post(API_URL_BANKDETAILS, form_data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false });
        response = res;
        //console.log("response", response);
      })
      .catch((error) => {
        this.setState({ setLoading: false });
        response = error;
      });

    return response;
  };

  uploadData = async () => {

    let country_field = this.state.country;
    let state_field = this.state.state;
    let district_field = this.state.district;
    let form_data = new FormData();
    let ParticularImage = this.state._uploadregpart;
    let gstImage = this.state._uploadgst;

    form_data.append("firmName", this.state.firmName);
    form_data.append("firmAddress1", this.state.firmAddress1);
    form_data.append("firmAddress2", this.state.firmAddress2);
    form_data.append("firstName", this.state.firstName);
    if (this.state.mobileNo) {
      form_data.append("mobileNo", this.state.mobileNo);
    }
    form_data.append("email", this.state.email);
    form_data.append("website", this.state.website);
    if (this.state.landlineNo && this.state.landlineNo !== null) {
      //console.log("this.state.landlineNo");
      form_data.append("landlineNo", parseInt(this.state.landlineNo));
    }
    form_data.append("businessAddress1", this.state.businessAddress1);
    form_data.append("businessAddress2", this.state.businessAddress2);
    form_data.append("country", country_field);
    form_data.append("state", state_field);
    form_data.append("district", district_field);
    if (this.state.pincode && this.state.pincode !== null) {
      form_data.append("pincode", this.state.pincode);
    }
    form_data.append("ownershipInfo", this.state.ownershipInfo);
    form_data.append("natureBusiness", this.state.natureBusiness);
    if (this.state.startYear) {
      form_data.append("startYear", this.state.startYear);
    }
    if (this.state.annualSales && this.state.annualSales !== null) {
      form_data.append("annualSales", this.state.annualSales);
    }
 
    if (ParticularImage && ParticularImage!=="") {
      form_data.append(
        "regParicularImage",
        ParticularImage,
        ParticularImage.name
      );
    }

    if (gstImage && gstImage!=="") {
      form_data.append("gstImage", gstImage, gstImage.name);
    }

    form_data.append("panNo", this.state.panNo);

    if (this.state.haveGst && this.state.haveGst !== null) {
      form_data.append("haveGST", this.state.haveGst);
    }
    form_data.append("gstNo", this.state.gstNo);
    let response = -[];

    await axios
      .post(API_URL_VENDORS, form_data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false, VendorID: res.data.id });
        response = res;
      })
      .catch((error) => {
        response = error;
      });

    return response;
  };

  render() {
    const { step, navigate } = this.state;
    const {
      error,
      firstName,
      firmAddress1,
      firmAddress2,
      firmName,
      firmNameError,
      mobileNo,
      email,
      website,
      landlineNo,
      businessAddress1,
      businessAddress2,
      pincode,
      _uploadregpart,
      _uploadgst,
      preview,
      startYear,
      annualSales,
      panNo,
      gstNo,
      gstImage,
      regParicularImage,
      ownershipInfo,
      natureBusiness,
      accountHolderName,
      bankName,
      accountType,
      accountNumber,
      branch,
      ifscCode,
      micr,
      city,
      postalCode,
      storagespace,
      CanceledChequeImage,
      BankPostal,
      BankPostalErr,
      VendorID,
      CWCID,
      CompanyBranchID,
      country,
      district,
      state,
      haveGst,
    } = this.state;
    const values = {
      error,
      firstName,
      firmNameError,
      firmAddress1,
      firmAddress2,
      firmName,
      mobileNo,
      email,
      website,
      landlineNo,
      businessAddress1,
      businessAddress2,
      pincode,
      _uploadregpart,
      _uploadgst,
      preview,
      startYear,
      annualSales,
      panNo,
      gstNo,
      gstImage,
      regParicularImage,
      ownershipInfo,
      natureBusiness,
      accountHolderName,
      bankName,
      accountType,
      accountNumber,
      branch,
      ifscCode,
      micr,
      city,
      postalCode,
      storagespace,
      CanceledChequeImage,
      BankPostal,
      BankPostalErr,
      VendorID,
      CWCID,
      CompanyBranchID,
      country,
      district,
      state,
      haveGst,
    };
    switch (step) {
      // case 0:
      //   return this.state.setLoading == true ? (
      //     <Loader />
      //   ) : this.state.resStatus ? (
      //     <>{this.setState({ step: 1 })}</>
      //   ) : (
      //     <>
      //       <Modal.Dialog>
      //         <Modal.Body>
      //           <h5>Do You want to complete your Profile ?</h5>
      //         </Modal.Body>

      //         <Modal.Footer>
      //           <Button variant="secondary" onClick={() => this.redirect("NO")}>
      //             No
      //           </Button>
      //           <Button variant="primary" onClick={() => this.redirect("YES")}>
      //             Yes
      //           </Button>
      //         </Modal.Footer>
      //       </Modal.Dialog>
      //     </>
      //   );

      case 0:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <VendorReg
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
          />
        );
      case 1:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <VendorReg2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
            handleDoc2={this.handleDoc2}
            handleDoc1={this.handleDoc1}
          />
        );
      case 2:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <VendorReg3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            retrieve_BankDetails={this.retrieve_BankDetails}
            uploadData={this.uploadData}
          />
        );
      case 3:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <BankDetail
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            handleDoc5={this.handleDoc5}
            postBankDetails={this.postBankDetails}
            values={values}

            //uploadData= {this.uploadBankDetails}
          />
        );
      case 4:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <BankDetail2
            // nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            postBankDetails={this.postBankDetails}
            navigate={navigate} //remove when reg fees of vendor is decided
            LastPage= {true} //temporarily change  because the reg fees for vendor not declared yet
          />
        );

      
        // case 6:
        //   return this.state.setLoading == true ? (
        //     <Loader />
        //   ) : (
        //     <RegPayment
        //       //nextStep={this.nextStep}
        //       user={user}
        //       prevStep={this.prevStep}
        //       values={values}
        //       navigate={navigate}
        //       uploadData={this.uploadData}
        //     />
        //   );

    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default withRouter(connect(mapStateToProps)(MainVendor));
