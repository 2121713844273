import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { MediumButtonStyle } from "../components/ButtonStyle";
import CheckoutSteps from "../components/CheckoutSteps";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import orderService from "../services/order.service";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
const tagManagerArgs = {
  gtmId: "GTM-NNVDS54",
};
TagManager.initialize(tagManagerArgs);

function ConfirmationDesktop() {
  const navigate = useNavigate()
  const location = useLocation()
  let orderId =  location?.state?.orderId; 
  const { t } = useTranslation();
  const [order, setOrder] = useState()
  const [loading, setLoading] = useState(false)

  const getOrderDetail=()=>{
    orderService.getOrderDataByOrderId(orderId)
                  .then((res)=>{
                    setOrder(res.data)
                    setLoading(false)
                })
                .catch((error)=>{
                    console.log(error)
                    setLoading(false)
                  })
  }

  const goToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    getOrderDetail()
  }, []);

  const OrderItemDetail=()=>{
    return(
      <div style={{background:'#fff', padding:10, margin:10, borderRadius:5}}>
          {
            order?.order_item?.map((item, index)=>{
              // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(item?.product?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
              let imageURL = (item?.product?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
             return(
              <div key={index} style={{display:'flex',alignItems:'center', margin:10, display:'flex', justifyContent:'space-around'}}>
                <img src={imageURL} style={{ aspectRatio:1, objectFit:'cover', height:80, width:80, margin:10, borderRadius:5}} alt="product"/>
                <text style={{width:'30%', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>
                  {item?.product?.product?.name}
                </text>
                <text>{item?.product_amount} X {item?.quantity}</text>
                <text>{(item?.product_amount * item?.quantity).toFixed(2)}</text>
              </div>
             )
            })
          }
      </div>
    )
  }

  const Shipping=()=>{
    return(
      <div style={{display:'flex'}}>
        <div style={{background:'#fff', padding:10, margin:10, borderRadius:5, width:'50%'}}>
          <div style={{display:'flex', flexDirection:'column' }}>
          <text style={{margin:10}}>
            Shipping Address
          </text>
            <div style={{margin:10, padding:10, display:'flex', flexWrap:'wrap'}}>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.full_name+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.apartment+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.address+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.city+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.pincode+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.state+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.country+','}</text>
              <text style={{margin:2}}>{order?.shipping_detail?.shipping_address?.mobile}</text> 
            </div>
          </div>
        </div>

        <div style={{background:'#fff', padding:10, margin:10, borderRadius:5, width:'50%'}}>
          <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', padding:10, margin:10, }}>
          <text style={{margin:10, padding:10}}>
            Shipping Charge
          </text>
            <text style={{margin:10, padding:10}}>
              {order?.shipping_detail?.shipping_charge}
            </text>
          </div>
        </div>
      </div>
    )
  }

  const TotalAmount=()=>{
    return(
      <div style={{background:'#fff', padding:10, margin:10, borderRadius:5}}>
        <div style={{display:'flex',alignItems:'center', display:'flex', justifyContent:'space-between', padding:10, margin:10}}>
        <text style={{margin:10, padding:10}}>
          Total Amount
        </text>
          <text style={{margin:10, padding:10}}>
            {order?.total_amount}
          </text>
        </div>
      </div>
    )
  }

  return (
    <div>
         <h1 style={{ fontWeight: "bold", color: "black" }}>
           {t("Confirmation")}
         </h1>
         <CheckoutSteps step3 />

         <div style={{background:'rgba(244, 246, 251, 1)', display:'flex', flexDirection:'column', }}>
            <OrderItemDetail/>
            <hr/>
            <Shipping/>
            <hr/>
            <TotalAmount/>
         </div>

         <div style={{display:'flex', justifyContent:'center', margin:10, padding:10}}>
          <Button
           style={{...MediumButtonStyle}}
           onClick={()=>goToHome()}
          >
            Go To Home
          </Button>
         </div>
    </div>
  );
}

export default ConfirmationDesktop;
