import React from "react";
import { Link } from "react-router-dom";
import Wishlist from "../HeaderImages/Wishlist.png";
import Cart from "../HeaderImages/Cart.png";
import { PushToCart } from "../actions/cartAction";
import { PushToWishlist } from "../actions/wishlistAction";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Message from "./message";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles((theme) => ({
  rootMain: {
    maxWidth: 345,
    ["@media (min-width: 320px)"]: {
      width: 160,
    },
    ["@media (min-width: 360px)"]: {
      width: 180,
    },

    ["@media (min-width: 375px)"]: {
      width: 187,
    },
    ["@media (min-width: 411px)"]: {
      width: 205,
    },
    ["@media (min-width: 414px)"]: {
      width: 207,
    },
  },
  root: {
    padding: 0,
    justifyContent: "center",
  },
  media: {
    height: "7.2rem",
    width: "7.2rem",
    marginLeft: "0.6rem",
    // paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  body1: {
    fontSize: "0.5rem",
    paddingLeft: "0.5rem",
  },
}));

const ProductsCategory = ({ posts, loading, history }) => {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();

  const addToWishlistHandler = async (pdt) => {
    var variation_arr = [];
    var VarPrice = [];
    var FinalPrice = pdt.sellingPrice ? pdt.sellingPrice : pdt.price;

    var countInStock = pdt.countInStock;

    if (pdt.varID !== null) {
      variation_arr = Number(pdt.varID);
      pdt.variations.forEach((obj) => {
        if (Number(pdt.varID) === obj.id) {
          VarPrice.push({ ...obj });

          FinalPrice =
            VarPrice[0].sellingPrice !== null
              ? VarPrice[0].sellingPrice
              : VarPrice[0].price;

          countInStock = VarPrice[0].quantity;
        }
      });
    }

    if (pdt.pdt_type === "farmproduct") {
      if (user) {
        PushToWishlist(
          pdt._id,
          pdt.name,
          pdt.image,
          pdt.price_max,
          pdt.quantity, // for count in stock
          pdt.pdt_type,
          pdt.quantity, //for selected quantity
          user.id
        );
        history.push(`/wishlist/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    } else {
      if (user) {
        PushToWishlist(
          pdt._id,
          pdt.name,
          pdt.image,
          FinalPrice,
          countInStock,
          pdt.pdt_type,
          1,
          user.id,
          variation_arr
        );
        history.push(`/wishlist/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    }
  };

  const addToCartHandler = async (pdt) => {
    var variation_arr = [];
    var VarPrice = [];
    var FinalPrice = pdt.sellingPrice ? pdt.sellingPrice : pdt.price;
    var countInStock = pdt.countInStock;

    if (pdt.varID !== null) {
      variation_arr = Number(pdt.varID);
      pdt.variations.forEach((obj) => {
        if (Number(pdt.varID) === obj.id) {
          VarPrice.push({ ...obj });

          FinalPrice =
            VarPrice[0].sellingPrice !== null
              ? VarPrice[0].sellingPrice
              : VarPrice[0].price;

          countInStock = VarPrice[0].quantity;
        }
      });
    }

    // var FinalPrice
    // let obj = { name: "mohan" };

    // if (variation) {
    //   variation_arr = handleVariationData(variation);
    //   pdt.variations.forEach((obj) => {
    //     if (varID === obj.id) {
    //       VarPrice.push({ ...obj });

    //       FinalPrice =
    //         VarPrice[0].sellingPrice !== null
    //           ? VarPrice[0].sellingPrice
    //           : VarPrice[0].price;
    //     }
    //   });
    // }

    if (pdt.pdt_type === "farmproduct") {
      if (user) {
        PushToCart(
          pdt._id,
          pdt.name,
          pdt.image,
          pdt.price_max,
          pdt.quantity, // for count in stock
          pdt.pdt_type,
          pdt.quantity, //for selected quantity
          user.id,
          "",
          "False"
        );
        history.push(`/cart/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    } else {
      if (user) {
        PushToCart(
          pdt._id,
          pdt.name,
          pdt.image,
          FinalPrice,
          countInStock,
          pdt.pdt_type,
          1,
          user.id,
          variation_arr
        );
        history.push(`/cart/`);
      } else {
        alert("Please, Login first..!!!");
        history.push(`/login/`);
      }
    }
  };

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  let offPercent = null; //set offer in percent. 10 = 10% off,
  let offPrice = null;
  let MRP = null;
  let price = null;

  const VarQuanCheck = (pdt) => {
    let Var =
      pdt.variations &&
      pdt.variations.filter((pdtVar) => pdtVar.id === Number(pdt.varID))[0];
    if (Number(Var.quantity) > 0) {
      return false;
    } else {
      return true;
    }
  };

  const setNewOffPrice = (MRPprice, sellPrice) => {
    // console.log(MRPprice)
    // console.log(sellPrice)
    // if company providing MRP and offer in percent
    // offPrice = (MRPprice/100) * offPercent
    // offPrice = offPrice.toFixed(2)
    // newPrice = (MRPprice-offPrice).toFixed(2)

    // if company provinding MRP and Selling price          offpercent = (offPrice/MRPprice) * 100
    offPrice = (MRPprice - sellPrice).toFixed(2);
    // console.log('offPrice',offPrice)
    offPercent = (offPrice / MRPprice) * 100;
    offPercent = Math.round(offPercent);
    // console.log('offPercent',offPercent)
  };

  return (
    <Grid container justify="center" align="center">
      {posts && posts.length > 0 && loading === false ? (
        posts.map((product) => (
          <Card className={classes.rootMain}>
            <Link
              to={`/productdetail/${product._id}/${product.pdt_type}/${
                product.varID === null ||
                product.pdt_type === "farmproduct" ||
                product.pdt_type === "quotaionproduct"
                  ? "novar"
                  : product.varID
              }`}
            >
              <CardMedia
                className={classes.media}
                alt={product.name}
                image={product.image}
                title="Paella dish"
              />
            </Link>

            <CardContent classes={{ root: classes.root }}>
              <Typography
                style={{ fontSize: 12 }}
                classes={{ body1: classes.body1 }}
              >
                {" "}
                {product.varName === null ||
                product.pdt_type === "farmproduct" ||
                product.pdt_type === "quotaionproduct"
                  ? product.name
                  : `${product.name}-${product.varName}`}
              </Typography>
              {product.pdt_type === "farmproduct" ? null : (
                <Typography
                  style={{ fontSize: 10 }}
                  classes={{ body1: classes.body1 }}
                >
                  {t("By-1")} {product.brand} {t("By-2")}
                </Typography>
              )}

              {product.pdt_type === "farmproduct"
                ? null
                : ((MRP = product.price),
                  (price = product.sellingPrice),
                  setNewOffPrice(MRP, price))}

              {
                product.pdt_type === "farmproduct" ? (
                  <> ₹ {Number(product.price_max)}</>
                ) : //else

                product.updated_sellingPrice ? ( //if
                  <>
                    <Typography>
                      {MRP != null ? (
                        <>
                          <strike style={{ fontSize: 13 }}>
                            MRP:₹{Number(product.updated_price)}
                          </strike>
                          <strong
                            style={{
                              fontSize: 12,
                              color: "green",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {" "}
                            {offPercent}% off
                          </strong>
                        </>
                      ) : null}
                    </Typography>
                    ₹{Number(product.updated_sellingPrice)}
                  </>
                ) : product.updated_price ? ( //else
                  <> ₹ {Number(product.updated_price)} </> //if
                ) : (
                  <>{t("Coming-soon")}</>
                ) //else
              }
            </CardContent>
            <CardActions classes={{ root: classes.root }} disableSpacing>
              <Button
                aria-label="add to favorites"
                onClick={() => addToWishlistHandler(product)}
                src={Wishlist}
              >
                <FavoriteIcon />
              </Button>
              <Button
                aria-label="cart"
                onClick={() => addToCartHandler(product)}
                src={Cart}
                disabled={
                  product.pdt_type === "farmproduct"
                    ? product.quantity === 0
                      ? true
                      : false
                    : product.varID === null
                    ? product.countInStock === null
                      ? true
                      : false
                    : VarQuanCheck(product)
                }
              >
                <ShoppingCartOutlinedIcon />
              </Button>
            </CardActions>
          </Card>
        ))
      ) : (
        <div style={{ marginLeft: 30 }}>
          <Message variant="info">{t("No-products-to-show")}</Message>
        </div>
      )}
    </Grid>
  );
};

export default ProductsCategory;
