import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Card, Image, ListGroupItem } from "react-bootstrap";
import {
  listNegotiations,
  listQuotations,
  listQuotationsVendorasCustomer,
  SendQuotationMail,
} from "../actions/DashboardActions";
import { PushToCart } from "../actions/cartAction";
import { BASE_URL_HOSTED } from "../constants/api";

import ReconsiderPopup from "../components/ReconsiderPopup";
import AcceptPopup from "../components/AcceptPopup";

function VendorDashboard({ match, history }) {
  const { t } = useTranslation();

  const {user} = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [AcceptmodalShow, setAcceptModalShow] = useState(false);

  const negotiationlist = useSelector((state) => state.negotiationlist);
  const {
    Negotiations,
  } = negotiationlist;

  const Quotationlist = useSelector((state) => state.Quotationlist);
  const {
    Quotations,
    // NegotiationProduct,
  } = Quotationlist;

  const QuotationVendorasCustomerlist = useSelector(
    (state) => state.QuotationVendorasCustomerlist
  );
  const {
    QuotationsVendorasCustomer,
    // NegotiationProduct,
  } = QuotationVendorasCustomerlist;

  useEffect(()=>{
      dispatch(listNegotiations(user.id, "vendor"));
      dispatch(listQuotations(user.id, "vendor"));
      dispatch(listQuotationsVendorasCustomer(user.id, "customer"));
  },[dispatch, match, user]);

  const addToCartHandler = (
    id,
    pdtname,
    image,
    negprice,
    actualqty,
    pdt_type,
    negqty,
    variation_arr
  ) => {
    //   console.log(id, pdtname, image, negprice, actualqty, pdt_type, negqty )
    PushToCart(
      id,
      pdtname,
      image,
      negprice,
      actualqty,
      pdt_type,
      negqty,
      user.id,
      variation_arr,
      "True"
    ); //sending true for negotiation status for negotiated price so that negotiated price can be uniquely identified
    history.push(`/cart/`);
  };

  const QuotationStatus = (
    id,
    status,
    price,
    quantity,
    name,
    vendor_id,
    customer_id
  ) => {
    //  console.log(id);
    //  console.log(status)
    const uploadData = new FormData();
    uploadData.append("status", status);

    fetch(`${BASE_URL_HOSTED}api/quotationstatus/${id}`, {
      method: "PUT",
      body: uploadData,
    })
      .then(
        dispatch(listQuotations(user.id, "vendor")),
        dispatch(
          SendQuotationMail(
            price,
            quantity,
            null,
            status === "Yes" ? "Accepted" : "Rejected",
            vendor_id,
            customer_id,
            name
          )
        )
        // alert(`Your request for negotiating in ?${price} has been sent to the farmer if he accept your offer we will notify you`),

        // history.push("/vendordashboard/"),
      )
      .catch((error) => console.log(""));
  };

  return (
    <div>
      <h3>{t("Dashboard")}</h3>
      <Row>
        <Col>
          <Col style={{ padding: 10 }}>
            <Card style={{ padding: 50, backgroundColor: "#eefff2" }}>
              <h5>
                <a href="#orderList">{t("Orders")}</a>
              </h5>
              <text>500</text>
            </Card>
          </Col>
          <Col style={{ padding: 10 }}>
            <Card style={{ padding: 50, backgroundColor: "#eefff2" }}>
              <h5>
                <a href="#visitors">{t("dailyvisitors")}</a>
              </h5>
              <text>500</text>
            </Card>
          </Col>
        </Col>
        <Col>
          <Col style={{ padding: 10 }}>
            <Card style={{ padding: 50, backgroundColor: "#eefff2" }}>
              <h5>
                <a href="#products">{t("Products")}</a>
              </h5>
              <text>6</text>
            </Card>
          </Col>
          <Col style={{ padding: 10 }}>
            <Card style={{ padding: 50, backgroundColor: "#eefff2" }}>
              <h5>
                {" "}
                <a href="#todaysales">{t("Todays-Sales")}</a>
              </h5>
              <text>₹ 5000</text>
            </Card>
          </Col>
        </Col>
      </Row>

      <ListGroupItem id="NegotiationList">
        <Col>
          <h5>{t("NegotiationList")}</h5>
          <ul>
            <Card md={10}>
              <li>
                {Negotiations &&
                  Negotiations.map((neg) => (
                    <ListGroupItem key={neg.pdt_id._id}>
                      <h6>{neg.pdt_id.name}</h6>
                      <Row>
                        <Col>
                          <Image
                            width={200}
                            src={neg.pdt_id.image}
                            alt="product image"
                            fluid
                          />
                        </Col>
                        <Col width={30}>
                          <div>
                            {t("OfferedPrice")} {neg.negotiation_price}
                          </div>
                          <div>
                            {t("ActualPrice")} {neg.pdt_id.price_max}
                          </div>
                          <div>
                            {t("Quantity")} {neg.quantity}
                          </div>
                        </Col>

                        <Col width={30}>
                          <div>
                            {t("FarmerId")}: {neg.farmer_id}
                          </div>
                        </Col>

                        <Col width={30}>
                          {neg.OfferAcceptedByFarmer === null ? (
                            <div>{t("Waiting-for-order-confirmaition")}</div>
                          ) : neg.OfferAcceptedByFarmer === "Yes" ? (
                            <Button
                              style={{ alignItems: "center", width: "100%" }}
                              onClick={() =>
                                addToCartHandler(
                                  neg.pdt_id._id,
                                  neg.pdt_id.name,
                                  neg.pdt_id.image,
                                  neg.negotiation_price,
                                  neg.pdt_id.quantity,
                                  neg.pdt_id.pdt_type,
                                  neg.quantity
                                )
                              }
                            >
                              Add to Cart
                            </Button>
                          ) : (
                            "Farmer has rejected your offer"
                          )}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
              </li>
            </Card>
          </ul>
        </Col>
      </ListGroupItem>

      <ListGroupItem id="QuotationList">
        <Col>
          <h5>{"Quotations List"}</h5>
          <ul>
            <Card md={10}>
              <li>
                {Quotations &&
                  Quotations.map((quote) => (
                    <ListGroupItem
                      key={
                        quote.pdt_type === "quotationproduct"
                          ? quote.quotation_pdt_variations.id
                          : quote.pdt_id._id
                      }
                    >
                      <h6>
                        {quote.pdt_variations && quote.pdt_variations
                          ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                          : quote.pdt_type === "quotationproduct"
                          ? quote.quotation_pdt_id.name
                          : quote.pdt_id.name}
                      </h6>
                      <Row>
                        <Col>
                          <Image
                            width={200}
                            src={
                              quote.pdt_type === "quotationproduct"
                                ? quote.quotation_pdt_id.image
                                : quote.pdt_id.image
                            }
                            alt="product image"
                            fluid
                          />
                        </Col>
                        <Col width={30}>
                          {quote.pdt_type === "quotationproduct" &&
                          quote.quotation_pdt_variations.value_category ===
                            "Price" ? (
                            <>
                              <div>
                                {t("MaxPrice")}{" "}
                                {quote.quotation_pdt_variations.max_value}
                              </div>
                              {/* <div>
                          {t("MinPrice")} {quote.pdt_id.quotation_price_min}
                        </div> */}
                              <div>
                                {t("OfferedPrice")} {quote.quotation_price}
                              </div>
                              <div>
                                {t("Quantity")} {quote.quantity}
                              </div>
                            </>
                          ) : quote.pdt_type === "quotationproduct" &&
                            quote.quotation_pdt_variations.value_category ===
                              "Value" ? (
                            <div>{`The customer has raised a quotation for this product with given specifications - ${parseInt(
                              quote.value
                            )} ${quote.quotation_pdt_variations.unit}`}</div>
                          ) : quote.pdt_type === "quotationproduct" &&
                            quote.quotation_pdt_variations.value_category ===
                              "Character Value" ? (
                            <div>{`The customer has raised a quotation for the product name ${quote.value_char}`}</div>
                          ) : (
                            <>
                              <div>
                                {t("MaxPrice")} {quote.pdt_id.price}
                              </div>
                              <div>
                                {t("MinPrice")}{" "}
                                {quote.pdt_id.quotation_price_min}
                              </div>
                              <div>
                                {t("OfferedPrice")} {quote.quotation_price}
                              </div>
                              <div>
                                {t("Quantity")} {quote.quantity}
                              </div>
                            </>
                          )}
                        </Col>
                        <Col width={30}>
                          <div>
                            {t("VendorId")} {quote.vendor_id}
                          </div>
                        </Col>
                        {quote.status === null ? (
                          <Col width={30}>
                            <Row>
                              {(quote.quotation_pdt_variations &&
                                quote.quotation_pdt_variations
                                  .value_category === "Value") ||
                              (quote.quotation_pdt_variations &&
                                quote.quotation_pdt_variations
                                  .value_category === "Character Value") ? (
                                <Button
                                  style={{
                                    marginTop: 6,
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                  onClick={
                                    () => setAcceptModalShow(true)
                                    //   QuotationStatus(quote._id, "Reconsider")
                                  }
                                >
                                  {t("Accept-Offer")}
                                </Button>
                              ) : (
                                <Button
                                  style={{
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                  onClick={() =>
                                    quote.pdt_type === "quotationproduct"
                                      ? QuotationStatus(
                                          quote._id,
                                          "Yes",
                                          quote.quotation_price,
                                          quote.quantity,
                                          quote.quotation_pdt_id.name,
                                          // quote.pdt_variations
                                          //   ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                                          //   : quote.pdt_id.name,
                                          quote.vendor_id,
                                          quote.customer_id
                                        )
                                      : QuotationStatus(
                                          quote._id,
                                          "Yes",
                                          quote.quotation_price,
                                          quote.quantity,
                                          quote.pdt_variations
                                            ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                                            : quote.pdt_id.name,
                                          quote.vendor_id,
                                          quote.customer_id
                                        )
                                  }
                                >
                                  {t("Accept-Offer")}
                                </Button>
                              )}
                            </Row>
                            <>
                              {user && AcceptmodalShow ? (
                                <AcceptPopup
                                  show={AcceptmodalShow}
                                  onHide={() => setAcceptModalShow(false)}
                                  price={quote.quotation_price}
                                  setModalShow={setAcceptModalShow}
                                  quantity={quote.quantity}
                                  id={quote._id}
                                  vendor_id={quote.vendor_id}
                                  customer_id={quote.customer_id}
                                  // pdt_name={
                                  //   quote.pdt_variations
                                  //     ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                                  //     : quote.pdt_id.name
                                  // }
                                />
                              ) : null}
                            </>
                            <Row>
                              <Button
                                style={{
                                  marginTop: 6,
                                  alignItems: "center",
                                  width: "100%",
                                }}
                                onClick={() =>
                                  quote.pdt_type === "quotationproduct"
                                    ? QuotationStatus(
                                        quote._id,
                                        "No",
                                        quote.quotation_price,
                                        quote.quantity,
                                        quote.quotation_pdt_id.name,
                                        // quote.pdt_variations
                                        //   ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                                        //   : quote.pdt_id.name,
                                        quote.vendor_id,
                                        quote.customer_id
                                      )
                                    : QuotationStatus(
                                        quote._id,
                                        "No",
                                        quote.quotation_price,
                                        quote.quantity,
                                        quote.pdt_variations
                                          ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                                          : quote.pdt_id.name,
                                        quote.vendor_id,
                                        quote.customer_id
                                      )
                                }
                              >
                                {t("Decline-Offer")}
                              </Button>
                            </Row>

                            <Row>
                              {(quote.quotation_pdt_variations &&
                                quote.quotation_pdt_variations
                                  .value_category === "Value") ||
                              (quote.quotation_pdt_variations &&
                                quote.quotation_pdt_variations
                                  .value_category ===
                                  "Character Value") ? null : (
                                <Button
                                  style={{
                                    marginTop: 6,
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                  onClick={
                                    () => setModalShow(true)
                                    //   QuotationStatus(quote._id, "Reconsider")
                                  }
                                >
                                  {"Reconsider Offer"}
                                </Button>
                              )}
                            </Row>
                          </Col>
                        ) : quote.status === "Yes" ||
                          quote.status === "Reconsidered" ||
                          quote.status === "QuotationAccepted" ? (
                          <Col>{t("Waiting-for-order-confirmaition")}</Col>
                        ) : (
                          <Col>{t("You-have-rejected-the-offer")}</Col>
                        )}
                      </Row>
                      <>
                        {user &&
                        modalShow &&
                        quote.pdt_type === "commercialproduct" ? (
                          <ReconsiderPopup
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            price={quote.quotation_price}
                            setModalShow={setModalShow}
                            quantity={quote.quantity}
                            max_price={quote.pdt_id.price}
                            min_price={quote.pdt_id.quotation_price_min}
                            id={quote._id}
                            vendor_id={quote.vendor_id}
                            customer_id={quote.customer_id}
                            pdt_name={
                              quote.pdt_variations
                                ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                                : quote.pdt_id.name
                            }
                          />
                        ) : user &&
                          modalShow &&
                          quote.pdt_type === "quotationproduct" ? (
                          <ReconsiderPopup
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            price={quote.quotation_price}
                            setModalShow={setModalShow}
                            quantity={quote.quantity}
                            max_price={quote.quotation_pdt_variations.max_value}
                            min_price={quote.quotation_pdt_variations.min_value}
                            id={quote._id}
                            vendor_id={quote.vendor_id}
                            customer_id={quote.customer_id}
                            pdt_name={quote.quotation_pdt_id.name}
                          />
                        ) : null}
                      </>
                    </ListGroupItem>
                  ))}
              </li>
            </Card>
          </ul>
        </Col>
      </ListGroupItem>

      <ListGroupItem id="QuotationTradeList">
        <Col>
          <h5>{"Quotation Trade List"}</h5>
          <ul>
            <Card md={10}>
              <li>
                {QuotationsVendorasCustomer &&
                  QuotationsVendorasCustomer.map((quote) => (
                    <ListGroupItem
                      key={
                        quote.pdt_type === "quotationproduct"
                          ? quote.quotation_pdt_id._id
                          : quote.pdt_id._id
                      }
                    >
                      <h6>
                        {quote.pdt_variations && quote.pdt_variations
                          ? `${quote.pdt_id.name}-${quote.pdt_variations.name}-${quote.pdt_variations.value}`
                          : quote.pdt_type === "quotationproduct"
                          ? quote.quotation_pdt_id.name
                          : quote.pdt_id.name}
                      </h6>
                      <Row>
                        <Col>
                          <Image
                            width={200}
                            src={
                              quote.pdt_type === "quotationproduct"
                                ? quote.quotation_pdt_id.image
                                : quote.pdt_id.image
                            }
                            alt="product image"
                            fluid
                          />
                        </Col>
                        {quote.pdt_type === "quotationproduct" &&
                        quote.quotation_pdt_variations.value_category ===
                          "Price" ? (
                          <Col width={30}>
                            {quote.status === "Reconsidered" ? (
                              //if
                              <>
                                <div>
                                  {t("OfferedPrice")}:{" "}
                                  <strike>{quote.quotation_price}</strike>
                                </div>

                                <div>
                                  {"Final Price:"} {quote.Reconsidered_price}
                                </div>
                              </>
                            ) : (
                              //else

                              <div>
                                {t("OfferedPrice")}: {quote.quotation_price}
                              </div>
                            )}
                            {/* <div>
                                {t("ActualPrice")}: {quote.quotation_pdt_id.quo.price}
                              </div> */}
                            <div>
                              {t("Quantity")}: {quote.quantity}
                            </div>
                          </Col>
                        ) : quote.pdt_type === "quotationproduct" &&
                          quote.quotation_pdt_variations.value_category ===
                            "Value" ? (
                          <Col width={30}>
                            <div>
                              {`You have raised a quotation for this product with given specifications - ${parseInt(
                                quote.value //it acts like a capacity
                              )} ${quote.quotation_pdt_variations.unit}`}
                            </div>
                          </Col>
                        ) : quote.pdt_type === "quotationproduct" &&
                          quote.quotation_pdt_variations.value_category ===
                            "Character Value" ? (
                          <Col width={30}>
                            <div>
                              {`You have raised a quotation for the product name ${quote.value_char}`}
                            </div>
                          </Col>
                        ) : (
                          <Col width={30}>
                            {quote.status === "Reconsidered" ? (
                              //if
                              <>
                                <div>
                                  {t("OfferedPrice")}:{" "}
                                  <strike>{quote.quotation_price}</strike>
                                </div>

                                <div>
                                  {"Final Price:"} {quote.Reconsidered_price}
                                </div>
                              </>
                            ) : (
                              //else

                              <div>
                                {t("OfferedPrice")}: {quote.quotation_price}
                              </div>
                            )}
                            <div>
                              {t("ActualPrice")}: {quote.pdt_id.price}
                            </div>
                            <div>
                              {t("Quantity")}: {quote.quantity}
                            </div>
                          </Col>
                        )}

                        <Col width={30}>
                          {quote.status === "Reconsidered" ? (
                            <div>
                              {`The vendor has reconsidered your offered price of Rs ${quote.quotation_price} and offered his final price thst is Rs ${quote.Reconsidered_price}.`}
                            </div>
                          ) : quote.status === "QuotationAccepted" ? (
                            <>
                              {" "}
                              {`The vendor has Accepted your Quotation and offered his final price thst is Rs ${quote.quotation_price}.`}
                            </>
                          ) : null}
                          {/* <div>
                            {"Customer Id"}: {quote.customer_id}
                          </div> */}
                        </Col>

                        <Col width={30}>
                          {quote.status === null ? (
                            <div>{t("Waiting-for-order-confirmaition")}</div>
                          ) : quote.status === "Yes" ||
                            quote.status === "Reconsidered" ||
                            quote.status === "QuotationAccepted" ? (
                            <Button
                              style={{ alignItems: "center", width: "100%" }}
                              onClick={() =>
                                quote && quote.pdt_type === "quotationproduct"
                                  ? addToCartHandler(
                                      quote.quotation_pdt_id._id,
                                      quote.quotation_pdt_id.name,
                                      quote.quotation_pdt_id.image,
                                      quote.status === "Reconsidered"
                                        ? quote.Reconsidered_price
                                        : quote.quotation_price,
                                      500, //default value for demo chang it later
                                      "quotationproduct",
                                      quote.quantity,
                                      null
                                    )
                                  : addToCartHandler(
                                      quote.pdt_id._id,
                                      quote.pdt_id.name,
                                      quote.pdt_id.image,
                                      quote.status === "Reconsidered"
                                        ? quote.Reconsidered_price
                                        : quote.quotation_price,
                                      quote.pdt_id.countInStock,
                                      "commercialquotationproduct",
                                      quote.quantity,
                                      quote.pdt_variations &&
                                        quote.pdt_variations.id !== null
                                        ? quote.pdt_variations.id
                                        : null
                                    )
                              }
                            >
                              Add to Cart
                            </Button>
                          ) : (
                            "Vendor has rejected your offer"
                          )}
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
              </li>
            </Card>
          </ul>
        </Col>
      </ListGroupItem>

      <ListGroupItem id="orderList">
        <Col>
          <h5>{t("OrderList")}</h5>
          <ul>
            <li>{t("order1")}</li>
            <li>{t("order2")}</li>
            <li>{t("order3")}</li>
            <li>{t("order4")}</li>
          </ul>
        </Col>
      </ListGroupItem>
      <ListGroupItem id="visitors">
        <Col>
          <h5>{t("VisitorsList")}</h5>
          <ul>
            <li>{t("Visitor1")}</li>
            <li>{t("Visitor2")}</li>
            <li>{t("Visitor3")}</li>
            <li>{t("Visitor4")}</li>
          </ul>
        </Col>
      </ListGroupItem>

      <ListGroupItem id="todaysales">
        <Col>
          <h5>{t("Todaysaledetails")}</h5>
          <text>{t("salesDetails")}</text>
        </Col>
      </ListGroupItem>
    </div>
  );
}
export default VendorDashboard;
