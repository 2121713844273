// Farmer Confirmation Page

import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {SmallButtonStyle } from "../../../components/ButtonStyle";
import {BackgroundGradientStyle} from '../../../components/BackgoundGradient';

class Confirmation extends Component {

  
  saveAndContinue = async(e) => {
    e.preventDefault();
    this.props.handleChange("setLoading", true);
    
  let response = await this.props.uploadData();
  if(response.status == 201) {
    this.setState({ isLoading: false, toastVisible: true})
  this.props.nextStep();
    
  } else {
    alert("Please check the connectivity..!!")
  }

};

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: {
        state,
        district,
       block,
        village,
        _firstname,
        _lastname,
        _fathersname,
        _farmeridcard,
        _mobile,
        _rakba,
        _rakbaunit,
        _landparcelnumber,
        _address1,
        _address2,
        _postal,
      },
    } = this.props;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
        <view style={styles.confirmation}>
      <text style={styles.header}>Confirm your Details</text>
   
      <view style={{margin:10}}>
        <view style={styles.confirmtext}> 
            <text style={styles.textinput}>State</text>
            <text>:</text> 
            <text style={styles.textinput}>{state}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>District</text>
          <text>:</text> 
          <text style={styles.textinput}>{district}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>SubDistrict</text>
          <text>:</text>  
          <text style={styles.textinput}>{block}</text>
        </view>
        <view style={styles.confirmtext}>
           <text style={styles.textinput}>Village/town </text>
           <text>:</text> 
           <text style={styles.textinput}>{village}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>First Name </text>
          <text>:</text> 
          <text style={styles.textinput}>{_firstname}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Last Name</text>
          <text>:</text> 
          <text style={styles.textinput}>{_lastname}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Father's Name</text> 
          <text>:</text> 
          <text style={styles.textinput}>{_fathersname}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>ID Card Number</text> 
          <text>:</text> 
          <text style={styles.textinput}>{_farmeridcard}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Mobile</text> 
          <text>:</text> 
          <text style={styles.textinput}>{_mobile}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Rakba</text> 
          <text>:</text> 
          <text style={styles.textinput}>{_rakba}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Rakba Unit</text> 
          <text>:</text> 
          <text style={styles.textinput}>{_rakbaunit}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Land parcel no.</text> 
          <text>:</text> 
          <text style={styles.textinput}>{_landparcelnumber}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Address 1</text>
          <text>:</text> 
          <text style={styles.textinput}>{_address1}</text>
        </view>
        <view style={styles.confirmtext}> 
          <text style={styles.textinput}>Address 2</text>
          <text>:</text> 
          <text style={styles.textinput}>{_address2}</text>
        </view>
        <view style={styles.confirmtext}> 
         <text style={styles.textinput}>Postal</text> 
         <text>:</text> 
         <text style={styles.textinput}>{_postal}</text>
        </view>
    </view>
            
            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
            <Button type="submit" onClick={this.saveAndContinue} style={SmallButtonStyle}>
                Confirm <i style={{marginLeft: 5}} className="fas fa-angle-right"/>
             </Button> 
             <div style={{marginTop:10}}></div>
             <Button type="submit" onClick={this.back} style={SmallButtonStyle}>
                 Back <i style={{marginLeft: 5}} className="fas fa-angle-left"/>
             </Button> 
             </div>
        {/* {setLoading == true ? (
          <ActivityIndicator
            style={[styles.container, styles.horizontal]}
            size="large"
            color="#0000ff"
          />
        ) : null} */}


       </view>

       </div>
       </div>
    );
  }
}

const styles = {
 
  header: {
    marginTop: 50,
    textAlign: "center",
    fontSize: 24,
    color: "#000",
    paddingBottom: 10,
    paddingTop: 10,
    marginBottom: 40,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    fontWeight:'bold'
  },
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 10,
    height: 'auto',
    margin:5,
    width:'40%',
  },
  regfarmer: {
    alignSelf: "stretch",
  },

  horizontal: {
    flexDirection: "row",
    justifyContent: "space-around",
    padding: 10,
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
  },
  background2: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    borderRadius: 20,
    height: 40,
  },
  confirmtext:{
    display:'flex',
    flexDirection:'row'
  }
};

export default Confirmation;
