import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  FEATURED_PRODUCT_LIST_REQUEST,
  FEATURED_PRODUCT_LIST_SUCCESS,
  FEATURED_PRODUCT_LIST_FAIL,
  FEATURED_PRODUCT_DETAILS_REQUEST,
  FEATURED_PRODUCT_DETAILS_SUCCESS,
  FEATURED_PRODUCT_DETAILS_FAIL,
  PRODUCT_CATEGORY_LIST_REQUEST,
  PRODUCT_CATEGORY_LIST_SUCCESS,
  PRODUCT_CATEGORY_LIST_FAIL,
  FILTERED_CATEGORY_LIST_REQUEST,
  FILTERED_CATEGORY_LIST_SUCCESS,
  FILTERED_CATEGORY_LIST_FAIL,
  INNOVATIVE_CATEGORY_LIST_REQUEST,
  INNOVATIVE_CATEGORY_LIST_SUCCESS,
  INNOVATIVE_CATEGORY_LIST_FAIL,
  FILTERED_QUOTATION_CATEGORY_LIST_REQUEST,
  FILTERED_QUOTATION_CATEGORY_LIST_SUCCESS,
  FILTERED_QUOTATION_CATEGORY_LIST_FAIL,
  BRAND_LIST_REQUEST,
  BRAND_LIST_SUCCESS,
  BRAND_LIST_FAIL,
  QUOTATION_BRAND_LIST_REQUEST,
  QUOTATION_BRAND_LIST_SUCCESS,
  QUOTATION_BRAND_LIST_FAIL,
  SEARCH_BRAND_LIST_REQUEST,
  SEARCH_BRAND_LIST_SUCCESS,
  SEARCH_BRAND_LIST_FAIL,
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS,
  SUBCATEGORY_LIST_FAIL,
  SUBSUBCATEGORY_LIST_REQUEST,
  SUBSUBCATEGORY_LIST_SUCCESS,
  SUBSUBCATEGORY_LIST_FAIL,
  FILTERING_CATEGORIES_LIST_REQUEST,
  FILTERING_CATEGORIES_LIST_SUCCESS,
  FILTERING_CATEGORIES_LIST_FAIL,
  FILTERING_QUOTATION_CATEGORIES_LIST_REQUEST,
  FILTERING_QUOTATION_CATEGORIES_LIST_SUCCESS,
  FILTERING_QUOTATION_CATEGORIES_LIST_FAIL,
  FILTERING_SEARCH_CATEGORIES_LIST_REQUEST,
  FILTERING_SEARCH_CATEGORIES_LIST_SUCCESS,
  FILTERING_SEARCH_CATEGORIES_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  FARM_PRODUCT_LIST_REQUEST,
  FARM_PRODUCT_LIST_SUCCESS,
  FARM_PRODUCT_LIST_FAIL,
  FARM_PRODUCT_DETAILS_REQUEST,
  FARM_PRODUCT_DETAILS_SUCCESS,
  FARM_PRODUCT_DETAILS_FAIL,
  QUOTATION_PRODUCT_DETAILS_REQUEST,
  QUOTATION_PRODUCT_DETAILS_SUCCESS,
  QUOTATION_PRODUCT_DETAILS_FAIL,
  FARM_PRODUCT_NAME_REQUEST,
  FARM_PRODUCT_NAME_SUCCESS,
  FARM_PRODUCT_NAME_FAIL,
  FILTERED_FARMPRODUCT_LIST_REQUEST,
  FILTERED_FARMPRODUCT_LIST_SUCCESS,
  FILTERED_FARMPRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_VARIATIONS_REQUEST,
  PRODUCT_DETAILS_VARIATIONS_SUCCESS,
  PRODUCT_DETAILS_VARIATIONS_FAIL,
  PRODUCT_SEARCH_LIST_REQUEST,
  PRODUCT_SEARCH_LIST_SUCCESS,
  PRODUCT_SEARCH_LIST_FAIL,
  FARMSUBCATEGORY_LIST_REQUEST,
  FARMSUBCATEGORY_LIST_SUCCESS,
  FARMSUBCATEGORY_LIST_FAIL,
  HOME_PAGE_PRODUCT_LIST_REQUEST,
  HOME_PAGE_PRODUCT_LIST_SUCCESS,
  HOME_PAGE_PRODUCT_LIST_FAIL,
  REVIEW_LIST_REQUEST,
  REVIEW_LIST_SUCCESS,
  REVIEW_LIST_FAIL,
  QNA_LIST_REQUEST,
  QNA_LIST_SUCCESS,
  QNA_LIST_FAIL,
} from "../constants/productConstants";

import axios from "axios";
import { BASE_URL_HOSTED } from "../constants/api";
import axiosAPI from "../components/AxiosAPI";

export const HomePageListProducts = () => async (dispatch) => {
  try {
    //console.log("inside HomePageListProducts");
    dispatch({ type: HOME_PAGE_PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/gethomepageproducts/`
    );
    //console.log(data);
    dispatch({
      type: HOME_PAGE_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HOME_PAGE_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

export const listProducts = () => async (dispatch) => {
  try {
    // //console.log("inside list")
    dispatch({ type: PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(`${BASE_URL_HOSTED}api/products/`);

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

export const listProductsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(`${BASE_URL_HOSTED}api/products/${id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//FEATURED PRODUCTS
////////////////////////////////////////////////

export const listFeatueredProducts = () => async (dispatch) => {
  try {
    dispatch({ type: FEATURED_PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(`${BASE_URL_HOSTED}api/featuredproducts/`);

    dispatch({
      type: FEATURED_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEATURED_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

export const listFeaturedProductsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: FEATURED_PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/featuredproducts/${id}`
    );

    dispatch({
      type: FEATURED_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FEATURED_PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//Product Category
/////////////////////////////////////////////////////////////////////////////

export const listCategoryProducts = (category) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: PRODUCT_CATEGORY_LIST_REQUEST });

    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/products/${category}`
    );

    dispatch({
      type: PRODUCT_CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//Filtered Category for Quotation product
/////////////////////////////////////////////////////////////////////////////

export const listFilteredQuotationProducts =
  (ActualPostingObj) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: FILTERED_QUOTATION_CATEGORY_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/quotationcategoryproducts/${ActualPostingObj}`
      );

      dispatch({
        type: FILTERED_QUOTATION_CATEGORY_LIST_SUCCESS,
        payload: data.data,
        // maxPrice: data.maxPrice,
      });
    } catch (error) {
      dispatch({
        type: FILTERED_QUOTATION_CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

/////////////////////////////////////////////////////////////////////////////

//Filtered Category
/////////////////////////////////////////////////////////////////////////////

export const listFilteredProducts = (ActualPostingObj, page) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: FILTERED_CATEGORY_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/products_page/?page=${page}&query=${ActualPostingObj.replaceAll("&", "%26")}`
    );

    dispatch({
      type: FILTERED_CATEGORY_LIST_SUCCESS,
      payload: data.data.results,
      totalCount: data.data.count,
      maxPrice: data.maxPrice,
    });
  } catch (error) {
    dispatch({
      type: FILTERED_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

/// Innovative Products catagary list 
/////////////////////////////////////////////////////////////////////////////

export const listInnovativeProducts = (ActualPostingObj, page) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: INNOVATIVE_CATEGORY_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/products_page/?page=${page}&query=${ActualPostingObj.replaceAll("&", "%26")}`
    );

    dispatch({
      type: INNOVATIVE_CATEGORY_LIST_SUCCESS,
      payload: data.data.results,
      totalCount: data.data.count,
      maxPrice: data.maxPrice,
    });
  } catch (error) {
    dispatch({
      type: INNOVATIVE_CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};
//////////////////////////////////////////////////////

//BrandList for filtering list of quotation products
//////////////////////////////////////////////////////////

export const listQuotationBrand =
  (category, catgselect) => async (dispatch) => {
    try {
      // console.log("inside brand list")
      dispatch({ type: QUOTATION_BRAND_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/filteringquotationCategoriesList/${category}/${catgselect}`
      );
      // console.log(data)
      // console.log("jdj")

      dispatch({
        type: QUOTATION_BRAND_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: QUOTATION_BRAND_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

//BrandList for filtering list
//////////////////////////////////////////////////////////

export const listBrand = (category, catgselect) => async (dispatch) => {
  try {
    // console.log("inside brand list")
    dispatch({ type: BRAND_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/filteringCategoriesList/${category}/${catgselect}`
    );
    // console.log(data)
    // console.log("jdj")

    dispatch({
      type: BRAND_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BRAND_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//BrandList for Searchbar filtering list
//////////////////////////////////////////////////////////

export const listSearchBrand = (category, catgselect) => async (dispatch) => {
  try {
    // console.log("inside brand list")
    dispatch({ type: SEARCH_BRAND_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/filteringSearchCategoriesList/${category}/${catgselect}`
    );
    // console.log(data)
    // console.log("jdj")

    dispatch({
      type: SEARCH_BRAND_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_BRAND_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//Subcategory List for filtering list
//////////////////////////////////////////////////////////

export const listSubcategory = (category, catgselect) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: SUBCATEGORY_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/filterproductlist/${category}/${catgselect}`
    );

    dispatch({
      type: SUBCATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUBCATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//SubSUbcategory List for filtering list
////////////////////////////////////////////////////////////

export const listsubsubCategory =
  (category, catgselect) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: SUBSUBCATEGORY_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/filterproductlist/${category}/${catgselect}`
      );

      dispatch({
        type: SUBSUBCATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUBSUBCATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

//Category List for filterring list
////////////////////////////////////////////////////////////

export const listCategory = (category, catgselect) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: CATEGORY_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/filterproductlist/${category}/${catgselect}`
    );

    dispatch({
      type: CATEGORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

///////////////////////////////////////////

//Farm Product Page
////////////////////////////////////////////////////////////

export const listfarmproducts = (category) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: FARM_PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/farmproducts/${category}`
    );

    dispatch({
      type: FARM_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FARM_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

///////////////////////////////////////////

//Farm Product Details
//////////////////////////////////////////////
export const listquotationproductsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: QUOTATION_PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/quotationproductsbyid/${id}`
    );

    dispatch({
      type: QUOTATION_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QUOTATION_PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

/////////////////////////////////////////////////

//Farm Product Details
//////////////////////////////////////////////
export const listfarmproductsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: FARM_PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/farmproducts/${id}`
    );

    dispatch({
      type: FARM_PRODUCT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FARM_PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

//Farm Product Names on filter list
//////////////////////////////////////////////
export const listFarmProductName =
  (category, catgselect) => async (dispatch) => {
    try {
      dispatch({ type: FARM_PRODUCT_NAME_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/filterfarmproductlist/${category}/${catgselect}`
      );

      dispatch({
        type: FARM_PRODUCT_NAME_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FARM_PRODUCT_NAME_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };
/////////////////////////////////////////////

//Filtered Farm Products
/////////////////////////////////////////////////////////////////////////////

export const listFilteredFarmProducts =
  (ActualPostingObj, page) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: FILTERED_FARMPRODUCT_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/farm_products_page/?page=${page}&query=${ActualPostingObj.replaceAll("&", "%26")}`
      );

      dispatch({
        type: FILTERED_FARMPRODUCT_LIST_SUCCESS,
        payload: data.data.results,
        totalCount: data.data.count,
        maxPrice: data.maxPrice,
      });
    } catch (error) {
      dispatch({
        type: FILTERED_FARMPRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

////////////////////////////////////////////////

export const listProductsDetailsWithVariations = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_VARIATIONS_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/productsdetail/${id}`
    );

    // console.log(data)

    dispatch({
      type: PRODUCT_DETAILS_VARIATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_VARIATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

export const searchResultProducts = (query, page) => async (dispatch) => {
  try {
    //console.log(query);
    // alert(query)
    dispatch({ type: PRODUCT_SEARCH_LIST_REQUEST });
    const { data } = await axios.get(`${BASE_URL_HOSTED}api/search/?page=${page}&query=${query.replaceAll("&", "%26")}`, {
      query: query,
    });

    //console.log(data, "data");

    dispatch({
      type: PRODUCT_SEARCH_LIST_SUCCESS,
      payload: data.data.results,
      totalCount: data.data.count,
      maxPrice: data.maxPrice,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_SEARCH_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};

////////////////////////////////////////////////////////////////
//for listing  Fitering Categories of quotation products

export const listFiteringQuotationCategories =
  (category, catgselect) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: FILTERING_QUOTATION_CATEGORIES_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/filteringquotationCategoriesList/${category}/${catgselect}`
      );

      dispatch({
        type: FILTERING_QUOTATION_CATEGORIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FILTERING_QUOTATION_CATEGORIES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////
//for listing  Fitering Categories

export const listFiteringCategories =
  (category, catgselect) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: FILTERING_CATEGORIES_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/filteringCategoriesList/${category}/${catgselect}`
      );

      dispatch({
        type: FILTERING_CATEGORIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FILTERING_CATEGORIES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

////////////////////////////////////////////////////////////////
//for listing  Search Fitering Categories

export const listFiteringSearchCategories =
  (category, catgselect) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: FILTERING_SEARCH_CATEGORIES_LIST_REQUEST });
      const { data } = await axiosAPI.get(
        `${BASE_URL_HOSTED}api/filteringSearchCategoriesList/${category}/${catgselect}`
      );

      dispatch({
        type: FILTERING_SEARCH_CATEGORIES_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FILTERING_SEARCH_CATEGORIES_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };

//Subcategory List for filtering list
//////////////////////////////////////////////////////////

export const listFarmpProduceSubcategory =
  (category, catgselect) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: FARMSUBCATEGORY_LIST_REQUEST });
      const { data } = await axiosAPI.get(
        `${BASE_URL_HOSTED}api/FarmProduceFilterProductList/${category}/${catgselect}`
      );

      dispatch({
        type: FARMSUBCATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FARMSUBCATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message === "Network Error"
            ? "Sorry! It seems there is some problem from our side"
            : error.message,
      });
    }
  };


//////////////////////////////////////////////////////////////////////////////////////

//Review lists
////////////////////////////////////////////////////////////

export const listReview = (pdt_id, pdt_type) => async (dispatch, getState) => {
  try {
    // console.log("inside list")
    dispatch({ type: REVIEW_LIST_REQUEST });
    
    const limit = 10;

    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/getReview/${pdt_id}/${pdt_type}/${limit}`
    );

    dispatch({
      type: REVIEW_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REVIEW_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};
//////////////////////////////////////////////////////////////////////////////

//QNA lists
////////////////////////////////////////////////////////////

export const listQNA = (pdt_id, pdt_type) => async (dispatch, getState) => {
  try {
    // console.log("inside list")
    dispatch({ type: QNA_LIST_REQUEST });

    const us_pr = "prod"


    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/getpublicquesans/${us_pr}/${pdt_id}/${pdt_type}`,
    );

    dispatch({
      type: QNA_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: QNA_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message === "Network Error"
          ? "Sorry! It seems there is some problem from our side"
          : error.message,
    });
  }
};
