import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import wishlistService from '../services/wishlist.service';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    marginTop: 5,
    marginBottom: 10,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 300,
  },
  balanceCard: {
    height: 200,
  },
  balanceCardunique: {
    height: 200,
  },
  Container: {
    margin: 0,
  },
}));

export default function WishlistScreen() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [wishlist, setWishlist] = useState()

  const getWishlistHandler=()=>{
        wishlistService.getWishlist()
                   .then((res)=>{
                    setWishlist(res.data)
                   })
                   .catch((error)=>{
                    console.log(error)
                   })
  }
  
  const removeFromWishlist=()=>{
    console.log('removeFromWishlist')
  }

  useEffect(()=>{
    !wishlist && getWishlistHandler()
  },[])

  const WishlistTable=()=>{
    return(
      <div
        style={{
          border:'1px solid gray',
          backgroundColor: '#fff',
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 10,
          margin: 10,
          }}
      >
      <table
      style={{
        borderCollapse: 'separate !important',
        borderSpacing: '0.8vmin 0.5vmin',
        width: '100%',
      }}
        >
        <thead>
          <tr>
            <td colSpan={7} style={{fontSize:20, padding:10}} >Wishlist</td>
          </tr>
        </thead>
        <tbody>
        <tr 
         style={{
          backgroundColor: '#fff',
          textAlign: 'center',
          fontSize: '1.5vmin',
          borderRadius: '5px',
        }}
        >
          <th style={{minWidth:'20%', borderRadius: 5}}>{'#'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Product'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Name'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Brand'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Size'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Price'}</th>
          <th style={{minWidth:'20%', borderRadius: 5}}>{'Delete'}</th>
        </tr>
        {wishlist?.length==0 &&
          <tr 
            style={{
              backgroundColor: '#fff',
              textAlign: 'center',
              fontSize: '1.5vmin',
              borderRadius: '5px',
            }}
          >
            <th colSpan={8} style={{minWidth:'20%', borderRadius: 5, height:50, borderRadius:5, background:'rgba(244, 246, 251, 1)'}}>
              {'Wishlist is empty'}
            </th>
          </tr>
        }
        {
          wishlist?.map((item, key)=>{
            // let imageURL = 'https://ik.imagekit.io/oet53wxls7nq/'+(item?.product_variation?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
            let imageURL = (item?.product_variation?.media?.[0]?.image?.image)?.replace(/\/media\/(marketplace\/)?/g, "")
            return(
              <tr key={key} style={{height:40}}>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <input type="checkbox"/>
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <img src={imageURL} style={{aspectRatio:1, objectFit:'cover', height:80, width:80, margin:10, borderRadius:5}} alt="product"/>
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.product?.name}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.product?.brand?.name}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.variation_name?.value}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                {item?.product_variation?.stock?.selling_price}
              </td>
              <td style={{backgroundColor: '#fff',textAlign:'center',fontSize: '1.5vmin',borderRadius: '5px', maxWidth:20}}>
                <text style={{cursor:'pointer'}} onClick={()=>removeFromWishlist(item.id)}>{'Delete'}</text>
              </td>
            </tr>
            )
          })
        }
        </tbody>
      </table>
      </div>
    )
  }

  return (
    <WishlistTable/>
  );
}
