import {
  SAVE_SHIPPING_ADDRESS,
  CARD_SAVE_PAYMENT_METHOD,
  CART_CLEAR_ITEMS,

  GET_CART_ITEM_REQUEST,
  GET_CART_ITEM_SUCCESS,
  GET_CART_ITEM_FAIL,

  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAIL,

} from "../constants/CartConstants";

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case CARD_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};

export const CartItemsList = (state = { CartItems: [] }, action) => {
  switch (action.type) {
    case GET_CART_ITEM_REQUEST:
      return { CartItemsLoading: true, CartItems: [] };
    case GET_CART_ITEM_SUCCESS:
      return { CartItemsLoading: false, CartItems: action.payload };
    case GET_CART_ITEM_FAIL:
      return { CartItemsLoading: false, CartItemsError: action.payload };
    default:
      return state;
  }
};

export const DeleteCartItems = (state = { CartItems: [] }, action) => {
  switch (action.type) {
    case DELETE_CART_ITEM_REQUEST:
      return { CartItemsLoading: true, CartItems: [] };
    case DELETE_CART_ITEM_SUCCESS:
      return { CartItemsLoading: false, CartItems: action.payload };
    case DELETE_CART_ITEM_FAIL:
      return { CartItemsLoading: false, CartItemsError: action.payload };
    default:
      return state;
  }
};

