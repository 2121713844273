import {
  GET_DISEASE_DETECTION_HISTORY_REQUEST,
  GET_DISEASE_DETECTION_HISTORY_FAIL,
  GET_DISEASE_DETECTION_HISTORY_SUCCESS,
  GET_PEST_DETECTION_HISTORY_REQUEST,
  GET_PEST_DETECTION_HISTORY_FAIL,
  GET_PEST_DETECTION_HISTORY_SUCCESS,
} from "../constants/MlConstants";

export const DiseaseDetectionHistoryListReducer = (
  state = { disease_detection_history: [] },
  action
) => {
  switch (action.type) {
    case GET_DISEASE_DETECTION_HISTORY_REQUEST:
      return {
        disease_detection_history_loading: true,
        disease_detection_history: [],
      };
    case GET_DISEASE_DETECTION_HISTORY_SUCCESS:
      return {
        disease_detection_history_loading: false,
        disease_detection_history: action.payload,
      };
    case GET_DISEASE_DETECTION_HISTORY_FAIL:
      return {
        disease_detection_history_loading: false,
        disease_detection_history_error: action.payload,
      };
    default:
      return state;
  }
};

export const PestDetectionHistoryListReducer = (
  state = { pest_detection_history: [] },
  action
) => {
  switch (action.type) {
    case GET_PEST_DETECTION_HISTORY_REQUEST:
      return {
        pest_detection_history_loading: true,
        pest_detection_history: [],
      };
    case GET_PEST_DETECTION_HISTORY_SUCCESS:
      return {
        pest_detection_history_loading: false,
        pest_detection_history: action.payload,
      };
    case GET_PEST_DETECTION_HISTORY_FAIL:
      return {
        pest_detection_history_loading: false,
        pest_detection_history_error: action.payload,
      };
    default:
      return state;
  }
};
