import { PortraitSharp } from "@material-ui/icons";
import React, { useState } from "react";
import { Row, Col, ListGroup, Button, Form, Modal } from "react-bootstrap";

import "react-dropdown-now/style.css";
import { SmallButtonStyle } from "../components/ButtonStyle";
import { BASE_URL_HOSTED } from "../constants/api";
import { SendQuotationMail } from "../actions/DashboardActions";
import { useDispatch } from "react-redux";

function ReconsiderPopup(props) {
  // const pdt={quantity: 36}
  // var d= [...Array(pdt.quantity).keys()]
  let max_price = Math.round(props.max_price);
  let min_price = Math.round(props.min_price);
  const [price, setPrice] = useState(props.price);
  const [qty, setQty] = useState(props.quantity);
  const dispatch = useDispatch();
  const Reconsidered_price = props.price;

  //   const { setModalShow } = props;

  const SaveData = () => {
    const uploadData = new FormData();
    uploadData.append("Reconsidered_price", price);
    uploadData.append("status", "Reconsidered");

    fetch(`${BASE_URL_HOSTED}api/quotationstatus/${props.id}`, {
      method: "PUT",
      body: uploadData,
    })
      .then(
        (res) => console.log(""),
        alert(
          `Your have reconsidered the negotiation price offered by customer and offered a new price ie. ₹${price} it has been sent to the customer if he accept your offer we will notify you`
        ),
        dispatch(
          SendQuotationMail(
            Reconsidered_price,
            qty,
            price,
            "Reconsidered",
            props.vendor_id,
            props.customer_id,
            props.pdt_name
          )
        ),
        props.setModalShow(false)
        // props.handleOffer()
      )
      .catch((error) => console.log(""));
  };

  // {console.log("price"+d)}
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Make a offer for Quotation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form.Group controlId="negotiable">
              <Form.Label>Is the price negotiable?</Form.Label>
              <Dropdown
                placeholder="Select an option"
                options={['Yes', 'No']}
                // value="one"
                // onChange={(value) => {value==="Yes" ? setNegotiation("negotiable") : setNegotiation("not negotiable") }}
                // onSelect={(value) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                // onClose={(closedBySelection) => console.log('closedBySelection?:', closedBySelection)}
                // onOpen={() => console.log('open!')}
              />
             
            </Form.Group> */}

        <ListGroup.Item>
          <Row>
            <Col>Quotation Price:</Col>
            <Col xs="auto" className="my-1">
              {props.price}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item>
          <Row>
            <Col>Quantity:</Col>
            <Col xs="auto" className="my-1">
              {props.quantity}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item>
          <Row>
            <Col>Your Offered Price:</Col>
            <Col xs="auto" className="my-1">
              <Form.Control
                as="select"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              >
                {[...Array(max_price).keys()]
                  .filter(
                    (price) => price >= min_price - 1 && price <= max_price
                  )
                  .map((x) => (
                    <option key={x + 1} value={x + 1}>
                      {x + 1}
                    </option>
                  ))}
              </Form.Control>
            </Col>
          </Row>
        </ListGroup.Item>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button style={SmallButtonStyle} onClick={props.onHide}>
              Close
            </Button>
          </Col>
          <Col>
            <Button style={SmallButtonStyle} onClick={() => SaveData()}>
              Offer
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default ReconsiderPopup;
