import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import { MediumButtonStyle } from "../../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../../components/BackgoundGradient";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
export default class VendorReg2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadimage: "",
      preview: "",
      startYear: "",
      annualSales: "",
      // productsell: "",
      panNo: "",
      gstNo: "",
      gstImage: "",
      regParicularImage: "",
      ownershipInfo: "",
      natureBusiness: "",
    };
  }

  setImg = (evt) => {
    this.setUploadimage(evt.target.files[0]);
    this.setPreview(URL.createObjectURL(evt.target.files[0]));
  };

  ownershiplist = [
    { title: "Govt. Of India Undertaking" },
    { title: "State Govt. Undertaking" },
    { title: "Public Limited Company" },
    { title: "Private Limited Company" },
    { title: "One Person Company" },
    { title: "Limited Liability Partnership" },
    { title: "Partnership Firm" },
    { title: "Proprietorship" },
    { title: "Co-Operative Society" },
  ];

  natureofblist = [
    { title: "Manufacturer" },
    { title: "Dealer" },
    { title: "Trader" },
    { title: "Distributor" },
    { title: "Stockiest" },
    { title: "Channel Partner" },
    { title: "Indian sales Office" },
  ];
  sellotherblist = [{ title: "Yes" }, { title: "No" }];

  gstlist = [{ title: "Yes" }, { title: "No" }];
  flatProps = {
    options: this.ownershiplist.map((option) => option.title),
    options: this.natureofblist.map((option) => option.title),
    options: this.sellotherblist.map((option) => option.title),
    options: this.gstlist.map((option) => option.title),
  };
  defaultProps1 = {
    options: this.ownershiplist,
    getOptionLabel: (option) => option.title,
  };

  defaultProps2 = {
    options: this.natureofblist,
    getOptionLabel: (option) => option.title,
  };
  defaultProps3 = {
    options: this.sellotherblist,
    getOptionLabel: (option) => option.title,
  };
  defaultProps4 = {
    options: this.gstlist,
    getOptionLabel: (option) => option.title,
  };

  saveandContinue = async (e) => {
    await this.props.uploadData();
    this.props.nextStep();
  };

  render() {
    const values = this.props;
    const {
      error,
    } = this.state;

    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 900,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Create Account
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Fill the business details to create account.
                  </Form.Label>

                  <Autocomplete
                    style={{
                      marginTop: -20,
                      marginBottom: -5,
                      borderBottomColor: "#5DA7CA",
                    }}
                    {...this.defaultProps1}
                    id="ownershipInfo"
                    defaultValue={this.props.values.ownershipInfo}
                    onInputChange={(e, v) =>
                      this.props.handleChange("ownershipInfo", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Ownership Information"
                        margin="normal"
                      />
                    )}
                  />
                  <Autocomplete
                    style={{
                      marginTop: -20,
                      marginBottom: -5,
                      borderBottomColor: "#5DA7CA",
                    }}
                    {...this.defaultProps2}
                    id="natureBusiness"
                    defaultValue={this.props.values.natureBusiness}
                    onInputChange={(e, v) =>
                      this.props.handleChange("natureBusiness", v)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nature of Business"
                        margin="normal"
                      />
                    )}
                  />

                  <Form.Group
                    controlId="startYear"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="startYear"
                      placeholder="Year of commencement of Business"
                      value={values.startYear}
                      defaultValue={this.props.values.startYear}
                      onChange={(e) =>
                        this.props.handleChange("startYear", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="annualSales"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="annualSales"
                      placeholder="Annual Sales excluding other income of past 3 year"
                      value={values.annualSales}
                      defaultValue={this.props.values.annualSales}
                      onChange={(e) =>
                        this.props.handleChange("annualSales", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  {/* <Form.Group controlId="productsell" style={{borderRadius:0, marginTop:20, border:0, borderBottom:"1px solid #5DA7CA", display:"flex", direction:"row", }}>
              <Form.Control
                style={{width:750, borderRadius:0,fontSize:15, boxShadow:"none", paddingLeft:0, border:0,}}
                type="productsell"
                placeholder="How Many Product You Will Sell ? (In No.)"
                value={values.productsell}
                defaultValue={this.props.values.productsell}
                onChange={(e) => this.props.handleChange("productsell", e.target.value)}
                 ></Form.Control>
            </Form.Group>
             */}
                  {/* <Autocomplete style={{marginTop:-20, marginBottom:-5, borderBottomColor:"#5DA7CA"}}
                {...this.defaultProps3}
                id="sellother"
                defaultValue={this.props.values.society}
                onInputChange = {(e,v) => this.props.handleChange("states",v) }   
                  renderInput={(params) => <TextField {...params} label="Do you Sell in Other website or Not" margin="normal" />}
           /> */}

                  <Form.Group controlId="uploadimage">
                    <Form.Label
                      style={{
                        borderRadius: 0,
                        marginTop: 20,
                        border: 0,
                        display: "flex",
                        direction: "row",
                      }}
                    >
                      Registration Particulars (Documents)
                    </Form.Label>
                    <input
                      type="file"
                      onChange={(evt) => this.props.handleDoc1(evt)}
                      style={{ marginLeft: 0 }}
                    />
                    {/* {console.log(uploadimage)} */}
                  </Form.Group>
                  {/* <Card><Image src={preview} alt={"previewImage"} fluid /></Card> */}

                  <Form.Group
                    controlId="panNo"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: "1px solid #5DA7CA",
                      display: "flex",
                      direction: "row",
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: "none",
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="panNo"
                      placeholder="Permanent Account No. (PAN)"
                      value={values.panNo}
                      defaultValue={this.props.values.panNo}
                      onChange={(e) =>
                        this.props.handleChange("panNo", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>

                  <Autocomplete
                    style={{
                      marginTop: -20,
                      marginBottom: -5,
                      borderBottomColor: "#5DA7CA",
                    }}
                    {...this.defaultProps4}
                    id="gst"
                    defaultValue={this.props.values.haveGst}
                    onInputChange={(e, v) =>
                      this.props.handleChange(
                        "haveGst",
                        v === "Yes" ? "True" : "False"
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Do you have GST"
                        margin="normal"
                      />
                    )}
                  />
                  {/* {console.log("ndnnmdbd", this.props.values.haveGst)} */}
                  {this.props.values.haveGst === true ||
                  this.props.values.haveGst === "True" ? (
                    <>
                      <Form.Group
                        controlId="gstNo"
                        style={{
                          borderRadius: 0,
                          marginTop: 20,
                          border: 0,
                          borderBottom: "1px solid #5DA7CA",
                          display: "flex",
                          direction: "row",
                        }}
                      >
                        <Form.Control
                          style={{
                            width: 750,
                            borderRadius: 0,
                            fontSize: 15,
                            boxShadow: "none",
                            paddingLeft: 0,
                            border: 0,
                          }}
                          type="gstNo"
                          placeholder="GSTIN"
                          value={values.gstNo}
                          defaultValue={this.props.values.gstNo}
                          onChange={(e) =>
                            this.props.handleChange("gstNo", e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                      <Form.Group controlId="uploadimage">
                        <Form.Label
                          style={{
                            borderRadius: 0,
                            marginTop: 20,
                            border: 0,
                            display: "flex",
                            direction: "row",
                          }}
                        >
                          Upload GST Certificate
                        </Form.Label>
                        <input
                          type="file"
                          onChange={(evt) => this.props.handleDoc2(evt)}
                          style={{ marginLeft: 0 }}
                        />
                        {/* {console.log(uploadimage)} */}
                      </Form.Group>{" "}
                    </>
                  ) : null}
                  {/* <Card><Image src={preview} alt={"previewImage"} fluid /></Card> */}

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      type="submit"
                      onClick={this.saveandContinue}
                      style={MediumButtonStyle}
                    >
                      Save & Priview{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      type="submit"
                      onClick={this.props.prevStep}
                      style={MediumButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
