import React, { useState, useEffect } from "react";
import "../App.css";
import "./style/farmequiprent.css";
import { Button, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { MediumButtonStyle, FindButtonStyle } from "../components/ButtonStyle";
import axios from "axios";
import Imagee from "../assets/tracktor.png"; // Import using relative path
import Avatar from "@mui/material/Avatar";
import {
  API_URL_STATE,
  API_URL_FARM_EQUIPMENT_LIST,
  API_URL_FARM_RENTAL_DETAILS,
  API_URL_CHECK_RENT_EQUIP_LOC,
} from "../screens/Registers/Constants";
import { Paper, Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import TracIcon from "../assets/tractor.svg";
import CarRentalIcon from "../assets/rental-car.svg";
import HandshakeIcon from "../assets/handshake.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles(() => ({
  rootimg: {
    minHeight: 251,
    background: `linear-gradient(to right bottom,rgba(93, 167, 202, 1), rgba(93, 167, 202, .7)) , url(${Imagee})`,
    backgroundSize: "cover",
  },
}));

function FarmEquipRent() {
  const { t } = useTranslation();

  const classes = useStyles();

  const matches = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    rentalequipmentget();
  }, []);

  const [Checkkey, setCheckkey] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [rentalequipmentlist, setRentalequipmentlist] = useState([]);
  let [SpecificImplelist, setSpecificImplelist] = useState([]);
  const [rentalDetails, setRentalDetails] = useState([]);
  const [states, setStates] = useState([]);
  const [district, setdistrict] = useState([]);
  const [block, setblock] = useState([]);
  const [village, setvillage] = useState([]);
  let [statesNow, setStatesNow] = useState("");
  let [districtNow, setdistrictNow] = useState("");
  let [blockNow, setblockNow] = useState("");
  let [villageNow, setvillageNow] = useState("");
  let [specificImpleSelected, setSpecificImpleSelected] = useState("");
  let [EquipmentType, setEquipmentType] = useState();

  useEffect(() => {
    setStates([]);
    setSpecificImplelist([]);
    setSpecificImpleSelected("");
  }, [EquipmentType]);

  const submitButton = async () => {
    setLoading(true);
    await FarmEquipmentRental();
    setCheckkey(true);
  };

  const FarmEquipmentRental = async () => {
    const data = {
      State: statesNow.toUpperCase(),
      District: districtNow,
      Block: blockNow,
      Village: villageNow,
      EquipmentType: EquipmentType,
      EquipmentDescription: specificImpleSelected,
    };
    await axios
      .post(`${API_URL_FARM_RENTAL_DETAILS}`, data)
      .then((result) => {
        setRentalDetails(result.data["message"]);
        setLoading(false);
      })
      .catch((error) => {
        //console.warn(error);
      });
  };

  const ImpleSpecific = async (value) => {
    const data = {
      State: statesNow,
      District: districtNow,
      Block: blockNow,
      Village: value,
      EquipmentType: EquipmentType,
    };

    await axios
      .post(`${API_URL_FARM_EQUIPMENT_LIST}`, data)
      .then((result) => {
        setSpecificImplelist(result.data["message"]);
      })
      .catch((error) => {
        //console.warn(error);
      });
  };
  const rentalequipmentget = async () => {
    await axios
      .get(`${API_URL_FARM_EQUIPMENT_LIST}`)
      .then((result) => {
        setLoading(true);
        setRentalequipmentlist(result.data["message"]);
        setLoading(false);
      })
      .catch((error) => {
        //console.warn(error);
      });
  };

  const stateget = async () => {
    await axios
      .get(`${API_URL_STATE}${1}`)
      .then((result) => {
        setLoading(true);
        setStates(result.data);
        setStatesNow("");
        setLoading(false);
      })
      .catch((error) => {
        //console.warn(error);
      });
  };

  const setSearchDistrict = async (value) => {
    if (value == "State") {
      // console.log("not found!");
      setStatesNow("");
    } else {
      const Value = value.split(",");
      setStatesNow(Value[0]);
      districtNow = "";
      blockNow = "";
      villageNow = "";
      var ids = Number(Value[1]);

      let checklocData = {
        State: Value[0].toUpperCase(),
        District: districtNow,
        Block: blockNow,
        EquipmentType: EquipmentType,
      };

      await axios
        .post(`${API_URL_CHECK_RENT_EQUIP_LOC}`, checklocData)
        .then((result) => {
          setLoading(true);
          setdistrict(result.data["message"]);
          setblock([]);
          setvillage([]);
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const blockget = async (val) => {
    unique = [];
    if (
      val == "No Matcing Equipment Found in Selected District." ||
      val == "Block*"
    ) {
      setblockNow("");
    } else {
      const Value = val.split(",");
      setdistrictNow(Value[0]);
      blockNow = "";
      villageNow = "";
      var ids = Number(Value[1]);
      let checklocData = {
        District: Value[0],
        Block: blockNow,
        EquipmentType: EquipmentType,
      };
      await axios
        .post(`${API_URL_CHECK_RENT_EQUIP_LOC}`, checklocData)
        .then((result) => {
          setLoading(true);
          setblock(result.data["message"]);
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const villageget = async (val) => {
    if (
      val == "No Matcing Equipment Found in Selected District." ||
      val == "Block*"
    ) {
      //console.log("not found!");
    } else {
      const Value = val.split(",");
      setblockNow(Value[0]);

      var ids = Number(Value[1]);
      let checklocData = {
        District: districtNow,
        Block: Value[0],
        EquipmentType: EquipmentType,
      };
      await axios
        .post(`${API_URL_CHECK_RENT_EQUIP_LOC}`, checklocData)
        .then((result) => {
          setLoading(true);
          setvillage(result.data["message"]);
          setLoading(false);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  };

  const setImpleSpeciSelected = (value) => {
    if (value == "Select implement specification") {
      //console.log("not found!");
      setSpecificImpleSelected("");
    } else {
      setSpecificImpleSelected(value);
    }
  };

  const TypoStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  };

  const AvatarStyle = { bgcolor: "#BFE2BA", marginRight: 1 };

  const InsideAvatarStyle = { color: "black", fontWeight: "bold" };
  const ImgSpanStyle = { marginLeft: 6, paddingRight: 10 };

  const villageSetFuct = (value) => {
    setvillageNow(value);
    ImpleSpecific(value);
  };

  const setAllValues = (value) => {
    setEquipmentType(value);
    stateget();
    setdistrict([]);
    setblock([]);
    setvillage([]);
  };
  let unique = [];
  let uniqueVillage = [];

  useEffect(() => {
    setblock([]);
    setblockNow("");
    setvillageNow("");
    setvillage([]);
  }, [districtNow]);

  useEffect(() => {
    setvillage([]);
    setvillageNow("");
  }, [blockNow]);

  useEffect(() => {
    setSpecificImplelist([]);
    setSpecificImpleSelected("");
  }, [village, villageNow]);

  return (
    <div>
      <section id="section1">
        <Paper className={classes.rootimg}>
          <div style={{ padding: 60 }}>
            <Typography
              style={{ fontSize: 23, color: "white", marginBottom: 3 }}
            >
              {t("FarmEquipmentRental1")}
            </Typography>
            <Typography
              style={{ fontSize: 23, color: "white", marginBottom: 3 }}
            >
              {t("FindFarmEquipmentRentalNearYou")}
            </Typography>
            <Button
              type="submit"
              style={FindButtonStyle}
              onClick={() => submitButton()}
            >
              {t("Findnow")}
            </Button>
          </div>
        </Paper>
      </section>
      {matches ? (
        <section id="section2">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            style={{ marginTop: 16, marginBottom: 23 }}
          >
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
              }}
            >
              <Avatar sx={{ bgcolor: "#BFE2BA", marginRight: 1 }}>
                <Typography style={{ color: "black", fontWeight: "bold" }}>
                  1
                </Typography>
              </Avatar>
              {t("Selectyourrequirements")}{" "}
              <span style={{ marginLeft: 6 }}>
                {" "}
                <img height={26} src={TracIcon} />
              </span>
            </Typography>
            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
              }}
            >
              {" "}
              <Avatar sx={{ bgcolor: "#BFE2BA", marginRight: 1 }}>
                <Typography style={{ color: "black", fontWeight: "bold" }}>
                  2
                </Typography>
              </Avatar>
              {t("Connectwithrenterandplaceorder")}{" "}
              <span style={{ marginLeft: 6 }}>
                {" "}
                <img height={30} src={HandshakeIcon} />
              </span>
            </Typography>

            <Typography
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
              }}
            >
              {" "}
              <Avatar sx={{ bgcolor: "#BFE2BA", marginRight: 1 }}>
                <Typography style={{ color: "black", fontWeight: "bold" }}>
                  3
                </Typography>
              </Avatar>
              {t("Getdeviceatyourdoor")}
              <span style={{ marginLeft: 6 }}>
                {" "}
                <img height={26} src={CarRentalIcon} />
              </span>
            </Typography>
          </Stack>
        </section>
      ) : (
        <section id="section2">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="Left"
            marginTop={2}
          >
            <Typography style={TypoStyle}>
              <Avatar sx={AvatarStyle}>
                <Typography style={InsideAvatarStyle}>1</Typography>
              </Avatar>
              <span style={ImgSpanStyle}>
                {" "}
                <img height={26} src={TracIcon} />
              </span>
              {t("Selectyourrequirements")}{" "}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="Left"
            marginTop={2}
          >
            <Typography style={TypoStyle}>
              <Avatar sx={AvatarStyle}>
                <Typography style={InsideAvatarStyle}>2</Typography>
              </Avatar>
              <span style={ImgSpanStyle}>
                {" "}
                <img height={26} src={HandshakeIcon} />
              </span>
              {t("Connectwithrenterandplaceorder")}{" "}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            spacing={2}
            justifyContent="Left"
            marginTop={2}
          >
            <Typography style={TypoStyle}>
              <Avatar sx={AvatarStyle}>
                <Typography style={InsideAvatarStyle}>3</Typography>
              </Avatar>
              <span style={ImgSpanStyle}>
                {" "}
                <img height={26} src={CarRentalIcon} />
              </span>
              {t("Getdeviceatyourdoor")}
            </Typography>
          </Stack>
        </section>
      )}

      <section
        id="section3"
        style={{
          display: "flex",
          flexDirection: "row",
          flexDirection: "column",
          marginTop: "5vh",
          padding: 4,
        }}
      >
        <view id="view1">
          <div
            id="rentalFrom"
            style={{ border: "1px solid #5DA7CA", padding: 23 }}
          >
            <Form.Group
              controlId="Category"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                flexDirection: "column",
              }}
              disabled={isLoading}
            >
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                }}
                as="select"
                placeholder={t("SelectCategory")}
                onChange={(e) => setAllValues(e.target.value)}
              >
                <option>{t("SelectCategory")}*</option>
                {rentalequipmentlist.map((res, key) => (
                  <option key={key} value={res.EquipmentType}>
                    {res.EquipmentType}
                  </option>
                ))}
              </Form.Control>
              {/* <text style={styles.ERR}>{values.stateErr}</text> */}
            </Form.Group>
            <Form.Group
              controlId="State"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,

                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  marginRight: 15,
                  border: 0,
                  borderBottom: "1px solid #5DA7CA",
                }}
                as="select"
                placeholder="Select State"
                defaultValue={statesNow}
                onChange={(e) => setSearchDistrict(e.target.value)}
              >
                <option>{t("State1")}</option>
                {states.map((res) => (
                  <option key={res.id} value={[res.state, res.id]}>
                    {res.state.toUpperCase()}
                  </option>
                ))}
              </Form.Control>
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  marginLeft: 15,
                  border: 0,
                  borderBottom: "1px solid #5DA7CA",
                }}
                as="select"
                placeholder="Select District"
                defaultValue={districtNow}
                onChange={(e) => blockget(e.target.value)}
              >
                <option>{t("District1")}</option>
                {district.map((res, key) => (
                  <option key={key} value={[res.District, res.id]}>
                    {res.District}
                  </option>
                ))}
              </Form.Control>

              {/* <text style={styles.ERR}>{values.stateErr}</text> */}
            </Form.Group>

            <Form.Group
              controlId="Block"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,

                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Control
                style={{
                  width: "50%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                  padding: 3,
                  marginRight: 15,
                  borderBottom: "1px solid #5DA7CA",
                }}
                as="select"
                placeholder="Select Block"
                onChange={(e) => villageget(e.target.value)}
              >
                <option>{t("Block1")}*</option>
                {(unique = [...new Set(block.map((res) => res.Block))])}
                {unique && unique.length > 0 ? (
                  unique.map((res, key) => <option key={key}>{res}</option>)
                ) : (
                  <option>
                    {t("NoMatcingEquipmentFound")}
                  </option>
                )}
              </Form.Control>
              <Form.Control
                style={{
                  width: "50%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                  marginLeft: 15,
                  borderBottom: "1px solid #5DA7CA",
                }}
                as="select"
                placeholder="Village/Town/Ward"
                defaultValue={villageNow}
                onChange={(e) => villageSetFuct(e.target.value)}
              >
                <option>{t("VillageTownWard")}</option>
                {
                  (uniqueVillage = [
                    ...new Set(village.map((res) => res.Village)),
                  ])
                }
                {uniqueVillage.map((res, key) => (
                  <option key={key}>{res}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group
              controlId="Select implement specification"
              style={{
                borderRadius: 0,
                marginTop: 20,
                marginBottom: 40,
                border: 0,
                borderBottom: "1px solid #5DA7CA",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Form.Control
                style={{
                  width: "95%",
                  borderRadius: 0,
                  fontSize: 15,
                  boxShadow: "none",
                  paddingLeft: 0,
                  border: 0,
                }}
                as="select"
                placeholder={t("Selectimplementspecification")}
                onChange={(e) => setImpleSpeciSelected(e.target.value)}
              >
                <option>{t("Selectimplementspecification")}</option>
                {SpecificImplelist.map((res, key) => (
                  <option key={key} value={res.EquipmentDescription}>
                    {res.EquipmentDescription}
                  </option>
                ))}
              </Form.Control>
              {/* <text style={styles.ERR}>{values.villageErr}</text> */}
            </Form.Group>

            <view style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                style={MediumButtonStyle}
                onClick={() => submitButton()}
                disabled={isLoading}
              >
                {t("Submit")}
              </Button>
            </view>
          </div>
        </view>
      </section>
      <section>
        {Checkkey ? (
          rentalDetails.length > 0 ? (
            <section
              style={{
                margin: 20,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {rentalDetails.map((res, key) => (
                <view
                  style={{
                    border: "1px solid black",
                    marginLeft: 10,
                    padding: 3,
                  }}
                >
                  <text key={key}>Name: {res.EquipmentOwnerName}</text>
                  <br />
                  <text key={key + 1}>Phone: {res.EquipmentOwnerPhone}</text>
                  <br />
                  <text key={key + 2}>Equipment Name: {res.EquipmentName}</text>
                  <br />
                  <text key={key + 3}>Equipment Type: {res.EquipmentType}</text>
                  <br />
                  <text key={key + 4}>
                    Description: {res.EquipmentDescription}
                  </text>
                  <br />
                  <text key={key + 4}>Cost Per Hour: {res.CostPerHour}</text>
                  <br />
                  <text key={key + 4}>Cost Per Acre: {res.CostPerAcre}</text>
                  <br />
                  <text key={key + 4}>
                    Equipment Owner Type: {res.EquipmentOwnerType}
                  </text>
                  <br />
                  <text key={key + 4}>
                    Equipment Quantity: {res.EquipmentQuantity}
                  </text>
                  <br />
                  <text key={key + 5}>Block: {res.Block}</text>
                  <br />
                  <text key={key + 6}>Village: {res.Village}</text>
                  <br />
                </view>
              ))}
            </section>
          ) : (
            <view
              style={{ border: "1px solid black", marginLeft: 10, padding: 3 }}
            >
              <text>No Matching Equipment Found!!</text>
              <br />
            </view>
          )
        ) : null}
      </section>
    </div>
  );
}

const styles = {
  ERR: {
    color: "red",
  },
};
export default FarmEquipRent;
