import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_LOGISTICSBYUSERID,
  API_URL_LOGISTICS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function CWCBusinessInfo({ history }) {
  const {user} = useSelector((state) => state.auth);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };

  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();
  const [values, setValues] = useState({
    // ////////////////////////////////
    // error : null,
    MaterialMovRoute: "",
    Full_Load_Freight_Rate: null,
    Small_Package_Freight_Rate: null,
    MaterialMovFreq: "",
    DeliverInsured: "",
    RouteMap: null,
    Owned_Self_Vehicles: null,
    Associate_Vehicles: null,
    No_of_Driver: null,
    No_of_Vehicles: null,
    No_of_MGV: null,
    No_of_LMV: null,
    No_of_HMV: null,
    No_of_HGMV: null,
    No_of_HTV: null,
    Load_Unload_Facility: "",
    Small_Package_Facility: "",
    routemap_Text: "",
  });

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const retrieve_Logistics = async () => {
    try {
      axios.get(`${API_URL_LOGISTICSBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            // logisticID : res.data[0].id,

            //    ////////////////////////////////////////
            No_of_Driver: res.data[0].No_of_Driver,
            No_of_Vehicles: res.data[0].No_of_Vehicles,
            Owned_Self_Vehicles: res.data[0].Owned_Self_Vehicles, //
            Associate_Vehicles: res.data[0].Associate_Vehicles, //

            No_of_MGV: res.data[0].No_of_MGV,
            No_of_LMV: res.data[0].No_of_LMV,
            No_of_HMV: res.data[0].No_of_HMV,
            No_of_HGMV: res.data[0].No_of_HGMV,
            No_of_HTV: res.data[0].No_of_HTV,

            Load_Unload_Facility: res.data[0].Load_Unload_Facility, //
            Small_Package_Facility: res.data[0].Small_Package_Facility, //
            Full_Load_Freight_Rate: res.data[0].Full_Load_Freight_Rate, //
            Small_Package_Freight_Rate: res.data[0].Small_Package_Freight_Rate, //
            MaterialMovRoute: res.data[0].MaterialMovRoute, //
            MaterialMovFreq: res.data[0].MaterialMovFreq, //
            DeliverInsured: res.data[0].DeliverInsured, //
            RouteMap: res.data[0].RouteMap, //
            routemap_Text: res.data[0].Routemap_Text, //
            //    userID: res.data[0].userID,
          });

          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////
  const UpdateData = async () => {
    // console.log("inside upload data")

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let form_data = new FormData();
    let RouteMap = values._rootMap;

    //   ////////////////////////////////////////////////////////
    if (values.No_of_Driver) {
      form_data.append("No_of_Driver", values.No_of_Driver);
    }
    if (values.No_of_Vehicles) {
      form_data.append("No_of_Vehicles", values.No_of_Vehicles);
    }
    if (values.Owned_Self_Vehicles) {
      form_data.append("Owned_Self_Vehicles", values.Owned_Self_Vehicles);
    }
    if (values.Associate_Vehicles) {
      form_data.append("Associate_Vehicles", values.Associate_Vehicles);
    }
    if (values.No_of_MGV) {
      form_data.append("No_of_MGV", values.No_of_MGV);
    }
    if (values.No_of_LMV) {
      form_data.append("No_of_LMV", values.No_of_LMV);
    }
    if (values.No_of_HMV) {
      form_data.append("No_of_HMV", values.No_of_HMV);
    }
    if (values.No_of_HGMV) {
      form_data.append("No_of_HGMV", values.No_of_HGMV);
    }
    if (values.No_of_HTV) {
      form_data.append("No_of_HTV", values.No_of_HTV);
    }
    if (values.MaterialMovRoute) {
      form_data.append("MaterialMovRoute", values.MaterialMovRoute);
    }

    if (values.MaterialMovFreq) {
      form_data.append("MaterialMovFreq", values.MaterialMovFreq);
    }
    form_data.append("Load_Unload_Facility", values.Load_Unload_Facility);
    form_data.append("Small_Package_Facility", values.Small_Package_Facility);
    if (values.Full_Load_Freight_Rate) {
      form_data.append("Full_Load_Freight_Rate", values.Full_Load_Freight_Rate);
    }
    if (values.Small_Package_Freight_Rate) {
      form_data.append(
        "Small_Package_Freight_Rate",
        values.Small_Package_Freight_Rate
      );
    }
    form_data.append("DeliverInsured", values.DeliverInsured);
    // form_data.append("userID", 1);
    // console/log("USERID", values.id)

    // console.log("RouteMap >>> " +  JSON.stringify(RouteMap))

    form_data.append("Routemap_Text", values.RouteMap);

    //   console.log("CName", values.MaterialMovFreq)
    //   for (var value of form_data.values()) {
    //     console.log("value");
    //     console.log(value);
    //   }

    let response = [];

    // console.log("config", config)

    await axios
      .post(API_URL_LOGISTICS, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_Logistics();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((err) => {
        response = err.response;
        setLoading(false);
        // setShowEdit(false)
        // console.log(response)
      });

    // history.push('/ManageProfile')

    return response;
  };

  ////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (user) {
      retrieve_Logistics();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        {/* ///////////////////////////////////////////////////// */}

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-No_of_Driver"
            value={values.No_of_Driver}
            onChange={handleChange("No_of_Driver")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-Drivers")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-No_of_Vehicles"
            value={values.No_of_Vehicles}
            onChange={handleChange("No_of_Vehicles")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-Vehicles")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Owned_Self_Vehicles"
            value={values.Owned_Self_Vehicles}
            onChange={handleChange("Owned_Self_Vehicles")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Vehicles-Owned-By-Self")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Associate_Vehicles"
            value={values.Associate_Vehicles}
            onChange={handleChange("Associate_Vehicles")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Vehicles-Owned-By-Associate")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-No_of_MGV"
            value={values.No_of_MGV}
            onChange={handleChange("No_of_MGV")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-MGV")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-No_of_LMV"
            value={values.No_of_LMV}
            onChange={handleChange("No_of_LMV")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-LMV")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-No_of_HMV"
            value={values.No_of_HMV}
            onChange={handleChange("No_of_HMV")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-HMV")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-No_of_HGMV"
            value={values.No_of_HGMV}
            onChange={handleChange("No_of_HGMV")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-HGMV")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-No_of_HTV"
            value={values.No_of_HTV}
            onChange={handleChange("No_of_HTV")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Number-of-HTV")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Load_Unload_Facility"
            value={values.Load_Unload_Facility}
            onChange={handleChange("Load_Unload_Facility")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Facilities-of-Loading")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Small_Package_Facility"
            value={values.Small_Package_Facility}
            onChange={handleChange("Small_Package_Facility")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Provide-Facilities-for-Small-Packages")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Full_Load_Freight_Rate"
            value={values.Full_Load_Freight_Rate}
            onChange={handleChange("Full_Load_Freight_Rate")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Freight-Rate-Full")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Small_Package_Freight_Rate"
            value={values.Small_Package_Freight_Rate}
            onChange={handleChange("Small_Package_Freight_Rate")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Freight-Rate-Small")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-MaterialMovFreq"
            value={values.MaterialMovFreq}
            onChange={handleChange("MaterialMovFreq")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Material-Movement")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-MaterialMovFreq"
            value={values.MaterialMovFreq}
            onChange={handleChange("MaterialMovFreq")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Frequency-of-Material")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-DeliverInsured"
            value={values.DeliverInsured}
            onChange={handleChange("DeliverInsured")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Is-Delivery")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-RouteMap"
            value={values.RouteMap}
            onChange={handleChange("RouteMap")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Route-Map")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CWCBusinessInfo;
