import React from 'react'
import farmer from '../assets/farmer.png'
import coordinator from '../assets/coordinator.png'
import Logistics from '../assets/Logistics.png'
import Vendor from '../assets/Vendor.png'
import user from '../assets/user.png'
import EmailImg from '../assets/Email.png'
import FpoImg from '../assets/FPOS icone.jpg'
import Loader from '../components/Loader'
import Message from '../components/message'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, Card } from 'react-bootstrap'
import { MediumButtonStyle } from '../components/ButtonStyle'
import { BackgroundGradientStyle } from '../components/BackgoundGradient'

export const DesktopSection = ({
  error,
  loading,
  firstname,
  setFirstname,
  lastname,
  setLastname,
  email,
  setEmail,
  mobile,
  setMobile,
  HandleImgBorder,
  submitHandler,
  setAgree,
  agreeCheckbox,
  t,
  role,
  validated,
  isValidMobileNumber,
}) => {
  return (
    <div id="root">
      <div style={BackgroundGradientStyle}>
        <div style={{ fontSize: 12 }}>
          {error && <Message variant="danger">{error}</Message>}
        </div>
        {loading && <Loader />}

        <div
          id="root"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{ padding: '20px 10px', margin: '20px 20px 20px 20px' }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  width: 750,
                  height: role === 'vendor' ? 700 : 650,
                  padding: '10px 25px',
                }}
              >
                <strong
                  style={{
                    color: 'black',
                    fontSize: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'left',
                    justifyContent: 'left',
                  }}
                >
                  {t("RegisterSignUp")}
                </strong>
                <Form noValidate validated={validated} onSubmit={submitHandler}>
                  <Form.Group
                    controlId="firstname"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: '1px solid #5DA7CA',
                      display: 'flex',
                      direction: 'row',
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: 'none',
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      required
                      placeholder={t("EnterFirstName")}
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    ></Form.Control>
                    <div>
                      <img alt="User" src={user} />
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="Lastname"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: '1px solid #5DA7CA',
                      display: 'flex',
                      direction: 'row',
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: 'none',
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      required
                      placeholder={t("EnterLastName")}
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    ></Form.Control>
                    <div>
                      <img alt="user" src={user} />
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="email"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: '1px solid #5DA7CA',
                      display: 'flex',
                      direction: 'row',
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: 'none',
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="email"
                      placeholder={t("EnterEmailOptional")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <div>
                      <img alt="Email Image" src={EmailImg} />
                    </div>
                  </Form.Group>
                  <Form.Label
                    style={{
                      marginTop: 25,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'left',
                      justifyContent: 'left',
                      color: 'black',
                    }}
                  >
                    {t("Wellsendyouatextverification")}
                  </Form.Label>
                  <Form.Group controlId="mobile">
                    <Form.Control
                      style={{
                        borderRadius: 0,
                        fontSize: 15,
                        border: 0,
                        borderBottom: '1px solid #5DA7CA',
                        boxShadow: 'none',
                        paddingLeft: 0,
                      }}
                      type="number"
                      required
                      placeholder={t("EnterMobileNumber")}
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      isValid={isValidMobileNumber()}
                      isInvalid={!isValidMobileNumber() || validated}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                    Please ensure mobile number is of 10 digits.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <strong
                    style={{
                      color: 'black',
                      fontSize: 20,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'left',
                      justifyContent: 'left',
                    }}
                  >
                    {t("Category1")}
                  </strong>

                  <div
                    style={{
                      padding: 'auto 10px',
                      margin: '0px 0px 0px 0px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: 200,
                        borderRadius: '5px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                        paddingLeft: 15,
                        paddingRight: 15,
                        margin: 30,
                      }}
                      id="farmer-Img1"
                      onClick={() =>
                        HandleImgBorder('farmer-Img1', 'img1', 'farmer')
                      }
                    >
                      <Card.Img src={farmer} />
                      <text style={{ color: 'black', fontSize: '1rem' }}>
                          {t("Farmer")}
                        <br />
                        <br />
                      </text>
                    </div>
                    <div
                      style={{
                        width: 150,
                        borderRadius: '5px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                        paddingLeft: 15,
                        paddingRight: 15,
                        margin: 30,
                      }}
                      id="coordinator-Img2"
                      onClick={() =>
                        HandleImgBorder(
                          'coordinator-Img2',
                          'img2',
                          'coordinator',
                        )
                      }
                    >
                      <Card.Img src={coordinator} style={{width:'78%',height:'56px'}} />
                      <text style={{ color: 'black', fontSize: '1rem' }}>
                      {t("CropwayCoordinator")}
                      </text>
                    </div>

                    <div
                      style={{
                        width: 150,
                        borderRadius: '5px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                        paddingLeft: 15,
                        paddingRight: 15,
                        margin: 30,
                      }}
                      id="logistics-Img3"
                      onClick={() =>
                        HandleImgBorder('logistics-Img3', 'img3', 'logistics')
                      }
                    >
                      <Card.Img src={Logistics} />
                      <text style={{ color: 'black', fontSize: '1rem' }}>
                        {t("Logistics")}
                        <br />
                        <br />
                      </text>
                    </div>

                    <div
                      style={{
                        width: 150,
                        borderRadius: '5px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                        paddingLeft: 15,
                        paddingRight: 15,
                        margin: 30,
                      }}
                      id="vendor-Img4"
                      onClick={() =>
                        HandleImgBorder('vendor-Img4', 'img4', 'vendor')
                      }
                    >
                      <Card.Img src={Vendor} />
                      <text style={{ color: 'black', fontSize: '1rem' }}>
                        {t("Vendor")}
                        <br />
                        <br />
                      </text>
                    </div>
                    {/* Fpo register form section card  */}
                    <div
                      style={{
                        width: 150,
                        borderRadius: '5px',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
                        paddingLeft: 15,
                        paddingRight: 15,
                        margin: 30,
                      }}
                      id="Fpo-Img5"
                      onClick={() => HandleImgBorder('Fpo-Img5', 'img5', 'fpo')}
                    >
                      <Card.Img
                        src={FpoImg}
                        style={{ width: 60, height: 60 }}
                      />
                      <text style={{ color: 'black', fontSize: '1rem' }}>
                        {t("FPO")}
                        <br />
                        <br />
                      </text>
                    </div>
                  </div>

                  {role === 'vendor' ? (
                    <div style={{ paddingTop: 25 }}>
                      <Row>
                        <Col style={{ paddingTop: 9 }}>
                          <input
                            type="checkbox"
                            id="terms"
                            defaultChecked={agreeCheckbox}
                            onChange={() => setAgree(!agreeCheckbox)}
                          />
                        </Col>
                        <Col
                          xs={11}
                          style={{ display: 'flex', justifyContent: 'left' }}
                        >
                          <Link
                            key="terms"
                            className="nav-link"
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              marginLeft: -20,
                            }}
                            to={{
                              pathname: '/regterms',
                            }}
                            target="_blank"
                          >
                            {t('AgreetoTerm')}
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  ) : null}

                  <div
                    style={{
                      marginTop: 25,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Button
                      style={MediumButtonStyle}
                      disabled={role === 'vendor' ? !agreeCheckbox : false}
                      type="submit"
                      // onClick={() => submitHandler()}
                    >
                      {t("SaveContinue")}{' '}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
