import React, { useState } from "react";
import Header3 from "../components/Header3";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './Style/header2.css';

export default function ButtonAppBar(){
  const navigate = useNavigate()
  const { t } = useTranslation();
  const [child, setChild] = useState(false);

  const headerList=[
    {
      name:'Home',
      internationalization:t("Home"),
      path:'/',
      children:false
    },
    {
      name:'Agriculture Input',
      internationalization:t("AgricultureInput"),
      path:'/productcategory',
      children:true
    },
    {
      name:'Agriculture Output',
      internationalization:t("AgricultureOutput"),
      path:'https://www.cropway.com/seller-studio/',
      children:false
    },
    {
      name:'Agriculture Services',
      internationalization:t("AgricultureServices"),
      path:'https://www.cropway.com/agriculture-services/',
      children:false
    },
    {
      name:'Farm Equipment Rental',
      internationalization:t("FarmEquipmentRental"),
      path:'/farmequipmentrental',
      children:false
    },
    // {
    //   name:'Sell Farm Produce',
    //   internationalization:t("Sell-Farm-Produce"),
    //   path:'/seller',
    //   children:false
    // },
    {
      name:'Buy Farm Produce',
      internationalization:t("Buy-Farm-Produce"),
      path:'https://seller-studio.cropway.com/',
      children:false
    },
    {
      name:'Resources & Insights',
      internationalization:t("Resources&Insights"),
      path:'https://www.cropway.com/blog/',
      children:false
    },
    {
      name:'Help',
      internationalization:t("Help"),
      path:'https://www.cropway.com/faqs/',
      children:false
    },
  ]

  return (
    <div className="header-2" onMouseLeave={()=>setChild()}>
        <ul className="header-2-ul">
          {
            headerList?.map((res, key)=>(
              <li
                key={key}
                className="header-2-li" 
                onMouseEnter={()=>(res.children ? setChild(res.name) : setChild(false))}
                onClick={()=>res.path.startsWith('/') ? navigate(res.path) : window.open(res.path)}
              >
                <text 
                 className="header-2-text"
                >
                  {res.internationalization}
                </text>
              </li>
            ))
          }
      </ul>
      <Header3
        setChild={setChild}
        child={child}
      />
    </div>
  );
}
