import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "2px solid #5DA7CA",
    "&:hover": {
      borderBottonColor: "#5DA7CA",
    },
  },
}));

export default function InputAdornments({ showEdit }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const classes = useStyles();
  const [values, setValues] = React.useState({
    UserName: `${user && user.username}`,
    FullName: `${user && user.first_name} ${
      user && user.last_name
    }`,
    Mobile: `${user && user.phone}`,
    Email: `${user && user.email}`,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-UserName"
            value={values.UserName}
            onChange={handleChange("UserName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("User-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <Input
            style={{ borderBottom: "2px solid #5DA7CA" }}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-FullName"
            value={values.FullName}
            onChange={handleChange("FullName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Full-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          <Input
            style={{ borderBottom: "2px solid #5DA7CA" }}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-Mobile"
            value={values.Mobile}
            onChange={handleChange("Mobile")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Mobile-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl fullWidth className={classes.margin}>
          {/* <StylesProvider jss={jss}> */}
          <Input
            //  jss={jss}
            style={{ borderBottom: "2px solid #5DA7CA" }}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-Email"
            value={values.Email}
            onChange={handleChange("Email")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Email")}</text>
              </InputAdornment>
            }
          />
          {/* </StylesProvider> */}
        </FormControl>
      </div>
    </div>
  );
}
