import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../../components/message";
import { SmallButtonStyle } from "../../../components/ButtonStyle";
import {BackgroundGradientStyle} from '../../../components/BackgoundGradient';
  export default class LogisticsReg4 extends React.Component {
    state = {
      CRegNo:"",
      CName:"",
      PANNo:"",
      GSTIN:"",
      IBARegNo:"",
      EstablishmentYrs:"",
      error : null,
      MaterialMovRoute:"",
      Full_Load_Freight_Rate:"",
      Small_Package_Freight_Rate:"",
      MaterialMovFreq:"",
      DeliverInsured:"",
      RouteMap:"",
      Owned_Self_Vehicles:"",
      Associate_Vehicles:"",
      No_of_MGV:"",
      No_of_LMV:"",
      No_of_HMV:"",
      No_of_HGMV:"",
      No_of_HTV:"",
      Load_Unload_Facility:"",
      Small_Package_Facility:"",
    }
  
   
saveandContinue = async (e) => {
  await this.props.uploadData();
  this.props.retrieve_CompanyBranch();
  this.props.nextStep();
 };

 back=()=>{
  this.props.prevStep();
 }


render(){
  const { error } = this.state
 
  return (
    <div id="root">
      <div style={BackgroundGradientStyle}>
      <div style={{fontSize:12}}>{error && <Message variant="danger">{error}</Message>}</div>
      {/* {loading && <Loader/>} */}
    
      <div id="root" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
      <div style={{width:'100%',padding:0,display:'flex',justifyContent:'center',alignItems:'center'}}>
        <div style={{padding:'20px 10px',margin:'20px 20px 20px 20px'}}>
          <div style={{backgroundColor:'white',width:460,height:1000,padding:'10px 25px'}}>
          <strong style={{color:'black',fontSize:24, display:"flex", flexDirection:"row", alignItems:"left", justifyContent:"left" }}>Preview Summary </strong>
            {/* <Form onSubmit={submitHandler}> */}
            <Form.Label style={{marginTop:5,fontSize:13, display:"flex", flexDirection:"row", alignItems:"left", justifyContent:"left", color: "black"}}>Submit to create a new Logistics account.</Form.Label>


    <div style={{marginTop:25}}>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>Comapany Registration Number</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.CRegNo}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>PAN Number</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.PANNo}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>GSTIN</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.GSTIN}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Name of Transport Company</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.CName}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>IBA Registration Number</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.IBARegNo}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>Year of Establishment</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.EstablishmentYrs}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>No. of Drivers</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.No_of_Driver}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>No. of Vehicles</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.No_of_Vehicles}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>Vehicle owned by self</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.Owned_Self_Vehicles}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>Number of MGV</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.No_of_MGV}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Number of LMV</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.No_of_LMV}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>Number of HMV</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.No_of_HMV}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Number of HGMV</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.No_of_HGMV}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Number of HTV</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.No_of_HTV}</strong>
        </div>
    
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Facilities of loading/unloading</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.Load_Unload_Facility}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Do you provide facilities for small packages</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.Small_Package_Facility}</strong>
        </div>
      
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Material Movement Route/Transport Route</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.MaterialMovRoute}</strong>
        </div>
        <div style={styles.confirmtext}> 
            <strong style={styles.textinput}>Freight Rate of Full loading</strong>
            <strong>:</strong> 
            <strong style={styles.textinput}>{this.props.values.Full_Load_Freight_Rate}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Freight Rate for Small Package</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.Small_Package_Freight_Rate}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Frequency of Material Movement in route</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.MaterialMovFreq}</strong>
        </div>
        <div style={styles.confirmtext}> 
          <strong style={styles.textinput}>Is delivery insured</strong>
          <strong>:</strong> 
          <strong style={styles.textinput}>{this.props.values.DeliverInsured}</strong>
        </div>
        </div>
        

            <div style={{marginTop:25,display:'flex',justifyContent:"center",alignItems:'center',flexDirection:'column'}}>
                <Button type="submit" onClick={this.saveandContinue} style={SmallButtonStyle}>
                  Submit <i style={{marginLeft: 5}} className="fas fa-angle-right"/>
                </Button> 
                <div style={{marginTop:10}}></div>
                <Button type="submit" onClick={this.props.prevStep} style={SmallButtonStyle}>
                  Back<i style={{marginLeft: 5}} className="fas fa-angle-left"/>
                </Button> 
            </div>
            
          </div>
          </div>
      </div>
      </div>
      </div>
    </div>
  );
}
}
     
const styles ={
    textinput: {
      color: "#000",
      backgroundColor: "transparent",
      paddingLeft: 0,
      height: 'auto',
      margin:3,
      width:'45%',
    },

   
    confirmtext:{
      display:'flex',
      flexDirection:'row'
    }
  };
  
