import React from 'react'
import { Form, Button, Row, Col, Container } from 'react-bootstrap'
import { MediumButtonStyle } from '../../../components/ButtonStyle'
import { BackgroundGradientStyle } from '../../../components/BackgoundGradient'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

export default class FpoReg3 extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadimage: '',
      preview: '',
      idType: '',
      idNo: '',
      idImage: '',
      CwcImage: '',
      // gst:"",
      gstNo: '',
      gstCert: '',
      storageArea: '',
      storageAreaImage: '',
      storeFrontImage: '',
      CWCLiveImage: '',
    }
  }

  validation = async () => {
    let aadhaar_number = this.props.values.idnumber
    let pan_number = this.props.values.pannumber
    let gst_number = this.props.values.gstnumber

    // let voter_number = this.props.values.idnumber
    // let aadhar_regex = new RegExp(/^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/)
    // let voter_regex = new RegExp(/^[A-Z]{3}[0-9]{7}$/)

    if (
      aadhaar_number.length < 10 ||
      aadhaar_number.length > 12 ||
      aadhaar_number.length === 11
    ) {
      this.setState({ AadharNumberErr: 'Please enter valid Id number' })
    } else if (pan_number.length < 10 || pan_number.length > 10) {
      this.setState({ PanNumberErr: 'Please enter valid Pan number' })
    } else if (gst_number.length > 15 || gst_number.length < 15) {
      this.setState({ GstNumberErr: 'Please enter valid GST number' })
    } else {
      this.setState({ AadharNumberErr: '' })
      this.setState({ PanNumberErr: '' })
      this.setState({ GstNumberErr: '' })
      // this.setState({ VoterIdErr: '' })
      await this.props.postData()
      this.props.nextStep()
    }
    // if (regex.test(aadhaar_number) == true) {
    //   console.log('True')
    //   this.setState({ AadharNumberErr: '' })
    // }
  }

  setImg = (evt) => {
    this.setUploadimage(evt.target.files[0])
  }

  idtypelist = [{ title: 'Aadhaar Card' }, { title: 'Voter Id Card' }]

  gstlist = [{ title: 'Yes' }, { title: 'No' }]

  flatProps = {
    options: this.idtypelist.map((option) => option.title),
    options: this.gstlist.map((option) => option.title),
  }

  defaultProps1 = {
    options: this.idtypelist,
    getOptionLabel: (option) => option.title,
  }
  defaultProps2 = {
    options: this.gstlist,
    getOptionLabel: (option) => option.title,
  }

  saveandContinue = async (e) => {
    await this.props.postData();
    this.props.nextStep()
  }

  render() {
    const formStyle = {
      formGroupStyle: {
        borderRadius: 0,
        marginTop: 20,
        border: 0,
        borderBottom: '1px solid #5DA7CA',
        alignItems: 'right',
      },

      formControlStyle: {
        borderRadius: 0,
        fontSize: 15,
        boxShadow: 'none',
        paddingLeft: 0,
        border: 0,
      },

      formErrorTextStyle: {
        color: 'red',
        position: 'absolute',
        left: 'auto',
      },
    }

    return (
      <Container>
        {/* {error && 
          <Message width="100%" variant="danger">
            'something is wrong can you please check the fields, are they correctly filled or not?'
          </Message>
         } */}
        <Col
          style={{
            ...BackgroundGradientStyle,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Row>
            <Form
              className="px-2 py-2"
              style={{ width: 300, backgroundColor: 'white' }}
            >
              <strong
                style={{
                  color: 'black',
                  fontSize: 24,
                  display: 'flex',
                  alignItems: 'left',
                }}
              >
                Create Account
              </strong>
              <text
                style={{
                  display: 'flex',
                  alignItems: 'left',
                }}
              >
                Fill the below form to create a new Cropway FPO account.
              </text>
              {/* start comment default value */}
              <Autocomplete
                style={{ marginTop: -20, marginBottom: -5 }}
                {...this.defaultProps1}
                defaultValue={this.props.values.idcardtype}
                id="text"
                onInputChange={(e, v) =>
                  this.props.handleChange('idcardtype', v)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Id Type"
                    margin="normal"
                  />
                )}
              />
              {/* dynamic  */}

              {(this.props.values.idcardtype === 'Aadhaar Card' ||
                this.props.values.idcardtype === 'Voter Id Card') && (
                <Form.Group controlId="idNo" style={formStyle.formGroupStyle}>
                  <Form.Control
                    style={formStyle.formControlStyle}
                    type="text"
                    placeholder="Enter Id Number"
                    defaultValue={this.props.values.idnumber}
                    onChange={(e) =>
                      this.props.handleChange('idnumber', e.target.value)
                    }
                  ></Form.Control>
                  <Form.Text style={formStyle.formErrorTextStyle}>
                    {this.state.AadharNumberErr}
                  </Form.Text>
                </Form.Group>
              )}

              <Form.Group controlId="uploadimage">
                <Form.Label
                  style={{
                    borderRadius: 0,
                    marginTop: 20,
                    border: 0,
                    display: 'flex',
                    direction: 'row',
                  }}
                >
                  Upload Photo ID
                </Form.Label>
                <input
                  type="file"
                  onChange={(evt) => this.props.handleDoc7(evt)}
                  style={{ marginLeft: 0 }}
                />
              </Form.Group>

              <Form.Group controlId="uploadimage">
                <Form.Label
                  style={{
                    borderRadius: 0,
                    marginTop: 20,
                    border: 0,
                    display: 'flex',
                    direction: 'row',
                  }}
                >
                  Upload Passport Photo of CWC
                </Form.Label>
                <input
                  type="file"
                  onChange={(evt) => this.props.handleDoc1(evt)}
                  style={{ marginLeft: 0 }}
                />
              </Form.Group>

              <Form.Group controlId="panNo" style={formStyle.formGroupStyle}>
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="text"
                  placeholder="PAN Number"
                  defaultValue={this.props.values.pannumber}
                  onChange={(e) =>
                    this.props.handleChange('pannumber', e.target.value)
                  }
                ></Form.Control>
                <Form.Text style={formStyle.formErrorTextStyle}>
                  {this.state.PanNumberErr}
                </Form.Text>
              </Form.Group>

              <Autocomplete
                style={{ marginTop: -20, marginBottom: -5 }}
                {...this.defaultProps2}
                defaultValue={this.props.values.gst}
                id="gst"
                onInputChange={(e, v) =>
                  this.props.handleChange('gst', v === 'Yes' ? 'True' : 'False')
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Do you have GST Certificate"
                    margin="normal"
                  />
                )}
              />
              {/*  Comment gst number and use default true */}
              {this.props.values.gst === true ||
              this.props.values.gst === 'True' ? (
                <div>
                  <Form.Group
                    controlId="gstNo"
                    style={{
                      borderRadius: 0,
                      marginTop: 20,
                      border: 0,
                      borderBottom: '1px solid #5DA7CA',
                      display: 'flex',
                      direction: 'row',
                    }}
                  >
                    <Form.Control
                      style={{
                        width: 750,
                        borderRadius: 0,
                        fontSize: 15,
                        boxShadow: 'none',
                        paddingLeft: 0,
                        border: 0,
                      }}
                      type="text"
                      placeholder="GST Number"
                      defaultValue={this.props.values.gstnumber}
                      onChange={(e) =>
                        this.props.handleChange('gstnumber', e.target.value)
                      }
                    ></Form.Control>
                    <br />
                    <Form.Text
                      style={{ color: 'red', position: 'absolute', margin: 40 }}
                    >
                      {this.state.GstNumberErr}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group controlId="uploadimage">
                    <Form.Label
                      style={{
                        borderRadius: 0,
                        marginTop: 20,
                        border: 0,
                        display: 'flex',
                        direction: 'row',
                      }}
                    >
                      Upload GST Certificate
                    </Form.Label>
                    <input
                      type="file"
                      onChange={(evt) => this.props.handleDoc2(evt)}
                      style={{ marginLeft: 0 }}
                    />
                  </Form.Group>
                </div>
              ) : null}

              <Form.Group
                controlId="storageArea"
                style={formStyle.formGroupStyle}
              >
                <Form.Control
                  style={formStyle.formControlStyle}
                  type="numeric"
                  placeholder="Enter Storage Space (sq. ft)"
                  defaultValue={this.props.values.storageSpace}
                  onChange={(e) =>
                    this.props.handleChange('storageSpace', e.target.value)
                  }
                ></Form.Control>
              </Form.Group>
              {/* End comment */}
              <Form.Group controlId="uploadimage">
                <Form.Label
                  style={{
                    borderRadius: 0,
                    marginTop: 20,
                    border: 0,
                    display: 'flex',
                    direction: 'row',
                  }}
                >
                  Upload Storage Area Photo
                </Form.Label>
                <input
                  type="file"
                  onChange={(evt) => this.props.handleDoc3(evt)}
                  style={{ marginLeft: 0 }}
                />
              </Form.Group>

              <Form.Group controlId="uploadimage">
                <Form.Label
                  style={{
                    borderRadius: 0,
                    marginTop: 20,
                    border: 0,
                    display: 'flex',
                    direction: 'row',
                  }}
                >
                  Upload Shop/Store Front Photo
                </Form.Label>
                <input
                  type="file"
                  onChange={(evt) => this.props.handleDoc4(evt)}
                  style={{ marginLeft: 0 }}
                />
              </Form.Group>

              <Form.Group controlId="uploadimage">
                <Form.Label
                  style={{
                    borderRadius: 0,
                    marginTop: 20,
                    border: 0,
                    display: 'flex',
                    direction: 'row',
                  }}
                >
                  Upload Photo of FPO
                </Form.Label>
                <input
                  type="file"
                  onChange={(evt) => this.props.handleDoc8(evt)}
                  style={{ marginLeft: 0 }}
                />
              </Form.Group>

              <div
                style={{
                  marginTop: 25,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Button
                  className="my-2"
                  onClick={this.saveandContinue}
                  style={MediumButtonStyle}
                >
                  Save & Preview
                  <i className="fas fa-angle-right mx-2" />
                </Button>

                <Button
                  className="my-2"
                  onClick={this.props.prevStep}
                  style={MediumButtonStyle}
                >
                  Back
                  <i className="fas fa-angle-left mx-2" />
                </Button>
              </div>
            </Form>
          </Row>
        </Col>
      </Container>
    )
  }
}
