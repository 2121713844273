import {
  SHOW_PAGE,
  SHOW_PAGE_FAIL,
  SHOW_IMG_SEARCH,
  SHOW_IMG_SEARCH_FAIL,
} from "../constants/ShowpageConstants";

export const Showpage = (sidebar) => async (dispatch) => {
  try {
    // console.log("inside showpage" + sidebar)
    dispatch({
      type: SHOW_PAGE,
      payload: sidebar,
    });
  } catch (error) {
    dispatch({
      type: SHOW_PAGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const ShowImgSearch = (ImageSearch) => async (dispatch) => {
  try {
    // console.log("inside showpage" + sidebar)
    dispatch({
      type: SHOW_IMG_SEARCH,
      payload: ImageSearch,
    });
  } catch (error) {
    dispatch({
      type: SHOW_IMG_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
