// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
import React from 'react';
import { useState } from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
function GoToTopButton() {

const [visible,setVisible] = useState(false)
const ToggleVisible =()=>{
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300){
        setVisible(true)
    }
    else if (scrolled <= 300){
        setVisible(false)
    }

}

const ScrollToTop =()=>{
    window.scrollTo({
        top:0,
        behavior:'smooth'
    });
}

window.addEventListener('scroll',ToggleVisible)

  return (
         <FaArrowCircleUp style={{display: visible ? 'inline' : 'none', position: 'fixed', 
            width: '5%',
            left: '90%',
            bottom: '60px',
            height: '60px',
            fontSize: '13rem',
            zIndex: 1,
            cursor: 'pointer',
            color: 'rgba(92,167,201,1)'}} onClick={ScrollToTop}/>
  );
}

export default GoToTopButton;