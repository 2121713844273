import React from "react";
import { Form, Button } from "react-bootstrap";
import Message from "../../components/message";
import {SmallButtonStyle} from "../../components/ButtonStyle";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";

export default class Partner2 extends React.Component {
  state = {
    fName: "",
    lName: "",
    designation: "",
    phone: "",
    altPhoneNo: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    district: "",
    postalCode: "",
    panNo: "",
    LogisticID: null,
  };

  saveandContinue = async (e) => {
    await this.props.uploadDataPartner();
    this.props.history.push("/");
  };

  back = () => {
    this.props.prevStep();
  };

  render() {
    const {
      error
    } = this.state;
    return (
      <div id="root">
        <div style={BackgroundGradientStyle}>
          <div style={{ fontSize: 12 }}>
            {error && <Message variant="danger">{error}</Message>}
          </div>
          {/* {loading && <Loader/>} */}

          <div
            id="root"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ padding: "20px 10px", margin: "20px 20px 20px 20px" }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    width: 360,
                    height: 600,
                    padding: "10px 25px",
                  }}
                >
                  <strong
                    style={{
                      color: "black",
                      fontSize: 24,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                    }}
                  >
                    Preview Partner Summary{" "}
                  </strong>
                  {/* <Form onSubmit={submitHandler}> */}
                  <Form.Label
                    style={{
                      marginTop: 5,
                      fontSize: 13,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "left",
                      justifyContent: "left",
                      color: "black",
                    }}
                  >
                    Submit to confirm you partner details.
                  </Form.Label>

                  <div style={{ marginTop: 25 }}>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>First Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.fName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Last Name</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.lName}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Designation</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.designation}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Mobile Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.phone}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>
                        Alternate Mobile Number
                      </strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.altPhoneNo}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Address Line1</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.partner_address1}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Address Line2</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.partner_address2}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Country</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.partner_country}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>State</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.partner_state}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>District</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.partner_district}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>Pincode</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.partner_postalCode}
                      </strong>
                    </div>
                    <div style={styles.confirmtext}>
                      <strong style={styles.textinput}>PAN Number</strong>
                      <strong>:</strong>
                      <strong style={styles.textinput}>
                        {this.props.values.panNo}
                      </strong>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={this.saveandContinue}
                      type="submit"
                      style={SmallButtonStyle}
                    >
                      Submit{" "}
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-right" />
                    </Button>
                    <div style={{ marginTop: 10 }}></div>
                    <Button
                      onClick={this.back}
                      type="submit"
                      style={SmallButtonStyle}
                    >
                      Back
                      <i style={{ marginLeft: 5 }} className="fas fa-angle-left" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  textinput: {
    color: "#000",
    backgroundColor: "transparent",
    paddingLeft: 0,
    height: "auto",
    margin: 3,
    width: "45%",
  },

  confirmtext: {
    display: "flex",
    flexDirection: "row",
  },
};
