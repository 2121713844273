import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_RESET,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_LIST_MINE_SUCCESS,
  ORDER_LIST_MINE_FAIL,
  ORDER_LIST_MINE_RESET,
  ORDER_LIST_MINE_REQUEST,
  ORDER_PAYMENT_CREATE_REQUEST,
  ORDER_PAYMENT_CREATE_SUCCESS,
  ORDER_PAYMENT_CREATE_FAIL,
} from "../constants/OrderConstants";

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = { loading: true, order: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderMineReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MINE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_LIST_MINE_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_LIST_MINE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case ORDER_LIST_MINE_RESET:
      return { orders: [] };

    default:
      return state;
  }
};



export const orderPaymentReducer = (state = {orderID:[]}, action) => {
  switch (action.type) {
    case ORDER_PAYMENT_CREATE_REQUEST:
      return {
        orderLoading: true,
      };
    case  ORDER_PAYMENT_CREATE_SUCCESS:
      return {
        orderLoading: false,
        success: true,
        orderPayment: action.payload,
      };
    case  ORDER_PAYMENT_CREATE_FAIL:
      return {
        orderLoading: false,
        OrderError: action.payload,
      };

    default:
      return state;
  }
};
