
import React, {useEffect, useState} from 'react';

import { useSelector } from "react-redux";
import { 
  Button,
} from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import VendorUploadedDocs from './VendorUploadedDocs'
import CWCUploadedDocs from './CWCUploadedDocs'
import LogisticsUploadedDocs from './LogisticsUploadedDocs'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: 'black',
  },
  border:{
    borderBottom:"1px solid #5DA7CA",
    // textTransform: 'capitalize',
}

}));





function RegistarionUploadedDocs({history}) {

  const {user} = useSelector((state) => state.auth);
  const classes = useStyles();

  return (
    <div >

{ user && user.role==='vendor' ?  //if
<VendorUploadedDocs /> 
:  //else
user.role==='logistics' ? //if
<LogisticsUploadedDocs /> 
:  //else
<CWCUploadedDocs />
}
     
    </div>
  );
}

export default RegistarionUploadedDocs
