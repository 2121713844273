import {
  FARMER_LISTED_PRODUCT_LIST_REQUEST,
  FARMER_LISTED_PRODUCT_LIST_SUCCESS,
  FARMER_LISTED_PRODUCT_LIST_FAIL,
  NEGOTIATION_LIST_REQUEST,
  NEGOTIATION_LIST_SUCCESS,
  NEGOTIATION_LIST_FAIL,
  QUOTATION_LIST_REQUEST,
  QUOTATION_LIST_SUCCESS,
  QUOTATION_LIST_FAIL,
  QUOTATION_VENDOR_AS_CUSTOMER_LIST_REQUEST,
  QUOTATION_VENDOR_AS_CUSTOMER_LIST_SUCCESS,
  QUOTATION_VENDOR_AS_CUSTOMER_LIST_FAIL,
  PREVIOUS_TRADE_LIST_REQUEST,
  PREVIOUS_TRADE_LIST_SUCCESS,
  PREVIOUS_TRADE_LIST_FAIL,
} from "../constants/DashboardConstants";

import axios from "axios";
import { BASE_URL_HOSTED } from "../constants/api";
import axiosAPI from "../components/AxiosAPI";

//for farmer's listed product
export const listfarmerlistedproducts = (id) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: FARMER_LISTED_PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/farmerlistedproducts/${id}`
    );

    dispatch({
      type: FARMER_LISTED_PRODUCT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FARMER_LISTED_PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listNegotiations = (id, role) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: NEGOTIATION_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/getnegotiationlist/${id}/${role}`
    );
    // .then((res)=> console.log(res))
    //  console.log(data.data)
    dispatch({
      type: NEGOTIATION_LIST_SUCCESS,
      NegotiationData: data.data,
      // NegotiationProduct: data.product
    });
  } catch (error) {
    dispatch({
      type: NEGOTIATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listQuotations = (id, role) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: QUOTATION_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/getquotationlist/${id}/${role}`
    );
    // .then((res)=> console.log(res))
    //  console.log(data.data)
    dispatch({
      type: QUOTATION_LIST_SUCCESS,
      QuotationData: data.data,
      // NegotiationProduct: data.product
    });
  } catch (error) {
    dispatch({
      type: QUOTATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listQuotationsVendorasCustomer =
  (id, role) => async (dispatch) => {
    try {
      // console.log("inside list")
      dispatch({ type: QUOTATION_VENDOR_AS_CUSTOMER_LIST_REQUEST });
      const { data } = await axios.get(
        `${BASE_URL_HOSTED}api/getquotationlist/${id}/${role}`
      );
      // .then((res)=> console.log(res))
      //  console.log(data.data)
      dispatch({
        type: QUOTATION_VENDOR_AS_CUSTOMER_LIST_SUCCESS,
        QuotationData: data.data,
        // NegotiationProduct: data.product
      });
    } catch (error) {
      dispatch({
        type: QUOTATION_VENDOR_AS_CUSTOMER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listPreviousTrades = (id, role) => async (dispatch) => {
  try {
    // console.log("inside list")
    dispatch({ type: PREVIOUS_TRADE_LIST_REQUEST });
    const { data } = await axios.get(
      `${BASE_URL_HOSTED}api/getPreviousTrades/${id}/${role}`
    );
    // .then((res)=> console.log(res))
    //  console.log("dvhdvhjd",data.data)

    dispatch({
      type: PREVIOUS_TRADE_LIST_SUCCESS,
      PreviousTradesData: data.data,
    });
  } catch (error) {
    dispatch({
      type: PREVIOUS_TRADE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const SendQuotationMail =
  (
    offered_price,
    quantity,
    Reconsidered_price,
    choice,
    vendor_id,
    customer_id,
    pdt_name
  ) =>
  async (dispatch, getState) => {
    // try {
    const data = {
      offered_price: offered_price,
      quantity: quantity,
      Reconsidered_price: Reconsidered_price,
      choice: choice,
      vendor_id: vendor_id,
      customer_id: customer_id,
      pdt_name: pdt_name,
    };

    axiosAPI
      .post(`${BASE_URL_HOSTED}core/sendQuotationEmail/`, data)

      .then((res) => console.log(res))
      // // // alert(`Your request for negotiating in ₹${price} has been sent to the farmer if he accept your offer we will notify you`),

      // // // history.push("/vendordashboard/"),
      // // )
      .catch((error) => console.log(""));
    // } catch (error) {
    //   console.log(error);
    // }
  };
