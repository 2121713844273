import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {
  API_URL_VENDORBYUSERID,
  API_URL_VENDORS,
} from "../screens/Registers/Constants";
import { SmallButtonStyle, SmallButtonStyleMobile } from "./ButtonStyle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  title: {
    color: "black",
  },
  border: {
    borderBottom: "1px solid #5DA7CA",
    // textTransform: 'capitalize',
  },
}));

function VendorPersonalInfo({ history }) {
  const {user} = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.access}`,
    },
  };
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:600px)");
  const [values, setValues] = useState({
    vendorID: "",
    firstName: "",
    // lastName: lastName,
    firmName: "",
    mobileNo: "",
    firmAddress1: "",
    firmAddress2: "",
    email: "",
    website: "",
    landlineNo: "",
    businessAddress1: "",
    businessAddress2: "",
    ownershipInfo: "",
    natureBusiness: "",
    startYear: "",
    annualSales: "",
    // _NoofProduct: qtyProduct,
    // _website_sell: isOtherWeb1,
    // isOtherWeb1: isOtherWeb1,
    // altMobileNo: altMobileNo,
    panNo: "",
    gstNo: "",
    haveGst: null,
    // _city: villTownWard,
    district: "",
    state: "",
    country: "",
    BankPostal: "",

    // loading: true,
    // password: password,
    // userID: userID
  });

  const [showEdit, setShowEdit] = useState(false);

  const [loading, setLoading] = useState(true);
  const [fresher, setFresher] = useState(false);
  // const [loading, setLoading] = useState(false)

  const handleClickShowEdit = async () => {
    await setShowEdit(!showEdit);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  ////////////////////////////////////////////////////////////////////////////////

  const UpdateData = async () => {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.access}`,
      },
    };

    let country_field = values.country;

    let state_field = values.state;

    // let district_obj =
    let district_field = values.district;

    let form_data = new FormData();
    let ParticularImage = values._uploadregpart;

    form_data.append("firmName", values.firmName);
    form_data.append("firmAddress1", values.firmAddress1);
    form_data.append("firmAddress2", values.firmAddress2);
    form_data.append("firstName", values.firstName);
    if (values.mobileNo) {
      form_data.append("mobileNo", values.mobileNo);
    }
    form_data.append("email", values.email);
    form_data.append("website", values.website);
    if (values.landlineNo && values.landlineNo !== null) {
      //console.log("values.landlineNo");
      form_data.append("landlineNo", parseInt(values.landlineNo));
    }
    form_data.append("businessAddress1", values.businessAddress1);
    form_data.append("businessAddress2", values.businessAddress2);
    form_data.append("country", country_field);
    form_data.append("state", state_field);
    form_data.append("district", district_field);
    if (values.pincode && values.pincode !== null) {
      form_data.append("pincode", values.pincode);
    }
    form_data.append("ownershipInfo", values.ownershipInfo);
    form_data.append("natureBusiness", values.natureBusiness);
    if (values.startYear) {
      form_data.append("startYear", values.startYear);
    }
    if (values.annualSales && values.annualSales !== null) {
      form_data.append("annualSales", values.annualSales);
    }

    form_data.append("panNo", values.panNo);

    if (values.haveGst && values.haveGst !== null) {
      form_data.append("haveGST", values.haveGst);
    }
    form_data.append("gstNo", values.gstNo);

    let response = -[];

    await axios
      .post(API_URL_VENDORS, form_data, config)
      .then((res) => {
        // console.log(res)
        setLoading(false);
        response = res.response;
        retrieve_Vendor();
        setShowEdit(false);

        // history.push('/ManageProfile')
      })
      .catch((error) => {
        response = error;
        setLoading(false);
      });

    return response;
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const retrieve_Vendor = async () => {
    try {
      axios.get(`${API_URL_VENDORBYUSERID}`, config).then((res) => {
        if (res.data.length > 0) {
          setValues({
            vendorID: res.data[0].id,
            firstName: res.data[0].firstName,
            // lastName: lastName,
            firmName: res.data[0].firmName,
            mobileNo: res.data[0].mobileNo,
            firmAddress1: res.data[0].firmAddress1,
            firmAddress2: res.data[0].firmAddress2,
            email: res.data[0].email,
            website: res.data[0].website,
            landlineNo: res.data[0].landlineNo,
            businessAddress1: res.data[0].businessAddress1,
            businessAddress2: res.data[0].businessAddress2,
            ownershipInfo: res.data[0].ownershipInfo,
            natureBusiness: res.data[0].natureBusiness,
            startYear: res.data[0].startYear,
            annualSales: res.data[0].annualSales,
            // _NoofProduct: res.data[0].qtyProduct,
            // _website_sell: res.data[0].isOtherWeb1,
            // isOtherWeb1: isOtherWeb1,
            // altMobileNo: altMobileNo,
            panNo: res.data[0].panNo,
            gstNo: res.data[0].gstNo,
            haveGst: res.data[0].haveGST,
            // _city: villTownWard,
            district: res.data[0].district,
            state: res.data[0].state,
            country: res.data[0].country,
            // BankPostal: res.data[0].pincode,

            // loading: false,
            // password: password,
            ParticularImage: res.data[0].regParicularImage,
            gstImage: res.data[0].gstImage,
            // userID: res.data[0].userID
          });
          setLoading(false);
        } else {
          setLoading(false);
          setFresher(true);
        }
      });
    } catch (error) {
      //console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      retrieve_Vendor();
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <div>
        <FormControl fullWidth className={classes.margin}>
          <Input
            //   className={classes.border}
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firstName"
            value={values.firstName}
            onChange={handleChange("firstName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Authorized-Person-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firmName"
            value={values.firmName}
            onChange={handleChange("firmName")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Firm-Name")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-mobileNo"
            value={values.mobileNo}
            onChange={handleChange("mobileNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Mobile-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firmAddress1"
            value={values.firmAddress1}
            onChange={handleChange("firmAddress1")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Reg-Add-Line")}1</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-firmAddress2"
            value={values.firmAddress2}
            onChange={handleChange("firmAddress2")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Reg-Add-Line")}2</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-email"
            value={values.email}
            onChange={handleChange("email")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Email")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-website"
            value={values.website}
            onChange={handleChange("website")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Website")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-landlineNo"
            value={values.landlineNo}
            onChange={handleChange("landlineNo")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Landline-Number")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            className={classes.border}
            disableUnderline="true"
            disabled={showEdit ? false : true}
            id="standard-adornment-businessAddress1"
            value={values.businessAddress1}
            onChange={handleChange("businessAddress1")}
            dir="rtl"
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Buss-Add-Line")}1</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-businessAddress2"
            value={values.businessAddress2}
            onChange={handleChange("businessAddress2")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Buss-Add-Line")}2</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-ownershipInfo"
            value={values.ownershipInfo}
            onChange={handleChange("ownershipInfo")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Ownership-Info")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-natureBusiness"
            value={values.natureBusiness}
            onChange={handleChange("natureBusiness")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Nature-of-Business")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-startYear"
            value={values.startYear}
            onChange={handleChange("startYear")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Year-of-Commencement")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-annualSales"
            value={values.annualSales}
            onChange={handleChange("annualSales")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Annual-Sales")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-panNo"
            value={values.panNo}
            onChange={handleChange("panNo")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("PAN")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        {values.haveGst ? (
          <FormControl fullWidth className={classes.margin}>
            <Input
              //  jss={jss}
              className={classes.border}
              // disabled="true"
              disableUnderline="true"
              disabled={showEdit ? false : true}
              dir="rtl"
              id="standard-adornment-gstNo"
              value={values.gstNo}
              onChange={handleChange("gstNo")}
              endAdornment={
                <InputAdornment className={classes.title} position="start">
                  <text>:{t("GST-No")}</text>
                </InputAdornment>
              }
            />
          </FormControl>
        ) : null}

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize={true}
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-state"
            value={values.state}
            onChange={handleChange("state")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("state")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-district"
            value={values.district}
            onChange={handleChange("district")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("District")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl fullWidth className={classes.margin}>
          <Input
            //  jss={jss}
            // autoCapitalize
            className={classes.border}
            // disabled="true"
            disableUnderline="true"
            disabled={showEdit ? false : true}
            dir="rtl"
            id="standard-adornment-block"
            value={values.block}
            onChange={handleChange("block")}
            endAdornment={
              <InputAdornment className={classes.title} position="start">
                <text>:{t("Block")}</text>
              </InputAdornment>
            }
          />
        </FormControl>

        {/* <FormControl  fullWidth className={classes.margin}>
         <Input
        //  jss={jss}
          // autoCapitalize
          className={classes.border}
          // disabled="true"
          disableUnderline="true"
          disabled ={showEdit ? false : true}
          dir="rtl"
           id="standard-adornment-Village"
           value={values.Village}
           onChange={handleChange('Village')}
           endAdornment={<InputAdornment className={classes.title}  position="start" ><text>:Village</text></InputAdornment>}
         />
       </FormControl> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={async () => await handleClickShowEdit()}
          >
            {t("Edit-Profile")}
          </Button>
          <Button
            variant="contained"
            style={matches ? SmallButtonStyle : SmallButtonStyleMobile}
            onClick={() => UpdateData()}
          >
            {t("Save-Settings")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default VendorPersonalInfo;
