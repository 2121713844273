import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Card, Image, ListGroupItem } from "react-bootstrap";
import { PushToCart } from "../actions/cartAction";
import { BASE_URL_HOSTED } from "../constants/api";
import {
  listfarmerlistedproducts,
  listNegotiations,
  listQuotations,
} from "../actions/DashboardActions";
import orderimg from "../assets/orders.svg";
import weatherimg from "../assets/weather.svg";
import productimg from "../assets/products.svg";
import { useNavigate } from "react-router-dom";
import { WeatherCard } from "../components/DashboardCards/WeatherCard"

function FarmerDashboard() {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const {user} = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const farmerlistedproducts = useSelector(
    (state) => state.farmerlistedproducts
  );
  const {
    FarmerListedProducts,
  } = farmerlistedproducts;

  const negotiationlist = useSelector((state) => state.negotiationlist);
  const {
    Negotiations,
    // NegotiationProduct,
  } = negotiationlist;

// For test data 
const testNeg={
  "data": [
      {
          "_id": 26,
          "pdt_id": {
              "_id": 12,
              "pdt_type": "farmproduct",
              "category": "Buy Farm Produce",
              "subcategory": null,
              "name": "Moringa powder",
              "about_farm": "Moringa farmer from Chhattisgarh, looking for export deals. I also supply other moringa related products.",
              "quantity": 300,
              "price_max": "598.00",
              "price_min": "299.00",
              "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/alice-pasqual-iDCnJbSyE14-unsplash.jpg",
              "rating": "3.00",
              "numReviews": 1,
              "negotiable": "negotiable",
              "isActive": true,
              "user": 6
          },
          "OfferAcceptedByFarmer": "Yes",
          "negotiation_price": "493.00",
          "quantity": 190,
          "farmer_id": 6,
          "vendor_id": 7
      },
      {
          "_id": 27,
          "pdt_id": {
              "_id": 12,
              "pdt_type": "farmproduct",
              "category": "Buy Farm Produce",
              "subcategory": null,
              "name": "Moringa powder",
              "about_farm": "Moringa farmer from Chhattisgarh, looking for export deals. I also supply other moringa related products.",
              "quantity": 300,
              "price_max": "598.00",
              "price_min": "299.00",
              "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/alice-pasqual-iDCnJbSyE14-unsplash.jpg",
              "rating": "3.00",
              "numReviews": 1,
              "negotiable": "negotiable",
              "isActive": true,
              "user": 6
          },
          "OfferAcceptedByFarmer": "Yes",
          "negotiation_price": "330.00",
          "quantity": 60,
          "farmer_id": 6,
          "vendor_id": 6
      },
      {
          "_id": 28,
          "pdt_id": {
              "_id": 11,
              "pdt_type": "farmproduct",
              "category": "Buy Farm Produce",
              "subcategory": null,
              "name": "Mustard",
              "about_farm": "Mustard farmer from Chhattisgarh, looking for buyers.",
              "quantity": 150,
              "price_max": "148.00",
              "price_min": "100.00",
              "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/joshua-lanzarini-Vct0oBHNmv4-unsplash.jpg",
              "rating": null,
              "numReviews": 0,
              "negotiable": "negotiable",
              "isActive": true,
              "user": 6
          },
          "OfferAcceptedByFarmer": "Yes",
          "negotiation_price": "131.00",
          "quantity": 135,
          "farmer_id": 6,
          "vendor_id": 6
      },
      {
          "_id": 29,
          "pdt_id": {
              "_id": 12,
              "pdt_type": "farmproduct",
              "category": "Buy Farm Produce",
              "subcategory": null,
              "name": "Moringa powder",
              "about_farm": "Moringa farmer from Chhattisgarh, looking for export deals. I also supply other moringa related products.",
              "quantity": 300,
              "price_max": "598.00",
              "price_min": "299.00",
              "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/alice-pasqual-iDCnJbSyE14-unsplash.jpg",
              "rating": "3.00",
              "numReviews": 1,
              "negotiable": "negotiable",
              "isActive": true,
              "user": 6
          },
          "OfferAcceptedByFarmer": "Yes",
          "negotiation_price": "598.00",
          "quantity": 300,
          "farmer_id": 6,
          "vendor_id": 6
      },
      {
          "_id": 30,
          "pdt_id": {
              "_id": 12,
              "pdt_type": "farmproduct",
              "category": "Buy Farm Produce",
              "subcategory": null,
              "name": "Moringa powder",
              "about_farm": "Moringa farmer from Chhattisgarh, looking for export deals. I also supply other moringa related products.",
              "quantity": 300,
              "price_max": "598.00",
              "price_min": "299.00",
              "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/alice-pasqual-iDCnJbSyE14-unsplash.jpg",
              "rating": "3.00",
              "numReviews": 1,
              "negotiable": "negotiable",
              "isActive": true,
              "user": 6
          },
          "OfferAcceptedByFarmer": null,
          "negotiation_price": "598.00",
          "quantity": 300,
          "farmer_id": 6,
          "vendor_id": 6
      },
      {
          "_id": 31,
          "pdt_id": {
              "_id": 10,
              "pdt_type": "farmproduct",
              "category": "Buy Farm Produce",
              "subcategory": null,
              "name": "Mushroom",
              "about_farm": "Mushroom farmer from Chhattisgarh, looking for buyer.",
              "quantity": 500,
              "price_max": "249.00",
              "price_min": "199.00",
              "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/waldemar-brandt-Ql9oYxramg0-unsplash.jpg",
              "rating": null,
              "numReviews": 0,
              "negotiable": "negotiable",
              "isActive": true,
              "user": 6
          },
          "OfferAcceptedByFarmer": null,
          "negotiation_price": "249.00",
          "quantity": 1,
          "farmer_id": 6,
          "vendor_id": 2320
      }
  ]
}
const testNegosition=testNeg.data
// console.log("test negosiation products:",testNegosition);

const testFarmerListed=[
  {
      "_id": 12,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": null,
      "name": "Moringa powder",
      "about_farm": "Moringa farmer from Chhattisgarh, looking for export deals. I also supply other moringa related products.",
      "quantity": 300,
      "price_max": "598.00",
      "price_min": "299.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/alice-pasqual-iDCnJbSyE14-unsplash.jpg",
      "rating": "3.00",
      "numReviews": 1,
      "negotiable": "negotiable",
      "isActive": true,
      "user": 6
  },
  {
      "_id": 11,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": null,
      "name": "Mustard",
      "about_farm": "Mustard farmer from Chhattisgarh, looking for buyers.",
      "quantity": 150,
      "price_max": "148.00",
      "price_min": "100.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/joshua-lanzarini-Vct0oBHNmv4-unsplash.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "negotiable",
      "isActive": true,
      "user": 6
  },
  {
      "_id": 10,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": null,
      "name": "Mushroom",
      "about_farm": "Mushroom farmer from Chhattisgarh, looking for buyer.",
      "quantity": 500,
      "price_max": "249.00",
      "price_min": "199.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/waldemar-brandt-Ql9oYxramg0-unsplash.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "negotiable",
      "isActive": true,
      "user": 6
  },
  {
      "_id": 19,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": "Vegetables",
      "name": "Cabbage",
      "about_farm": "products are good here",
      "quantity": 9,
      "price_max": "500.00",
      "price_min": "100.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/01631.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "negotiable",
      "isActive": false,
      "user": 6
  },
  {
      "_id": 20,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": "Vegetables",
      "name": "Cabbage",
      "about_farm": "Its good",
      "quantity": 2,
      "price_max": "500.00",
      "price_min": "100.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/download.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "negotiable",
      "isActive": false,
      "user": 6
  },
  {
      "_id": 27,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": "Crops",
      "name": "Tomato",
      "about_farm": "Tomato farm",
      "quantity": 200,
      "price_max": "8.00",
      "price_min": "6.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/IMG-20230104-WA0001.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "not negotiable",
      "isActive": false,
      "user": 6
  },
  {
      "_id": 28,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": "Vegetables",
      "name": "Test A",
      "about_farm": "Its good",
      "quantity": 12,
      "price_max": "174.00",
      "price_min": "97.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/fdffdfd.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "negotiable",
      "isActive": false,
      "user": 6
  },
  {
      "_id": 29,
      "pdt_type": "farmproduct",
      "category": "Buy Farm Produce",
      "subcategory": "Vegetables",
      "name": "TestN",
      "about_farm": "Its good",
      "quantity": 12,
      "price_max": "493.00",
      "price_min": "324.00",
      "image": "https://storage.googleapis.com/cropway_user_uploaded_files/files/sellingproducts/images_2.jpg",
      "rating": null,
      "numReviews": 0,
      "negotiable": "negotiable",
      "isActive": false,
      "user": 6
  }
]

// end test data

  const Quotationlist = useSelector((state) => state.Quotationlist);
  const {
    Quotations,
    // NegotiationProduct,
  } = Quotationlist;

  //Weather Part
  /////////////////////////////////////////////////

  const [status, setStatus] = useState("init");
  const [isLoaded, setIsloaded] = useState(false);
  const [state, setState] = useState({
    WindSpeed: "",
    Temp: "",
    Humidty: "",
    WeatherCondition:"",
  });

  const weatherInit = () => {
    const success = (position) => {
      setStatus("fetching");
      getWeatherData(position.coords.latitude, position.coords.longitude);
    };

    const error = () => {
      setStatus("unable");
      // alert("Unable to retrieve location.");
    };

    if (navigator.geolocation) {
      setStatus("fetching");
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      setStatus("unsupported");
      alert(
        "Your browser does not support location tracking, or permission is denied."
      );
    }
  };

  const getWeatherData = (lat, lon) => {
    const weatherApi = `https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=${""}&units=metric&appid=93935b65792108dbf1573f3ac1a11afb`;

    fetch(weatherApi)
      .then((response) => response.json())
      .then(
        (result) => {
          //   console.log(result);

          setIsloaded(true);
          setStatus("success");
          setState({
            WindSpeed: result.current.wind_speed,
            Temp: result.current.temp,
            Humidty: result.current.humidity,
            WeatherCondition: result.current.weather[0].description,
          });

          //   console.log(state.WindSpeed)
        },
        (error) => {
          //console.log(error);
          // this.setState({
          //   isLoaded: true,
          //   error
          // });
        }
      );
  };

  ////////////////////////////////////////////////////////

  useEffect(()=>{
      weatherInit();
      dispatch(listfarmerlistedproducts(user.id));
      dispatch(listNegotiations(user.id, "farmer"));
      dispatch(listQuotations(user.id, "customer"));
  }, [dispatch, user]);

  const NegotiationStatus = (id, status) => {
    const uploadData = new FormData();
    uploadData.append("OfferAcceptedByFarmer", status);
    fetch(`${BASE_URL_HOSTED}api/negotiationstatus/${id}`, {
      method: "PUT",
      body: uploadData,
    })
      .then(
        dispatch(listNegotiations(user.id, "farmer"))
      )
      .catch((error) => console.log(""));
  };

  const addToCartHandler = (
    id,
    pdtname,
    image,
    negprice,
    actualqty,
    pdt_type,
    negqty,
    pdt_variations
  ) => {
    //   console.log(id, pdtname, image, negprice, actualqty, pdt_type, negqty )

    PushToCart(
      id,
      pdtname,
      image,
      negprice,
      actualqty,
      pdt_type,
      negqty,
      user.id,
      pdt_variations,
      "True"
    ); //sending true for negotiation status for negotiated price so that negotiated price can be uniquely identified
    navigate('/cart');
  };

  const DashCard=({data})=>{
    return(
      <div
        onClick={()=>navigate(data?.path)}
        style={{
              backgroundColor: "#eefff2",
              padding:50,
              margin:10,
              borderRadius:10, 
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              flexDirection:'column',
              width:'45%',
              height:'30vh',
              maxHeight:350
            }}
      >
        <img alt="Order" src={data?.icon} style={{backgroundColor:'#fff', padding:10, borderRadius:'50%', height:'8rem', width:'8rem'}}/>
        <text variant="h6">{data?.header}</text>
      </div>
    )
  }

  let dashboardData=[
    {
      header:'Orders',
      icon:orderimg,
      path:'/previous-orders'
    },
    {
      header:'Products',
      icon:productimg,
      path:''
    },
    {
      header:'Weather',
      icon:weatherimg,
      path:'',
      details:{temprature:state.Temp, humidity:state.Humidty, windSpeed:state.WindSpeed, weatherCondition: state.WeatherCondition}
    },
    {
      header:`Today's Sales`,
      icon:productimg,
      path:''
    },
  ]

  return (
    <> 
    <div className="dashboard">
      <div className="cards-container">
        {/* <StatsCard title="Orders" value="100" icon="fas fa-shopping-cart" />
        <StatsCard title="Products" value="50" icon="fas fa-box" />
        <StatsCard title="Total Sales" value="Rs. 5000" icon="fas fa-dollar-sign" /> */}
        <WeatherCard
          temperature={state.Temp}
          wind={state.WindSpeed}
          humidity={state.Humidty}
          weatherCondition={state.WeatherCondition}
        />
      </div>
    </div>

    <div>
            
    {/* <NegotiationListCard products={testNegosition}/> */}
    {/* <QuotationListCard userInfoId={userInfo.id}/>   */}
    {/* <YourProductsListCard farmerlistedproducts={testFarmerListed} /> */}

      <ListGroupItem id="products">
        <Col>
          <h5>{t("ProductList")}</h5>
          <ul>
            <Card md={10}>
              <li>
                {FarmerListedProducts &&
                  FarmerListedProducts.map((item) => (
                    <ListGroupItem key={item._id}>
                      <h6>{item.name}</h6>
                      <Row>
                        <Col>
                          <Image
                            width={200}
                            src={item.image}
                            alt="product image"
                            fluid
                          />
                        </Col>
                        <Col width={30}>
                          <div>
                            {t("MaxPrice")} {item.price_max}
                          </div>
                          <div>
                            {t("MinPrice")} {item.price_min}
                          </div>
                          <div>
                            {t("Quantity")} {item.quantity}
                          </div>
                        </Col>
                        <Col>
                          <Button
                            onClick={() =>
                              navigate('/updatefarmproduct', {state:{itemId : item?.id}})
                            }
                          >
                            {t("EditProductdetails")}
                          </Button>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
              </li>
            </Card>
          </ul>
        </Col>
      </ListGroupItem>
      <ListGroupItem id="todaysales">
        <Col>
          <h5>{t("Todaysaledetails")}</h5>
          <text>{t("salesDetails")}</text>
        </Col>
      </ListGroupItem>

    </div>
   </>
  );
}
export default FarmerDashboard;
