// MainForm.jsx
import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import FarmerReg from "./FarmerReg";
import FarmerReg2 from "./FarmerReg2";
import FarmerReg3 from "./FarmerReg3";
import RegPayment from "../Payment/RegistrationPayment";
import axios from "axios";
import {
  API_URL_FARMERBYUSERID,
  API_URL_FARMERS,
} from "../Constants";
import { connect } from "react-redux";
import Loader from "../../../components/Loader";
import { withRouter } from "../../../components/Router/withRouter";

class MainFarmer extends Component {
  constructor(props) {
    super(props);
    const { navigate } = this.props;
    const { user } = this.props.user;

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${user.access}`,
      },
    };

    this.state = {
      step: 0,
      resStatus: false,
      navigate: navigate,
      firstName: user.first_name,
      lastName: user.last_name,
      fathersName: "",
      gender: "",
      mobileNo: null,
      mobileNoErr:'',
      idType: "",
      idTypeErr: "",
      idNo: "",
      idNoErr: "",
      rakba: null,
      rakba_unit: null,
      landParcelNo: "",
      address: "",
      villTownWard: "",
      society: "",
      freight: "",
      block: "",
      district: "",
      state: "",
      password: "",
      paymentMode: "",
      DOB: "",
      CWCID: null,
      userID: user.id,
      reduxState: {},
      config: config,
      setLoading: true,
    };
  }

  componentDidMount = () => {
    this.retrieve_Farmer();
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  redirect = (res) => {
    if (res === "YES") {
      this.setState({ step: 1 });
    } else if (res === "NO") {
      this.props.navigate("/");
    }
  };

  retrieve_Farmer = async () => {
    try {
      axios
        .get(`${API_URL_FARMERBYUSERID}`, this.state.config)
        .then((res) => {
          if (res.data.length > 0) {
            if (res.data[0].status) {
              this.setState({ setLoading: true, resStatus: true })
              this.props.navigate('/')
            } else {
              this.setState({
                firstName: res.data[0].firstName,
                lastName: res.data[0].lastName,
                fathersName: res.data[0].fathersName,
                gender: res.data[0].gender,
                mobileNo: res.data[0].mobileNo,
                idType: res.data[0].idType,
                idNo: res.data[0].idNo,
                rakba: res.data[0].rakba,
                landParcelNo: res.data[0].landParcelNo,
                rakba_unit: res.data[0].rakba_unit,
                address: res.data[0].address1,
                states: res.data[0].state,
                block: res.data[0].block,
                villTownWard: res.data[0].villTownWard,
                district: res.data[0].district,
                society: res.data[0].society,
                userID: res.data[0].userID,
                setLoading: false,
              });
            }
          } else {
            this.setState({ fresher: true, setLoading: false });
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  handleChange = (input, value) => {
    this.setState({ [input]: value });
  };

  uploadData = async () => {
    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      fathersName: this.state.fathersName,
      gender: this.state.gender,
      mobileNo: this.state.mobileNo,
      idType: this.state.idType,
      idNo: this.state.idNo,
      rakba: this.state.rakba,
      rakba_unit: this.state.rakba_unit,
      landParcelNo: this.state.landParcelNo,
      address1: this.state.address,
      state: this.state.states,
      district: this.state.district,
      block: this.state.block,
      villTownWard: this.state.villTownWard,
      society: this.state.society,
      paymentMode: this.state.paymentMode,
    };

    let response = [];

    await axios
      .post(API_URL_FARMERS, data, this.state.config)
      .then((res) => {
        this.setState({ setLoading: false });
        response = res.response;
      })
      .catch((err) => {
        response = err.response;
        this.setState({ setLoading: false });
      });

    return response;
  };

  render() {

    const { user } = this.props.user;
    const { step } = this.state;
    const {
      firstName,
      lastName,
      fathersName,
      gender,
      DOB,
      mobileNo,
      mobileNoErr,
      idType,
      idTypeErr,
      idNo,
      idNoErr,
      rakba,
      rakba_unit,
      landParcelNo,
      address,
      states,
      district,
      block,
      villTownWard,
      freight,
      society,
      paymentMode,
      CWCID,
    } = this.state;
    const values = {
      firstName,
      lastName,
      fathersName,
      gender,
      DOB,
      mobileNo,
      mobileNoErr,
      idType,
      idTypeErr,
      idNo,
      idNoErr,
      rakba,
      rakba_unit,
      landParcelNo,
      address,
      states,
      district,
      block,
      villTownWard,
      freight,
      society,
      paymentMode,
      CWCID,
    };
    switch (step) {
      case 0:
        return this.state.setLoading == true ? (
          <Loader />
        ) : this.state.resStatus ? (
          <>{this.setState({ step: 1 })}</>
        ) : (
          <>
            <Modal.Dialog>
              {/* <Modal.Header closeButton>
                      {/* <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header> */}
              {/* {console.log("status Res:  ", this.state.resStatus)} */}
              <Modal.Body>
                <h5>Do You want to complete your Profile ?</h5>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.redirect("NO")}>
                  No
                </Button>
                <Button variant="primary" onClick={() => this.redirect("YES")}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal.Dialog>
          </>
        );

      case 1:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <FarmerReg
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
          />
        );
      case 2:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <FarmerReg2
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            values={values}
            uploadData={this.uploadData}
          />
        );
      case 3:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <FarmerReg3
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            navigate={this.state.navigate}
            uploadData={this.uploadData}
          />
        );
      case 4:
        return this.state.setLoading == true ? (
          <Loader />
        ) : (
          <RegPayment
            //nextStep={this.nextStep}
            user={user}
            prevStep={this.prevStep}
            values={values}
            navigate={this.state.navigate}
            uploadData={this.uploadData}
          />
        );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default withRouter(connect(mapStateToProps)(MainFarmer));

// export default MainFarmer;
