import React from "react";
import OtpInput from "react-otp-input";
import img123 from "../../assets/otp.png";
import { Col, Container } from "react-bootstrap";
import { BackgroundGradientStyle } from "../../components/BackgoundGradient";
import {ExtraSmallButtonStyle} from "../../components/ButtonStyle";
import axios from "axios";
import {
  API_URL_OTP_VERIFY,
  API_URL_USERS,
  API_URL_OTP_SEND,
} from "./Constants";
import GA4 from "../../components/GoogleAnalytics4/GA-4";
import { withRouter } from "../../components/Router/withRouter";

class Otp extends React.Component {
  constructor(props) {
    super(props);
    const { location, navigate } = this.props;
    this.state = {
      _id: "",
      otp: "",
      logged_in: "",
      setLoading: false,
      fName: location?.state?.fName,
      lName: location?.state?.lName,
      email: location?.state?.email,
      mobile: location?.state?.mobile,
      role: location?.state?.role,
      navigate: navigate,
      matches: location?.state?.matches,
    };
  }

  verifyOTP = () => {
    this.setState({ setLoading: true });
    var postOtp = parseInt(this.state.otp);

    axios
      .post(`${API_URL_OTP_VERIFY}`, {
        otp: postOtp,
        mobile: this.state.mobile,
      })
      .then((res) => {
        this.setState({ setLoading: false });
        if (res.data==="pending") {
          alert("Please Enter Valid OTP");
        } else {
          this.passData();
          alert(
            "Login with credentials sent to " +
              this.state.email +
              "&" +
              this.state.mobile
          );
          this.state.navigate("/login");
        }
      })
      .catch((err) => {
        this.setState({ setLoading: true });
      });
  };

  passData = async () => {
    await this.handle_signup();
  };

  handle_signup = () => {
    this.setState({ setLoading: true });
    const data = {
      first_name: this.state.fName,
      last_name: this.state.lName,
      email: this.state.email,
      phone: this.state.mobile,
      role: this.state.role,
      is_staff: this.state.role==='vendor' ? true : false,
      groups: this.state.role==='vendor' ? [{"name" : "VENDOR"}] : [{"name" : "NA"}],
    };

    fetch(`${API_URL_USERS}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 400) {
          alert("Email and Phone number have already been used for this role.");
        } else {
          //console.log("Status:", res.status);
          // console.log(res.json())
          // this.roleform()
        }
      })

      let signUpDataGA4={
        first_name: this.state.fName,
        last_name: this.state.lName,
        email: this.state.email,
        phone: this.state.mobile,
        role: this.state.role,
      }
      GA4.signUp(signUpDataGA4)

  };

  handleChange = (otp) => this.setState({ otp });

  roleform = () => {
    this.props.navigation.navigate("Login");
  };

  ResendOPT = () => {
    const mobile = this.state.mobile;
    let phone_number = "91" + mobile;
    axios.post(`${API_URL_OTP_SEND}`, {
      phone_number: phone_number,
    });
  };

  render() {
    return (
      <div
        style={
          this.state.matches
            ? { backgroundColor: "white" }
            : BackgroundGradientStyle
        }
      >
        <Container style={this.state.matches ? BackgroundGradientStyle : null}>
          <div
            style={
              this.state.matches
                ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "15px 0px",
                  }
                : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px 25px",
                  }
            }
          >
            <div
              style={
                this.state.matches
                  ? {
                      backgroundColor: "white",
                      width: 750,
                      height: 650,
                      padding: "10px 25px",
                    }
                  : {
                      backgroundColor: "white",
                      width: 730,
                      height: 650,
                      padding: "10px 0px",
                    }
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Col>
                  <h2>Verify Mobile Number</h2>
                  <h5>
                    A verification code has been sent to {this.state.mobile}
                  </h5>
                  <img src={img123} alt="OTP" style={{}}></img>
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      direction: "column",
                    }}
                  >
                    <OtpInput
                      className="otpStyle"
                      value={this.state.otp}
                      onChange={this.handleChange}
                      numInputs={4}
                      separator={<span></span>}
                    />
                  </div>
                  <br />
                  <button
                    type="button"
                    style={{
                      backgroundColor: "transparent",
                      border: "0px",
                      marginBottom: "15px"
                    }}
                    onClick={this.ResendOPT}
                  >
                    Send again
                  </button>{" "}
                  <br />
                  <button
                    onClick={this.verifyOTP}
                    style={ExtraSmallButtonStyle}
                  >
                    Submit
                  </button>
                </Col>
              </div>
            </div>
          </div>
        </Container>
        {/* for countdown circle */}
      </div>
    );
  }
}

export default withRouter(Otp);
