import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import LanguageIcon from "@material-ui/icons/Language";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LogoutIcon from "@mui/icons-material/Logout";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
// import { logout } from "../actions/actionUser";
import { logout } from "../actions/auth.action";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";

export const MobileToolbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {user} = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function redirectMyAccount() {
    handleClose();
    if (user) {
      navigate("/account");
    } else {
      navigate("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  }

  function redirectMyDashbord() {
    handleClose();
    if (user) {
      navigate(`/${user.role}dashboard`);
    } else {
      navigate("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  }

  function redirectWishlist() {
    handleClose();
    if (user) {
      navigate("/wishlist");
    } else {
      navigate("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  }

  function redirectLogout() {
    handleClose();
    dispatch(logout());
    <Navigate to="/" />;
    setAnchorEl(null);
  }

  function redirectCart() {
    handleClose();
    if (user) {
      navigate("/cart");
    } else {
      navigate("/login");
      alert("Session expired or You are not logged in. Please login!");
    }
  }
  return (
    <List>
      <MenuItem>
        {user ? (
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            style={{ backgroundColor: "transparent" }}
            disableTouchRipple="true"
          >
            <AccountCircle />
            <Typography>{t("Hi") + ", " + user.first_name}</Typography>
          </IconButton>
        ) : (
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            style={{ backgroundColor: "transparent" }}
            onClick={() => navigate("/login")}
            disableTouchRipple="true"
          >
            <AccountCircle />
            <Typography>{t("Login")}</Typography>
          </IconButton>
        )}
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          style={{ backgroundColor: "transparent" }}
          disableTouchRipple="true"
          onClick={() => redirectMyAccount()}
        >
          <DashboardIcon
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          />
          <Typography>{t("MyAccount")}</Typography>
        </IconButton>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          style={{ backgroundColor: "transparent" }}
          disableTouchRipple="true"
          onClick={() => redirectMyDashbord()}
        >
          <DashboardIcon
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          />
          <Typography>{t("MyDashboard")}</Typography>
        </IconButton>
      </MenuItem>

      <MenuItem>
        <IconButton
          id="simple-menu"
          aria-label="show 4 new mails"
          color="inherit"
          style={{ backgroundColor: "transparent" }}
          disableTouchRipple="true"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          // onMouseOut={handleClose}
          onClick={handleClick}
        >
          <LanguageIcon
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          />
          <Typography> {t("Language")}</Typography>
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "left", horizontal: "center" }}
          transformOrigin={{ vertical: "left", horizontal: "center" }}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              i18n.changeLanguage("en");
              handleClose();
            }}
          >
            English
          </MenuItem>
          <MenuItem
            onClick={() => {
              i18n.changeLanguage("hi");
              handleClose();
            }}
          >
            हिंदी
          </MenuItem>
        </Menu>
        {/* <p>Messages</p> */}
      </MenuItem>

      <MenuItem>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          style={{ backgroundColor: "transparent" }}
          disableTouchRipple="true"
          onClick={() => redirectCart()}
        >
          <ShoppingCartIcon
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          />
          <Typography>{t("Cart")}</Typography>
        </IconButton>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-label="show 4 new mails"
          color="inherit"
          disableTouchRipple="true"
          style={{ backgroundColor: "transparent" }}
          onClick={() => redirectWishlist()}
        >
          <FavoriteBorderIcon
            disableTouchRipple="true"
            style={{ backgroundColor: "transparent" }}
          />
          <Typography>{t("Wishlist")}</Typography>
        </IconButton>
      </MenuItem>

      {
        user ? (
          <MenuItem>
            <IconButton
              aria-label="show 4 new mails"
              color="inherit"
              disableTouchRipple="true"
              style={{ backgroundColor: "transparent" }}
              onClick={() => redirectLogout()}
            >
              <LogoutIcon
                disableTouchRipple="true"
                style={{ backgroundColor: "transparent" }}
              />
              <Typography>{t("Logout")}</Typography>
            </IconButton>
          </MenuItem>
        ) : null //else
      }
    </List>
  );
};
