const initialState=
    {
        loading:false,
        error:false,
        wishlist:null,
    }

export default function(state=initialState, action){
    switch (action.type){
      case 'POST_WISHLIST_REQUEST':
        return {loading:true, error:null, wishlist:null};

      case 'POST_WISHLIST_SUCCESS':
        return {loading:false, error:null, wishlist:action.payload?.wishlist};

      case 'POST_WISHLIST_FAILED':
        return {loading:false, error:action.payload, wishlist:null};

      case 'DELETE_WISHLIST_REQUEST':
        return {loading:true, error:null, wishlist:null};

      case 'DELETE_WISHLIST_SUCCESS':
        return {loading:false, error:null, wishlist:action.payload?.wishlist};

      case 'DELETE_WISHLIST_FAILED':
        return {loading:false, error:action.payload, wishlist:null};

      default:
        return state;
    }
  };
  