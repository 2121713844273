const user = JSON.parse(localStorage.getItem('user'))
const initialState= user ?
    {
        user,
        isLoggedIn:true,
        loading:false,
        error:false
    }
    :
    {
        user:null,
        isLoggedIn:false,
        loading:false,
        error:false
    }

export default function(state=initialState, action){
    switch (action.type){
      case 'LOGIN_REQUEST':
        return {user:null, isLoggedIn:false, loading:true, error:null};

      case 'LOGIN_SUCCESS':
        return {user:action.payload, isLoggedIn:true, loading:false, error:null};

      case 'LOGIN_FAILED':
        return {user:null, isLoggedIn:false, loading:false, error:action.payload};

      case 'LOGOUT_REQUEST':
        return {user:null, isLoggedIn:false, loading:true, error:null};

      case 'LOGOUT_SUCCESS':
        return {user:null, isLoggedIn:false, loading:false, error:null};

      default:
        return state;
    }
  };
  