import React, { useState } from "react";
import { Row, Col, ListGroup, Button, Form, Modal } from "react-bootstrap";
import "react-dropdown-now/style.css";
import { SmallButtonStyle } from "../components/ButtonStyle";
import { BASE_URL_HOSTED } from "../constants/api";
import { SendQuotationMail } from "../actions/DashboardActions";
import { useDispatch } from "react-redux";

function AcceptPopup(props) {
  const [price, setPrice] = useState(props.price);
  const [qty, setQty] = useState(props.quantity);
  const dispatch = useDispatch();
  const Reconsidered_price = props.price;

  //   const { setModalShow } = props;

  const SaveData = () => {
    const uploadData = new FormData();
    uploadData.append("quotation_price", price);
    uploadData.append("status", "QuotationAccepted");

    fetch(`${BASE_URL_HOSTED}api/quotationstatus/${props.id}`, {
      method: "PUT",
      body: uploadData,
    })
      .then(
        (res) => console.log(""),
        alert(
          `Your have reconsidered the negotiation price offered by customer and offered a new price ie. ₹${price} it has been sent to the customer if he accept your offer we will notify you`
        ),
        dispatch(
          SendQuotationMail(
            0,
            qty,
            price,
            "QuotationAccepted",
            props.vendor_id,
            props.customer_id,
            props.pdt_name
          )
        ),
        props.setModalShow(false)
        // props.handleOffer()
      )
      .catch((error) => console.log(""));
  };

  // {console.log("price"+d)}
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Make a offer for Quotation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup.Item>
          <Row>
            <Col>Quantity:</Col>
            <Col xs="auto" className="my-1">
              {props.quantity}
            </Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item>
          <Row>
            <Col>Your Offered Price:</Col>
            <Col xs="auto" className="my-1">
              <Form.Control
                type="number"
                placeholder="Enter your age"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Col>
          </Row>
        </ListGroup.Item>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col>
            <Button style={SmallButtonStyle} onClick={props.onHide}>
              Close
            </Button>
          </Col>
          <Col>
            <Button style={SmallButtonStyle} onClick={() => SaveData()}>
              Offer
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}

export default AcceptPopup;
